import OkteloAI from "./okteloai";
import Advertising from "./advertising";
import CalendarPage from "./calendar";
import Home from "./home";
import Help from "./help";
import Settings from "./settings"
import EditPost from "./editpost";
import Campaign from "./campaign/campaign";
import CampaignCreator from "./campaign/campaign-creator";
import CampaignEditor from './campaign-editor';
import PageAnalytics from "./pageanalytics";
import CreateAI from "./createai";
import { LoginPage } from "./auth/_login";
import { RegisterPage } from "./auth/_register";
import { ForgotPasswordPage } from "./auth/_forgotpassword";
import ErrorPage from "./errorpage";
import CreateHUB from "./createhub";
import OkteloPayment from "./payments/payment";
import PaymentSuccess from "./payments/success";
import PreviewList from "./preview/previewlist";
import PreviewDisplay from "./preview/previewdisplay";
import PreviewCreate from "./preview/previewcreate";
import Websites from "./websites";
import SiteData from "./sitedata/sitedata";
import PostsPage from "./post/posts";
import PostViewDetails from "./post/postview";
import TagsHome from "./tags";
import PostEdit from "./post/postedit";
import SocialPosts from "./post/socialposts";
import CampaignDisplay from "./campaign/campaigndisplay";
import PostAnalytics from "./post/postanalytics";
import SocialPostAnalytics from "./post/socialpostanalytics";
import IGSiteData from "./sitedata/igsitedata";
import MyMedia from "./media/mymedia";
import ScheduledPosts from "./post/scheduledposts";

export {
    OkteloAI,
    Advertising,
    CalendarPage,
    Home,
    Help,
    Settings,
    EditPost,
    Campaign,
    CampaignCreator,
    CampaignEditor,
    PageAnalytics,
    LoginPage,
    ForgotPasswordPage,
    CreateAI,
    RegisterPage,
    ErrorPage,
    CreateHUB,
    OkteloPayment,
    PaymentSuccess,
    PreviewList,
    PreviewDisplay,
    PreviewCreate,
    Websites,
    SiteData,
    IGSiteData,
    PostsPage,
    PostViewDetails,
    TagsHome,
    PostEdit,
    CampaignDisplay,
    SocialPosts,
    PostAnalytics,
    SocialPostAnalytics,
    MyMedia,
    ScheduledPosts
};

