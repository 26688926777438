import React from "react";
import { Typography, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";

interface HeaderTextProps {
  spn: string;
  spp: string;
}


// Define the text map
const textMap: { [key: string]: string } = {
  "/calendar": "Naptár",
  "/posts/show/": "Bejegyzés részletek",
  "/home": "Vezérlőpult",
  "/settings": "Profil & Beállítások",
  "/approvals": "Jóváhagyások",
  "/create": "Poszt készítés",
  "/websites": "Weboldalak",
  "/analytics": "Elemzések",
  "/post-analytics": "Poszt elemzés",
  "/oktelohub": "Oktelo hub",
  "/campaign": "Kampányok",
  "/success": "Sikeres fizetés",
  "/ads": "Hirdetések",
  "/props": "Sablonok",
  "/subscription": "Előfizetés",
  "/campaign/create": "Kampánykészítő",
  "/postanalytics": "Poszt elemzés",
  "/posts/social": "Közösségi posztok",
  "/posts": "Bejegyzések",
  "/site": "Oktelo Analytics",
  "/mymedia": "Média és tervek",
  "/help": "Segítség"
};

// Function to get the text based on the current location
const getHeaderText = (pathname: string) => {
  // Check for exact match first
  if (textMap[pathname]) {
    return textMap[pathname];
  }
  // Check for paths that contain specific segments (e.g., "/posts/show/:id")
  for (const key in textMap) {
    if (pathname.startsWith(key)) {
      return textMap[key];
    }
  }
  return "Oktelo"; // Fallback text
};


const HeaderText: React.FC<HeaderTextProps> = ({ spn, spp }) => {

  const location = useLocation();
  const headerText = getHeaderText(location.pathname);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography className="size_18 t_oktelo_medium darkblue">
        {headerText}
      </Typography>
    </Stack>
  );
};

export default HeaderText;
