import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Box,
  Stack,
  Typography,
  Avatar,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import {
  CalendarMonthOutlined,
  ChatBubbleOutline,
  Delete,
  EditNoteOutlined,
  Facebook,
  InboxOutlined,
  Instagram,
  LabelOutlined,
  LinkedIn,
  SubjectOutlined,
  TagOutlined,
} from "@mui/icons-material";
import { OkteloPostProps } from "interfaces/oktelo";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import "../../components/styles/editpost.css";
import { Radio, DatePicker, Tag, Upload, Input, Checkbox } from "antd";
import { LinkPlaceholder, VideoPlaceholder, okt_placeholer } from "assets";
import dayjs, { Dayjs } from "dayjs";
import { PictureOutlined, TagsOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";
import type { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { useNotification } from "@refinedev/core";
import apiconfig from "config";

type SocialPage = {
  id: string;
  name: string;
  platform: string;
  photo: string;
};

type UserPagesData = {
  allPages: SocialPage[];
};

type UserTags = {
  id: string;
  title: string;
  color: string;
  posts: number;
};

type UserHashtags = {
  id: string;
  title: string;
  hashtags: string[];
  posts?: number;
};

interface Gallery extends UploadFile {
  name: string;
  url: string;
  type: string;
  uid: string;
}

const PostEdit = () => {
  const navigate = useNavigate();
  document.title = "Bejegyzés szerkesztő | Oktelo App";
  const { id } = useParams();
  const { open } = useNotification();
  const [postData, setPostData] = useState<OkteloPostProps>();
  const [isLoading, setLoading] = useState(false);
  const [socialPages, setSocialPages] = useState<UserPagesData>({
    allPages: [],
  });
  const [userHashtags, setUserHashtags] = useState<UserHashtags[]>([]);
  const [userTags, setUserTags] = useState<UserTags[]>([]);
  const [parentPhoto, setParentPhoto] = useState<string>(okt_placeholer);

  /* Post data */
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState({
    id: "",
    name: "",
    platform: "",
    photo: "",
  });
  const [selectedMessage, setSelectedMessage] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState({
    id: "",
    title: "",
    color: "",
  });
  const [selectedHashtag, setSelectedHashtag] = useState<UserHashtags>({
    id: "",
    title: "",
    hashtags: [],
  });
  const [postDate, setPostDate] = useState<Dayjs>();
  const [postDateType, setPostDateType] = useState("");
  const [postAttachmentType, setPostAttachmentType] = useState("");
  const [postAttachment, setPostAttachment] = useState({
    photoUrl: "",
    videoUrl: "",
    thumbnailUrl: "",
    webUrl: "",
    carouselUrls: [],
  });

  const [postMedia, setPostMedia] = useState([
    { name: "", url: "", type: "", uid: "" },
  ]);
  const [galleryPhotos, setGalleryPhotos] = useState<any[]>([]);

  useEffect(() => {
    setPostMedia([{ name: "", url: "", type: "", uid: "" }]);
    setGalleryPhotos([]);
  }, [postAttachmentType]);

  const [postComment, setPostComment] = useState({
    enabled: false,
    message: "",
  });

  /* Select States */
  const [selectedEdit, setSelectedEdit] = useState("media");

  /* send post */

  const saveModifiedPost = async () => {
    try {
      const payload = {
        message: selectedMessage,
        platform: selectedPlatform,
        page: selectedPage.id,
        tag: selectedTag.id,
        hashtag: selectedHashtag.id,
        date: dayjs.utc(postDate).toISOString(),
        dateType: postDateType,
        attachment: postMedia[0],
        attachmentType: postAttachmentType,
        gallery: galleryPhotos,
        comment: postComment,
      }
      
      const response = await axios.patch(`${apiconfig.BACKEND_URI}/posts/edit/${postData?._id}`, payload)

      if (response.status === 200){
        open?.({
          type: 'success',
          message: 'Sikeres módosítás'
        })
      }

    } catch (error) {
      console.log(error);
    }
  }


  /* Change handlers */
  const rSelectTag = (id: any) => {
    const newtag = userTags.find((tag) => tag.id === id);
    if (newtag) {
      setSelectedTag({
        id: newtag.id,
        title: newtag.title,
        color: newtag.color,
      });
    }
  };

  const rSelectPage = (id: any) => {
    const newpage = socialPages.allPages.find((page) => page.id === id);
    if (newpage) {
      setSelectedPage({
        id: newpage.id,
        name: newpage.name,
        platform: selectedPage.platform,
        photo: newpage.photo,
      });
    }
  };

  const rSelectHashtag = (id: any) => {
    const ht = userHashtags.find((tag) => tag.id === id);
    if (ht) {
      setSelectedHashtag({
        id: ht.id,
        title: ht.title,
        hashtags: ht.hashtags,
      });
    } else {
      setSelectedHashtag({
        id: "",
        title: "",
        hashtags: [],
      });
    }
  };

  const getPostData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiconfig.BACKEND_URI}/data/postedit/${id}`
      );
      if (response.status === 200) {
        setPostData(response.data.post);
        setParentPhoto(response.data.parentPhoto);

        const postInit = response.data.post;

        if (postInit) {
          //Set editable props
          setSelectedPlatform(postInit.platform);
          if (postInit.parent) {
            const _photo =
              socialPages.allPages.find(
                (page) => page.id === postInit.parent.id
              )?.photo || "";
            const _platform =
              socialPages.allPages.find(
                (page) => page.id === postInit.parent.id
              )?.platform || "";
            setSelectedPage({
              id: postInit.parent.id,
              name: postInit.parent.name,
              platform: _platform,
              photo: _photo,
            });
          }
          setSelectedMessage(postInit.message);
          console.log(postInit.tag);
          if (postInit.tag) {
            setSelectedTag({
              id: postInit.tag._id,
              title: postInit.tag.title,
              color: postInit.tag.color,
            });
          }
          if (postInit.attachment.type) {
            setPostAttachmentType(postInit.attachment.type);
          }
          if (postInit.attachment.url) {
            setPostAttachment(postInit.attachment.url);
          }
          if (postInit.firstComment && postInit.firstComment.message) {
            setPostComment({
              enabled: postInit.firstComment.enabled,
              message: postInit.firstComment.message,
            });
          }
          if (postInit.hashtagTemplate) {
            setSelectedHashtag({ id: postInit.hashtagTemplate._id, title: postInit.hashtagTemplate.title, hashtags: postInit.hashtagTemplate.hashtags})
          }
        }
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const getSocialPages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiconfig.BACKEND_URI}/data/socials`);
      if (response.status === 200) {
        const allFacebookPages = response.data.facebookPages.map(
          (page: {
            id: string;
            name: string;
            platform: string;
            photo: string;
          }) => ({
            id: page.id,
            name: page.name,
            platform: "facebook",
            photo: page.photo,
          })
        );

        const allInstagramPages = response.data.instagramPages.map(
          (page: {
            id: string;
            name: string;
            platform: string;
            photo: string;
          }) => ({
            id: page.id,
            name: page.name,
            platform: "instagram",
            photo: page.photo,
          })
        );

        const allLinkedinPages = response.data.linkedinPages.map(
          (page: {
            id: string;
            name: string;
            platform: string;
            photo: string;
          }) => ({
            id: page.id,
            name: page.name,
            platform: "linkedin",
            photo: page.photo,
          })
        );

        const userPagesData: UserPagesData = {
          allPages: [
            ...allFacebookPages,
            ...allInstagramPages,
            ...allLinkedinPages,
          ],
        };

        setSocialPages(userPagesData);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const getUserHashtags = async () => {
    try {
      const _hashtags = await axios.get(
        `${apiconfig.BACKEND_URI}/tags/hashtags`
      );

      if (_hashtags.status === 200) {
        setUserHashtags(_hashtags.data);
      } else {
        setUserHashtags([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserTags = async () => {
    try {
      const response = await axios.get(`${apiconfig.BACKEND_URI}/tags`);

      if (response.status === 200) {
        setUserTags(response.data);
      } else {
        setUserTags([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPostData();
    getSocialPages();
    getUserHashtags();
    getUserTags();
  }, []);

  useEffect(() => {
    if(selectedPlatform !== postData?.platform){
    setSelectedPage({id: '', name: '', platform: '', photo: ''})
    }
  }, [selectedPlatform])

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];

    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const AttachmentTextMap: Record<string, string> = {
    textOnly: "Szöveg",
    photo: "Kép",
    video: "Videó",
    carousel: "Galéria",
    webUrl: "Hivatkozás",
    story: "Történet",
    default: "Nem elérhető",
  };

  const PlatformTextMap: Record<string, JSX.Element> = {
    facebook: (
      <Typography
        className="t_oktelo_semibold size_20"
        sx={{ color: "#1e90ff" }}
      >
        Facebook
      </Typography>
    ),
    instagram: (
      <Typography
        className="t_oktelo_semibold size_20"
        sx={{ color: "#E4405F" }}
      >
        Instagram
      </Typography>
    ),
    linkedin: (
      <Typography
        className="t_oktelo_semibold size_20"
        sx={{ color: "#0077B5" }}
      >
        LinkedIn
      </Typography>
    ),
    default: (
      <Typography
        className="t_oktelo_semibold size_20"
        sx={{ color: "#925FFF" }}
      >
        Platform
      </Typography>
    ),
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return okt_placeholer;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };


  const hNewImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return okt_placeholer;
    } else if (type === "photo" || type === "story") {
      return url;
    } else if (type === "video") {
      return VideoPlaceholder;
    } else if (type === "carousel") {
      return galleryPhotos[0].url;
    } else if (type === "webUrl") {
      return checkIfImageUrl(url) ? url : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };


  const PostDateMap: Record<string, string> = {
    draft: "Nincs dátum (Vázlat)",
    calendarDraft:
      dayjs(postData?.calendarDate).format("YYYY.MM.DD HH:mm") +
      " (Csak naptárban)",
    scheduledWithPublish:
      dayjs(postData?.publish.date).format("YYYY.MM.DD HH:mm") + " (Ütemezve)",
    published:
      dayjs(postData?.publish.date).format("YYYY.MM.DD HH:mm") + " (Közzétéve)",
    errorWithPublishing: "Hiba a közzétételnél",
    unPublished: "Visszavont közzététel",
    default: "Nincs dátum",
  };

  const hAttachmentText = (type: string) =>
    AttachmentTextMap[type] || AttachmentTextMap.default;
  const hPlatformText = (type: string) =>
    PlatformTextMap[type] || PlatformTextMap.default;
  const hPostDate = (type: string) => PostDateMap[type] || PostDateMap.default;

  const handleImageChange = (file: File | string, type: string) => {
    if (file instanceof File) {
      const reader = (readFile: File) =>
        new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(readFile);
        });

      reader(file).then((result: string) =>
        setPostMedia([{ name: file.name, url: result, type: type, uid: "-1" }])
      );
    }
  };
  const handleUploadChange = (
    info: UploadChangeParam<UploadFile<any>>,
    type: string
  ) => {
    const file = info.fileList[0]?.originFileObj as RcFile | undefined;
    if (file) {
      handleImageChange(file, type);
    }
  };

  const handleMultipleUploadChange = (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const files = info.fileList
      .map((file) => file.originFileObj)
      .filter(Boolean) as File[];
    handleCarouselChange(files);
  };

  const handleCarouselChange = (files: File[]) => {
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            name: file.name,
            url: reader.result as string, // Ensure reader.result is typed correctly
            uid: `${file.name}-${file.lastModified}-${file.size}`, // Example of creating a unique uid
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file); // Read file as Data URL
      });
    });

    Promise.all(promises)
      .then((results) => {
        console.log("Promise.all results: ", results);
        setGalleryPhotos(results);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
        // Handle error if FileReader fails to read files
      });
  };

  const handleRemoveGallery = (file: any) => {
    console.log("Clicked remove photo:", file);
    // Remove the photo from galleryPhotos based on uid
    setGalleryPhotos((prevPhotos) =>
      prevPhotos.filter((photo) => photo.uid !== file.uid)
    );
  };

  useEffect(() => {
    console.log("galleryPhotos state changed: ", galleryPhotos);
  }, [galleryPhotos]);

  const renderUploadComponent = () => {
    switch (postAttachmentType) {
      case "photo":
        return (
          <Upload
            onChange={(e) => handleUploadChange(e, "photo")}
            accept="image/*"
            type="drag"
            listType="picture-card"
            beforeUpload={() => false}
            className="upload-list-inline"
            multiple={false}
            maxCount={1}
            onRemove={() =>
              setPostMedia([{ name: "", url: "", type: "", uid: "-1" }])
            }
            fileList={postMedia[0].url !== "" ? postMedia : []}
          >
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
              <Box textAlign="left">
                <Typography className="t_oktelo t_purple t_highlight">
                  Kép kiválasztása
                </Typography>
                <Typography className="t_oktelo t_misc">
                  Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
                </Typography>
              </Box>
            </Stack>
          </Upload>
        );
      case "video":
        return (
          <Upload
            onChange={(e) => handleUploadChange(e, "video")}
            accept="video/*"
            type="drag"
            listType="picture-card"
            beforeUpload={() => false}
            className="upload-list-inline"
            multiple={false}
            maxCount={1}
            onRemove={() =>
              setPostMedia([{ name: "", url: "", type: "", uid: "-1" }])
            }
            fileList={postMedia[0].url !== "" ? postMedia : []}
          >
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
              <Box textAlign="left">
                <Typography className="t_oktelo t_purple t_highlight">
                  Videó kiválasztása
                </Typography>
                <Typography className="t_oktelo t_misc">
                  Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
                </Typography>
              </Box>
            </Stack>
          </Upload>
        );
      case "carousel":
        return (
          <Upload
            onChange={({ fileList, file }) => {
              // Handle removals
              const removedFile = fileList.find((f: any) => !f.status);
              if (removedFile) {
                handleRemoveGallery(removedFile);
              }

              // Handle uploads
              handleCarouselChange(fileList.map((f: any) => f.originFileObj));
            }}
            accept="image/*"
            type="drag"
            listType="picture"
            onRemove={handleRemoveGallery}
            fileList={galleryPhotos.map((photo, index) => ({
              ...photo,
              uid: photo.uid || `-${index}`,
              status: "done",
            }))}
            className="upload-list-inline"
            multiple={true}
            beforeUpload={() => false}
            maxCount={5}
            defaultFileList={[]}
          >
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
              <Box textAlign="left">
                <Typography className="t_oktelo t_purple t_highlight">
                  Képek
                </Typography>
                <Typography className="t_oktelo t_misc">
                  Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
                </Typography>
              </Box>
            </Stack>
          </Upload>
        );
      case "webUrl":
        return (
          <>
          <Input
            type="url"
            placeholder="https://url-poszt.hu"
            onChange={(e) =>
              setPostMedia([
                { name: "", url: e.target.value, type: "url", uid: "-1" },
              ])
            }
            style={{ marginBottom: '10px'}}
          />
          <button className="home-action-button bc6a"
          disabled={!postMedia[0].url.includes('https')}
          onClick={() => window.open(postMedia[0].url, '_blank')}>
            {!postMedia[0].url.includes('https') ? 'Hibás link' : 'Link megnyitása'}
          </button>
          </>
        );
      case "story":
        return (
          <Upload
            onChange={(e) => handleUploadChange(e, "story")}
            accept="image/*,video/*"
            type="drag"
            listType="picture-card"
            beforeUpload={() => false}
            className="upload-list-inline"
            multiple={false}
            maxCount={1}
            onRemove={() =>
              setPostMedia([{ name: "", url: "", type: "", uid: "-1" }])
            }
            fileList={postMedia[0].url !== "" ? postMedia : []}
          >
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
              <Box textAlign="left">
                <Typography className="t_oktelo t_purple t_highlight">
                  Történet kiválasztása
                </Typography>
                <Typography className="t_oktelo t_misc">
                  Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
                </Typography>
              </Box>
            </Stack>
          </Upload>
        );
      default:
        return null;
    }
  };

  if (postData && !isLoading) {
    const filteredPages = socialPages.allPages.filter(
      (page) =>
        page.platform ===
        (selectedPlatform === "" ? "facebook" : selectedPlatform)
    );

    return (
      <Box width="100%">
        <Stack direction="row" alignItems="center" gap={1.5} mb={1.5}>
          <Avatar
            sx={{ width: "30px", height: "30px", backgroundColor: "#efe5ef" }}
          >
            <EditNoteOutlined sx={{ fontSize: "16px", color: "#925fff" }} />
          </Avatar>
          <Typography className="t_oktelo_bold size_24 darkblue">
            Bejegyzés szerkesztése
          </Typography>
        </Stack>
        <Typography className="t_oktelo_normal size_14 darkblue" mb={3}>
          Kattints arra az elemre, amelyet szerkeszteni szeretnél
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid
            item
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="edit-screen-container"
          >
            <Box className="edit-screen" width="100%">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="edit-section"
                mb={1}
                onClick={() => setSelectedEdit("main")}
              >
                {hPlatformText(selectedPlatform)}
                <Stack direction="row" alignItems="center" gap={1}>
                  <Tag
                    className="tag_oktelo size_12 t_oktelo_medium"
                    color="purple"
                    style={{ padding: "4px 12px" }}
                    icon={<PictureOutlined />}
                  >
                    {hAttachmentText(postAttachmentType)}
                  </Tag>
                  {selectedTag && selectedTag.title !== "" && (
                    <Tag
                      className="tag_oktelo size_12 t_oktelo_medium"
                      color="#f9f9f9"
                      style={{
                        border: "1px solid",
                        borderColor: selectedTag.color,
                        color: selectedTag.color,
                        padding: "4px 12px",
                      }}
                      icon={<TagsOutlined />}
                    >
                      {selectedTag.title}
                    </Tag>
                  )}
                </Stack>
              </Stack>
              {selectedPlatform === "facebook" ? (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    className=""
                  >
                    <>
                      <Avatar
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid #d9d9d9",
                        }}
                        src={selectedPage.photo || okt_placeholer}
                      ></Avatar>
                      <Typography className="t_oktelo_medium size_16 darkblue">
                        {selectedPage.name ? selectedPage.name : "Vázlat oldal"}
                      </Typography>
                    </>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    className="edit-section"
                    onClick={() => setSelectedEdit("date")}
                  >
                    <CalendarMonthOutlined
                      sx={{
                        fontSize: "16px",
                        color: postDate ? "#989898" : "#1e1e47",
                      }}
                    />
                    <Typography
                      className="t_oktelo_normal size_14"
                      color={postDate ? "#989898" : "#1e1e47"}
                    >
                      {hPostDate(postData.status)}
                    </Typography>
                  </Stack>
                  {postDate && (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CalendarMonthOutlined sx={{ fontSize: "16px", color: "#925FFF" }} />
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        {dayjs(postDate).format("YYYY.MM.DD HH:mm")}{" "}
                        {postDateType === "draft"
                          ? "(Vázlat)"
                          : "(Közzététel/ütemezés)"}
                      </Typography>
                    </Stack>
                  )}
                  <Stack
                    direction="column"
                    gap={1}
                    className="edit-section"
                    onClick={() => setSelectedEdit("message")}
                  >

                    {postData.message !== selectedMessage && (
                      <Stack direction="row" alignItems="center" gap={1}>
                      <SubjectOutlined sx={{ fontSize: "16px" }} />
                      <Typography
                        className="t_oktelo_normal size_14"
                        color="#989898"
                      >
                        {postData.message}
                      </Typography>
                      </Stack> 
                    )}
                        <Stack direction="row" alignItems="center" gap={1}>
                        <SubjectOutlined sx={{ fontSize: "16px", color: "#925FFF" }} />                  
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {selectedMessage || "Nem adtál meg szöveges tartalmat."}
                    </Typography>
                    </Stack>
                    {selectedHashtag &&
                      selectedHashtag.hashtags &&
                      selectedHashtag.hashtags.length > 0 && (

                        <Typography
                          className="t_oktelo_normal size_14"
                          sx={{ color: "#1e90ff" }}
                        >
                          {selectedHashtag.hashtags.join(" ")}
                        </Typography>

                      )}

                  </Stack>
                  {postAttachmentType !== "textOnly" && (
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    p={1}
                    sx={{ borderRadius: "6px", border: "1px solid #d9d9d9" }}
                  >
                    {postAttachment.photoUrl && (
                      <>
                        <Avatar
                          sx={{ width: "40px", height: "40px" }}
                          src={postAttachment.photoUrl}
                        />
                        <Typography
                          className="size_12 t_oktelo_normal"
                          sx={{
                            wordBreak: "break-all",
                            cursor: "pointer",
                            ":hover": { color: "#925FFF" },
                          }}
                          onClick={() =>
                            window.open(postAttachment.photoUrl, "_blank")
                          }
                        >
                          {postAttachment.photoUrl.slice(0, 64)}
                        </Typography>
                      </>
                    )}
                    {postAttachment.thumbnailUrl && (
                      <>
                        <Avatar
                          sx={{ width: "40px", height: "40px" }}
                          src={postAttachment.thumbnailUrl}
                        />
                        <Typography
                          className="size_12 t_oktelo_normal"
                          sx={{
                            wordBreak: "break-all",
                            cursor: "pointer",
                            ":hover": { color: "#925FFF" },
                          }}
                          onClick={() =>
                            window.open(postAttachment.thumbnailUrl, "_blank")
                          }
                        >
                          {postAttachment.thumbnailUrl.slice(0, 90)}
                        </Typography>
                      </>
                    )}
                    {postAttachment.webUrl &&
                      checkIfImageUrl(postAttachment.webUrl) && (
                        <>
                          <Avatar
                            sx={{ width: "40px", height: "40px" }}
                            src={postAttachment.webUrl}
                          />
                          <Typography
                            className="size_12 t_oktelo_normal"
                            sx={{
                              wordBreak: "break-all",
                              cursor: "pointer",
                              ":hover": { color: "#925FFF" },
                            }}
                            onClick={() =>
                              window.open(postAttachment.webUrl, "_blank")
                            }
                          >
                            {postAttachment.webUrl.slice(0, 90)}
                          </Typography>
                        </>
                      )}
                     {postAttachment.carouselUrls &&
                      postAttachment.carouselUrls.map((photo, index) => (
                        <Avatar sx={{ width: '40px', height: '40px'}}
                        key={photo+index}
                        src={photo}/>
                      ))
                     }
                  </Stack>
                )}
                  {postAttachmentType !== 'textOnly' && postAttachmentType !== 'carousel' &&
                  <Stack
                    direction="column"
                    sx={{
                      height: "450px",
                      width: "100%",
                      backgroundColor: "#f6f6f6",
                      position: "relative",
                    }}
                    className="edit-section"
                    onClick={() => setSelectedEdit("media")}
                  >
                    {postMedia[0].url === "" ? (
                      <img
                        src={hImageSrc(
                          postData.attachment.type,
                          postData.attachment.url
                        )}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    ) : postMedia[0].type !== "video" ? (
                      <img
                        src={hNewImageSrc(postAttachmentType, postMedia[0].url)}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <video width="100%" height="100%" controls>
                        <source src={postMedia[0].url} />
                      </video>
                    )}
                  </Stack>}
                  {(postData.attachment.type === "carousel" || postAttachmentType === "carousel") &&
                  <Grid container spacing={1} 
                  className="edit-section"
                    onClick={() => setSelectedEdit("media")}>
                  {(postAttachmentType === 'carousel' &&
                    galleryPhotos && galleryPhotos.length > 0) ?

                      galleryPhotos.map((photo, index) => (
                        <Grid item xs={ index === 0 ? 12 : 6} key={index}>
                          <img
                            key={photo.name}
                            src={photo.url}
                            alt={`facebook post preview ${index + 1}`}
                            style={{ 
                              borderRadius: '0px',
                              height: index === 0 ? '400px' : '200px',
                              width: '100%'
                            }}
                            className="gallery-edit-photo"
                          />
                        </Grid>
                      ))  : postAttachmentType === 'carousel' &&
                      postData.attachment.url && postData.attachment.url.carouselUrls && 
                      postData.attachment.url.carouselUrls.map((photo, index) => (
                        <Grid item xs={ index === 0 ? 12 : 6} key={index}>
                        <img
                          key={photo}
                          src={photo}
                          alt={`facebook post preview ${index + 1}`}
                          style={{ 
                            borderRadius: '0px',
                            height: index === 0 ? '400px' : '200px',
                            width: '100%'
                          }}
                          className="gallery-edit-photo"
                        />
                      </Grid>
                      ))
                  }
                  </Grid>}
                </>
              ) : selectedPlatform === "instagram" ?
              <>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                className=""
              >
                <>
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      border: "1px solid #d9d9d9",
                    }}
                    src={selectedPage.photo || okt_placeholer}
                  ></Avatar>
                  <Typography className="t_oktelo_medium size_16 darkblue">
                    {selectedPage.name ? selectedPage.name : "Vázlat oldal"}
                  </Typography>
                </>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                className="edit-section"
                onClick={() => setSelectedEdit("date")}
              >
                <CalendarMonthOutlined
                  sx={{
                    fontSize: "16px",
                    color: postDate ? "#989898" : "#1e1e47",
                  }}
                />
                <Typography
                  className="t_oktelo_normal size_14"
                  color={postDate ? "#989898" : "#1e1e47"}
                >
                  {hPostDate(postData.status)}
                </Typography>
              </Stack>
              {postDate && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <CalendarMonthOutlined sx={{ fontSize: "16px", color: "#925FFF" }} />
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    {dayjs(postDate).format("YYYY.MM.DD HH:mm")}{" "}
                    {postDateType === "draft"
                      ? "(Vázlat)"
                      : "(Közzététel/ütemezés)"}
                  </Typography>
                </Stack>
              )}
              {postAttachmentType !== "textOnly" && (
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                p={1}
                sx={{ borderRadius: "6px", border: "1px solid #d9d9d9" }}
              >
                {postAttachment.photoUrl && (
                  <>
                    <Avatar
                      sx={{ width: "40px", height: "40px" }}
                      src={postAttachment.photoUrl}
                    />
                    <Typography
                      className="size_12 t_oktelo_normal"
                      sx={{
                        wordBreak: "break-all",
                        cursor: "pointer",
                        ":hover": { color: "#925FFF" },
                      }}
                      onClick={() =>
                        window.open(postAttachment.photoUrl, "_blank")
                      }
                    >
                      {postAttachment.photoUrl.slice(0, 64)}
                    </Typography>
                  </>
                )}
                {postAttachment.thumbnailUrl && (
                  <>
                    <Avatar
                      sx={{ width: "40px", height: "40px" }}
                      src={postAttachment.thumbnailUrl}
                    />
                    <Typography
                      className="size_12 t_oktelo_normal"
                      sx={{
                        wordBreak: "break-all",
                        cursor: "pointer",
                        ":hover": { color: "#925FFF" },
                      }}
                      onClick={() =>
                        window.open(postAttachment.thumbnailUrl, "_blank")
                      }
                    >
                      {postAttachment.thumbnailUrl.slice(0, 90)}
                    </Typography>
                  </>
                )}
                {postAttachment.webUrl &&
                  checkIfImageUrl(postAttachment.webUrl) && (
                    <>
                      <Avatar
                        sx={{ width: "40px", height: "40px" }}
                        src={postAttachment.webUrl}
                      />
                      <Typography
                        className="size_12 t_oktelo_normal"
                        sx={{
                          wordBreak: "break-all",
                          cursor: "pointer",
                          ":hover": { color: "#925FFF" },
                        }}
                        onClick={() =>
                          window.open(postAttachment.webUrl, "_blank")
                        }
                      >
                        {postAttachment.webUrl.slice(0, 90)}
                      </Typography>
                    </>
                  )}
                 {postAttachment.carouselUrls &&
                  postAttachment.carouselUrls.map((photo, index) => (
                    <Avatar sx={{ width: '40px', height: '40px'}}
                    key={photo+index}
                    src={photo}/>
                  ))
                 }
              </Stack>
            )}
              {postAttachmentType !== 'textOnly' && postAttachmentType !== 'carousel' &&
              <Stack
                direction="column"
                sx={{
                  height: "500px",
                  width: "500px",
                  backgroundColor: "#f6f6f6",
                  position: "relative",
                  aspectRatio: "1 / 1",
                }}
                className="edit-section"
                onClick={() => setSelectedEdit("media")}
              >
                {postMedia[0].url === "" ? (
                  <img
                    src={hImageSrc(
                      postData.attachment.type,
                      postData.attachment.url
                    )}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : postMedia[0].type !== "video" ? (
                  <img
                    src={hNewImageSrc(postAttachmentType, postMedia[0].url)}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <video width="100%" height="100%" controls>
                    <source src={postMedia[0].url} />
                  </video>
                )}
              </Stack>}
              {(postData.attachment.type === "carousel" || postAttachmentType === "carousel") &&
              <Grid container spacing={1} 
              className="edit-section"
                onClick={() => setSelectedEdit("media")}>
              {(postAttachmentType === 'carousel' &&
                galleryPhotos && galleryPhotos.length > 0) ?

                  galleryPhotos.map((photo, index) => (
                    <Grid item xs={ index === 0 ? 12 : 6} key={index}>
                      <img
                        key={photo.name}
                        src={photo.url}
                        alt={`facebook post preview ${index + 1}`}
                        style={{ 
                          borderRadius: '0px',
                          height: index === 0 ? '400px' : '200px',
                          width: '100%'
                        }}
                        className="gallery-edit-photo"
                      />
                    </Grid>
                  ))  : postAttachmentType === 'carousel' &&
                  postData.attachment.url && postData.attachment.url.carouselUrls && 
                  postData.attachment.url.carouselUrls.map((photo, index) => (
                    <Grid item xs={ index === 0 ? 12 : 6} key={index}>
                    <img
                      key={photo}
                      src={photo}
                      alt={`facebook post preview ${index + 1}`}
                      style={{ 
                        borderRadius: '0px',
                        height: index === 0 ? '400px' : '200px',
                        width: '100%'
                      }}
                      className="gallery-edit-photo"
                    />
                  </Grid>
                  ))
              }
              
              </Grid>}
              <Stack
                direction="column"
                gap={1}
                className="edit-section"
                onClick={() => setSelectedEdit("message")}
              >

                {postData.message !== selectedMessage && (
                  <Stack direction="row" alignItems="center" gap={1}>
                  <SubjectOutlined sx={{ fontSize: "16px" }} />
                  <Typography
                    className="t_oktelo_normal size_14"
                    color="#989898"
                  >
                    {postData.message}
                  </Typography>
                  </Stack> 
                )}
                    <Stack direction="row" alignItems="center" gap={1}>
                    <SubjectOutlined sx={{ fontSize: "16px", color: "#925FFF" }} />                  
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {selectedMessage || "Nem adtál meg szöveges tartalmat."}
                </Typography>
                </Stack>
                {selectedHashtag &&
                  selectedHashtag.hashtags &&
                  selectedHashtag.hashtags.length > 0 && (

                    <Typography
                      className="t_oktelo_medium size_14"
                      sx={{ color: "#1e1e47" }}
                    >
                      {selectedHashtag.hashtags.join(" ")}
                    </Typography>

                  )}

              </Stack>
            </>
            
              :
              <Box>

              </Box>
              }
              <Stack direction="column" gap={1.5}>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Első megjegyzés
                </Typography>
                <Stack
                  direction="column"
                  className="edit-section"
                  gap={1}
                  onClick={() => setSelectedEdit("comment")}
                >

                  {postData.firstComment && postData.firstComment.enabled ? (
                    <Stack direction="row" alignItems="center" gap={1}>
                    <ChatBubbleOutline sx={{ fontSize: "16px" }} />
                    <Typography
                      className="t_oktelo_normal size_14"
                      sx={{
                        color: postComment.message !== postData.firstComment?.message ? "#989898" : "#1e1e47",
                      }}
                    >
                      {postData.firstComment.message}
                    </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="center" gap={1}>
                    <ChatBubbleOutline sx={{ fontSize: "16px" }} />
                    <Typography className="t_oktelo_normal size_14"
                      sx={{
                        color: postComment.enabled ? "#989898" : "#1e1e47",
                      }}>
                      Nincs hozzáadva
                    </Typography>
                    </Stack>
                  )}
                  {postComment.enabled && postComment.message !== postData.firstComment?.message && (
                    <Stack direction="row" alignItems="center" gap={1}>
                    <ChatBubbleOutline sx={{ fontSize: "16px", color: "#925FFF" }} />
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {postComment.message}
                    </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center" width="100%" justifyContent="flex-end">
              <button className="home-action-button b925" onClick={() => saveModifiedPost()}>
                Módosítások mentése
              </button>
              <button className="home-action-button bc6a"
              onClick={() => navigate(`/posts/show/${postData._id}`)}>
                Mégsem
              </button>
              </Stack>
            </Box>
          </Grid>
          {selectedEdit === "main" && (
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="edit-screen-container"
            >
              <Box className="edit-tab" width="100%">
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Platform
                </Typography>
                <Select
                  defaultValue={postData.platform}
                  displayEmpty
                  onChange={(e) => setSelectedPlatform(e.target.value)}
                  fullWidth
                  sx={{ marginBottom: "18px" }}
                  className="editpost-select"
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "350px", overflowY: "scroll" },
                    },
                  }}
                  renderValue={(selected) => {
                    return (
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        height="36px"
                      >
                        <Typography className="size_14 t_oktelo_normal darkblue">
                          {selected}
                        </Typography>
                      </Stack>
                    );
                  }}
                >
                  <MenuItem key="facebook" value="facebook">
                    <Typography className="size_14 t_oktelo_normal darkblue">
                      Facebook
                    </Typography>
                  </MenuItem>
                  <MenuItem key="instagram" value="instagram">
                    <Typography className="size_14 t_oktelo_normal darkblue">
                      Instagram
                    </Typography>
                  </MenuItem>
                  <MenuItem key="linkedin" value="linkedin">
                    <Typography className="size_14 t_oktelo_normal darkblue">
                      LinkedIn
                    </Typography>
                  </MenuItem>
                </Select>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Oldal
                </Typography>
                <Select
                  defaultValue={selectedPage.id}
                  onChange={(e) => rSelectPage(e.target.value)}
                  displayEmpty
                  fullWidth
                  sx={{ marginBottom: "18px" }}
                  className="editpost-select"
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "350px", overflowY: "scroll" },
                    },
                  }}
                  renderValue={(selected) => {
                    if (selected) {
                      if (selected === "") {
                        return (
                          <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            height="36px"
                          >
                            <Typography className="t_oktelo_normal darkblue size_14">
                              Ne legyen kapcsolva
                            </Typography>
                          </Stack>
                        );
                      }
                      const selectedPage = socialPages.allPages.find(
                        (page) => page.id === selected
                      );
                      return (
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          height="36px"
                        >
                          <Stack direction="row" alignItems="center" gap={1}>
                            {selectedPlatform === "instagram" ? (
                              <Instagram
                                sx={{
                                  color: "#E4405F !important",
                                  fontSize: "18px",
                                }}
                              />
                            ) : selectedPlatform === "facebook" ? (
                              <Facebook
                                sx={{
                                  color: "#1e90ff !important",
                                  fontSize: "18px",
                                }}
                              />
                            ) : (
                              <LinkedIn
                                sx={{
                                  color: "#0077B5 !important",
                                  fontSize: "18px",
                                }}
                              />
                            )}
                            <Typography className="t_oktelo_normal darkblue size_14">
                              {selectedPage?.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    } else {
                      return (
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          height="36px"
                        >
                          <Typography className="t_oktelo_normal darkblue size_14">
                            Ne legyen kapcsolva
                          </Typography>
                        </Stack>
                      );
                    }
                  }}
                >
                  <MenuItem
                    value=""
                    className="create-menuitem"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    <Typography className="t_oktelo_normal darkblue size_14">
                      Ne legyen kapcsolva
                    </Typography>
                  </MenuItem>
                  {filteredPages.map((page) => (
                    <MenuItem
                      key={page.id}
                      value={page.id}
                      sx={{ backgroundColor: "#fff" }}
                      className="create-menuitem"
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <img
                          src={page.photo}
                          style={{
                            width: "26px",
                            height: "26px",
                            borderRadius: "50%",
                          }}
                          alt={page.name}
                        />
                        <Typography className="t_oktelo_normal darkblue size_14">
                          {page.name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Címke
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  <Select
                    displayEmpty
                    fullWidth
                    style={{ padding: "2px 6px" }}
                    value={selectedTag.id}
                    defaultValue={selectedTag && selectedTag.id}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "350px", overflowY: "scroll" },
                      },
                    }}
                    onChange={(e) => rSelectTag(e.target.value)}
                    renderValue={(selected) => {
                      if (selected) {
                        return (
                          <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            height="36px"
                          >
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#f6f6f6",
                              }}
                            >
                              <LabelOutlined
                                sx={{
                                  color: selectedTag.color + " !important",
                                  fontSize: "18px",
                                }}
                              />
                            </Avatar>
                            <Typography className="t_oktelo_normal size_14 darkblue">
                              {
                                userTags.find((tag) => tag.id === selected)
                                  ?.title
                              }
                            </Typography>
                          </Stack>
                        );
                      } else {
                        return (
                          <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            height="36px"
                          >
                            <Typography className="t_oktelo_normal size_14 darkblue">
                              Nincs címke
                            </Typography>
                          </Stack>
                        );
                      }
                    }}
                    className="editpost-select"
                  >
                    <MenuItem value="" key="tag-noselect">
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography className="t_oktelo_normal size_14 darkblue">
                          Nincs címke
                        </Typography>
                      </Stack>
                    </MenuItem>
                    {userTags.map((tag: UserTags) => (
                      <MenuItem
                        key={tag.id}
                        value={tag.id}
                        sx={{ backgroundColor: "#fff" }}
                        className="create-menuitem"
                      >
                        <Stack direction="row" gap={1} alignItems="center">
                          <Avatar
                            sx={{
                              width: "30px",
                              height: "30px",
                              backgroundColor: "#f6f6f6",
                            }}
                          >
                            <LabelOutlined
                              sx={{ color: tag.color, fontSize: "18px" }}
                            />
                          </Avatar>
                          <Typography className="t_oktelo_normal size_14 darkblue">
                            {tag.title}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                  <button
                    className="social-select-button"
                    style={{ color: "#1E1E47" }}
                    onClick={() =>
                      setSelectedTag({ id: "", title: "", color: "" })
                    }
                  >
                    <Delete />
                  </button>
                </Stack>
              </Box>
            </Grid>
          )}
          {selectedEdit === "date" && (
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="edit-screen-container"
            >
              <Box className="edit-tab" width="100%">
                <Typography
                  className="t_oktelo_semibold size_14 darkblue"
                  mb={1.5}
                >
                  Dátum
                </Typography>
                <DatePicker
                  showHour
                  showMinute
                  showTime
                  value={postDate || undefined}
                  needConfirm={false}
                  placeholder="Bejegyzés dátuma"
                  format={"YYYY.MM.DD HH:mm"}
                  onChange={(e) => setPostDate(e)}
                  style={{ marginBottom: "10px" }}
                />
                <Radio.Group
                  onChange={(e) => {
                    if (e.target.value === "remove") {
                      setPostDateType("");
                      setPostDate(undefined);
                    } else {
                      setPostDateType(e.target.value);
                    }
                  }}
                  value={postDateType}
                  style={{ marginBottom: "10px" }}
                >
                  <Radio value={"draft"}>Vázlat/naptár</Radio>
                  <Radio value={"publish"}>Közzététel/ütemezés</Radio>
                  <Radio value={"remove"}>Törlés</Radio>
                </Radio.Group>
                <Typography className="t_oktelo_normal size_12 darkblue">
                  <span className="t_purple">Vázlat/naptár:</span> A bejegyzésed
                  bekerül a tartalomnaptárba és a kiválasztott dátum lesz
                  hozzákapcsolva, de <b>nem</b> lesz közzétéve, vagy ütemezve.
                  <br></br>
                </Typography>
                <Typography className="t_oktelo_normal size_12 darkblue">
                  <span className="t_purple">Közzététel/ütemezés:</span> A
                  bejegyzésed ütemezve lesz és automatikusan publikálásra kerül
                  a választott időpontban.
                </Typography>
                <Typography className="t_oktelo_normal size_12 darkblue">
                  <span className="t_purple">Törlés:</span> A bejegyzésedhez nem
                  lesz dátum csatolva.
                </Typography>
              </Box>
            </Grid>
          )}
          {selectedEdit === "message" && (
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="edit-screen-container"
            >
              <Box className="edit-tab" width="100%">
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Tartalom
                </Typography>
                <TextArea
                  value={selectedMessage}
                  placeholder={postData.message}
                  onChange={(e) => setSelectedMessage(e.target.value)}
                  rows={5}
                  maxLength={500}
                  showCount
                />
                <Stack direction="row" gap={1} alignItems="center">
                  <Select
                    displayEmpty
                    fullWidth
                    value={selectedHashtag.id}
                    style={{ padding: "2px 6px" }}
                    onChange={(e) => rSelectHashtag(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "350px", overflowY: "scroll" },
                      },
                    }}
                    renderValue={(selected) => {
                      if (selected) {
                        return (
                          <Stack direction="row" gap={1} alignItems="center">
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#f6f6f6",
                              }}
                            >
                              <TagOutlined
                                sx={{ color: "#925FFF", fontSize: "18px" }}
                              />
                            </Avatar>
                            <Typography className="t_oktelo_normal size_14 darkblue">
                              {
                                userHashtags.find((tag) => tag.id === selected)
                                  ?.title
                              }
                            </Typography>
                          </Stack>
                        );
                      } else {
                        return (
                          <Stack direction="row" gap={1} alignItems="center">
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#f6f6f6",
                              }}
                            >
                              <TagOutlined
                                sx={{ color: "#925FFF", fontSize: "18px" }}
                              />
                            </Avatar>
                            <Typography className="t_oktelo_normal size_14 darkblue">
                              Nincs hashtag
                            </Typography>
                          </Stack>
                        );
                      }
                    }}
                    className="editpost-select"
                  >
                    <MenuItem value="" key="hashtag-noselect">
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography className="t_oktelo_normal size_14 darkblue">
                          Nincs hashtag
                        </Typography>
                      </Stack>
                    </MenuItem>
                    {userHashtags.map((tag: UserHashtags) => (
                      <MenuItem
                        key={tag.id}
                        value={tag.id}
                        sx={{ backgroundColor: "#fff" }}
                        className="create-menuitem"
                      >
                        <Stack direction="row" gap={1} alignItems="center">
                          <Avatar
                            sx={{
                              width: "30px",
                              height: "30px",
                              backgroundColor: "#f6f6f6",
                            }}
                          >
                            <TagOutlined
                              sx={{ color: "#925FFF", fontSize: "18px" }}
                            />
                          </Avatar>
                          <Typography className="t_oktelo_normal size_14 darkblue">
                            {tag.title}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                  <button
                    className="social-select-button"
                    style={{ color: "#1E1E47" }}
                    onClick={() =>
                      setSelectedHashtag({ id: "", hashtags: [], title: "" })
                    }
                  >
                    <Delete />
                  </button>
                </Stack>
              </Box>
            </Grid>
          )}
          {selectedEdit === "media" && (
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="edit-screen-container"
            >
              <Box className="edit-tab" width="100%">
                <Typography
                  className="t_oktelo_semibold size_14 darkblue"
                  mb={1.5}
                >
                  Média
                </Typography>
                <Radio.Group
                  onChange={(e) => {
                    setPostAttachmentType(e.target.value);
                  }}
                  value={postAttachmentType}
                  style={{ marginBottom: "10px" }}
                >
                  <Radio value={"textOnly"} disabled={selectedPlatform==="instagram"}>Csak szöveg</Radio>
                  <Radio value={"photo"}>Kép</Radio>
                  <Radio value={"video"}>Videó</Radio>
                  <Radio value={"carousel"}>Galéria</Radio>
                  <Radio value={"webUrl"} disabled={selectedPlatform==="instagram"}>URL</Radio>
                  <Radio value={"story"}>Történet</Radio>
                </Radio.Group>
               
                <Stack>{renderUploadComponent()}</Stack>
              </Box>
            </Grid>
          )}
          {selectedEdit === "comment" && (
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="edit-screen-container"
            >
              <Box className="edit-tab" width="100%">
                <Typography
                  className="t_oktelo_semibold size_14 darkblue"
                  mb={1.5}
                >
                  Első komment
                </Typography>
                <Checkbox
                  defaultChecked={postComment.enabled}
                  onChange={() =>
                    setPostComment({
                      enabled: !postComment.enabled,
                      message: postComment.message,
                    })
                  }
                  title="Engedélyezés"
                  style={{
                    color: "#1e1e47",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Engedélyezve
                </Checkbox>
                <TextArea
                  value={postComment.message}
                  rows={3}
                  onChange={(e) =>
                    setPostComment({
                      enabled: postComment.enabled,
                      message: e.target.value,
                    })
                  }
                  disabled={!postComment.enabled}
                  maxLength={300}
                  showCount
                />
                <button
                  className="home-action-button bc6a"
                  onClick={() =>
                    setPostComment({ enabled: false, message: "" })
                  }
                >
                  Törlés
                </button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }
};

export default PostEdit;
