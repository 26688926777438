import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import { OkteloPostProps } from "interfaces/oktelo";
import axios from "axios";
import {
  CalendarMonth,
  Facebook,
  Instagram,
  LinkedIn,
  Window,
  List,
  Add,
  Schedule,
  OndemandVideoOutlined,
  FormatAlignCenterOutlined,
  PhotoCameraBackOutlined,
  LinkOutlined,
  LocalCafeOutlined,
  CollectionsOutlined,
  ArticleOutlined,
  CheckCircleOutline,
  CancelOutlined,
  PendingOutlined,
  OfflineBoltOutlined,
  PublicOutlined,
  CalendarMonthOutlined,
  HowToRegOutlined,
  SearchOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  DeleteForeverRounded,
  LabelOutlined,
  LocalOfferOutlined,
} from "@mui/icons-material";
import {
  LinkPlaceholder,
  TextPlaceholder,
  VideoPlaceholder,
  okt_placeholer,
} from "assets";
import dayjs from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import { Tag, Tooltip, Checkbox } from "antd";
import OkteloCalendar from "components/common/calendar/OkteloCalendar";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@refinedev/core";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hu';
import apiconfig from "config";

type PageInfo = {
  id: string;
  name: string;
  photo: string;
  platform: string;
};

type SocialData = {
  pages: PageInfo[];
};

interface FilterProps {
  searchTerm: string;
  status: string;
  parent: string;
  sortField: SortField;
  sortOrder: "asc" | "desc";
}

type SortField = "createdAt" | "updatedAt" | "calendarDate";

dayjs.locale('hu')
dayjs.extend(utc);
dayjs.extend(timezone);

const PostsPage = () => {
  const [isLoading, setLoading] = useState(false);
  const { open } = useNotification();
  const [posts, setPosts] = useState<OkteloPostProps[]>([]);
  const [socials, setSocials] = useState<SocialData>({ pages: [] });
  const [view, setView] = useState<string>("grid");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const [isShowDeleteOpt, setShowDeleteOpt] = useState(false);
  const [filters, setFilters] = useState<FilterProps>({
    searchTerm: "",
    status: "",
    parent: localStorage.getItem('sp') || socials.pages[0]?.id || '',
    sortField: "createdAt",
    sortOrder: "desc",
  });
 
const navigate = useNavigate();

  const handleFilterChange = (key: keyof FilterProps, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters, // Spread the previous filters to retain other properties
      parent: localStorage.getItem('sp') || socials.pages[0]?.id || '' // Update the 'parent' field
    }));
  
    console.log(filters);
  }, [socials]);

  const handlePostSelect = (id: string) => {
    // Create a copy of the current state to avoid direct mutation
    const updatedPosts = [...selectedPosts];

    if (updatedPosts.includes(id)) {
        // Remove the ID from the array
        const index = updatedPosts.indexOf(id);
        if (index > -1) {
            updatedPosts.splice(index, 1);
        }
    } else {
        // Add the ID to the array
        updatedPosts.push(id);
    }

    // Update the state with the modified array
    setSelectedPosts(updatedPosts);
};

useEffect(() => {
  if (selectedPosts.length > 0){
    setShowDeleteOpt(true);
  } else {
    setShowDeleteOpt(false);
  }
}, [selectedPosts])

  const getData = async () => {
    try {
      setLoading(true);

      const postResponse = await axios.get(`${apiconfig.BACKEND_URI}/data/posts`);
      const socialResponse = await axios.get(
        `${apiconfig.BACKEND_URI}/data/socials`
      );

      if (postResponse.status === 200) {
        setPosts(postResponse.data);
      }

      if (socialResponse.status === 200) {
        const allFacebook = socialResponse.data.facebookPages;
        const allInstagram = socialResponse.data.instagramPages;
        const allLinkedin = socialResponse.data.linkedinPages;

        const merge: SocialData = {
          pages: [...allFacebook, ...allInstagram, ...allLinkedin],
        };

        setSocials(merge);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSelectedPosts = async () => {
    try {
      const test = await axios.get('http://localhost:8080/api/v1/posts/test');
      console.log(test);
      if (selectedPosts.length > 0) {
        const response = await axios.post(`${apiconfig.BACKEND_URI}/posts/delposts`, {
          data: { postids: selectedPosts }
        });
  
        if (response.status === 200) {
          open?.({
            type: 'success',
            message: 'Sikeresen törölted a posztokat.',
          });
  
          return getData();
        }
      }
    } catch (error) {
      console.log(error);
      open?.({
        type: 'error',
        message: 'Hiba történt a posztok törlése közben.',
      });
    }
  }
  

  useEffect(() => {
    getData();
  }, []);

  /* Header & filter only & call views */
  return (
    <>
        <Grid container spacing={2}>
          <Grid item xl={2}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="space-evenly"
            className="filter-icons-row"
            width="100%"
            height="100%"
          >
            <Stack direction="row" alignItems="center" gap={0.5}>
              <button
                className="filter-post-view-button"
                onClick={() => setView("list")}
              >
                <List sx={{ color: view === "list" ? "#925FFF" : "#1E1E47" }} />
              </button>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              onClick={() => setView("grid")}
            >
              <button className="filter-post-view-button">
                <Window
                  sx={{ color: view === "grid" ? "#925FFF" : "#1E1E47" }}
                />
              </button>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              onClick={() => setView("calendar")}
            >
              <button className="filter-post-view-button">
                <CalendarMonth
                  sx={{ color: view === "calendar" ? "#925FFF" : "#1E1E47" }}
                />
              </button>
            </Stack>
          </Stack>
          </Grid>
            <Grid item xl={2} xs={12}>
            <Select
            className="posts-select"
            value={filters.parent || 'all'}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "all") {
                setFilters((prevFilters) => ({ ...prevFilters, parent: "" }));
              } else {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  parent: value,
                }));
              }
            }}
            renderValue={(selected) => {
              if (selected) {
                if (selected === "all") {
                  return (
                    <Typography className="t_oktelo_normal darkblue size_14" ml={1}>
                      Összes oldal
                    </Typography>
                  );
                } else {
                  const selectedPage = socials.pages.find(
                    (page) => page.id === selected
                  );
                  return (
                    <Stack direction="row" gap={1} alignItems="center">
                      {selectedPage?.platform === "facebook" ? (
                        <Facebook sx={{ fontSize: "16px" }} />
                      ) : selectedPage?.platform === "instagram" ? (
                        <Instagram sx={{ fontSize: "16px" }} />
                      ) : (
                        <LinkedIn sx={{ fontSize: "16px" }} />
                      )}
                      <img
                        src={selectedPage?.photo}
                        style={{
                          objectFit: "contain",
                          borderRadius: "50%",
                          border: "1px solid #d9d9d9",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <Typography className="posts-select-item size_14 t_oktelo_normal darkblue">
                        {selectedPage?.name}
                      </Typography>
                    </Stack>
                  );
                }
              }
            }}
          >
            <MenuItem value="all" className="posts-select-item">
              Összes
            </MenuItem>
            {socials &&
              socials.pages &&
              socials.pages.length > 0 &&
              socials.pages.map((p) => (
                <MenuItem key={p.id} value={p.id} className="posts-select-item">
                  <Stack direction="row" gap={1} alignItems="center">
                    {p.platform === "facebook" ? (
                      <Facebook sx={{ fontSize: "16px" }} />
                    ) : p.platform === "instagram" ? (
                      <Instagram sx={{ fontSize: "16px" }} />
                    ) : (
                      <LinkedIn sx={{ fontSize: "16px" }} />
                    )}
                    <img
                      src={p.photo}
                      style={{
                        objectFit: "contain",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography className="posts-select-item">
                      {p.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
          </Select>
            </Grid>
            <Grid item xl={2} xs={12}>
            {/* Filters */}
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
              <TextField
                placeholder="Szöveg keresése..."
                value={filters.searchTerm}
                onChange={(e) =>
                  handleFilterChange("searchTerm", e.target.value)
                }
                fullWidth
                disabled={view === 'calendar'}
                className="posts-select-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined sx={{ fontSize: '16px' }}/>
                    </InputAdornment>
                  ),
                  sx: {
                    height: "40px",
                    "& .MuiInputBase-input": {
                      height: "40px",
                      padding: "0 14px",
                      color: "#1E1E47",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "0",
                      outline: "0",
                    },
                  },


                }}
                InputLabelProps={{
                  sx: {
                    lineHeight: "40px",
                    color: "#1E1E47",
                    fontSize: "14px",
                    border: "0",
                    outline: "0",
                    '&.Mui-disabled': {
                      WebkitTextFillColor: '#000', // Change to the color you prefer
                      color: '#000', // Change to the color you prefer
                      opacity: 1, // Ensure the text is opaque
                    },
                  },
                }}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                    color: "#1E1E47",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "0",
                    outline: "0",
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xl={2} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
              <Select
                value={filters.status}
                onChange={(e) => handleFilterChange("status", e.target.value)}
                className="posts-select"
                placeholder="Jóváhagyások"
                displayEmpty
                disabled={view === 'calendar'}
                startAdornment={
                  <InputAdornment position="start">
                    <CheckCircleOutline sx={{ fontSize: '16px' }}/>
                  </InputAdornment>
                }
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <Typography className="t_oktelo_normal darkblue size_14">
                        Jóváhagyások (összes)
                      </Typography>
                    );
                  }
                  return (
                    <Typography className="t_oktelo_medium darkblue size_14">
                      {selected === "approved"
                        ? "Jóváhagyott"
                        : selected === "pending"
                        ? "Folyamatban"
                        : "Elutasítva"}
                    </Typography>
                  );
                }}
              >
                <MenuItem value="" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Összes
                  </Typography>
                </MenuItem>
                <MenuItem value="approved" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Jóváhagyott
                  </Typography>
                </MenuItem>
                <MenuItem value="pending" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Folyamatban
                  </Typography>
                </MenuItem>
                <MenuItem value="rejected" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Elutasítva
                  </Typography>
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xl={2} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
              <Select
                value={filters.sortField}
                onChange={(e) =>
                  handleFilterChange("sortField", e.target.value)
                }
                disabled={view === 'calendar'}
                displayEmpty
                startAdornment={
                  <InputAdornment position="start">
                    <CalendarMonthOutlined sx={{ fontSize: '16px' }}/>
                  </InputAdornment>
                }
                className="posts-select"
                renderValue={(selected) => {
                  if (selected) {
                    return (
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        {selected === "createdAt"
                          ? "Létrehozva"
                          : selected === "updatedAt"
                          ? "Módosítva"
                          : "Naptár dátum"}
                      </Typography>
                    );
                  }
                }}
              >
                <MenuItem value="createdAt" className="posts-select-item">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Létrehozva
                </Typography>
                </MenuItem>
                <MenuItem value="updatedAt" className="posts-select-item">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Módosítva
                </Typography>
                </MenuItem>
                <MenuItem value="calendarDate" className="posts-select-item">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Naptár dátum
                </Typography>
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xl={2} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
            <button className="b_oktelo_purple_action" 
            onClick={() => handleFilterChange("sortOrder", filters.sortOrder === "asc" ? "desc" : "asc")}
            style={{ height: '40px', width: '100%', backgroundColor: '#1E1E47', display: 'flex', alignItems: 'center', gap: '6px', justifyContent: 'center' }}>
               {filters.sortOrder === "asc" ? <ArrowUpwardOutlined sx={{ fontSize: '16px' }}/> : <ArrowDownwardOutlined sx={{ fontSize: '16px' }}/>}
                {filters.sortOrder === "asc" ? "Növekvő" : "Csökkenő"}
            </button>
            </Stack>
          </Grid>
          <Grid item xl={12}>
            {isShowDeleteOpt &&
              <DeleteForeverRounded onClick={() => deleteSelectedPosts()}/>
            }
          </Grid>
        </Grid>

      <Grid container spacing={3} mt={2}>
        {view === "grid" &&
          posts &&
          posts.length > 0 &&
          renderGridPosts(posts, handlePostSelect, currentPage, setCurrentPage, filters, navigate)}
        {view === "list" &&
          posts &&
          posts.length > 0 &&
          renderListPosts(posts, currentPage, setCurrentPage, filters, navigate)}
        {view === "calendar" &&
          posts &&
          posts.length > 0 &&
          renderCalendarPosts(posts, filters)}
      </Grid>
    </>
  );
};

const renderGridPosts = (
  posts: OkteloPostProps[],
  handlePostSelect: (id: string) => void,
  currentPage: number,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  filters: FilterProps,
  navigate: any,
) => {
  const pageSize = 12;
  // Apply filters
  const filteredPosts = posts.filter((post) => {
    return (
      (!filters.searchTerm ||
        post.message
          ?.toLowerCase()
          .includes(filters.searchTerm.toLowerCase())) &&
      (!filters.status || post.approval.status === filters.status) &&
      (!filters.parent || post.parent?.id === filters.parent)
    );
  });

  // Apply sorting
  const sortedPosts = filteredPosts.sort((a, b) => {
    let fieldA: any;
    let fieldB: any;

    if (filters.sortField === "calendarDate") {
      fieldA = dayjs(a.calendarDate);
      fieldB = dayjs(b.calendarDate);
    } else if (
      filters.sortField === "createdAt" ||
      filters.sortField === "updatedAt"
    ) {
      fieldA = dayjs(a[filters.sortField]);
      fieldB = dayjs(b[filters.sortField]);
    } else {
      return 0; // If the sortField is not a date, do not sort
    }

    if (filters.sortOrder === "asc") {
      return fieldA.isBefore(fieldB) ? -1 : 1;
    } else {
      return fieldA.isAfter(fieldB) ? -1 : 1;
    }
  });

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedPosts = sortedPosts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredPosts.length / pageSize);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if(!url) return false
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video" || type === "reels") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const iconMap: Record<string, JSX.Element> = {
    textOnly: <FormatAlignCenterOutlined sx={{ color: '#925FFF !important' }}/>,
    video: <OndemandVideoOutlined sx={{ color: '#925FFF !important' }}/>,
    photo: <PhotoCameraBackOutlined sx={{ color: '#925FFF !important' }}/>,
    webUrl: <LinkOutlined sx={{ color: '#925FFF !important' }}/>,
    story: <LocalCafeOutlined sx={{ color: '#925FFF !important' }}/>,
    carousel: <CollectionsOutlined sx={{ color: '#925FFF !important' }}/>,
    default: <ArticleOutlined sx={{ color: '#925FFF !important' }}/>,
  };

  const approvalMap: Record<string, JSX.Element> = {
    approved: <CheckCircleOutline sx={{ color: "#00991f" }} />,
    pending: <PendingOutlined sx={{ color: "#3268bd" }} />,
    rejected: <CancelOutlined sx={{ color: "#a50000" }} />,
    noNeed: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
    default: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
  };

  const returnIcon = (type: string) => iconMap[type] || iconMap.default;

  const returnApproval = (status: string) =>
    approvalMap[status] || approvalMap.default;

  return (
    <>
      {paginatedPosts.map((post: OkteloPostProps) => (
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={post._id}>
          <Box className="post-grid-card" onClick={() => navigate(`show/${post._id}`)} sx={{ cursor: 'pointer' }}>
            <Box className="post-grid-type-box">
            {returnIcon(post.attachment.type)}
            {post.calendarDate &&
            <>
            <CalendarMonthOutlined sx={{ fontSize: "12px" }}/>
            <Typography className="parent-name t_purple t_oktelo_medium">
              {dayjs.utc(post.calendarDate).format("YYYY.MM.DD HH:mm")}
            </Typography>
            </>
            }
            {post.approval.isNeeded && 
                returnApproval(post.approval.status)
              }
            {post.platform === "facebook" ? (
                  <Facebook sx={{ fontSize: "12px", color: "#1e90ff !important" }} />
                ) : post.platform === "instagram" ? (
                  <Instagram sx={{ fontSize: "12px", color: "#E4405F !important" }} />
                ) : (
                  <LinkedIn sx={{ fontSize: "12px" }} />
                )}

              <Typography className="parent-name t_purple t_oktelo_medium">{post.parent?.name || 'Nem kapcsolt'}</Typography>
              {post.tag &&
              <>
                <LocalOfferOutlined sx={{ fontSize: "12px" }}/>
                <Typography className="t_purple t_oktelo_medium parent-name">
                  {post.tag.title}
                </Typography>
                </>
                }
            </Box>
            <img
              src={hImageSrc(post.attachment.type, post.attachment.url)}
            />
            <Box p={1.5} className="post-message-box">
              <Typography className="t_white size_12 t_oktelo_medium" mb={1}>
                {post.message ? post.message.slice(0, 32) + "..." : null}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid container mt={2}>
        <Grid item xl={12} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <button
              className="post-grid-pagination"
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              Előző
            </button>
            <Typography mx={2}>
              Oldal: {currentPage} / {totalPages}
            </Typography>
            <button
              className="post-grid-pagination"
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              Következő
            </button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const renderListPosts = (
  posts: OkteloPostProps[],
  currentPage: number,
  setCurrentPage: any,
  filters: FilterProps,
  navigate: any,
) => {
  // Apply filters
  const filteredPosts = posts.filter((post) => {
    return (
      (!filters.searchTerm ||
        post.message
          ?.toLowerCase()
          .includes(filters.searchTerm.toLowerCase())) &&
      (!filters.status || post.approval.status === filters.status) &&
      (!filters.parent || post.parent?.id === filters.parent)
    );
  });

  // Apply sorting
  const sortedPosts = filteredPosts.sort((a, b) => {
    let fieldA: any;
    let fieldB: any;

    if (filters.sortField === "calendarDate") {
      fieldA = dayjs(a.calendarDate);
      fieldB = dayjs(b.calendarDate);
    } else if (
      filters.sortField === "createdAt" ||
      filters.sortField === "updatedAt"
    ) {
      fieldA = dayjs(a[filters.sortField]);
      fieldB = dayjs(b[filters.sortField]);
    } else {
      return 0; // If the sortField is not a date, do not sort
    }

    if (filters.sortOrder === "asc") {
      return fieldA.isBefore(fieldB) ? -1 : 1;
    } else {
      return fieldA.isAfter(fieldB) ? -1 : 1;
    }
  });

  const pageSize = 8;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedPosts = sortedPosts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredPosts.length / pageSize);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if(!url) return false
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const iconMap: Record<string, JSX.Element> = {
    textOnly: <FormatAlignCenterOutlined sx={{ color: '#925FFF !important' }}/>,
    video: <OndemandVideoOutlined sx={{ color: '#925FFF !important' }}/>,
    photo: <PhotoCameraBackOutlined sx={{ color: '#925FFF !important' }}/>,
    webUrl: <LinkOutlined sx={{ color: '#925FFF !important' }}/>,
    story: <LocalCafeOutlined sx={{ color: '#925FFF !important' }}/>,
    carousel: <CollectionsOutlined sx={{ color: '#925FFF !important' }}/>,
    default: <ArticleOutlined sx={{ color: '#925FFF !important' }}/>,
  };

  const approvalMap: Record<string, JSX.Element> = {
    approved: <CheckCircleOutline sx={{ color: "#00991f" }} />,
    pending: <PendingOutlined sx={{ color: "#3268bd" }} />,
    rejected: <CancelOutlined sx={{ color: "#a50000" }} />,
    noNeed: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
    default: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
  };

  const ApprovalTextMap: Record<string, string> = {
    approved: "Jóváhagyott",
    pending: "Függőben",
    rejected: "Elutasított",
    noNeed: "Nem szükséges",
    default: "Nincs adat",
  };

  const returnIcon = (type: string) => iconMap[type] || iconMap.default;

  const returnApproval = (status: string) =>
    approvalMap[status] || approvalMap.default;
  const returnApprovalText = (type: string) =>
    ApprovalTextMap[type] || ApprovalTextMap.default;

  const returnPriorityDate = (status: string, publish: any) => {
    if (status === "scheduledWithPublish") {
      if (publish.scheduleDate) {
        return (
          "Ütemezett közzététel: " +
          dayjs.utc(publish.scheduleDate).format("YYYY.MM.DD HH:mm")
        );
      } else {
        return "Ütemezett közzététel";
      }
    } else if (status === "published") {
      if (publish.publishDate) {
        return (
          "Közzétéve: " + dayjs.utc(publish.scheduleDate).format("YYYY.MM.DD HH:mm")
        );
      } else {
        return "Közzétéve";
      }
    } else if (status === "errorWithPublishing") {
      return "Hibás közzététel";
    } else {
      return "Nincs adat";
    }
  };

  return (
    <>
      <Grid item xl={12} xs={12}>
        <TableContainer>
        <Table className="post-list-table" sx={{ borderCollapse: 'separate', borderSpacing: '0' }}>
        <TableHead
          sx={{
            backgroundColor: "#fff",
            marginBottom: '12px !important',
            borderRadius: '20px 20px 20px 20px',
            borderBottom: '0px',
            overflow: 'hidden', // Ensure children do not overflow the rounded corners
          }}
        >
          <TableRow>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Típus</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Tartalom</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Platform</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Dátum</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Jóváhagyás</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
              {paginatedPosts.map((post) => (
                <TableRow key={post._id} onClick={() => navigate(`show/${post._id}`)} sx={{ cursor: 'pointer', border: '0px' }}>
                  <TableCell>
                  {returnIcon(post.attachment.type)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                    
                      <img
                        src={hImageSrc(
                          post.attachment.type,
                          post.attachment.url
                        )}
                        alt={post.message}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "8px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography className="darkblue size_14 t_oktelo_normal">
                        {post.message
                          ? post.message.slice(0, 32) + "..."
                          : null}
                      </Typography>
                      {post.tag &&
              <Stack direction="row" gap={0.5} alignItems="center">
                <LocalOfferOutlined sx={{ fontSize: "16px", color: '#925FFF' }}/>
                <Typography className="t_purple t_oktelo_medium size_14">
                  {post.tag.title}
                </Typography>
                </Stack>
                }
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="A posztod platformja és a hozzákapcsolt közösségi média oldalad">
                        <Stack direction="row" alignItems="center" gap={1}>
                            {post.platform === "facebook" ? (
                            <Facebook sx={{ fontSize: "16px", color: '#925FFF' }} />
                            ) : post.platform === "instagram" ? (
                            <Instagram sx={{ fontSize: "16px", color: '#925FFF' }} />
                            ) : (
                            <LinkedIn sx={{ fontSize: "16px", color: '#925FFF' }} />
                            )}
                        <Typography className="darkblue size_14 t_oktelo_normal">
                            {post.parent &&
                            typeof post.parent === "object" &&
                            post.parent.name
                            ? post.parent.name
                            : "Nem kapcsolt"}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Ide helyezted a naptárban a posztot">
                    <Stack direction="row" alignItems="center" gap={1}>
                    <CalendarMonthOutlined sx={{ fontSize: "16px", color: '#925FFF' }} />
                        <Typography className="darkblue size_14 t_oktelo_normal">
                            {post.calendarDate && !post.publish.isPublished
                            ? dayjs.utc(post.calendarDate).format("YYYY.MM.DD HH:mm")
                            : post.publish.isPublished && post.publish.date ?
                              dayjs.utc(post.publish.date).format('YYYY.MM.DD HH:mm')
                              :
                              "Nincs dátum"
                          }
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    { post.approval.isNeeded &&
                      <Tooltip title="Poszt jóváhagyás állapota">
                        <Tag
                          className="tag_oktelo size_14 t_oktelo_medium"
                          bordered={false}
                          color={
                            post.approval.status === "approved"
                              ? "success"
                              : post.approval.status === "rejected"
                              ? "red"
                              : post.approval.status === "pending"
                              ? "yellow"
                              : "volcano"
                          }
                        >
                      <Typography className="t_oktelo_normal darkblue size_14">{returnApprovalText(post.approval.status)}</Typography>
                            
                        </Tag>
                      </Tooltip>
                      }
                    { !post.approval.isNeeded &&
                    <Stack direction="row" gap={0.5} alignItems="center">
                      <HowToRegOutlined sx={{ fontSize: "16px", color: '#925FFF' }} />
                      <Typography className="t_oktelo_normal darkblue size_14">Nem jóváhagyandó</Typography>
                      </Stack>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container mt={2}>
        <Grid item xl={12} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <button
              className="post-grid-pagination"
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              Előző
            </button>
            <Typography mx={2}>
              Oldal: {currentPage} / {totalPages}
            </Typography>
            <button
              className="post-grid-pagination"
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              Következő
            </button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const renderCalendarPosts = (posts: OkteloPostProps[], filters: FilterProps,) => {
  const returnImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const filteredPosts = posts.filter((post) => {
    const isStatusValid = 
        post.status === "scheduledWithPublish" || 
        post.status === "calendarDraft" || 
        post.status === "published";
    
    const isParentValid = filters.parent !== '' ? post.parent && post.parent.id === filters.parent : true;
    
    return isStatusValid && isParentValid;
});

  const items = filteredPosts.map((post) => ({
    title: post.message || "",
    date: dayjs(post.calendarDate),
    image: returnImageSrc(post.attachment.type, post.attachment.url),
    type: post.status,
    id: post._id,
  }));

  return (
    <Grid item xl={12} xs={12}>
      <OkteloCalendar items={items} />
    </Grid>
  );
};

export default PostsPage;
