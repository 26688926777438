// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-chart-info{
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: none;
    max-height: 90px !important;
    height: 90px !important;
    min-width: 0;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    flex: 1 1;
}

.hover-chart-info:hover{
    background-color: #f4efff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.small-icon-metric{
    background-color: #ede4ff !important;
    width: 30px !important;
    height: 30px !important;
}

.small-icon-metric svg{
    font-size: 16px !important;
    color: #925fff !important;
}

.analytics-filter-box{
    padding: 10px;
    border-radius: 6px;
    background-color: #ede4ff;
   /*  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
}

.analytics-filter-box .ant-input-affix-wrapper{
    background-color: #fff !important;
}

.MuiLinearProgress-bar {
    background-color: #925fff !important;
}

.MuiLinearProgress-root{
    background-color: #e0d2ff !important;
    height: 8px !important;
    border-radius: 4px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/sitedata/sitedata.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,2BAA2B;IAC3B,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,SAAO;AACX;;AAEA;IACI,yBAAyB;IACzB,mFAAmF;AACvF;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;GAC1B,0FAA0F;AAC7F;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":[".hover-chart-info{\n    cursor: pointer;\n    transition: all 0.3s;\n    box-shadow: none;\n    max-height: 90px !important;\n    height: 90px !important;\n    min-width: 0;\n    width: auto;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    flex-basis: 0;\n    flex-grow: 1;\n    flex: 1;\n}\n\n.hover-chart-info:hover{\n    background-color: #f4efff;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;\n}\n\n.small-icon-metric{\n    background-color: #ede4ff !important;\n    width: 30px !important;\n    height: 30px !important;\n}\n\n.small-icon-metric svg{\n    font-size: 16px !important;\n    color: #925fff !important;\n}\n\n.analytics-filter-box{\n    padding: 10px;\n    border-radius: 6px;\n    background-color: #ede4ff;\n   /*  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */\n}\n\n.analytics-filter-box .ant-input-affix-wrapper{\n    background-color: #fff !important;\n}\n\n.MuiLinearProgress-bar {\n    background-color: #925fff !important;\n}\n\n.MuiLinearProgress-root{\n    background-color: #e0d2ff !important;\n    height: 8px !important;\n    border-radius: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
