import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";

import InputAdornment from '@mui/material/InputAdornment';

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useRef } from "react";

import CampaignIcon from '@mui/icons-material/Campaign';
import MessageIcon from '@mui/icons-material/Message';

import { Col, Row } from 'antd';

import { CampaignFormProps, PropertyProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Facebook, Instagram } from "@mui/icons-material";

import { tts_title, tts_content } from "utils/toolTipTexts";
import ShowTooltip from "components/common/ToolTips";


import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';



const CampaignForm = ({
  type,
  register,
  handleSubmit,
  formLoading,
  onFinishHandler,
  onDateChange,
  selectedCampaignPosts,
  updateSelectedCampaignPosts,
  dateValue
}: CampaignFormProps) => {
  const { control } = useForm();


  const [textFieldValue, setTextFieldValue] = useState('');
  const [areaFieldValue, setAreaFieldValue] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [posts, setPosts] = useState([]);


  useEffect(() => {
    async function fetchUserData() {
        try {
            const response = await axios.get(`http://localhost:8080/api/v1/users`, {
                headers: {
                    "user_request_type": "get_properties",
                },
            });

            const allUserPosts = response.data || [];

            setPosts(allUserPosts);
            console.log(allUserPosts);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    fetchUserData();

}, []);
  
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior of Enter key (e.g., form submission)
      setAreaFieldValue(prevValue => prevValue + '\n'); // Insert newline character into textarea value
    }
  };



  const handlePostChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, postId: string) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedPosts = selectedCampaignPosts?.includes(postId)
      ? selectedCampaignPosts?.filter(id => id !== postId)
      : [...selectedCampaignPosts, postId];

    updateSelectedCampaignPosts(updatedPosts);
  };

  
  const isPostSelected = (postId: string) => {
    return selectedCampaignPosts?.includes(postId);
  };



  const currentDate = dayjs();


 

  return(
<Box>
  <Row gutter={[16, 16]} style={{flexWrap: 'nowrap', gap: '16px 16px'}} justify="space-around" align="top">
    <Col style={{borderRadius: '16px', backgroundColor: "#FFFFFF", padding: '16px'}} span={24}>
    <Box display="flex" flexDirection="column">
    <Typography className="t_main_title">
        {type} a Campaign
      </Typography>
      <form
        style={{
          marginTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px"
        }}
        onSubmit={handleSubmit(onFinishHandler)}
      >
        <FormControl>
          <FormHelperText sx={{ fontWeight: 500, margin: "10px 0", fontSize: 16, color: "#1E1E47" }}>
            Your new campaign's title
          </FormHelperText>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
            <TextField
              required
              id="input-with-icon-textfield"
              sx={{ width: '100% !important' }}
              color="info"
              variant="outlined"
              margin="dense"
              label="What your campaign should be called?"
              InputProps={{
                maxLength: 100,
                startAdornment: (
                  <InputAdornment position="start">
                    <CampaignIcon />
                  </InputAdornment>
                ),
              }}
              {...register("title", {
                value: textFieldValue,
                required: true,
                maxLength: 100,
                minLength: 4,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => { setTextFieldValue(e.target.value)}
              })}
            />
            <FormHelperText id="component-helper-text">
              This will help you identify your campaign later on. Character limit: 
              {textFieldValue.length > 56 ? (
                <span style={{ color: "red" }}> {textFieldValue.length} / 100. </span>
              ) : (
                <span> {textFieldValue.length} / 56. </span>
              )}
            </FormHelperText>
          </Box>
        </FormControl>

        <FormControl>
          <FormHelperText sx={{ fontWeight: 500, margin: "10px 0", fontSize: 16, color: "#1E1E47" }}>
            You can add a custom description to this campaign
          </FormHelperText>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
            <TextField
              ref={textareaRef}
              required
              onKeyDown={handleKeyDown}
              sx={{ width: '100% !important' }}
              placeholder=""
              variant="outlined"
              color="info"
              margin="dense"
              InputProps={{
                maxLength: 100,
                startAdornment: (
                  <InputAdornment position="start">
                    <MessageIcon />
                  </InputAdornment>
                ),
              }}
              {...register("description", { required: false,
                maxLength: 100,
                minLength: 0,
                value: areaFieldValue,
                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => { setAreaFieldValue(e.target.value)},
                onKeyDown: handleKeyDown,
                
              })}
            />
            <FormHelperText id="component-helper-text">
              Character limit: 
              {areaFieldValue.length > 400 ? (
                <span style={{ color: "red" }}> {areaFieldValue.length} / 100. </span>
              ) : (
                <span> {areaFieldValue.length} / 100. </span>
              )}
            </FormHelperText>       
          </Box>
        </FormControl>
        <FormControl fullWidth>
              <FormHelperText sx={{ fontWeight: 500, margin: "10px 0", fontSize: 16, color: "#1E1E47" }}>
                Select the posts
              </FormHelperText>
              <Select
                variant="outlined"
                color="info"
                displayEmpty
                multiple
                value={selectedCampaignPosts}
                inputProps={{ "aria-label": "Without label" }}
                {...register("posts", {
                  required: false,
                  value: selectedCampaignPosts,
                })}
              >
                {posts.map((post: PropertyProps) => (
                <MenuItem key={post._id} value={post._id} onMouseDown={(event) => handlePostChange(event, post._id)}>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <Typography>{post.propertyType === 'instagram' ? <Instagram sx={{fontSize: '14px', color: '#e66de5'}}/> : post.propertyType === 'facebook' ? <Facebook sx={{fontSize: '14px', color: '#007eff'}}/> : 'Error'}</Typography>
                        <Typography>{post.title}</Typography>
                    </Stack>

                </MenuItem>
                ))}
              </Select>
        </FormControl>
            <FormControl fullWidth sx={{marginBottom: '14px'}}>
              <FormHelperText sx={{ fontWeight: 500, margin: "10px 0", fontSize: 16, color: "#1E1E47" }}>
                Schedule the start of your campaign
              </FormHelperText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="schedule"
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      value={field.value ? dayjs(field.value) : dateValue? dayjs(dateValue) : null}
                      onChange={(newValue: Dayjs | null) => {
                        field.onChange(newValue);
                        onDateChange(newValue); // Call the function with the selected date
                      }}
                      referenceDate={currentDate}
                    />
                  )}
                  rules={{ required: false }} // Add rules if needed
                />
              </LocalizationProvider>
              <FormHelperText id="component-helper-text">
                <span>You can set the start time of this campaign. You can leave it blank if you don't want to schedule yet!</span>
            </FormHelperText> 
            </FormControl>

        <CustomButton
          type="submit"
          title={formLoading ? "Saving..." : "Finish"}
          backgroundColor="#925FFF"
          color="#FFFFFF"
          fullWidth
        />
      </form>
    </Box>
  </Col>
</Row>
        <Stack
          display="flex"
          flexDirection="column"
          alignContent="center"
          alignItems="flex-start"
          width="100%"
          justifyContent="flex-end"
          my={3}
          gap={0.5}
        >
          <Typography className="t_main_title">
            Your Oktelo posts 🚀
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography fontSize={16} fontWeight={400} color="#1E1E47">
              You can also select posts from this list to include in this campaign
            </Typography>
            <ShowTooltip title={tts_title.s_campaign_form_posts} content={tts_content.s_campaign_form_posts} question />
          </Stack>
        </Stack>

<TableContainer component={Paper}>
  <Table>
    <TableBody>
      {posts && posts.map((post: PropertyProps) => (
        <TableRow
          key={post._id}
          className={isPostSelected(post._id) ? "selectedPostCampaign" : ""}
          sx={{ cursor: "pointer", padding: "2px" }}
          onMouseDown={(event) => handlePostChange(event, post._id)}
        >
          <TableCell>
            <img
              src={post.photo}
              alt="card post image"
              style={{ width: "80px"}}
            />
          </TableCell>
          <TableCell>
          <Typography variant="subtitle1" fontWeight={500} color="#1E1E47">
          {post && post.title ?
          (post.title.length <= 36 ? post.title : `${post.title.substring(0, 24)}...`)
          : "Title is missing"}
          </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" fontWeight={400} color="#3b3b3b">
            <Typography>{post.propertyType === 'instagram' ? <Instagram sx={{fontSize: '16px', color: '#e66de5'}}/> : post.propertyType === 'facebook' ? <Facebook sx={{fontSize: '16px', color: '#007eff'}}/> : 'Error'}</Typography>
            </Typography>
          </TableCell>
          <TableCell>
          <Typography variant="body1" fontWeight={400} color="#3b3b3b">
            {post && post.description ? 
              (post.description.length <= 36 ? post.description : `${post.description.substring(0, 24)}...`)
              : "Description is missing"}
          </Typography>

          </TableCell>
          <TableCell>
            <Typography variant="body1" fontWeight={400} color="#3b3b3b">
              {post.scheduled_at ? dayjs(post.scheduled_at).format('LLL') : "Not scheduled"}
            </Typography>
          </TableCell>
          <TableCell>
            {selectedCampaignPosts?.includes(post._id) && (
                
              <Typography variant="body1" fontWeight={500} color="#925FFF">
                Added ✔️
              </Typography>
            )}
          </TableCell>
        </TableRow>

      ))}
    </TableBody>
  </Table>
</TableContainer>
</Box>

);
};

export default CampaignForm;
