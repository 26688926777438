import React from "react";
import { Box, Stack, Typography, Grid } from '@mui/material';
import { Tag } from "antd";
import OkteloCard from "components/common/oktelo_components/OkteloCard";
import { AutoAwesome } from "@mui/icons-material";
import CreatePost from "components/common/oktelo_forms/CreatePost";


const CreateHUB = () => {

    return(
        <Box width="100%" display="flex" alignContent="center" alignItems="center" justifyContent="center" flexDirection="column" gap={4} pt={0}>

                <CreatePost/>

        </Box>
    )
    }


export default CreateHUB;