import React, { createContext, SetStateAction, useCallback } from "react";
import { Box, Stack, Typography, Button, Grid, Avatar, Tooltip } from "@mui/material";
import { FacebookProvider, LoginButton } from "react-facebook";
import { useState, useEffect } from "react";
import {
  LaptopOutlined,
  UserOutlined,
  CreditCardOutlined,
  FacebookOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import FacebookIcon from "@mui/icons-material/Facebook";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useNotification } from "@refinedev/core";
import { UploadChangeParam } from "antd/lib/upload/interface";
import type { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import {
  AccountCircle,
  AddShoppingCartOutlined,
  Build,
  ContentCopy,
  Google,
  Home,
  Image,
  MoneyOffOutlined,
  RedeemOutlined,
  RemoveCircleOutline,
  Subscriptions,
  Token,
  UploadOutlined,
} from "@mui/icons-material";
import { LinkedIn } from "react-linkedin-login-oauth2";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLogout } from "@react-oauth/google";
import { Layout, Menu, Upload } from "antd";
import dayjs from "dayjs";
import { okt_placeholer } from "assets";
import { Progress } from "antd";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useFacebookSDK } from "utils/FBSDK";
import apiconfig from "config";
import { ConnectMetaTour } from "utils/connectMetaTour";

const { Content } = Layout;

declare global {
  interface Window {
      FB: any;
  }
}


const Settings = () => {
  document.title = "Beállítások | Oktelo";

  const { open } = useNotification();
  const [data, setData] = useState<any>(undefined);
  const [selectedKey, setSelectedKey] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  /*   const [myProfile, setMyProfile] = useState<ProfileProps | undefined>(undefined); */
  const [profileData, setProfileData] = useState<any>(null);
  const [roleData, setRoleData] = useState<any>(null);
  const [connectionsData, setConnectionsData] = useState<any>(null);
  const [websiteData, setWebsiteData] = useState<any>(null);
  const [affiliateData, setAffiliateData] = useState<any>(null);
  const [partnerData, setPartnerData] = useState<any>(null);
  const [ isTourOpen, setTourOpen ] = useState(false);
  const [profilePic, setProfilePic] = useState([
    { name: "", url: "", type: "", uid: "" },
  ]);
  const [ canvaUserName, setCanvaUserName ] = useState('');
  const { isFBInitialized, isFBSdkLoaded } = useFacebookSDK();

  const [state, setState] = useState({
    profileData: null,
    roleData: null,
    websiteData: null,
    connectionsData: null,
    affiliateData: null,
    partnerData: null,
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiconfig.BACKEND_URI}/data/account`);
      if (response.data){
        setState({
          profileData: response.data.account,
          roleData: response.data.role,
          websiteData: response.data.connections.analytics,
          connectionsData: response.data.connections,
          affiliateData: response.data.affiliate,
          partnerData: response.data.partner,
        });
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

   const getCanvaData = async () => { 
    try {  
        const response = await axios.get('http://127.0.0.1:8080/api/v1/integration/canvadesigns', {
          headers: {
            'canva_type': 'profile'
          } 
        });

        if (response.data) {
            setCanvaUserName(response.data.data.username);
        }

    } catch (error) {
        console.log("Error getting canva data", error);
    }
} 

   const handleFacebookResponse = async (response: any) => {
    try {
      setIsLoading(true);

      if (response?.authResponse?.accessToken) {
        const accessToken = response.authResponse.accessToken;
        // Call the get pages:

        const getpages = await axios.get(
          `${apiconfig.BACKEND_URI}/connect/`,
          {
            params: {
              accessToken: accessToken,
            },
          }
        );

        if (getpages.status === 200) {
          open?.({
            type: "success",
            message: "Sikeresen csatlakozás.",
            description: "Remek hírek!",
          });
        }
      }
    } catch (error) {
      console.error("Error handling Facebook response:", error);
    } finally {
      setIsLoading(false);
      fetchData(); // Refresh data
    }
  };


  const handleDisconnectAll = async () => {
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/connect/disconnect`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Minden oldalad lecsatlakoztatva.",
          description: "Sikeres művelet!",
        });
      } else {
        console.error("Failed to connect FB page:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding pages:", error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: "http://localhost:3000",
    scope:
      "https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
    onSuccess: async (response) => {
      const authorizationCode = response.code;
      if (authorizationCode) {
        await connectAnalytics(authorizationCode);
      }
    },
  });

  const connectAnalytics = async (token: string) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${apiconfig.BACKEND_URI}/websites/connect`,
        { token: token }
      );
      if (response.status === 200) {
        console.log("success", response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    googleLogout();
  };
 
  const handleImageChange = (file: File | string, type: string) => {
    if (file instanceof File) {
      const reader = (readFile: File) =>
        new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(readFile);
        });

      reader(file).then((result: string) =>
        setProfilePic([{ name: file.name, url: result, type: type, uid: "-1" }])
      );
    }
  };
  const handleProfilePicChange = (
    info: UploadChangeParam<UploadFile<any>>,
    type: string
  ) => {
    const file = info.fileList[0]?.originFileObj as RcFile | undefined;
    if (file) {
      handleImageChange(file, type);
    }
  };

  const sendLinkedin = async (code: string): Promise<void> => {
    if (!code) {
      console.log("No code available");
      return;
    }

    try {
      const response = await axios.post(
        `${apiconfig.BACKEND_URI}/connect/connect-linkedin/`,
        { code }
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeres linkedin kapcsolódás.",
          description: "Jó hírek!",
        });
      } else {
        open?.({
          type: "error",
          message: "Sikertelen művelet",
          description: "Hiba!",
        });
      }
    } catch (error) {
      open?.({
        type: "error",
        message: "Sikertelen művelet",
        description: "Hiba!",
      });
    } finally {
      fetchData();
    }
  };

  const handleRemoveFBPage = async (pageId: any) => {
    try {
      setIsLoading(true);

      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/connect/okt-remove-facebook/${encodeURIComponent(
          pageId
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Successfully disconnected a page.",
          description: "Good job!",
        });
      } else {
        console.error(
          "Failed to remove connected FB page:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error removing connected FB page:", error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const handleRemoveAnalyticsProperty = async (id: any) => {
    try {
      setIsLoading(true);

      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/websites/disconnect/${id}`
      );

      if (response.status === 200) {
        open?.({
          message: "Sikeresen leválasztottál egy Google oldalt",
          type: "success",
        });
      } else {
        open?.({
          message: "Sikertelen művelet",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      open?.({
        message: "Sikertelen művelet, próbáld újra később.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const handleRemoveInstagramPage = async (pageId: any) => {
    try {
      setIsLoading(true);

      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/connect/okt-remove-instagram/${encodeURIComponent(
          pageId
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeresen lecsatlakoztatva.",
          description: "Szuper!",
        });
      } else {
        open?.({
          type: "error",
          message: "Sikertelen művelet",
          description: "Hiba!",
        });
      }
    } catch (error) {
      open?.({
        type: "error",
        message: "Sikertelen művelet",
        description: "Hiba!",
      });
    } finally {
      setIsLoading(false);
      fetchData();
    }
  }; 
  
  const renderContent = useCallback(() => {
    const { profileData, roleData, connectionsData, websiteData, affiliateData, partnerData } = state;

    switch (selectedKey) {
      case "0":
        return profileData ? FelhasznaloRender(profileData, handleProfilePicChange, setProfilePic) : <div>Nem sikerült betölteni</div>;
      case "1":
        return roleData ? ElofizetesRender(roleData) : <div>Nem sikerült betölteni</div>;
      case "2":
        return connectionsData && roleData ? (
          <KozossegiRender
            role={roleData}
            connection={connectionsData}
            handleRemoveFBPage={handleRemoveFBPage}
            handleRemoveInstagramPage={handleRemoveInstagramPage}
            handleFacebookResponse={handleFacebookResponse}
            handleLogout={handleLogout}
            googleLogin={googleLogin}
            handleDisconnectAll={handleDisconnectAll}
            sendLinkedin={sendLinkedin}
            isFBSdkLoaded={isFBSdkLoaded}
            isFBInitialized={isFBInitialized}
            isTourOpen={isTourOpen}
            setTourOpen={setTourOpen}
          />
        ) : <div>Nem sikerült betölteni</div>;
      case "3":
        return websiteData ? WeboldalRender(roleData, websiteData, googleLogin, handleRemoveAnalyticsProperty) : <div>Nem sikerült betölteni.</div>;
      case "4":
        return affiliateData ? AffiliateRender(affiliateData) : <div>Nem sikerült betölteni.</div>;
      case "5":
        return partnerData ? PartnerRender(partnerData) : <div>Nem sikerült betölteni.</div>;
      default:
        return <div>Default Content</div>;
    }
  }, [selectedKey, state, isTourOpen]);

  const handleMenuClick = ({ key }: { key: string }) => {
    setSelectedKey(key);
    console.log("clicked:", key);
  }; 

   useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([fetchData(), getCanvaData()]);
    };
  
    fetchAllData();
  }, []); 

  const subnavLabels = [
    "Felhasználó",
    "Előfizetés",
    "Közösségi oldalak",
    "Weboldalak",
    "Barátmeghívások",
    "Partnerprogram",
  ];
  const subnavKeys = ["0", "1", "2", "3", "4", "5", "6"];

  const icons = [
    UserOutlined,
    CreditCardOutlined,
    FacebookOutlined,
    LaptopOutlined,
    UserOutlined,
    CrownOutlined,
  ];

  const items2: MenuProps["items"] = subnavLabels.map((label, index) => {
    const key = subnavKeys[index];
    const icon = icons[index];

    return {
      key: `${key}`,
      icon: React.createElement(icon),
      label: label,
    };
  });

  if (isLoading)
    return (
      <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
      </Box>
    );


  return (
    <>
      <Box width="100%">
        <Typography className="t_oktelo_bold size_24 darkblue" mb={2}>
          Profil & Beállítások
        </Typography>
        <Layout>
          <Content style={{ padding: "0 0px" }}>
            <Layout
              style={{
                padding: "24px 0",
                background: "#fff",
                borderRadius: "12px",
              }}
              className="settings_layout"
            >
              <Menu
                mode="horizontal"
                defaultSelectedKeys={[selectedKey]}
                defaultOpenKeys={[selectedKey]}
                style={{
                  width: "100%",
                  paddingBottom: "12px",
                  marginBottom: "20px",
                }}
                items={items2}
                 onClick={handleMenuClick}
              />
              <Box
                style={{
                  padding: "0 24px",
                  minHeight: 500,
                  backgroundColor: "#fff !important",
                }}
              >
                 {renderContent()} 
              </Box>
            </Layout>
          </Content>
        </Layout>
        {/* <Grid container spacing={2}>
          <Grid item xl={12}>
            <Typography className="t_oktelo_medium size_20 darkblue">
              Integrációk
            </Typography>
          </Grid>
          <Grid item xl={3}>
          <Box className="integration-holder">
              <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" gap={2} alignItems="center">
                      <img src="https://res.cloudinary.com/duhpymkbk/image/upload/v1722793226/spnhbdn6nf4xyj79dcss.png"
                      style={{ width: '60px', height: 'auto', objectFit: 'contain'}}
                      alt="Canva App logó"/>
                      <Stack direction="column">
                          <Typography className="t_oktelo_normal size_12 darkblue">
                              {canvaUserName ? 'Összekötve' : 'Nincs összekötve'}
                          </Typography>
                          <Typography className="t_oktelo_medium size_14 darkblue">
                              {canvaUserName || 'Jelentkezz be'}   
                          </Typography>
                  </Stack>
                  </Stack>
                  <Stack direction="column" gap={0.5}>
                      <Tooltip title="Canva megnyitása">
                          <button className="integration-settings-button">
                              <Home/>
                          </button>
                      </Tooltip>
                      <Tooltip title="Integrációk beállításai">
                          <button className="integration-settings-button">
                              <Settings/>
                          </button>
                      </Tooltip>
                  </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
};

const ElofizetesRender = (role: any) => {
  const roleDisplay = (role: number) => {
    if (role === 0) {
      return "Ingyenes csomag";
    } else if (role === 1) {
      return "Kezdő csomag";
    } else if (role === 2) {
      return "Haladó csomag";
    } else if (role === 3) {
      return "Prémium csomag";
    } else if (role === 4) {
      return "Support";
    }
  };

  const tokenDisplay = (role: number) => {
    if (role === 0) {
      return "0 / nap";
    } else if (role === 1) {
      return "5 / nap";
    } else if (role === 2) {
      return "20 / nap";
    } else if (role === 3) {
      return "50 / nap";
    } else if (role === 4) {
      return "999 / nap";
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Stack direction="column" width="100%" gap={5} flexWrap="wrap">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexWrap="wrap"
          >
            <Box>
              <Typography className="t_oktelo_bold size_16 darkblue">
                Oktelo tagságod, előfizetésed
              </Typography>
            </Box>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} xs={12} sm={12} md={12}>
              <Stack direction="row" gap={1} alignItems="center" mb={3}>
                <Subscriptions sx={{ color: "#925FFF", fontSize: "20px" }} />
                <Typography className="t_oktelo_semibold size_16 darkblue">
                  Jelenlegi előfizetésed
                </Typography>
              </Stack>
              <Stack
                direction="column"
                gap={2.5}
                justifyContent="space-between"
                maxHeight="100%"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  borderBottom="1px solid #f6f6f6"
                  pb={1.5}
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Csomag neve:
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    {roleDisplay(role.type)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  borderBottom="1px solid #f6f6f6"
                  pb={1.5}
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Előfizetés kezdete:
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    {dayjs(role.startDate).format("YYYY.MM.DD HH:mm")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  borderBottom="1px solid #f6f6f6"
                  pb={1.5}
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Hosszabbítás dátuma / lejárat:
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    {role.endDate
                      ? dayjs(role.endDate).format("YYYY.MM.DD HH:mm")
                      : "Nincs"}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  borderBottom="1px solid #f6f6f6"
                  pb={1.5}
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Lemondva:
                  </Typography>
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    {role.cancalAtEnd ? "Igen" : "Nem"}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  borderBottom="1px solid #f6f6f6"
                  pb={1.5}
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Token frissítés:
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    {tokenDisplay(role.type)}
                  </Typography>
                </Stack>

                <button
                  className="b_oktelo_purple_action"
                  style={{ width: "100%", marginTop: "16px" }}
                  onClick={() => (window.location.href = "/subscription")}
                >
                  Részletek
                </button>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} xs={12} sm={12} md={12}>
              <Stack direction="row" gap={1} alignItems="center" mb={3}>
                <Build sx={{ color: "#925FFF", fontSize: "20px" }} />
                <Typography className="t_oktelo_semibold size_16 darkblue">
                  Csomag limitek
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                gap={1.5}
                flexWrap="wrap"
              >
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  gap={1}
                  width="100%"
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Posztok{" "}
                    <span className="t_oktelo_normal oktgray">
                      | {role.limits.currentPosts}/{role.limits.maxPosts}{" "}
                    </span>
                  </Typography>
                  <Progress
                    percent={
                      ((role.limits.currentPosts / role.limits.maxPosts) * 100)
                    }
                    size={["100%", 15]}
                    strokeColor="#925FFF"
                  />
                </Stack>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  gap={1}
                  width="100%"
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Közösségi oldalak:{" "}
                    <span className="t_oktelo_normal oktgray">
                      |{role.limits.currentConnections}/
                      {role.limits.maxConnections}
                    </span>
                  </Typography>
                  <Progress
                    percent={
                      (role.limits.currentConnections /
                        role.limits.maxConnections) *
                      100
                    }
                    size={["100%", 15]}
                    strokeColor="#925FFF"
                  />
                </Stack>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  gap={1}
                  width="100%"
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Jóváhagyások:{" "}
                    <span className="t_oktelo_normal oktgray">
                      |{role.limits.currentApprovals}/{role.limits.maxApprovals}
                    </span>
                  </Typography>
                  <Progress
                    percent={
                      (role.limits.currentApprovals /
                        role.limits.maxApprovals) *
                      100
                    }
                    size={["100%", 15]}
                    strokeColor="#925FFF"
                  />
                </Stack>

                <Stack
                  direction="column"
                  alignItems="flex-start"
                  gap={1}
                  width="100%"
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Hirdetések:{" "}
                    <span className="t_oktelo_normal oktgray">
                      |{role.limits.currentAds}/{role.limits.maxAds}
                    </span>
                  </Typography>
                  <Progress
                    percent={
                      (role.limits.currentAds / role.limits.maxAds) * 100
                    }
                    size={["100%", 15]}
                    strokeColor="#925FFF"
                  />
                </Stack>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  gap={1}
                  width="100%"
                >
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Riportok:{" "}
                    <span className="t_oktelo_normal oktgray">
                      |{role.limits.currentReports}/{role.limits.maxReports}
                    </span>
                  </Typography>
                  <Progress
                    percent={
                      (role.limits.currentReports / role.limits.maxReports) *
                      100
                    }
                    size={["100%", 15]}
                    strokeColor="#925FFF"
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

const FelhasznaloRender = (profile: any, handleProfilePicChange: any, setProfilePic: any) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Stack direction="column" width="100%" gap={5} flexWrap="wrap">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexWrap="wrap"
          >
            <Box>
              <Typography className="t_oktelo_bold size_16 darkblue">
                Felhasználói fiókod
              </Typography>
              <Typography className="t_oktelo_normal size_12 darkblue">
                Frissítve{" "}
                <span className="t_oktelo_medium">
                  {dayjs(profile.updatedAt).format("YYYY.MM.DD HH:mm")}
                </span>
              </Typography>
            </Box>
            <Box>
              <button className="b_oktelo_purple_action">Szerkesztés</button>
            </Box>
          </Stack>
          <Box>
            <Stack direction="row" gap={1} alignItems="center" mb={1.5}>
              <Image sx={{ color: "#925FFF", fontSize: "20px" }} />
              <Typography className="t_oktelo_semibold size_16 darkblue">
                Profilkép
              </Typography>
            </Stack>
            <Stack direction="row" gap={3} alignItems="center">
              <Avatar
                src={
                  'https://res.cloudinary.com/duhpymkbk/image/upload/v1721207457/ggy25dbxjvjxcnjahlur.png'
                }
                sx={{ height: "100px", width: "100px", objectFit: 'contain' }}
              ></Avatar>
{/*             <Upload 
            type="select"
            maxCount={1}
            multiple={false}
            accept="image/*"
            beforeUpload={() => false}
            onChange={(e) => handleProfilePicChange(e)}
            fileList={profilePic[0].url !== "" ? profilePic : []}
            onRemove={() =>
              setProfilePic([{ name: "", url: "", type: "", uid: "-1" }])
            }
            >
              <button className="home-action-button b925" disabled> <UploadOutlined /> Megváltoztatás</button>
          </Upload> */}
              <button className="home-action-button bc6a" disabled>Törlés</button>
            </Stack>

          </Box>
          <Box>
            <Stack direction="row" gap={1} alignItems="center" mb={1.5}>
              <AccountCircle sx={{ color: "#925FFF", fontSize: "20px" }} />
              <Typography className="t_oktelo_semibold size_16 darkblue">
                Adatok
              </Typography>
            </Stack>
            <Stack direction="column" gap={1}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Felhasználónév:
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {profile.username}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  E-mail cím:
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {profile.email}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Vállalat:
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {profile.company ?? "Nincs megadva"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Regisztrációs kód:
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {profile.regpromo}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Oktelo Tokenek:
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {profile.tokens}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

type KozossegiRenderProps = {
  role: any;
  connection: any;
  handleRemoveFBPage: (id: string) => void;
  handleRemoveInstagramPage: (id: string) => void;
  handleFacebookResponse: (response: any) => void;
  handleLogout: () => void;
  googleLogin: () => void;
  handleDisconnectAll: () => void;
  sendLinkedin: (code: string) => void; // Adjusting the type signature here
  isFBSdkLoaded: boolean;
  isFBInitialized: boolean;
  isTourOpen: boolean;
  setTourOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type SocialSite = {
  id: string;
  name: string;
  photo: string;
};

const KozossegiRender: React.FC<KozossegiRenderProps> = ({
  role,
  connection,
  handleRemoveFBPage,
  handleRemoveInstagramPage,
  handleFacebookResponse,
  handleLogout,
  googleLogin,
  handleDisconnectAll,
  sendLinkedin,
  isFBSdkLoaded,
  isFBInitialized,
  isTourOpen,
  setTourOpen,
}) => {

  

// Generate a random string of specified length
function generateRandomString(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// Generate the code verifier
function generateCodeVerifier() {
  return generateRandomString(128);
}

// Encode the array buffer to Base64 URL-safe format
function base64UrlEncode(arrayBuffer: any) {
  const byteArray = new Uint8Array(arrayBuffer);
  const base64String = btoa(String.fromCharCode.apply(null, Array.from(byteArray)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return base64String;
}

// Generate the code challenge from the code verifier
async function generateCodeChallenge(codeVerifier: any) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest('SHA-256', data);
  return base64UrlEncode(digest);
}

  const tiktokRedirect = async () => {
    try {
      const state = Math.random().toString(36).substring(2);
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallenge(codeVerifier); 

       localStorage.setItem('codeVerifier', codeVerifier); 

      const response = await axios.get(`${apiconfig.BACKEND_URI}/connect/tiktokconnect`, {
        params: {
          state,          
          code_challenge: codeChallenge,
          code_challenge_method: 'S256',
          code_verifier: codeVerifier,
        }
      });

      const { authUrl } = response.data;
      console.log('Redirecting to:', authUrl);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error during TikTok login:', error);
    }
  };

  const handleTourClose = () => {
    console.log("close trigger");
    setTourOpen(false);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {isTourOpen &&
            <ConnectMetaTour isOpen={isTourOpen} handleClose={handleTourClose}/>
          }
          <Stack direction="column" width="100%" gap={1} flexWrap="wrap">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              flexWrap="wrap"
            >
              <Box>
                <Typography className="t_oktelo_bold size_16 darkblue">
                  Közösségi oldalaid
                </Typography>
              </Box>
              <Stack direction="row" alignItems="center" gap={1.5}>
                <Button className="oktelo_card_button" onClick={() => setTourOpen(true)}>
                  Segítség
                </Button>
                {(connection.linkedin.length > 0 ||
                  connection.facebook.length > 0 ||
                  connection.instagram.length > 0) ? (
                  <Button
                    className="oktelo_card_button"
                    sx={{ color: "#cd486b !important" }}
                    onClick={() => {
                      handleDisconnectAll();
                    }}
                  >
                    Összes leválasztása
                  </Button>
                ): <></>}
              </Stack>
            </Stack>
            <Stack direction="column" gap={1.5} mb={4}>
              <Typography className="t_oktelo_normal darkblue size_14">
                Itt tudod csatlakoztatni a közösségi oldalaidat az Oktelo-hoz. A
                gombok megnyitják az engedélykérő, bejelentkezési felületeket,
                ahol ki tudod választani, hogy mely oldalakat szeretnéd
                csatlakoztatni. A sikeres összekapcsolás után itt fognak
                megjelenni az oldalaid.
              </Typography>
              {role.type > 0 ? (
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  className="login_provider_stack"
                  mt={1}
                >
                  {!isFBSdkLoaded ? (
        <div>Loading Facebook SDK...</div>
      ) : !isFBInitialized ? (
        <div>Initializing Facebook SDK...</div>
      ) : (
                    <FacebookProvider appId="690285639938286" version="v2.7">
                      <LoginButton
                        data-max-rows="1"
                        data-size="large"
                        data-button-type="continue_with"
                        data-use-continue-as="true"
                        scope="pages_show_list,email,instagram_basic,instagram_manage_insights,read_insights,pages_read_engagement,pages_read_user_content,pages_manage_engagement,instagram_manage_comments,pages_messaging,instagram_manage_messages,pages_manage_posts,instagram_content_publish"
                        onError={(error) => console.log(error)}
                        onSuccess={handleFacebookResponse}
                      >
                        <FacebookIcon
                          sx={{ fontSize: "18px", color: "#1877F2" }}
                        />
                        <InstagramIcon
                          sx={{ fontSize: "18px", color: "#E4405F" }}
                        />{" "}
                        Meta
                      </LoginButton>
                    </FacebookProvider>
                  )}
                  <button className="home-action-button b925" onClick={tiktokRedirect}>Tiktok</button>
                  <LinkedIn
                    clientId="77e25zzvrjzf76"
                    redirectUri={`http://localhost:3000/linkedin`}
                    scope="w_member_social,email,profile,openid"
                    onSuccess={(code) => {
                      console.log("success", code);
                      sendLinkedin(code as string);
                    }}
                    onError={(error) => {
                      console.log(error);
                    }}
                  >
                    {({ linkedInLogin }) => (
                      <button
                        onClick={linkedInLogin}
                        className="linkedin-connect"
                      >
                        <LinkedInIcon sx={{ fontSize: "18px" }} />
                        LinkedIn
                      </button>
                    )}
                  </LinkedIn>

                  {/* <button className="google-connect" onClick={() => googleLogin()}><Google sx={{ fontSize: '18px' }}/> Google Analytics</button> */}
                  {/* <Button onClick={() => handleLogout()}>Logout</Button> */}
                </Stack>
              ) : (
                <Typography className="t_oktelo_normal t_purple size_14">
                  Előfizetés nélkül nem tudsz oldalakat csatlakoztatni.
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {connection.facebook?.map((fbpage: SocialSite) => (
          <Grid item xs={12} sm={12} md={6} xl={3} lg={3} key={fbpage.id}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                border: "1px solid #f6f6f6",
                p: 1.5,
                position: "relative",
              }}
            >
              <Stack direction="column" gap={1.5} alignItems="flex-start">
                <Stack direction="row" gap={1} alignItems="center">
                  <img
                    src={fbpage.photo}
                    style={{
                      borderRadius: "50%",
                      width: "45px",
                      height: "45px",
                      objectFit: "contain",
                      border: "2px solid #1877F2",
                    }}
                    alt={fbpage.name}
                  />
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <FacebookIcon sx={{ fontSize: '16px', color: '#989898'}}/>
                    <Typography className="t_oktelo_medium darkblue size_14">
                      {fbpage.name}
                    </Typography>
                  </Stack>
                </Stack>
                <button
                  className="notransform size_10"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    border: 0,
                    background: "transparent",
                  }}
                  onClick={() => handleRemoveFBPage(fbpage.id)}
                >
                  <RemoveCircleOutline className="size_14 b_oktelo_red" />
                </button>
              </Stack>
            </Box>
          </Grid>
        ))}
        {connection.instagram?.map((igpage: SocialSite) => (
          <Grid item xs={12} sm={12} md={6} xl={3} lg={3} key={igpage.id}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                border: "1px solid #f6f6f6",
                p: 1.5,
                position: "relative",
              }}
            >
              <Stack direction="column" gap={1.5} alignItems="flex-start">
                <Stack direction="row" gap={1} alignItems="center">
                  <img
                    src={igpage.photo}
                    style={{
                      borderRadius: "50%",
                      width: "45px",
                      height: "45px",
                      objectFit: "contain",
                      border: "2px solid #E4405F",
                    }}
                    alt={igpage.name}
                  />
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <InstagramIcon sx={{ fontSize: '16px', color: '#989898'}}/>
                    <Typography className="t_oktelo_medium darkblue size_14">
                      @{igpage.name}
                    </Typography>
                  </Stack>
                </Stack>
                <button
                  className="notransform size_10"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    border: 0,
                    background: "transparent",
                  }}
                  onClick={() => handleRemoveInstagramPage(igpage.id)}
                >
                  <RemoveCircleOutline className="size_14 b_oktelo_red" />
                </button>
              </Stack>
            </Box>
          </Grid>
        ))}
      {connection.linkedin?.map((lipage: SocialSite) => (
          <Grid item xs={12} sm={12} md={6} xl={3} lg={3} key={lipage.id}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                border: "1px solid #f6f6f6",
                p: 1.5,
                position: "relative",
              }}
            >
              <Stack direction="column" gap={1.5} alignItems="flex-start">
                <Stack direction="row" gap={1} alignItems="center">
                  <img
                    src={lipage.photo}
                    style={{
                      borderRadius: "50%",
                      width: "45px",
                      height: "45px",
                      objectFit: "contain",
                      border: "2px solid #E4405F",
                    }}
                    alt={lipage.name}
                  />
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <LinkedInIcon sx={{ fontSize: '16px', color: '#989898'}}/>
                    <Typography className="t_oktelo_medium darkblue size_14">
                      {lipage.name}
                    </Typography>
                  </Stack>
                </Stack>
                <button
                  className="notransform size_10"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    border: 0,
                    background: "transparent",
                  }}
                  onClick={() => handleRemoveInstagramPage(lipage.id)}
                >
                  <RemoveCircleOutline className="size_14 b_oktelo_red" />
                </button>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const WeboldalRender = (
  role: any,
  websites: any,
  googleLogin: () => void,
  handleRemoveAnalyticsProperty: (id: any) => void
) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Stack direction="column" width="100%" gap={1} flexWrap="wrap">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              flexWrap="wrap"
            >
              <Box>
                <Typography className="t_oktelo_bold size_16 darkblue">
                  Csatlakoztatott Google Analyitcs tulajdonok
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              className="login_provider_stack"
              mt={1}
            >
              <button hidden style={{ display: "none" }}></button>
              {role.type > 0 ?
                  <button className="google-connect" onClick={() => googleLogin()}>
                  <Google sx={{ fontSize: "18px" }} /> Google Analytics
                </button>

                  :
                <Typography className="t_oktelo_normal t_purple size_14">
                Előfizetés nélkül nem tudsz weboldalakat csatlakoztatni.
              </Typography>
                
            }

            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2.5}>
        {websites?.map((page: any) => (
          <Grid item xs={12} sm={12} md={6} xl={3} lg={3} key={page.propertyId}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                border: "1px solid #f6f6f6",
                p: 1.5,
                position: "relative",
              }}
            >
              <Stack direction="column" gap={1.5} alignItems="flex-start">
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    {page.propertyName}
                  </Typography>
                </Stack>
              </Stack>
              <button
                className="notransform size_10"
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  border: 0,
                  background: "transparent",
                }}
                onClick={() => {
                  handleRemoveAnalyticsProperty(page.propertyId);
                }}
              >
                <RemoveCircleOutline className="size_14 b_oktelo_red" />
              </button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const AffiliateRender = (affiliate: any) => {
  const getPercent = (level: number, count: number) => {
    if (level === 0 && count <= 15) {
      return parseFloat(((count / 15) * 100).toFixed(2));
    } else if (level === 1 && count <= 30 && count > 15) {
      return parseFloat(((count / 30) * 100).toFixed(2));
    } else if (level === 2 && count <= 80 && count > 30) {
      return parseFloat(((count / 80) * 100).toFixed(2));
    } else {
      return 0; 
    }
  };

  const copyUrl = (code: string) => {
    const url = `http://localhost:3000/register?promo=${code}`;
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Stack direction="column" width="100%" gap={1} flexWrap="wrap">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              flexWrap="wrap"
            >
              <Box>
                <Typography className="t_oktelo_bold size_16 darkblue">
                  Barátmeghívások és jutalmak
                </Typography>
              </Box>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography className="t_oktelo_normal size_14 darkblue">
                Küldd el az ismerőseidnek ezt a kódot:{" "}
                <span className="t_oktelo_normal t_purple">
                  {affiliate.promoCode}
                </span>
                , amit regisztrációkor tudnak beírni és szerezz különféle
                jutalmakat.
              </Typography>
              <Typography className="t_oktelo_normal size_14 darkblue">
                Jelenleg{" "}
                <span className="t_oktelo_normal t_purple">
                  {affiliate.usedCount}
                </span>{" "}
                meghívott barátod van.
              </Typography>
              <Typography className="t_oktelo_normal size_14 darkblue">
                A szinted:{" "}
                <span className="t_oktelo_normal t_purple">
                  {affiliate.rewardLevel}
                </span>
                . Következő szint:{" "}
                <span className="t_oktelo_normal t_purple">
                  {affiliate.rewardLevel === 0
                    ? "15 barát"
                    : affiliate.rewardLevel === 1
                    ? "30 barát"
                    : "80 barát"}
                </span>{" "}
                meghívásánál.
              </Typography>
              <Typography className="t_oktelo_medium size_14 darkblue">
                Haladás:
              </Typography>
              <Progress
                percent={getPercent(affiliate.rewardLevel, affiliate.usedCount)}
                size={["100%", 15]}
                strokeColor="#925FFF"
              />
              <Typography className="t_oktelo_medium size_14 darkblue">
                Következő ajánédkod:
              </Typography>
              {affiliate.rewardLevel === 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  p={1.5}
                  border={"1px solid #d3d3d3"}
                >
                  <Token sx={{ color: "#925FFF" }} />
                  <Typography className="t_oktelo_medium size_14 t_purple">
                    50 Oktelo Token
                  </Typography>
                </Stack>
              )}
              {affiliate.rewardLevel === 1 && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Token />
                  <Typography className="t_oktelo_medium size_14 t_purple">
                    40% kedvezmény a következő előfizetésedből
                  </Typography>
                </Stack>
              )}
              {affiliate.rewardLevel === 1 && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Token />
                  <Typography className="t_oktelo_medium size_14 t_purple">
                    2 hónap ingyenes prémium tagság
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              className="login_provider_stack"
              mt={1}
            >
              <button hidden style={{ display: "none" }}></button>
              <button
                className="copy-affiliate"
                onClick={() => {
                  copyUrl(affiliate.promoCode);
                }}
              >
                <ContentCopy sx={{ fontSize: "18px" }} /> Meghívó link
              </button>
              <button className="claim-reward" onClick={() => {}}>
                <RedeemOutlined sx={{ fontSize: "18px" }} /> Ajándék beváltása
              </button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};


const PartnerRender = (partner: any) => {
  const { open } = useNotification();

  const copyUrl = (code: string) => {
    const url = `http://localhost:3000/register?promo=${code}`;
    navigator.clipboard.writeText(url);
  };

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code);
  };

  const handlePayout = () => {
    if (!partner.payoutAllowed) {
      return open?.({
        type: 'error',
        message: 'Nem érted el a kiutalás szintjét'
      })
    }
  }


  return (
    <>
<Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Stack direction="column" width="100%" gap={1} flexWrap="wrap">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              flexWrap="wrap"
            >
              <Box>
                <Typography className="t_oktelo_bold size_16 darkblue">
                  Partnerprogram
                </Typography>
              </Box>
            </Stack>
            {partner.isPartner? 
            <>
            <Stack direction="column" gap={2}>
              <Typography className="t_oktelo_normal size_14 darkblue">
                Hello Oktelo Partner! A partnerséggel jutalmakat tudsz szerezni, aminek a részleteid itt találod.
              </Typography>
              <Stack direction="row" gap={1.2} alignItems="center" p={1.5} border={'1px solid #d3d3d3'} width="auto">
                <UserOutlined className="t_oktelo_semibold size_16 darkblue"/>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Kódoddal regisztráltak száma:
                </Typography>
                <Typography className="t_oktelo_normal size_16 t_purple">
                  {partner.usedCountRegister}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1.2} alignItems="center" p={1.5} border={'1px solid #d3d3d3'} width="auto">
                <AddShoppingCartOutlined className="t_oktelo_semibold size_16 darkblue"/>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Kódoddal előfizetők száma:
                </Typography>
                <Typography className="t_oktelo_normal size_16 t_purple">
                  {partner.usedCount}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1.2} alignItems="center" p={1.5} border={'1px solid #d3d3d3'} width="auto">
                <CreditCardOutlined className="t_oktelo_semibold size_16 darkblue"/>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Eddigi jutalmad: 
                </Typography>
                <Typography className="t_oktelo_normal size_16 t_purple">
                  {partner.payoutValue} Ft
                </Typography>
              </Stack>
              <Stack direction="row" gap={1.2} alignItems="center" p={1.5} border={'1px solid #d3d3d3'} width="auto">
                <MoneyOffOutlined className="t_oktelo_semibold size_16 darkblue"/>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Kiutalás minimum: 
                </Typography>
                <Typography className="t_oktelo_normal size_16 t_purple">
                  {partner.payoutCount} előfizető
                </Typography>
                <Typography className="t_oktelo_normal size_12" sx={{ color: '#989898'}}>
                  {partner.payoutCount - partner.usedCount} van hátra
                </Typography>
              </Stack>
              <Stack direction="row" gap={1.2} alignItems="center" p={1.5} border={'1px solid #d3d3d3'} width="auto">
                <MoneyOffOutlined className="t_oktelo_semibold size_16 darkblue"/>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Promóciós kódod: 
                </Typography>
                <Typography className="t_oktelo_normal size_16 t_purple">
                  {partner.promoCode}
                </Typography>
                <Typography className="t_oktelo_normal size_12" sx={{ color: '#989898'}}>
                  Fizetésnél és regisztrációnál lehet megadni
                </Typography>
              </Stack>
              <Stack direction="row" gap={1.2} alignItems="center" p={1.5} border={'1px solid #d3d3d3'} width="auto">
                <MoneyOffOutlined className="t_oktelo_semibold size_16 darkblue"/>
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Kiutalás kezdeményezése: 
                </Typography>
                <Typography className="t_oktelo_normal size_16 t_purple">
                  {partner.payoutAllowed? 'Lehetséges' : 'Nem lehetséges'}
                </Typography>
                <Typography className="t_oktelo_normal size_12" sx={{ color: '#989898'}}>
                  {partner.payoutAllowed? 'Elérted az elegendő előfizetőt' : 'Nem érted el az elegendő előfizetéseket'}
                </Typography>
              </Stack>       
              <Typography className="t_oktelo_normal size_14 darkblue">
                Figyelem! A kiutalások kezdeményezését 24 óránként frissítjük.
              </Typography>      
                <Stack
                direction="row"
                gap={2}
                alignItems="center"
                className="login_provider_stack"
                mt={1}
              >
                <button hidden style={{ display: "none" }}></button>
                <button
                  className="copy-affiliate"
                  onClick={() => {
                    copyUrl(partner.promoCode);
                  }}
                >
                  <ContentCopy sx={{ fontSize: "18px" }} /> Regisztráció meghívó URL
                </button>
                <button
                  className="google-connect"
                  onClick={() => {
                    copyCode(partner.promoCode);
                  }}
                >
                  <ContentCopy sx={{ fontSize: "18px" }} /> Kód másolása
                </button>
                <button className="claim-reward" disabled={partner.payoutAllowed? true : false} onClick={handlePayout}>
                  <RedeemOutlined sx={{ fontSize: "18px" }} /> Kiutalás kérése
                </button>
              </Stack>

            </Stack>
          </> :
          
          <Typography className="t_oktelo_normal dakblue size_14">Nem vagy Oktelo partner.</Typography>
          
          }
          </Stack>
        </Grid>
      </Grid>
    </>


  )

}

export default Settings;
