import React, { useState, useEffect, FormEvent } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Box, Stack, Typography, Chip, Avatar, CircularProgress, Button, TextareaAutosize } from '@mui/material';
import { okt_placeholer } from 'assets';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AI_Charts from 'components/charts/AI_Charts';
import { Tag } from 'antd';
import { useNotification } from "@refinedev/core";
import dayjs from 'dayjs';
import { Popconfirm } from "antd";
import { AddTaskRounded, PersonRounded, ReplyRounded, ForumRounded, DeleteRounded } from '@mui/icons-material';
import apiconfig from 'config';

interface Attachment {
    description: string;
    media?: {
        image?: {
            src: string;
            height: number;
            width: number;
        };
    };
    url?: string;
}



interface PostAnalyticsData {
    type: string;
    id: string;
    page: string;
    platform: string;
    photo_url: string;
    created_time?: Date;
    timestamp?: Date;
    attachments?: Attachment[];
    message: string;
    page_name: string;
    page_image_url: string;
};

interface BaseMetrics {
    title: string | undefined;
    count: number | 0;
}

interface CommentAttachment {
    media: {
        image?: {
            height: number;
            src: string;
            width: number;
        };
        source?: string; 
    };
    target?: {
        id?: string;
        url: string;
    };
    title?: string;
    type: string;
    url?: string;
}

interface Reply {
    timestamp: string;
    text: string;
    id: string;
}

interface Comment {
    comment_count?: number;
    like_count?: number;
    parent?: Comment;
    attachment?: CommentAttachment;
    from: {
        name: string;
        id: string;
        username?: string;
    };
    created_time: string;
    timestamp?: string;
    message: string;
    text?: string;
    id: string;
    replies?: { data: Reply[] }; 
}

interface CommentData {
    comments: Comment[];
}

interface PostScores {
    name: string;
    score: number;
  }

const PostAnalytics = () => {
    document.title = "Poszt elemzés | Oktelo";
    const { open, close } = useNotification();
    const location = useLocation();
    const { post, sp, platform } = location.state;
    const [impressions, setImpressions] = useState<BaseMetrics>({ title: undefined, count: 0 });
    const [engagedUsers, setEngagedUsers] = useState<BaseMetrics>({ title: undefined, count: 0 });
    const [totalReactions, setTotalReactions] = useState<BaseMetrics>({ title: undefined, count: 0 }); 
    const [totalBase, setTotalBase] = useState<number>(0);   
    const [commentData, setCommentData] = useState<CommentData>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [selectedComment, setSelectedComment] = useState<Comment>();
    const [areaFieldValue, setAreaFieldValue] = useState<string>('');
    const [needRefetch, setNeedRefetch] = useState<Boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<Boolean>(false);
    const [getPostScore, setPostScore] = useState<number>(0);
    const [getAvgScore, setAvgScore] = useState<number>(0);
    const [getPointDifference, setPointDifference] = useState<number>(0);
    const [commentType, setCommentType] = useState<string>('');
    const navigate = useNavigate();

    console.log(location.state);

const chartDesc = {
    imp: "The number of times your post entered a person's screen.",
    eng: "The number of people who clicked anywhere in your posts.",
    reac: "The number of reactions, saves, comments and shares for your post.",
    reach: "The number of unique accounts that saw this post at least once. Reach and impressions are not the same, the latter can also include when the same account sees your post more than once."
}

    let getPostDate, getPostImage, getPostUrl;
    getPostDate = post.created_time? dayjs(post.created_time).format('LLL') : post.timestamp? dayjs(post.timestamp).format('LLL') : 'Unknown';
    getPostImage = post.attachment_image;
    getPostUrl =  post.permalink || '';


    useEffect(() => {
        if (post && post.insights) {
            Object.entries(post.insights).forEach(([name, value]) => {
                switch (name) {
                    case "post_impressions":
                    case "impressions":
                        setImpressions((prevState) => ({ ...prevState, title: 'Impressions', count: value as number }));
                        break;
                    case "post_engaged_users":
                    case "total_interactions":
                        setEngagedUsers((prevState) => ({ ...prevState, title: name === 'post_engaged_users'? 'Engaged users' : 'Total interactions',  count: value as number }));
                        break;
                    case "post_reactions_by_type_total":
                        let totalReactionsCount: number = 0;
                        if (typeof value === 'object') {
                            totalReactionsCount = Object.values(value as Record<string, number>).reduce((acc, curr) => acc + curr, 0);
                        } else if (typeof value === 'number') {
                            totalReactionsCount = value;
                        }
                        setTotalReactions({ title: "Reactions", count: totalReactionsCount });
                        break;
                    case "reach":
                        setTotalReactions({ title: "Reach", count: value as number });
                        break;
                    default:
                        break;
                }
            }); 
        }
    }, [post]);

    
useEffect(() => {
    async function fetchPostAnalytics(post: PostAnalyticsData) {
        const getPageID = localStorage.getItem('sp');
        setLoading(true);
        try {
            const response = await axios.get(
                `${apiconfig.BACKEND_URI}/socialdata/post/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        pageId: sp,
                        postId: post.id,
                        timeFrame: "week",
                        platform: platform,
                    },
                } 
            );
            const data = response.data;
            if (data){
                if (data.comments){
                    const comments = data.comments;
                    setCommentData({ comments });
                    if (data.comments.length <= 0)
                        {
                            setSelectedComment(undefined);
                        }
                }
            }
        } catch (error) {
            console.error("Error fetching post analytics:", error);
        }
        finally{
            setLoading(false);
            setNeedRefetch(false);
        }
    }
    fetchPostAnalytics(post);       
}, [post, needRefetch]);   

const renderComments = (comments: Comment[]) => { 
    const mainComments = comments.filter(comment => !comment.parent);
    if(!mainComments || mainComments.length <= 0) { return <Typography className="t_oktelo t_desc" p={3}>No comments to display.</Typography>};
    return mainComments.map((mainComment, mainId) => (
        <Grid item lg={6}>
        <Stack direction="column" key={mainId} gap={1} mb={2} className="b_okt_commentsbox" width='100%'>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                <Stack direction="row" gap={0.5} alignItems="center">
                    <PersonRounded/>
                    <Typography className="t_oktelo t_highlight">
                        {mainComment.from 
                            ? (mainComment.from.name || mainComment.from.username || 'Anonymous Person')
                            : 'Anonymous Person'}
                    </Typography>

                </Stack>
                <Tag bordered={false} color='purple'>
                {mainComment.created_time ? dayjs(mainComment.created_time).format('LLL') : mainComment.timestamp ? dayjs(mainComment.timestamp).format('LLL') : 'N/A'}
                </Tag>
                <Tag bordered={false} color='gold'>
                Replies: { mainComment.comment_count ? mainComment.comment_count 
                : (mainComment.replies && mainComment.replies.data.length > 0 ? mainComment.replies.data.length : 'N/A') 
                } 
                </Tag>
                <Tag bordered={false} color='blue'>Likes: {mainComment.like_count}</Tag>
                {mainComment.attachment && mainComment.attachment.type &&
                <Tag bordered={false} color='magenta'>Attachment: {mainComment.attachment.type}</Tag>
                }
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                {mainComment.attachment?.media?.image?.src && (
                    <img src={mainComment.attachment.media.image.src} width="100px" />
                )}
                <Typography className="t_oktelo t_misc" sx={{ textAlign: 'justify' }}>{mainComment.message || mainComment.text}</Typography>
            </Stack>
            <Stack alignItems="center" justifyContent="flex-end" direction="row">
                <Button className="b_oktelo b_purple" sx={{ width: 'auto', color: '#925fff' }} endIcon={<ReplyRounded/>}
                onClick={() => setSelectedComment(mainComment)}
                >
                    Reply
                </Button>
                <Popconfirm title="Comment Manager" description="Are you sure to delete this comment? You can't undo this action."
                onCancel={cancelDeleteConfirm} onConfirm={handleDeleteComment}>
                <Button onClick={() => {showDeleteConfirm(); setSelectedComment(mainComment); }} className="b_oktelo b_purple"
                sx={{ width: 'auto', color: '#C41E3A' }} endIcon={<DeleteRounded/>}>
                    Delete
                </Button></Popconfirm>
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="center">
                <ForumRounded sx={{ fontSize: '16px'}}/>
                <Typography className="t_oktelo t_highlight">Replies</Typography>
            </Stack>
                {mainComment.replies ? (
                mainComment.replies.data.map((comment, id) => (
                <Stack key={id} m={2}>
                    <Stack direction="column" gap={2}>
                        <Stack direction="row" gap={0.5} alignItems="center">
                            <PersonRounded/>
                            <Typography className="t_oktelo t_highlight">Instagram user</Typography>
                            <Tag bordered={false} color='purple'>
                            {comment.timestamp ? dayjs(comment.timestamp).format('LLL') : 'N/A'}
                            </Tag>
                        </Stack>
                        <Typography className="t_oktelo t_misc" sx={{ textAlign: 'justify' }}>{comment.text || ""}</Typography>
                    </Stack>
                    </Stack>
                ))
            ) : (
                renderReplies(mainComment.id, comments)
)}
        </Stack>
        </Grid>
    ));
};

const renderReplies = (parentId: string, comments: Comment[]) => {
    const replies = comments.filter(comment => comment.parent && comment.parent.id === parentId);
    return replies.map((reply, replyId) => (
        <Stack direction="column" key={replyId} gap={1} mt={1} ml={4} sx={{ border: '1px solid #f6f6f6', padding: '12px', borderRadius: '12px'}}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                <ReplyRounded sx={{ color: '#925FFF'}}/>
                <Stack direction="row" gap={0.5} alignItems="center">
                    <PersonRounded/>
                    <Typography className="t_oktelo t_highlight">{reply.parent && reply.parent.from ? reply.parent.from.name : (reply.from ? reply.from.name : 'Unknown person')}</Typography>
                </Stack>
                <Tag bordered={false} color='purple'>{dayjs(reply.created_time).format('LLL')}</Tag>
                <Tag bordered={false} color='gold'>Replies: {reply.comment_count}</Tag>
                <Tag bordered={false} color='blue'>Likes: {reply.like_count}</Tag>
                {reply.attachment && reply.attachment.type &&
                <Tag bordered={false} color='magenta'>Attachment: {reply.attachment.type}</Tag>
                }
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                {reply.attachment?.media?.image?.src && (
                    <img src={reply.attachment.media.image.src} width="100px" />
                )}
                <Typography className="t_oktelo t_misc" sx={{ textAlign: 'justify' }}>{reply.message}</Typography>
            </Stack>
            <Stack alignItems="center" justifyContent="flex-end" direction="row">
                <Button className="b_oktelo b_purple" sx={{ width: 'auto', color: '#925fff' }} endIcon={<ReplyRounded/>}
                onClick={() => setSelectedComment(reply)}
                >
                    Reply
                </Button>
                <Popconfirm title="Comment Manager" description="Are you sure to delete this comment? You can't undo this action."
                onCancel={cancelDeleteConfirm} onConfirm={handleDeleteComment}>
                <Button className="b_oktelo b_purple" sx={{ width: 'auto', color: '#C41E3A' }} endIcon={<DeleteRounded/>}
                onClick={() => {showDeleteConfirm(); setSelectedComment(reply); }}>
                    Delete
                </Button></Popconfirm>
            </Stack>
            {renderReplies(reply.id, comments)}
        </Stack>
    ));
};

const postCommentReply = (type: string) => async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCommentType(type);
    setLoading(true);
    let send_data = {};
    if(selectedComment && areaFieldValue && areaFieldValue.length > 1, post && post.page_name && post.id && post.platform )
    {
        send_data = {
            msg_content: areaFieldValue,
            comment_id: selectedComment?.id,
            page_name: post.page_name,
            post_platform: post.platform,
            post_id: post.id, 
            comment_type: commentType,
        }
    }
    else {return;}
    
    try {
        const response = await axios.post('http://localhost:8080/api/v1/socialdata/postcomment', send_data);
        
        if(response.status===200)
            {
                open?.({
                    type: "success",
                    message: "Successfully posted a reply.",
                    description: "Good news!",
                  });
            }
    } catch (error) {
        // Handle errors
        console.error('Error:', error);
    }
    finally{
        setAreaFieldValue('');
        setLoading(false);
        setNeedRefetch(true);
        setSelectedComment(undefined);
    }
};

const handleDeleteComment = async () => {
    setLoading(true);
    let send_data = {};

    if(selectedComment && selectedComment.id && post && post.page_name)
    {
        send_data = {
            comment_id: selectedComment?.id,
            page_name: post.page_name
        }
    }
    else {return;}

    try {

        if (selectedComment && selectedComment.id)
            {
                console.log("deleting: ", selectedComment.id);

            const response = await axios.post(`http://localhost:8080/api/v1/socialdata/delcomment`, send_data);
        
            if(response.status===200)
                {
                    open?.({
                        type: "success",
                        message: "Successfully deleted a comment.",
                        description: "No problemo!",
                      });
                }
            }
    } catch (error) {
        console.error('Error:', error);
    }
    finally{
        setLoading(false);
        setNeedRefetch(true);
        setSelectedComment(undefined);
    }
};

const showDeleteConfirm = () => {
    setOpenConfirm(true);
};

const cancelDeleteConfirm = () => {
    setOpenConfirm(false);
};

useEffect(() => {
    if(impressions.count && engagedUsers.count && totalReactions.count)
        {
            const calculateMaxVal = impressions.count + engagedUsers.count + totalReactions.count;
            setTotalBase(calculateMaxVal);
            
        }  
}, [impressions, engagedUsers, totalReactions]);

/*     useEffect(() => {
        console.log(commentData);
    }, [commentData]); */

    return (

        <Box flex={1} width="100%">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={7} p={2}> {/* Post display */}
                    <Box className="oktelo_card" display="flex" flexDirection="column" justifyContent="space-between" gap={2} width='100%'>
                        <Stack direction="row" gap={1} justifyContent='space-between' width='100%' alignItems='center'>
                            <Stack direction="row" gap={1}>
                                <Avatar alt="Page image" src={post.page_image_url} />
                                <Stack direction='column'>
                                    <Typography className="t_oktelo t_highlight">{post.page_name}</Typography>
                                    <Typography className="t_oktelo t_misc">{getPostDate.toLocaleString()}</Typography>
                                </Stack>
                            </Stack>
                            <a href={getPostUrl} target='_blank'><Chip sx={{ borderColor: '#925fff', padding: '6px'}} className="t_oktelo t_misc t_purple" icon={<OpenInNewIcon sx={{ color: '#925FFF !important', fontSize: '16px'}}/>} label="Open" variant="filled" color='default' /></a>
                        </Stack>
                        <Stack
                            sx={{
                            width: '100%',
                            overflow: 'hidden',
                            marginTop: '0px',
                            height: 'auto',
                            background: '#f6f6f6',
                            alignItems: 'center'
                            }}
                        >
                            <img src={getPostImage} 
                            alt="post_details"
                            height="auto"
                            style={{ objectFit: "cover", borderRadius: "0px", height: '500px' }}
                            className="property_details-img"/>
                        </Stack>
                        <Stack  width='100%'>
                            <Typography className="t_oktelo t_misc">{post.message}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                
                <Grid item xs={12} md={12} lg={5} p={2} > {/* Comments display */}
                    <Box display="flex" flexDirection="column" gap={2}>   
                        <Box className="b_wp">
                        <Typography className="t_oktelo t_secondary_title" mb={2}>Post score</Typography>
                        {post && post.insights && (
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Typography className="t_oktelo t_highlight">
                                Points:          
                                <span style={{ color: '#925FFF'}}> {getPostScore.toFixed(2)}</span>, 
                                Average: 
                                <span style={{ color: '#925FFF'}}> {getAvgScore.toFixed(2)}</span> 
                                {getPointDifference > 0 ? ' +'+getPointDifference.toFixed(1) + '%' : ' ' + getPointDifference.toFixed(1) + '%'} {/* NEED TAG */}
                            </Typography>
                            <AI_Charts key={impressions.title} title={impressions.title} count={impressions.count} color="#925FFF" percentage={(impressions.count / totalBase)*100} desc={chartDesc.imp}/> 
                            <AI_Charts key={engagedUsers.title} title={engagedUsers.title} count={engagedUsers.count} color="#925FFF" percentage={(engagedUsers.count / totalBase)*100} desc={chartDesc.eng}/>
                            {( totalReactions?.count >= 0) && post.platform === "Instagram" &&
                                <AI_Charts key={totalReactions.title} title={totalReactions.title} count={totalReactions.count} color="#925FFF" percentage={(totalReactions.count / totalBase)*100} desc={chartDesc.reach}/>
                            }
                            {(totalReactions?.count >= 0) && post.platform === "Facebook" &&
                                <AI_Charts key={totalReactions.title} title={totalReactions.title} count={totalReactions.count} color="#925FFF" percentage={(totalReactions.count / totalBase)*100} desc={chartDesc.reac}/>
                            }
                        </Box>
                    )}
                        </Box> 
                        <Box className="b_wp">    
                            <Typography className="t_oktelo t_secondary_title" mb={2}>Actions</Typography>
                           <Stack direction='column' gap={2} flex={1}>
                                <Button className="b_oktelo_action">Write a comment</Button>
                                <Button className="b_oktelo_action">Analyze with AI</Button>
                                <Button className="b_oktelo_action_delete">Delete post</Button>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box className="oktelo_card" width='100%'>
                        <Typography className="t_oktelo t_secondary_title" mb={2}>💬 Comment summary</Typography>
                        <Grid container spacing={2}>
                    {commentData && renderComments(commentData.comments)}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                {selectedComment &&   
                    <Box className="oktelo_card"> 
                    <Box width='100%'>
                        <Typography className="t_oktelo t_secondary_title" mb={2}>
                            Write a reply to <span style={{color: '#925fff'}}>{selectedComment.from.name}</span>
                        </Typography>
                        <Stack direction="column" justifyContent="flex-start" gap={2}>
                            <Typography className="t_oktelo t_highlight">Comment:</Typography>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                               {/*  <CommentRounded sx={{ fontSize: '16px'}}/> */}
                                <Typography className="t_oktelo t_misc">
                                {selectedComment.message || selectedComment.text || 'No text'}
                                </Typography>
                            </Stack>
                            <Typography className="t_oktelo t_highlight">Your reply:</Typography>
                        <form onSubmit={postCommentReply("reply")} style={{
                        marginTop: "20px", width: "100%", display: "flex", flexDirection: "column", alignItems: 'flex-end', gap: "12px" }}>
                            <TextareaAutosize
                            name="msg_content"
                            minRows={2}
                            required
                            placeholder="Let's write something nice.."
                            color="info"
                            style={{
                              width: "100%",
                              background: "transparent",
                              fontSize: "14px",
                              borderColor: "rgba(0,0,0,0.23)",
                              borderRadius: 6,
                              padding: 10,
                              fontWeight: '400',
                              color: '#1E1E47',
                            }}
                            value={areaFieldValue? areaFieldValue : ''}
                            onChange={ (e: React.ChangeEvent<HTMLTextAreaElement>) => { setAreaFieldValue(e.target.value)}}
                            />
                            <Button disabled={loading? true : false} className='b_oktelo t_purple' sx={{ width: 'auto' }} type='submit'>Post reply<AddTaskRounded sx={{ marginLeft: '6px', fontSize: '16px'}}/></Button>
                        </form>
                        </Stack>
                    </Box>
                    
                    </Box>}
                </Grid>
            </Grid>

        {loading && (
            <Box 
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
            </Box>
            )}
        </Box>
    )
}

export default PostAnalytics;
