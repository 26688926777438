import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import apiconfig from "config";

const SuccessPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (sessionId) {
            // Retrieve checkout session from your backend
            axios.get(`${apiconfig.BACKEND_URI}/payment/session/${sessionId}`)
                .then(response => {
                    const { paymentStatus } = response.data;
                    setPaymentStatus(paymentStatus);
                })
                .catch(error => {
                    console.error('Error retrieving checkout session:', error);
                    setError('Failed to retrieve checkout session');
                });
        }
    }, [sessionId]);

    return (
        <div>
            <h2>Sikeres fizetés!</h2>
            {paymentStatus && <p>Payment Status: {paymentStatus}</p>}
            {error && <p>Hiba: {error}</p>}
            {/* Display any additional information */}
        </div>
    );
};

export default SuccessPage;
