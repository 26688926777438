import React from 'react'
import {Box, Grid, Stack, Typography} from "@mui/material";
import { Add } from '@mui/icons-material';
import { Tag } from 'antd';

const Help = () => {
  document.title = "Segítség | Oktelo";
  return (
    <Box width="100%">
      <Typography className="t_oktelo_bold size_24 darkblue" mb={2}>
        Segítség központ
      </Typography>
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Box className="oktelo-box">
            <Stack direction="column" gap={2} alignItems="flex-start">
            <Typography className="t_oktelo_medium size_18 darkblue">
              Tudásbázis
            </Typography>
            <Typography className="t_oktelo_normal size_14 darkblue">
              Ha elakadtál bármelyik funkció használata közben, vagy csak többet szeretnél
              megtudni róluk, bármikor böngészheted az Oktelo dokumentációt, ahol videókat és
              szöveges leírásokat teszünk közzé.
            </Typography>
            <button className="home-action-button b925">
              Tovább
            </button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={8}>
          <Box className="oktelo-box">
            <Stack direction="column" gap={2} alignItems="flex-start">
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Typography className="t_oktelo_medium size_18 darkblue">
                  Ticketek
                </Typography>
                <button className="home-action-button b925">
                  <Add/>
                  Új ticket
                </button>
              </Stack>
            <Typography className="t_oktelo_normal size_14 darkblue">
              Az ügyfélszolgálatnak küldött segítségkéréseid.
            </Typography>
            <Box className="ticket-container" width="100%">
              <Stack direction="column" alignItems="flex-start" gap={1} sx={{ border: '1px solid #d9d9d9', width: '100%', padding: '12px', borderRadius: '8px', cursor: 'pointer'}}>
                <Tag color="success">Megoldva</Tag>
                <Typography className="t_oktelo_medium size_16 darkblue">
                  Posztolási segítség
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Létrehozva: 2024.08.20. 14:50
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Munkatárs: Norbert
                </Typography>
              </Stack>
              <Stack direction="column" alignItems="flex-start" gap={1} sx={{ border: '1px solid #d9d9d9', width: '100%', padding: '12px', borderRadius: '8px', cursor: 'pointer'}}>
                <Tag color="warning">Folyamatban</Tag>
                <Typography className="t_oktelo_medium size_16 darkblue">
                  Posztolási segítség
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Létrehozva: 2024.08.20. 14:50
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Munkatárs: Norbert
                </Typography>
              </Stack>
            </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={4}>
          <Box className="oktelo-box">
            <Stack direction="column" gap={2} alignItems="flex-start">
            <Typography className="t_oktelo_medium size_18 darkblue">
              Továbbiak
            </Typography>
            <Typography className="t_oktelo_normal size_14 darkblue">
              Egyéb kapcsolatfelvételi lehetőségek és információk.
            </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Help