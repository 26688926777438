import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { Button } from '@mui/material';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';



interface CustomModalProps {
    visible: boolean;
    handleProfileSave: (values: any) => void;
    onCancel: () => void;
    defaultValues: {
        username: string,
        email: string,
        company: string,
    };
  }

const ProfileModal: React.FC<CustomModalProps> = ({ visible, handleProfileSave, onCancel, defaultValues }) => {
  const [form] = Form.useForm();
  const [isPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState('');

  const handlePassword = (e: any) => {
      setPassword(e.target.value);
  };

  const handleSave = () => {
    form.validateFields().then(values => {
      form.resetFields();
      handleProfileSave(values);
    });
  };

  const decidePwVisible = () => {
    if(!isPassword){
        setShowPassword(true);
    }
    else{
        setShowPassword(false);
    }
  };

  return (
    <Modal
      open={visible}
      title="🔒 Edit Profile"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSave}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="username" label="Username" className="t_oktelo t_desc"
        extra={<span style={{ color: '#1E1E47', fontSize: '12px'}} >Only letters and numbers</span>}>
            <Input size="middle" width="100%" className="t_misc" count={{ show: true, max: 24 }} placeholder={defaultValues.username}/>
        </Form.Item>
        <Form.Item name="email" label="Email" className="t_oktelo t_desc">
        <Input size="middle" width="100%" className="t_misc" placeholder={defaultValues.email}/>
        </Form.Item>
        <Form.Item name="company" label="Company or assignment" className="t_oktelo t_desc">
        <Input size="middle" width="100%" className="t_misc" count={{ show: true, max: 24 }} placeholder={defaultValues.company}/>
        </Form.Item>
        <Button onClick={()=> decidePwVisible()} className="oktelo_card_button" sx={{ paddingLeft: '0px !important', marginBottom: '8px'}}>Change password</Button>
        {isPassword &&
        <>
        <Form.Item name="old_password" label="Old password" className="t_oktelo t_desc">
        <Input.Password type="password" size="middle" className="t_misc" />
        </Form.Item>     
        <Form.Item name="new_password" label="New password" className="t_oktelo t_desc">
            <Input.Password type="password" size="middle" className="t_misc"
                             iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                             onChange={handlePassword} />
        </Form.Item>  
        <Form.Item name="new_password_confirm" label="Re-enter new password" className="t_oktelo t_desc">
          <Input.Password />
        </Form.Item>  
        </>
        }
      </Form>
    </Modal>
  );
};

export default ProfileModal;
