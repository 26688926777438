import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Grid, Button } from "@mui/material";
import OkteloCard from "components/common/oktelo_components/OkteloCard";
import { Select, Space } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const PostSelectCard = () => {
    const navigate = useNavigate();
    return(
    <OkteloCard title="Milyen típusú posztokat szeretnél elemezni?"
            desc="Kattints a gombra, és átirányítunk a megfelelő oldalra."
            icon=""
            content={
                <Stack direction="row" gap={1}>
                    <Button className="oktelo_card_button" onClick={() => navigate('/userposts')}>📢 Oktelo Posztok</Button>
                    <Button className="oktelo_card_button" onClick={() => navigate('/home')}>💎 Social Posztok</Button>
                </Stack>
            }
            hasTag
            tagColor="purple"
            tagTitle="#posztelemzés"
            />
    );
}
 

const PageSelectCard = () => {
const navigate = useNavigate();
const [userPages, setUserPages] = useState<any>(null);
const [selectedPage, setSelectedPage] = useState<string | undefined>(localStorage.getItem('sp') || undefined);
const [getPageType, setPageType] = useState<string>("");

useEffect(() => {
    async function fetchUserData() {
        try {
            const response = await axios.get(`http://localhost:8080/api/v1/users/`, {
                headers: {
                    "user_request_type": "get_all_pages",
                },
            });
            const allFacebookPages = response.data.allFacebook.map((page: any) => ({ id: page.id, name: page.name }));
            const allInstagramPages = response.data.allInstagram.map((page: any) => ({ id: page.id, username: page.name }));
            const allPages = [...allFacebookPages, ...allInstagramPages];
        
            // Extract both names (or usernames) and ids from all pages
            const allNames = allPages.map((page: any) => ({ id: page.id, name: page.name }));
        
            const userPagesData = {
                allNames: allNames,
            };
            const isFacebookPage = allFacebookPages.some((page: any) => page.id === selectedPage);
            const isInstagramPage = allInstagramPages.some((page: any) => page.id === selectedPage);

            if(isFacebookPage){
                setPageType("facebook");
            }
            else if( isInstagramPage){
                setPageType("instagram")
            }
            else {
                console.error('Cant find page!');
            }

            setUserPages(userPagesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    } fetchUserData();
}, [selectedPage]);

    return(
        <OkteloCard title="Melyik oldaladat szeretnéd elemezni?"
        desc="Kattints a Megerősítés gombra, és azonnal átirányítalak az Oldalelemzésre!"
        icon=""
        content={
            <Select 
            showSearch={false} 
            maxCount={1}  
            maxTagCount={1} 
            allowClear 
            defaultValue={selectedPage} 
            style={{ width: '320px'}} 
            className="ai_page_selector" 
            dropdownStyle={{ background: 'rgba(255,255,255, 0.95)', color: '#28282B'}} 
            onChange={(selectedPageId) => setSelectedPage(selectedPageId)}>
            {userPages?.allNames.map((page: any, index: number) => (
                <Option key={index} value={page.id}>
                    <Space>
                        <Typography className="t_oktelo t_desc">💎 {page.name}</Typography>
                    </Space>
                </Option>
            ))}
        </Select>
        }
        hasTag
        hasButton
        tagColor="gold"
        tagTitle="#oldalelemzés"
        buttonTitle="Megerősítés, nézzük!"
        handleButton={() => navigate(`/analytics/${getPageType}/${selectedPage}`)}
        />
    );
};

const AnalyticsHub = () => {
    document.title = "Oktelo Hub | Oktelo";
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);

    return (
        <Box  width="100%" display="flex" alignContent="center" alignItems="flex-start" justifyContent="center" flexDirection="column" gap={1} pt={0}>
            <Stack direction="column" gap={1}>
                <Typography className="t_main_title">🔎 Oktelo Hub</Typography>
                <Typography className="t_oktelo t_misc">Itt találod a fontos funkciókat. Kattins a kártyára a navigációhoz.</Typography>
            </Stack>
            <Box p={2} mt={3.5}>
                <Typography className="t_important t_oktelo t_highlight t_purple">Elemzések</Typography>
            </Box>
            <Grid container spacing={3} direction={{lg: 'row', xs: 'column'}} flex={1} mt={0}>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Poszt elemzés"
                    desc="Tartalom- és teljesítményelemzés az Oktelo és a közösségi médiás bejegyzésekhez."
                    icon=""
                    content={""}
                    clickable={true}
                    hasTag={true}
                    tagColor="volcano"
                    tagTitle="#posztok"
                    handleBoxClick={() => setSelectedItem('post')}
                    handleButton={() => setSelectedItem('post')}
                    hasButton
                    buttonTitle="📢 Kiválasztom"
                    style={{ border: selectedItem === 'post'? '1px solid #925fff' : 'none'}}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Oldal elemzés"
                    desc="Oldaltartalom, oldalteljesítmény és közönségelemzés az összekapcsolt oldalakhoz."
                    icon=""
                    content={""}
                    clickable={true}
                    hasTag={true}
                    tagColor="gold"
                    tagTitle="#oldalak"
                    handleBoxClick={() => setSelectedItem('page')}
                    handleButton={() => setSelectedItem('page')}
                    hasButton
                    buttonTitle="💎 Kiválasztom"
                    style={{ border: selectedItem === 'page'? '1px solid #925fff' : 'none'}}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Kampány elemezés"
                    desc="Elemezd az ütemezett és futó kampányait, és kövesd nyomon azok előrehaladását."
                    icon=""
                    content={""}
                    clickable={true}
                    hasTag={true}
                    tagColor="green"
                    tagTitle="#kampányok"
                    redirect={"/campaign"}
                    hasButton
                    buttonTitle="💼 Kiválasztom"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Oktelo teljesítmény"
                    desc="Csak az Oktelóból közzétett bejegyzések teljesítményének elemzése."
                    icon=""
                    content={""}
                    clickable={true}
                    hasTag={true}
                    tagColor="purple"
                    tagTitle="#okteloposztok"
                    redirect={"/myperformance"}
                    hasButton
                    buttonTitle="🔥 Kiválasztom"
                    style={{ border: '1px solid #925fff', boxShadow: 'rgba(146, 95, 255, 0.2) 0px 5px 15px' }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
                {selectedItem !== undefined && selectedItem === 'page' && (
                <Grid item xs={12} lg={12}>
                    <PageSelectCard/>
                </Grid>
                )}
                {selectedItem !== undefined && selectedItem === 'post' && (
                <Grid item xs={12} lg={12}>
                    <PostSelectCard/>
                </Grid>
                )}
            </Grid>
            <Box p={2} mt={3.5}>
                <Typography className="t_important t_oktelo t_highlight t_purple">Oktelo AI</Typography>
            </Box>
            <Grid container spacing={3} direction={{lg: 'row', xs: 'column'}} flex={1} mt={0}>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Oktelo AI"
                    desc="Az AI konkrét oldal- és bejegyzésadatok alapján ad ajánlásokat."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="magenta"
                    tagTitle="#ai"
                    clickable
                    redirect={"/ai"}
                    hasButton
                    buttonTitle="🚀 Kiválasztom"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Bejegyzés AI"
                    desc="Az AI az általad megadott információk alapján bejegyzést hoz létre."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="orange"
                    tagTitle="#ai-posztok"
                    clickable
                    redirect={"/postwriter"}
                    hasButton
                    buttonTitle="🤖 Kiválasztom"
                    />
                </Grid>
            </Grid>
            <Box p={2} mt={3.5}>
                <Typography className="t_important t_oktelo t_highlight t_purple">Tartalom létrehozás</Typography>
            </Box>
            <Grid container spacing={3} direction={{lg: 'row', xs: 'column'}} flex={1} mt={0}>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Bejegyzések & történetek"
                    desc="Hozz létre különféle típusú bejegyzéseket a közösségi oldalaidhoz."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="blue"
                    tagTitle="#bejegyzések"
                    clickable
                    redirect={"/create"}
                    hasButton
                    buttonTitle="✨ Kiválasztom"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Kampány"
                    desc="Hozz létre kampányokat, és ütemezd a bejegyzéseket különböző közösségi oldalakon."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="green"
                    tagTitle="#kampányok"
                    clickable
                    redirect={"/campaign"}
                    hasButton
                    buttonTitle="💼 Kiválasztom"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Posztjaim"
                    desc="Hozzáférés az Oktelo használatával létrehozott bejegyzésekhez."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="gold"
                    tagTitle="#posztjaim"
                    clickable
                    redirect={"/userposts"}
                    hasButton
                    buttonTitle="📢 Kiválasztom"
                    />
                </Grid>
            </Grid>
            <Box p={2} mt={3.5}>
                <Typography className="t_important t_oktelo t_highlight t_purple">Egyebek</Typography>
            </Box>
            <Grid container spacing={3} direction={{lg: 'row', xs: 'column'}} flex={1} mt={0}>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Tartalom naptár"
                    desc="Tekintsd meg az ütemezett tartalmaid, kampányaid, jegyezd fel a fontos dátumokat."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="cyan"
                    tagTitle="#naptár"
                    clickable
                    redirect={"/calendar"}
                    hasButton
                    buttonTitle="📅 Kiválasztom"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Profil & beállítások"
                    desc="Szerkeszd a profilod, tekintsd meg az előfizetésed, és kapcsold össze a közösségi oldalakaid."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="blue"
                    tagTitle="#beállítások"
                    clickable
                    redirect={"/settings"}
                    hasButton
                    buttonTitle="⚙️ Kiválasztom"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <OkteloCard title="Segítség központ"
                    desc="Kérjsegítséget, és olvasd el az Oktelóval kapcsolatos dokumentációt. Itt hozhatsz létre támogatási jegyeket."
                    icon=""
                    content={""}
                    hasTag
                    tagColor="red"
                    tagTitle="#segítség"
                    clickable
                    redirect={"/help"}
                    hasButton
                    buttonTitle="💡 Kiválasztom"
                    />
                </Grid>
            </Grid>

        </Box>

)



}


export default AnalyticsHub