import { PickerLocale } from 'antd/es/date-picker/generatePicker';

export const customLocale: PickerLocale = {
    lang: {
      locale: "hu_HU",
      placeholder: "Válassz egy dátmot",
      rangePlaceholder: ["Kezdő dátum", "Vége dátum"],
      today: "Ma",
      now: "Most",
      backToToday: "Vissza mára",
      ok: "OK",
      clear: "Törlés",
      month: "Hónap",
      year: "Év",
      timeSelect: "Idő választás",
      dateSelect: "Dátum választás",
      monthSelect: "Choose a month",
      yearSelect: "Choose a year",
      decadeSelect: "Choose a decade",
      yearFormat: "YYYY",
      dateFormat: "YYYY.MM.DD",
      dayFormat: "D",
      dateTimeFormat: "YYYY.MM.DD HH:mm:ss",
      monthFormat: "MMMM",
      monthBeforeYear: false,
      previousMonth: "Previous month (PageUp)",
      nextMonth: "Next month (PageDown)",
      previousYear: "Last year (Control + left)",
      nextYear: "Next year (Control + right)",
      previousDecade: "Last decade",
      nextDecade: "Next decade",
      previousCentury: "Last century",
      nextCentury: "Next century",
      shortWeekDays: ["Vas", "Hét", "Ked", "Szer", "Csüt", "Pén", "Szo"],
      shortMonths: [
        "Jan",
        "Feb",
        "Már",
        "Ápr",
        "Máj",
        "Jún",
        "Júl",
        "Aug",
        "Szep",
        "Okt",
        "Nov",
        "Dec"
      ]
    },
    timePickerLocale: {
      placeholder: "Select time"
    },
    dateFormat: "YYYY-MM-DD",
    dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    weekFormat: "YYYY-wo",
    monthFormat: "YYYY-MM"
  };