import { Box, Typography, Stack, Avatar } from "@mui/material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { PreviewProps } from "interfaces/common";

import { okt_likeicon, okt_hearticon, okt_wowicon, okt_placeholer } from 'assets';


const FacebookPreview:React.FC<PreviewProps> = ({  postImage, areaFieldValue, pageName }) => {

    return (
    <Box display="flex" flexDirection="column" gap={2}>

      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Box flexDirection="row" flex={1} display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
        <Avatar/>

         <Typography className="t_oktelo t_highlight">
          {pageName? pageName : 'Page name'}
        </Typography>
        </Box>
        <MoreHorizIcon/>
      </Stack>
      <Stack style={{ overflowX: 'hidden', maxHeight: '100%', height: 'auto', overflowWrap: 'break-word' }}>
  <Typography className="t_oktelo t_misc" style={{whiteSpace: 'pre-wrap'}}>{areaFieldValue}</Typography>
</Stack>

    {/* Image preview */}
    <Stack
        sx={{
          width: '100%',
          position: 'relative',
          paddingTop: '100%', // 1:1 aspect ratio
          overflow: 'hidden',
          marginTop: '0px',
        }}
      >
        <img
          src={postImage.url ? postImage.url :  okt_placeholer}
          alt={postImage ? postImage.name : 'Placeholder'}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Cover the entire area
          }}
        />
      </Stack>        
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Box flexDirection="row" flex={1} display="flex" alignItems="center" justifyContent="flex-start" gap={0}>
        <Avatar src={okt_likeicon}/><Avatar src={okt_hearticon} sx={{marginLeft: '-12px'}}/><Avatar src={okt_wowicon} sx={{marginLeft: '-12px'}}/>
        <Typography className="t_oktelo t_desc">879</Typography>
        </Box>
        <Box flexDirection="row" flex={1} display="flex" alignItems="center" justifyContent="flex-end" gap={0}>
        <Typography className="t_oktelo t_desc">241 comments 42 shares</Typography>
        </Box>
      </Stack>
      <Box flexDirection="row" gap={1} alignItems="center" display="flex" flex={1} justifyContent="space-around" sx={{borderTop: '1px solid #D3D3D3'}}>
          <Stack direction="row" gap={1} p={1}>
          <ThumbUpOffAltIcon sx={{color: '#65676b'}}/>
          <Typography className="t_oktelo t_desc" color="#65676b">Like</Typography>
          </Stack>
          <Stack direction="row" gap={1} p={1}>
          <ModeCommentOutlinedIcon sx={{color: '#65676b'}}/>
          <Typography className="t_oktelo t_desc" color="#65676b">Comment</Typography>
          </Stack>
          <Stack direction="row" gap={1} p={1}>
          <ShareOutlinedIcon sx={{color: '#65676b'}}/>
          <Typography className="t_oktelo t_desc" color="#65676b">Share</Typography>
          </Stack>
      </Box>
    </Box>
    )
}

export default FacebookPreview