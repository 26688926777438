import React from "react";
import {
  AuthBindings,
  Authenticated,
  Refine,
  useLogout,
  useNotification, 
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";

import {
  notificationProvider,
  RefineSnackbarProvider,
} from "@refinedev/mui";

import { ThemedLayoutV2 } from "components/layout";
import { useNotificationHandler } from "utils/NotificationProvider";
import {
  AccountBalanceWalletOutlined,
  AdsClickOutlined,
  ArticleOutlined,
  AutoFixHighOutlined,
  BrowseGalleryOutlined,
CalendarMonthOutlined,
  CampaignOutlined,
  CheckBoxOutlined,
  DashboardOutlined,
  ExtensionOutlined,
  HelpCenterOutlined,
InterestsOutlined,
LanguageOutlined,
ManageAccountsOutlined,
PermMediaOutlined,
PublicOutlined,
RocketOutlined,
ScheduleSendOutlined,
ScienceOutlined,
SettingsOutlined,
}
from '@mui/icons-material'
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import axios, { AxiosRequestConfig, AxiosError } from 'axios';

import { useEffect, useState } from "react";

import { MyMedia, IGSiteData, SocialPostAnalytics, SocialPosts, PostEdit, PostViewDetails, PostsPage, SiteData, Websites, PreviewCreate, PreviewDisplay, PreviewList, PaymentSuccess, OkteloPayment, CreateHUB, ErrorPage, Home, CalendarPage, Advertising, OkteloAI, Settings, Help, Campaign, CampaignCreator, CampaignEditor, PageAnalytics, CreateAI, LoginPage, RegisterPage, TagsHome, CampaignDisplay, ForgotPasswordPage, ScheduledPosts } from "pages";

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { MuiInferencer } from "@refinedev/inferencer/mui";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import AnalyticsHub from "pages/analyticshub";
import axiosInstance from "utils/axiosInstance";
import CreatePost from "components/common/oktelo_forms/CreatePost";
import AuthLayout from "pages/auth/AuthLayout";
import apiconfig from "config";


interface RetryRequest {
  request: AxiosRequestConfig;
  retry: () => Promise<any>;
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

let isRefreshing = false;
let failedRequestsQueue: { resolve: (token: string) => void; reject: (error: any) => void }[] = [];
let requestInterceptorId: number | null = null;
let responseInterceptorId: number | null = null;



function App() {
  const getUserData = localStorage.getItem('user');
  const userData = getUserData ? JSON.parse(getUserData) : null;
  const { open } = useNotification();
  const { mutate: logout } = useLogout();
  const [isLoading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [sessionToken, setSessionToken] = useState<string | null>(userData ? userData.session : null);
  const [requestQueue, setRequestQueue] = useState<RetryRequest[]>([]);

  useEffect(() => {
    setSessionToken(userData ? userData.session : null);
  }, [userData]);

  useEffect(() => {
    if (!sessionToken) {
      setExpired(true);
    }
  }, [sessionToken]);

  useEffect(() => {
    requestInterceptorId = axios.interceptors.request.use((config) => {
      const _userdata = localStorage.getItem('user');
      const _userjson = _userdata ? JSON.parse(_userdata) : null;

      if (_userjson === null || !_userdata) {
        return config;
      }

      const token = _userjson.session;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    responseInterceptorId = axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config as CustomAxiosRequestConfig;

        if (error.response && error.response.status === 401) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedRequestsQueue.push({ resolve, reject });
            })
            .then((token) => {
              if (originalRequest && token && originalRequest.headers) {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
              }
              return Promise.reject('No original request or token available');
            })
            .catch((err) => {
              return Promise.reject(err);
            });
          }

          if (!originalRequest) {
            return Promise.reject('No original request available');
          }

          isRefreshing = true;
          originalRequest._retry = true;

          try {
            const newToken = await continueSessionHandler(originalRequest);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
            if (originalRequest && originalRequest.headers) {
              originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
            }

            processQueue(null, newToken);
            if (originalRequest) {
              return axios(originalRequest);
            }
            return Promise.reject('No original request available');
          } catch (err) {
            processQueue(err, null);
            handleFailedRequests(err);
          } finally {
            isRefreshing = false;
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      if (requestInterceptorId !== null) {
        axios.interceptors.request.eject(requestInterceptorId);
      }
      if (responseInterceptorId !== null) {
        axios.interceptors.response.eject(responseInterceptorId);
      }
    };
  }, []);

  const continueSessionHandler = async (originalRequest: AxiosRequestConfig) => {
    try {
      const _userdata = localStorage.getItem('user');
      const currentUserData = _userdata ? JSON.parse(_userdata) : null;
  
      if (!currentUserData || !currentUserData.email) {
        throw new Error('User data missing');
      }
  
      const { email, session: expiredToken } = currentUserData;
  
      const response = await axios.put(`${apiconfig.BACKEND_URI}/users/session`, { email, expiredToken });
  
      if (response.status === 200 && response.data && response.data.newToken) {
        const newToken = response.data.newToken;
  
        const updatedUserData = { ...currentUserData, session: newToken };
        localStorage.setItem('user', JSON.stringify(updatedUserData));
        localStorage.setItem('expire', response.data.expire);
  
        setSessionToken(newToken);
  
        return newToken; // Return the new token
      } else {
        logout();
        throw new Error('Failed to refresh session');
      }
    } catch (error) {
      console.error('Token refresh error:', error);
      throw error;
    }
  };

  const processQueue = (error: any, token: string | null = null) => {
    failedRequestsQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        if (token) {
          prom.resolve(token);
        } else {
          prom.reject('No token available'); // Or handle the null case as needed
        }
      }
    });
    failedRequestsQueue = [];
  };

  const handleFailedRequests = (error: any) => {
    setRequestQueue([]); // Clear the queue
    open?.({
      type: 'error',
      message: 'Session expired. Please log in again.',
    });
    logout(); // Redirect to login
  };

  useEffect(() => {
    const retryFailedRequests = async () => {
      try {
        const _userdata = localStorage.getItem('user');
        if (!_userdata) {
          setRequestQueue([]);
          return;
        }

        await Promise.all(requestQueue.map(({ retry }) => retry()));
        setRequestQueue([]);
      } catch (error) {
        console.error('Error retrying requests:', error);
      }
    };

    if (requestQueue.length > 0 && !expired) {
      retryFailedRequests();
    }
  }, [requestQueue, expired]);

  const checkToken = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('user') || '{}'); // Ensure correct key
      const refreshToken = userData.refresh;
  
      if (!refreshToken) {
        console.error('No refresh token available');
        return;
      }
  
      const response = await axios.post(`${apiconfig.BACKEND_URI}/users/refreshtoken`, {
        token: refreshToken
      });
  
      const { accessToken } = response.data;
  
      if (accessToken) {
        userData.session = accessToken;
        localStorage.setItem('user', JSON.stringify(userData)); // Ensure consistent key
      }
    } catch (error) {
      console.error('Error refreshing token', error);
    }
  };
  
  // Call checkToken every 2 minutes
  useEffect(() => {
    const intervalId = setInterval(checkToken, 120000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  if (isLoading) return (
    <Box style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <CircularProgress sx={{color: "#925FFF", fontSize: "32px"}} />
    </Box>);

const authProvider: AuthBindings = {
  login: async ({ email, password, remember }) => {
    try {
      const response = await axiosInstance.post('/users/login', { email, password, remember });
      if (response.status === 200) {
        const { user, session, expire } = response.data;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('isWelcome', "true");
        localStorage.setItem('expire', expire);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${session}`;
        return { success: true, authenticated: true, redirectTo: '/home', message: 'Successful login!' };
      } else {
        return { success: false };
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return { success: false, message: error.response.data.message };
      } else {
        return { success: false };
      }
    }
  },

  register: async ({ firstName, lastName, email, password, username, regpromo }) => {
    try {
      const response = await axiosInstance.post('/users/register', { firstName, lastName, email, password, username, regpromo });
      localStorage.setItem('isWelcome', "true");
      if (response.status === 200) {
        return { success: true, message: 'Sikeres regisztráció!', redirectTo: '/login' };
      } else {
        return { success: false, message: 'Hiba, próbáld újra később, vagy lépj kapcsolatba velünk.' };
      }
    } catch (error: any) {
      console.error('Registration error:', error);
      if (error.response && error.response.status === 400) {
        return { success: false, message: error.response.data.message };
      } else {
        return { success: false, message: 'Hiba, próbáld újra később, vagy lépj kapcsolatba velünk.' };
      }
    }
  },

  forgotPassword: async ({ email }) => {
    //Handle forgot pass
      return {
        success: true,
      };
    },

  updatePassword: async ({ password, confirmPassword }) => {
      // You can handle the update password process according to your needs.
  
      // If the process is successful.
      return {
        success: true,
      };
    },

    check: async () => {

      const storedUserString = localStorage.getItem('user');

      if (!storedUserString) {
        return { authenticated: false}
      }
  
      const storedUser = JSON.parse(storedUserString);

      if (!storedUser || !storedUser.session) {
        throw new Error("Session token not found");
      }
      if (storedUserString && storedUser && storedUser.session) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${storedUser.session}`,
        };
        return {
          authenticated: true,
          
        };
      }
  
      return {
        authenticated: false,
        logout: true,
        redirectTo: "/login",
        error: {
          message: "Check failed",
          name: "Unauthorized",
        },
      };
    },

    logout: async () => {
      if (requestInterceptorId !== null) {
        axios.interceptors.request.eject(requestInterceptorId);
      }
      if (responseInterceptorId !== null) {
        axios.interceptors.response.eject(responseInterceptorId);
      }
      localStorage.removeItem("user");
      localStorage.removeItem("sp");
      localStorage.removeItem("spn");
      localStorage.clear();
      setExpired(false);
      return {
        success: true,
        authenticated: false,
        successNotification: {
          message: "Kijelentkeztél. Várunk vissza!",
        },
        redirectTo: "/login",
        redirectPath: "/login"
      };
    },
  getPermissions: async () => null,
  onError: async (error) => {
    console.error("onError:", error);
    if (error.response.status === 401) {
      setExpired(true);
      open?.({
        type: 'progress',
        message: 'Oktelo is now verifying your session, you can continue!',
        description: 'Checking your session..'
      });
    }
    return { error };
  },
  getIdentity: async () => {
    try {
      const storedUserString = localStorage.getItem('user');
  
      if (!storedUserString) {
        return { authenticated: false };
      }
  
      const storedUser = JSON.parse(storedUserString);
  
      const response = await axios.get(`${apiconfig.BACKEND_URI}/data/identity`, {
        headers: {
          email: storedUser.email,
          session_token: storedUser.session,
        }
      });
  
      if (response.status === 200 && response.data && response.data.data) {
        return response.data.data;
      } else {
        return { authenticated: false, message: 'Unable to fetch identity' };
      }
    } catch (error: any) {
      console.error("Error fetching identity:", error);
      return { authenticated: false, message: 'Error fetching identity', error: error.message };
    }
  }
  

};

const customTitleHandler = ( ) => {
  let title = "Oktelo App";

  return title;
};



  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
        <DocumentTitleHandler /* handler={customTitleHandler} */ />
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
           <RefineSnackbarProvider> 
              <Refine
                dataProvider={dataProvider(apiconfig.BACKEND_URI)}
                 notificationProvider={notificationProvider} 
                routerProvider={routerBindings}
                authProvider={authProvider} 
                resources={[
                  {
                    name: "home",
                    list: MuiInferencer,
                    options: { label: 'Vezérlőpult'},
                    icon: <DashboardOutlined/>
                  },
/*                     {
                    name: "oktelohub",
                    list: AnalyticsHub,
                    options: { label: 'Oktelo hub'},
                     icon: <ScienceOutlined/>, 

                  },   */
                  {
                    name: "mymedia",
                    list: MyMedia,
                    options: { label: 'Média'},
                     icon: <PermMediaOutlined/>, 
/*                     icon: "🔎" */
                  },  

                  {
                    name: "create",
                    list: CreateHUB,
                    options: { label: 'Létrehozás'},
                    icon: <AutoFixHighOutlined/>,
                    /* icon: "✨", */
                    meta: {
                      headers:
                          {
                          Authorization: `Bearer ${sessionToken}`,
                          },
                  },
                  },
                  {
                    name: "_posts",
                    list: PostsPage,
                    show: PostViewDetails,
                    create: CreatePost,
                    edit: PostEdit,
                    options: { label: 'Bejegyzések'},
                    icon: <ArticleOutlined/>,
                  },
                  {
                    name: "posts",
                    list: PostsPage,
                    show: PostViewDetails,
                    create: CreatePost,
                    edit: PostEdit,
                    options: { label: 'Oktelo'},
                    icon: <InterestsOutlined/>,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "posts/social",
                    list: SocialPosts,
                    options: { label: 'Közösségi'},
                    icon: <PublicOutlined/> ,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "posts/scheduled",
                    list: ScheduledPosts,
                    options: { label: 'Ütemezések'},
                    icon: <ScheduleSendOutlined/> ,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "props",
                    list: TagsHome,
                    options: { label: 'Sablonok'},
                    icon: <ExtensionOutlined/> ,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "approvals",
                    list: PreviewList,
                    show: PreviewDisplay,
                    create: PreviewCreate,
                    options: { label: 'Jóváhagyások'},
                    icon: <CheckBoxOutlined/>
                  },
                  {
                    name: "ads",
                    list: Home,
                    options: { label: 'Hirdetések'},
                     icon: <AdsClickOutlined/> 
/*                     icon: "💰" */
                  },
                  {
                    name: "websites",
                    list: Websites,
                    options: { label: 'Weboldalak'},
                    icon: <LanguageOutlined/>
                  },
                  {
                    name: "calendar",
                    list: CalendarPage,
                    options: { label: 'Naptár'},
                     icon: <CalendarMonthOutlined/> 
/*                    icon: "📅", */
                  },
                  {
                    name: "postanalytics",
                    show: SocialPostAnalytics,
                    options: { label: 'Poszt analízis'},
                    icon: <CalendarMonthOutlined/>,
                    meta: { hide: true}
                  },
                  {
                    name: "campaign",
                    list: Campaign,
                    show: CampaignDisplay,
                    create: CampaignCreator,
                    edit: CampaignEditor,
                    options: { label: 'Kampányok'},
                     icon: <CampaignOutlined/>, 
                  },
                  {
                    name: "ai",
                    list: OkteloAI,
                    options: { label: 'OkteloAI'},
                     icon: <RocketOutlined/>, 
/*                     icon: "🤖", */
                  },
                  {
                    name: "settings_",
                    list: Settings,
                    options: { label: 'Beállítások'},
                     icon: <SettingsOutlined/>, 
/*                     icon: "⚙️", */
                  },
                  {
                    name: "settings",
                    list: Settings,
                    options: { label: 'Profil'},
                     icon: <ManageAccountsOutlined/>, 
                    meta: {parent: "settings_"}
                  },
                  {
                    name: "subscription",
                    list: OkteloPayment,
                    options: { label: 'Előfizetés'},
                     icon: <AccountBalanceWalletOutlined/>, 
                    meta: {parent: "settings_"}
                  },                    
                  {
                    name: "help",
                    list: Home,
                    options: { label: 'Segítség'},
                     icon: <HelpCenterOutlined/>, 
/*                     icon: "💡" */
                  },
                  {
                    name: "oktelo-ai-create",
                    list: CreateAI,
                    options: { label: 'AI Poszt'},
                    meta: { hide: true}
                  },

                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "pmMrRk-3tYk6o-dzqmD5",
                  liveMode: "auto"
                }}
                
              >
               

                <Routes>
                <Route path="/previews/show/:previd" element={<PreviewDisplay />} />
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/login" />}
                      >

                      <ThemedLayoutV2 Header={() => <Header sticky={true} />}>
                        <Outlet />
                      </ThemedLayoutV2>
                      </Authenticated>
                    } 
                  >
                    <Route
                      index
                      element={<NavigateToResource resource="home" />}
                    />
                    <Route path="/home">
                      <Route index element={<Home />} />
                    </Route>
                    <Route path="/settings" element={<Settings />} />
                    {/* <Route path="/create" element={<Navigate to="/createhub" />} /> */}
                    <Route path="/posts">
                      <Route index element={<PostsPage />} />
                      <Route path="edit/:id" element={<PostEdit />} />
                      <Route path="social" element={<SocialPosts />} />
                      <Route path="scheduled" element={<ScheduledPosts />} />
                      <Route path="show/:id" element={<PostViewDetails />} />
                    </Route>
                    <Route path="/approvals">
                      <Route index element={<PreviewList/>} />
                      {/* <Route path="show/:previd" element={<PreviewDisplay/>} /> */}
                      <Route path="create" element={<PreviewCreate/>} />
                    </Route>
                    <Route path="/postwriter">
                      <Route index element={<CreateAI/>} />
                    </Route>
                    <Route path="/create">
                      <Route index element={<CreateHUB/>} />
                    </Route>       
                    <Route path="/mymedia">
                      <Route index element={<MyMedia/>} />
                    </Route>                   
                    <Route path="/posts">
                      <Route index element={<PostsPage/>} />
                    </Route> 
                    <Route path="/calendar">
                      <Route index element={<CalendarPage/>} />
                    </Route>
                    <Route path="/site/:pageid">
                      <Route index element={<SiteData/>} />
                    </Route>
                    <Route path="/igsite/:pageid">
                      <Route index element={<IGSiteData/>} />
                    </Route>     
                    <Route path="/props">
                      <Route index element={<TagsHome/>} />
                    </Route>  
                      <Route path="/websites">            
                        <Route index element={<Websites/>} />
                      </Route>
                    <Route path="/analytics/:type/:id">
                      <Route index element={<PageAnalytics/>} />
                    </Route>
                    <Route path="/postanalytics/:id">
                      <Route index element={<SocialPostAnalytics/>} />
                    </Route>
                    <Route path="/linkedin">
                      <Route index element={<LinkedInCallback/>} />
                    </Route>
                    <Route path="/help">
                      <Route index element={<Help/>} />
                    </Route>
                    <Route path="/oktelohub">
                      <Route index element={<AnalyticsHub/>} />
                      <Route path=":type/:id" element={<PageAnalytics/>} />
                    </Route>
                    <Route path="/ai">
                      <Route index element={<OkteloAI/>} />
                    </Route>
                    <Route path="/subscription">
                      <Route index element={<OkteloPayment/>} />
                    </Route>
                    <Route path="/success">
                      <Route index element={<PaymentSuccess/>} />
                    </Route>
                    <Route path="/campaign">
                      <Route index element={<Campaign />} />
                      <Route path="show/:id" element={<CampaignDisplay />} />
                      <Route path="create" element={<CampaignCreator />} />
                      <Route path="edit/:id" element={<CampaignEditor />} />
                      {/* <Route path="show/:id" element={<PostDetails />} /> */}
                    </Route>

                    <Route path="*" element={<ErrorPage />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<AuthLayout><LoginPage /></AuthLayout>} />
                    <Route path="/forgot-password" element={<AuthLayout><ForgotPasswordPage /></AuthLayout>} />
                    <Route path="/register" element={<AuthLayout><RegisterPage /></AuthLayout>} />
                  </Route>
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
                
                <DocumentTitleHandler handler={customTitleHandler}/>
                
              </Refine>
           </RefineSnackbarProvider> 
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}


export default App;
