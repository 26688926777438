import React from "react";
import {Box, Stack, Typography} from "@mui/material"
import { Tag } from "antd";

interface MetricCardsProps {
    title: string;
    color?: string;
    count: number;
    desc?: string;
    since: Date | string;
    until: Date | string;
}

const MetricCardsIG = ({ title, color, count, desc, since, until}: MetricCardsProps) => {

   

    return (
        <Box width="100%" height="100% !important" display="flex" flexDirection="column">
                <Stack direction="row" gap={1} alignItems="center" height="100%" mb={2}>
                    <Stack direction="column" alignItems="stretch" justifyContent="center" width="80%" gap={2}>
                        <Typography className="t_oktelo t_highlight">{title} </Typography>
                        <Typography className="t_oktelo t_misc" sx={{fontSize: '12px !important'}}>{desc? desc : ''}</Typography>
                        <Stack direction="row">
                        <Tag color="gold" bordered={false}>{since.toLocaleString()}</Tag>
                        <Tag color="gold" bordered={false}>{until.toLocaleString()}</Tag>
                        </Stack>
                    </Stack>
                    <Stack direction="column" alignItems="center" justifyContent="center" width="20%">
                    <Typography className="t_oktelo t_secondary_title" sx={{ color: "#925FFF !important"}}>{count.toLocaleString()}</Typography>
                    </Stack>
                </Stack> 
        </Box>
    )
};

export default MetricCardsIG;