// utils/postAnalyticsManual.ts
// Helper for postview.tsx

const emotionalWords = [
    "szeretet",        // love
    "gyűlölet",        // hate
    "izgatott",        // excited
    "dühös",           // angry
    "boldog",          // happy
    "szomorú",         // sad
    "lelkes",          // thrilled
    "frusztrált",      // frustrated
    "hálás",           // grateful
    "motivált",        // motivated
    "elégedett",       // content
    "szorongó",        // anxious
    "megnyugtató",     // comforting
    "meglepett",       // surprised
    "bátorított",       // encouraged
    "inspiráció",
    "megoldás",
    "biztonság",
]

const callToActions = [
    "vásárolj most",       // buy now
    "kattints ide",        // click here
    "iratkozz fel",        // sign up
    "csatlakozz hozzánk",  // join us
    "tudd meg többet",     // learn more
    "lépj kapcsolatba velünk", // contact us
    "fedezd fel",          // discover
    "próbáld ki most",     // try it now
    "tedd a kosárba",      // add to cart
    "ne hagyd ki",         // don't miss out
    "rendeld meg",     // order here
    "kapj értesítést",      // get notified
    "kövess",              // follow
    "nézd meg",            // check it out
    "csatlakozz",          // join
    "oszd meg",            // share
    "szavazz",             // vote
    "töltsd le",           // download
    "irj véleményt",       // leave a review
    "ne hagyd ki",         // don't miss out
    "látogass el",  // visit us
    "írd meg",
    "kommentelj",
    "tapasztald meg",
    "indítsd el",
    "látogasd meg",
    "kövess minket"
];


const minTextLengthFB = 100; 
const idealTextLengthFB = 200; 
const minTextLengthIG = 50; 
const idealTextLengthIG = 125; 
const minHashtags = 1;
const maxHashtags = 8;

function analyzeEmotionalWords(text: string) {
    const words = text.split(/\s+/);
    const emotionalCount = words.filter(word => emotionalWords.includes(word.toLowerCase())).length;
    if (emotionalCount > 0) return "Megfelelő";
    return "Javítani való";
}

function analyzeHashtagUsage(text: string) {
    const hashtags = (text.match(/#[\w]+/g) || []).length;
    if (hashtags >= minHashtags && hashtags <= maxHashtags) return "Megfelelő";
    if (hashtags > 0) return "Javítani való";
    return "Elégtelen";
}

function analyzeTextLength(text: string, platform: string) {
    if (platform === "instagram") {
        if (text.length >= idealTextLengthIG) return "Megfelelő";
        if (text.length >= minTextLengthIG) return "Javítani való";
        return "Elégtelen";
    } else {
        if (text.length >= idealTextLengthFB) return "Megfelelő";
        if (text.length >= minTextLengthFB) return "Javítani való";
        return "Elégtelen";
    }
}

/* function analyzeEmojiUsage(text: string) {
    const emojiRegex = /([\u2700-\u27BF]|[\u1F600-\u1F64F]|[\u1F300-\u1F5FF]|[\u1F680-\u1F6FF]|[\u1F700-\u1F77F]|[\u1F780-\u1F7FF]|[\u1F800-\u1F8FF]|[\u1F900-\u1F9FF]|[\u1FA00-\u1FA6F]|[\u1FA70-\u1FAFF])/g;
    const emojis = (text.match(emojiRegex) || []).length;
    const textLength = text.length;

    console.log("found emojis:", emojis);

    // Define acceptable emoji ranges based on text length
    let minEmojis = 1;
    let maxEmojis = 3;

    if (textLength > 100 && textLength <= 300) {
        maxEmojis = 5;
    } else if (textLength > 300) {
        maxEmojis = 8;
    }

    if (emojis >= minEmojis && emojis <= maxEmojis) return "Megfelelő";
    if (emojis > 0) return "Javítani való";
    return "Elégtelen";
}
 */

function analyzeCallToAction(text: string) {
    const hasCTA = callToActions.some(phrase => text.toLowerCase().includes(phrase));
    if (hasCTA) return "Megfelelő";
    return "Elégtelen";
}

function analyzeRepetitiveWords(text: string) {
    const words = text.toLowerCase().split(/\s+/);
    const wordCount: { [key: string]: number } = {};

    words.forEach(word => {
        if (word.length > 4) { // Only count words longer than 4 characters
            wordCount[word] = (wordCount[word] || 0) + 1;
        }
    });

    const repetitiveWords = Object.values(wordCount).filter(count => count > 2).length;

    if (repetitiveWords === 0) return "Megfelelő";
    if (repetitiveWords <= 2) return "Javítani való";
    return "Elégtelen";
}


function analyzeSchedulingTime(publishDate: Date) {
    const hour = new Date(publishDate).getHours();
    if (hour >= 8 && hour <= 20) return "Megfelelő";
    return "Javítani való";
}

export {
    analyzeEmotionalWords,
    analyzeHashtagUsage,
    analyzeTextLength,
/*     analyzeEmojiUsage, */
    analyzeCallToAction,
    analyzeRepetitiveWords,
    analyzeSchedulingTime,
};
