export const styles: string[] = [
    "Kalandvágyó",
    "Analitikus",
    "Művészi kifejezés",
    "Laza",
    "Beszélgetős",
    "Oktató",
    "Érzelmes",
    "Humoros",
    "Informatív",
    "Inspiráló",
    "Motiváló",
    "Nosztalgikus",
    "Személyes élmény",
    "Költői",
    "Professzionális",
    "Vélemény",
    "Szarkasztikus",
    "Komoly",
    "Elgondolkodtató",
    "Úti beszámoló",
    "Játékos",
    "Egyéb",
];

export const industries: string[] = [
    "Mezőgazdaság",
    "Művészet és dizájn",
    "Autóipar",
    "Biotechnológia",
    "Vegyipar",
    "Építőipar",
    "E-kereskedelem",
    "Oktatás",
    "Oktatási technológia",
    "Energia",
    "Környezetvédelem",
    "Szórakoztatás",
    "Divat",
    "Divatkiegészítők",
    "Pénzügy",
    "Fitnesz",
    "Élelmiszer és ital",
    "Erdészet",
    "Játék",
    "Egészségügy",
    "Otthon és kert",
    "Vendéglátás",
    "Biztosítás",
    "Logisztika",
    "Luxuscikkek",
    "Gyártás",
    "Marketing és reklám",
    "Média",
    "Bányászat",
    "Zene",
    "Kőolaj",
    "Gyógyszeripar",
    "Ingatlan",
    "Megújuló energia",
    "Kiskereskedelem",
    "Robotika",
    "Közösségi média",
    "Űrkutatás",
    "Sport",
    "Távközlés",
    "Telekommunikációs szolgáltatások",
    "Technológia",
    "Turizmus és utazás",
    "Szállítás",
    "Bor és szeszes italok",
    "Egyéb",
];


export const ai_models: string[] = [
/*     "Oktelo_v1_Simple", */
    "Oktelo_v2_Pro"
];


export const languages: string[] = [
    'Magyar',
    'English',
    'Arabic',
    'Bulgarian',
    'Chinese (Simplified)',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Indonesian',
    'Italian',
    'Japanese',
    'Korean',
    'Malay',
    'Macedonian',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swedish',
    'Thai',
    'Turkish',
    'Ukrainian',
    'Vietnamese',
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Armenian',
    'Azerbaijani',
    'Bengali',
    'Bosnian',
    'Catalan',
    'Chinese (Traditional)',
    'Estonian',
    'Filipino',
    'Georgian',
    'Gujarati',
    'Icelandic',
    'Irish',
    'Kannada',
    'Kurdish',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Maltese',
    'Nepali',
    'Pashto',
    'Persian',
    'Punjabi',
    'Russian',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Swahili',
    'Tamil',
    'Telugu',
    'Uzbek',
];