import React from "react";
import { useLogin, useNotification } from "@refinedev/core";
import { Form, Input, Checkbox } from "antd"
import { Typography, Box, Stack, Grid, Button } from "@mui/material";
import { oktelo } from "assets";
import { useNavigate } from "react-router-dom";
import "./authlayout.css";

export interface ILoginForm {
    email: string;
    password: string;
    remember: boolean;
  }

export const LoginPage: React.FC = () => {
  document.title = "Oktelo App | Bejelentkezés";
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login } = useLogin<ILoginForm>();
    const { open } = useNotification();
    const navigate = useNavigate();

    const onSubmit = (values: ILoginForm) => {

    
        login({
          email: values.email,
          password: values.password,
          remember: values.remember
        },
        {
        onSuccess: (data) => {
          if(!data.success){
            open?.({
                type: "error",
                message: data.message as string,
                description: "Hiba történt."
            })
        }
        else{
            open?.({
                type: "success",
                message: "Sikeres bejelentkezés.",
                description: "Üdvözöllek!"
                })
           }
        }   
      },
    );
  }
  
  

  return (
    <main style={{ minHeight: '100vh', flex: 1, overflow: 'clip', display: 'flex', padding: '20px' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        alignSelf="center"
      >
      <Grid container className="auth-container-background" >
        <Grid item xs={12} md={6} p={4} className="login-box-left">
          <Box display="flex" flexDirection="column" gap={3} width="100%">
            <img src={oktelo} width="80px" alt="Logo" />
            <Typography className="darkblue size_24 t_oktelo_medium">Vágjunk bele!</Typography>
            <Typography className="darkblue size_16 t_oktelo_light" mb={2}>
              Kérlek, jelentkezz be az Oktelo használatához.
            </Typography>
            <Form
              layout="vertical"
              form={form}
              onFinish={onSubmit}
              requiredMark={false}
              initialValues={{ remember: false }}
            >
              <Form.Item
                name="email"
                label="E-mail címed"
                rules={[{ required: true, message: 'Az e-mailt kötelező megadni.' }]}
                className="t_oktelo_normal darkblue size_14"
                style={{ marginBottom: '24px' }}
              >
                <Input size="large" className="t_oktelo_normal darkblue size_14" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Jelszó"
                rules={[{ required: true, message: 'A jelszavadat kötelező megadni.' }]}
                className="t_oktelo_normal darkblue size_14"
                style={{ marginBottom: '24px' }}
              >
                <Input type="password" size="large" className="t_oktelo_normal darkblue size_14" />
              </Form.Item>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="t_oktelo_normal darkblue size_14">Emlékezz rám</Checkbox>
                </Form.Item>
                <a className="t_oktelo_normal t_purple size_14" href="#">
                  Elfelejtett jelszó?
                </a>
              </Box>
              <button className="login_primary_button" type="submit"> 
              <Typography className="t_white size_16 t_oktelo_normal">
                Bejelentkezés
              </Typography>
              </button>
            </Form>
            <button
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                  onClick={() => navigate('/register')}
                >
                    <Typography className="darkblue size_14 t_oktelo_normal" sx={{ ':hover': { opacity: '0.8'}}}>
                        Nincs még fiókod? <span className="t_purple">Regisztráció</span>
                    </Typography>
                </button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="login-box-right">
          <img src="https://res.cloudinary.com/duhpymkbk/image/upload/v1724100782/rrpy7ioa0olbnid8qnz1.png" width="100%" height="auto"/>
        </Grid>
      </Grid>
      <Box mt={5}>
      <Stack direction="row" gap={3} alignItems="center" flexWrap="wrap" justifyContent="center">
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/adatkezelesi-tajekoztato/" target="_blank">
            Adatkezelési Tájékoztató
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/altalanos-szerzodesi-feltetelek/" target="_blank">
            Általános Szerződési Feltételek
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/adatmegorzesi-szabalyzat/" target="_blank">
            Adatfeldolgozási irányelvek
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com" target="_blank">
            Oktelo kezdőlap
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/kapcsolat/" target="_blank">
            Kapcsolat
          </a>
        </Typography>
      </Stack>
    </Box>
    </Box>

    </main>
  );
  
}
