import React from "react";
import {Box, Stack, Grid, Typography} from "@mui/material";

interface AiChartProps {
    title: string | undefined;
    percentage?: number;
    color: string;
    count: number;
    date?: Date;
    desc?: string;
}

const AI_Charts = ({ title, percentage, color, count, date, desc}: AiChartProps) => {

   

    return (
        <Box width="100%">
            <Stack direction="column" gap={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography className="t_oktelo t_desc">{title} {count?.toLocaleString()}</Typography>
                    <Typography className="t_oktelo t_misc">{percentage? percentage.toLocaleString() : count}{percentage? '%' : ''}</Typography>
                </Stack>
                <Box
                    mt={0}
                    mb={1}
                    position="relative"
                    width="100%"
                    height="8px"
                    borderRadius={1}
                    bgcolor="#f6f6f6"
                >
                    <Box
                        width={`${percentage && percentage >= 100 ? '100%' : `${percentage}%`}`}
                        bgcolor={color}
                        position="absolute"
                        height="100%"
                        borderRadius={1}
                    />
                </Box>
                {date &&
                             <Typography className="t_oktelo t_misc t_purple">{date?.toISOString()}</Typography>   
                }

                <Typography className="t_oktelo t_misc">{desc? desc : ''}</Typography>
            </Stack>
        </Box>
    )
};

export default AI_Charts;