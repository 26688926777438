import axios from 'axios';
import apiconfig from 'config';

const instance = axios.create({
  baseURL: `${apiconfig.BACKEND_URI}`,
});

instance.interceptors.request.use((config) => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  if (user && user.session) {
    config.headers.Authorization = `Bearer ${user.session}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    const newToken = response.headers.authorization;
    if (newToken) {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        user.session = newToken.replace('Bearer ', '');
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
