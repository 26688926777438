import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Box, Stack, Typography, Grid, CircularProgress, Avatar } from '@mui/material';
import { Select, ConfigProvider, DatePicker, } from 'antd';
import {  AdsClick, Home, LockReset, Person, PersonAdd, Search, Work, WorkHistory } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import DateAreaChart from "components/WebsiteAnalytics/DateAreaChart";
import { useNavigate } from "react-router-dom";
import SourcePieChart from "components/WebsiteAnalytics/SourcePieChart";
import locale from 'antd/locale/hu_HU';
import apiconfig from "config";

const { Option } = Select;
const { RangePicker } = DatePicker;


// Type for the chart data used by ApexCharts
export interface ChartSeriesData {
  name: string;
  data: { x: Date | string; y: number }[];
}

// Adjust DateMetric to align with chart data if needed
// This is for internal representation of DateMetric only, you can keep it as is if not used directly
export interface DateMetric {
  newUsers: { date: Date; value: number }[];
  totalUsers: { date: Date; value: number }[];
  sessions: { date: Date; value: number }[];
  engagedSessions: { date: Date; value: number }[];
  screenPageViews: { date: Date; value: number }[];
  eventCount: { date: Date; value: number }[];
  eventCountPerUser: { date: Date; value: number }[];
  averageSessionDuration: { date: Date; value: number }[];
  engagementRate: { date: Date; value: number }[];
}


export interface BrowserDevicePlatform {
  browser: string;
  deviceCategory: string;
  platform: string;
  sessions: string;
  newUsers: string;
  engagedSessions: string;
}

export interface Demographic {
  country: string;
  city: string;
  sessions: string;
  newUsers: string;
  engagedSessions: string;
  totalUsers: string;
  language?: string; // Optional, used in some cases
}

export interface TopPage {
  pageTitle: string;
  pageLocation: string;
  screenPageViews: string;
  averageSessionDuration: string;
  bounceRate: string;
  totalUsers: string;
  eventCount: string;
}

export interface Source {
  sessionSourceMedium: string;
  firstUserSource: string;
  sessions: string;
  newUsers: string;
  totalUsers: string;
}

interface LanguageMetric {
  language: string;
  sessions: string;
  newUsers: string;
  totalUsers: string;
}

interface AnalyticsData {
  sources: Source[];
  topPages: TopPage[];
  demographics: Demographic[];
  browserDevicePlatform: BrowserDevicePlatform[];
  dateMetrics: DateMetric[];
  languages: LanguageMetric[];
}

interface SummaryData {
  allUsers: number;
  allSessions: number;
  allNewUsers: number;
  allPageViews: number;
  allEvents: number;
  allEngagedSessions: number;
}

interface AnalyticsProps {
  id: string;
  name: string;
}


interface SummaryBoxProps {
  title: string;
  value: number;
  icon: JSX.Element;
}

const Websites = () => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>();
  const [properties, setProperties] = useState<AnalyticsProps[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<AnalyticsProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [authError, setAuthError] = useState(false);
  const navigate = useNavigate();

  const [newUsersTotalUsersData, setNewUsersTotalUsersData] = useState<ChartSeriesData[]>([]);
  const [sessionsEngagedSessionsData, setSessionsEngagedSessionsData] = useState<ChartSeriesData[]>([]);
  const [screenPageViewsEventCountData, setScreenPageViewsEventCountData] = useState<ChartSeriesData[]>([]);
  const [eventCountPerUser, setEventCountPerUser] = useState<ChartSeriesData[]>([]);
  const [averageSessionDuration, setAverageSessionDuration] = useState<ChartSeriesData[]>([]);
  const [engagementRate, setEngagementRate] = useState<ChartSeriesData[]>([]);
  const [totalUsersWithinPeriod, setTotalUsersWithinPeriod] = useState<number>(0);
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [since, setSince] = useState<string | null>(null);
  const [until, setUntil] = useState<string | null>(null);
  const [dateRangeLength, setDateRangeLength] = useState<number>(7); // Initial value
  
  const onRangeChange = useCallback((dates: (Dayjs | null)[] | null, dateStrings: [string, string]) => {
    if (dates && dates[0] && dates[1]) {
      // Handle valid dates
      setSince(dateStrings[0] ?? null);
      setUntil(dateStrings[1] ?? null);
      setDateRange(dates as [Dayjs | null, Dayjs | null]);
  
      // Calculate the number of days in the range
      const startDate = dayjs(dateStrings[0]);
      const endDate = dayjs(dateStrings[1]);
      const numberOfDays = endDate.diff(startDate, 'day') + 1; // Adding 1 to include both start and end dates
  
      setDateRangeLength(numberOfDays);
    } else {
      // Handle null or invalid dates
      setSince(null);
      setUntil(null);
      setDateRange([null, null]);
      setDateRangeLength(7); // Default value if no dates are selected
    }
  }, []);

  useEffect(() => {
    if (analyticsData && analyticsData.dateMetrics && analyticsData.dateMetrics.length > 0) {
      // Extract dateMetrics from analyticsData
      const dateMetrics = analyticsData.dateMetrics;
      
      // Prepare data for each chart group
      setNewUsersTotalUsersData([
        { name: 'newUsers', data: dateMetrics.flatMap(metric => 
          metric.newUsers ? metric.newUsers.map(({ date, value }) => ({ x: dayjs(date).format('MM.DD'), y: Number(value) })) : []
        )},
        { name: 'totalUsers', data: dateMetrics.flatMap(metric => 
          metric.totalUsers ? metric.totalUsers.map(({ date, value }) => ({ x: dayjs(date).format('MM.DD'), y: Number(value) })) : []
        )},
      ]);  
  
      setSessionsEngagedSessionsData([
        { name: 'sessions', data: dateMetrics.flatMap(metric => 
          metric.sessions ? metric.sessions.map(({ date, value }) => ({ x: dayjs(date).format('MM.DD'), y: Number(value) })) : []
        )},
        { name: 'engagedSessions', data: dateMetrics.flatMap(metric => 
          metric.engagedSessions ? metric.engagedSessions.map(({ date, value }) => ({ x: dayjs(date).format('MM.DD'), y: Number(value) })) : []
        )},
      ]);
  
      setScreenPageViewsEventCountData([
        { name: 'screenPageViews', data: dateMetrics.flatMap(metric => 
          metric.screenPageViews ? metric.screenPageViews.map(({ date, value }) => ({ x: dayjs(date).format('MM.DD'), y: Number(value) })) : []
        )},
        { name: 'eventCount', data: dateMetrics.flatMap(metric => 
          metric.eventCount ? metric.eventCount.map(({ date, value }) => ({ x: dayjs(date).format('MM.DD'), y: Number(value) })) : []
        )},
      ]);

      setEventCountPerUser([
        { 
          name: 'eventCountPerUser', data: dateMetrics.flatMap(metric => 
          metric.eventCountPerUser ? metric.eventCountPerUser.map(({ date, value }) => ({ x: dayjs(date).format('YYYY.MM.DD'), y: Number(value) })) : []
        )}
      ]);

      setAverageSessionDuration([
       { 
        name: 'averageSessionDuration', data: dateMetrics.flatMap(metric => 
        metric.averageSessionDuration ? metric.averageSessionDuration.map(({ date, value }) => ({ x: dayjs(date).format('YYYY.MM.DD'), y: Number(value) })) : []
      )}
      ]);

      setEngagementRate([
        { 
         name: 'engagementRate', data: dateMetrics.flatMap(metric => 
         metric.engagementRate ? metric.engagementRate.map(({ date, value }) => ({ x: dayjs(date).format('YYYY.MM.DD'), y: Number(value) })) : []
       )}
       ]);

    }

  }, [analyticsData]);
  
  useEffect(() => {
    if (analyticsData && analyticsData.dateMetrics && analyticsData.dateMetrics.length > 0) {
      const dateMetrics = analyticsData.dateMetrics;
  
      const totalUsersSum = dateMetrics.reduce((sum, metric) => {
        if (metric.totalUsers) {
          const totalUsersForDate = metric.totalUsers.reduce((acc, { value }) => acc + Number(value), 0);
          return sum + totalUsersForDate;
        }
        return sum;
      }, 0);
  
      const totalSessionsSum = dateMetrics.reduce((sum, metric) => {
        if (metric.sessions) {
          const sessionsForDate = metric.sessions.reduce((acc, { value }) => acc + Number(value), 0);
          return sum + sessionsForDate;
        }
        return sum;
      }, 0);
  
      const totalNewUsersSum = dateMetrics.reduce((sum, metric) => {
        if (metric.newUsers) {
          const newUsersForDate = metric.newUsers.reduce((acc, { value }) => acc + Number(value), 0);
          return sum + newUsersForDate;
        }
        return sum;
      }, 0);
  
      const totalPageViewsSum = dateMetrics.reduce((sum, metric) => {
        if (metric.screenPageViews) {
          const pageViewsForDate = metric.screenPageViews.reduce((acc, { value }) => acc + Number(value), 0);
          return sum + pageViewsForDate;
        }
        return sum;
      }, 0);
  
      const totalEventsSum = dateMetrics.reduce((sum, metric) => {
        if (metric.eventCount) {
          const eventsForDate = metric.eventCount.reduce((acc, { value }) => acc + Number(value), 0);
          return sum + eventsForDate;
        }
        return sum;
      }, 0);
  
      const totalEngagedSessionsSum = dateMetrics.reduce((sum, metric) => {
        if (metric.engagedSessions) {
          const engagedSessionsForDate = metric.engagedSessions.reduce((acc, { value }) => acc + Number(value), 0);
          return sum + engagedSessionsForDate;
        }
        return sum;
      }, 0);
  
      setSummaryData({
        allUsers: totalUsersSum,
        allSessions: totalSessionsSum,
        allNewUsers: totalNewUsersSum,
        allPageViews: totalPageViewsSum,
        allEvents: totalEventsSum,
        allEngagedSessions: totalEngagedSessionsSum,
      });

      setTotalUsersWithinPeriod(totalUsersSum);

    }
  }, [analyticsData]);
  

  const fetchAnalytics = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiconfig.BACKEND_URI}/websites/`);
      if (response.status === 200) {
        setProperties(response.data.analytics);
        if (!selectedProperty && response.data.analytics.length > 0) {
          setSelectedProperty(response.data.analytics[0]);
        }
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProperty = async () => {
    if (!selectedProperty) return;
    setLoading(true);
    try {
      const response = await axios.post(`${apiconfig.BACKEND_URI}/websites/analytics`, 
        { propertyId: selectedProperty.id,
          start: since || '7daysAgo',
          end: until || 'today',
         });

/*         if(response.status === 401){
          return setAuthError(true);
        } */

        setAnalyticsData(response.data);
    } catch (error) {
      console.error(error);
/*       setAuthError(true); */
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  useEffect(() => {
    fetchProperty();
  }, [selectedProperty, dateRange]);


  if (authError){
    return (
      <Box width="100%" height="100%" flex={1}>
        <Stack direction="column" gap={1.5} alignItems="center">
        <LockReset/>
        <Typography className="t_oktelo_semibold size_18 darkblue">
          Google biztonsági megerősítés
        </Typography>
        <Typography className="t_oktelo_normal size_14 darkblue">
          A Google kapcsolatod lejárt, ezért biztonsági okokból újra kell csatlakoztatnod
          a fiókodat. Ezt megteheted a beállításokban, hogy hozzáférhess az elemzésekhez.
        </Typography>
        <button className="home-action-button b925" onClick={() => navigate('/settings')}>
          Beállítások
        </button>
        </Stack>
      </Box>
    )
  }

  return (
    <Box width="100%">
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={12} lg={4} xl={4}>
          <Stack flexDirection="column" alignItems="flex-start" gap={1}>
            <Typography className="size_20 darkblue t_oktelo_semibold">Weboldal elemzések</Typography>
            <Typography className="t_oktelo_normal size_14 darkblue">A Google Analytics által mért, válogatott adatokat jelenítünk meg.</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={8} xl={8} display="flex" justifyContent="flex-end" alignItems="center">
          <Grid container spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Stack direction="column" alignItems="flex-start" justifyContent="center" p={1} gap={0.5}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Tulajdon
            </Typography>
            <Select
            style={{ width: '100%' }}
            value={selectedProperty?.id ?? null}
            placeholder="Oldalak.."
            onChange={(value) => setSelectedProperty(properties.find(item => item.id === value) || null)}
            disabled={loading}
          >
            {properties.map(item => (
              <Option key={item.id} value={item.id}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Home style={{ fontSize: "14px", color: "#f09433" }} />
                  <Typography className="t_oktelo t_desc">{item.name}</Typography>
                </Stack>
              </Option>
            ))}
          </Select>
          </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Stack direction="column" alignItems="flex-start" justifyContent="center" p={1} gap={0.5}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Elemzés intervalluma
            </Typography>
            <ConfigProvider locale={locale}>
              <RangePicker
              style={{ width: '100%' }}
              onChange={onRangeChange}
              value={dateRange}
              maxDate={dayjs()}
              minDate={dayjs().subtract(90, 'day')}
              showTime={false}
              />
            </ConfigProvider>
          </Stack>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
      {loading || !analyticsData ? <CircularProgress sx={{ color: '#925FFF'}}/> : (
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <SummaryBox title="Felhasználók" value={Number(summaryData?.allUsers)} icon={<Person/>}/>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <SummaryBox title="Új felhasználók" value={Number(summaryData?.allNewUsers)} icon={<PersonAdd/>}/>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <SummaryBox title="Munkamenetek" value={Number(summaryData?.allSessions)} icon={<Work/>}/>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <SummaryBox title="Elköt. munkamenetek" value={Number(summaryData?.allEngagedSessions)} icon={<WorkHistory/>}/>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <SummaryBox title="Oldal megtekintések" value={Number(summaryData?.allPageViews)} icon={<Search/>}/>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <SummaryBox title="Események" value={Number(summaryData?.allEvents)} icon={<AdsClick/>}/>
        </Grid>
        <Grid item xl={dateRangeLength > 12 ? 12 : 4} lg={dateRangeLength > 12 ? 12 : 4} md={12} xs={12}>
        <Box className="oktelo_card" width="100%" sx={{ padding: '12px !important'}}>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Felhasználók alakulása
          </Typography>
          <DateAreaChart
          title="New Users & Total Users"
          chartData={newUsersTotalUsersData}
        />
        </Box>
        </Grid>
        <Grid item xl={dateRangeLength > 12 ? 12 : 4} lg={dateRangeLength > 12 ? 12 : 4} md={12} xs={12}>
        <Box className="oktelo_card" width="100%" sx={{ padding: '12px !important'}}>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Munkamenetek
          </Typography>
          <DateAreaChart
          title="Session"
          chartData={sessionsEngagedSessionsData}
        />
        </Box>
        </Grid>
        <Grid item xl={dateRangeLength > 12 ? 12 : 4} lg={dateRangeLength > 12 ? 12 : 4} md={12} xs={12}>
        <Box className="oktelo_card" width="100%" sx={{ padding: '12px !important'}}>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Oldalak és események
          </Typography>
          <DateAreaChart
          title="View"
          chartData={screenPageViewsEventCountData}
        />
        </Box>
        </Grid>
        
        <Grid item xl={4} lg={4} md={12} xs={12}>
        <Box className="oktelo_card">
          <Typography className="t_oktelo_medium size_16 darkblue">
            Esemény/Felhasználó
          </Typography>
          {eventCountPerUser && eventCountPerUser.length > 0 &&
        eventCountPerUser.map((val, index) => (
          <Box key={`${val.name}-${index}`} py={1} width="100%">
            {val.data.map(d => (
              <Box key={`${d.x}-${d.y}`} my={1} sx={{ borderBottom: '1px solid #d9d9d9' }}>
                <Stack direction="row" gap={1} justifyContent="space-between" py={0.5}>
                <Typography className="t_oktelo_normal size_14 darkblue">{dayjs(d.x).format('YYYY.MM.DD, (dddd)')}</Typography>
                <Typography className="t_oktelo_medium size_14 darkblue">{d.y.toFixed(2)}</Typography>
                </Stack>
              </Box>
            ))}
            <Typography className="t_oktelo_normal miscgray size_12">
              Jelentése, hogy egy adott napon egy felhasználó átlagosan hány eseményt generált a weboldalon vagy alkalmazásban.
            </Typography>
          </Box>
        ))
      }
        </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} xs={12}>
        <Box className="oktelo_card">
          <Typography className="t_oktelo_medium size_16 darkblue">
            Átlagos munkamenet időtartam
          </Typography>
          {averageSessionDuration && averageSessionDuration.length > 0 &&
  averageSessionDuration.map((val, index) => (
    <Box key={`${val.name}-${index}`} py={1} width="100%">
      {val.data.map(d => {
        // Convert minutes to seconds

        // Convert seconds to minutes and seconds
        const minutes = Math.floor(d.y / 60);
        const seconds = Math.floor(d.y % 60);
        const formattedDuration = `${minutes}p ${seconds}mp`;

        return (
          <Box key={`${d.x}-${d.y}`} my={1} sx={{ borderBottom: '1px solid #d9d9d9' }}>
            <Stack direction="row" gap={1} justifyContent="space-between" py={0.5}>
              <Typography className="t_oktelo_normal size_14 darkblue">
                {dayjs(d.x).format('YYYY.MM.DD, (dddd)')}
              </Typography>
              <Typography className="t_oktelo_medium size_14 darkblue">
                {formattedDuration}
              </Typography>
            </Stack>
          </Box>
        );
      })}
      <Typography className="t_oktelo_normal miscgray size_12">
      Az átlagos munkamenet időtartam azt mutatja, hogy egy felhasználó átlagosan mennyi időt tölt egy munkamenet során a weboldalon vagy alkalmazásban.
      </Typography>
    </Box>
  ))}
        </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} xs={12}>
        <Box className="oktelo_card">
          <Typography className="t_oktelo_medium size_16 darkblue">
            Elköteleződési arány
          </Typography>
          {engagementRate && engagementRate.length > 0 &&
        engagementRate.map((val, index) => (
          <Box key={`${val.name}-${index}`} py={1} width="100%">
            {val.data.map(d => (
              <Box key={`${d.x}-${d.y}`} my={1} sx={{ borderBottom: '1px solid #d9d9d9' }}>
                <Stack direction="row" gap={1} justifyContent="space-between" py={0.5}>
                <Typography className="t_oktelo_normal size_14 darkblue">{dayjs(d.x).format('YYYY.MM.DD, (dddd)')}</Typography>
                <Typography className="t_oktelo_medium size_14 darkblue">{(d.y * 100).toFixed(2)}%</Typography>
                </Stack>
              </Box>
            ))}
            <Typography className="t_oktelo_normal miscgray size_12">
            Az a százalékos arány, amely megmutatja, hogy a felhasználók hány százaléka vett részt aktívan a weboldal vagy alkalmazás tartalmában.
          </Typography>
          </Box>
        ))
      }
        </Box>
        </Grid>
        <Grid item xl={12} xs={12} >
  <Box className="oktelo_card">
    <Typography className="t_oktelo_medium size_16 darkblue">
      Eszközök és böngészők (top 6)
    </Typography>
    <Grid container spacing={1} p={1}>
    {analyticsData?.browserDevicePlatform.map((item, index) => (
      <Grid item key={index} xl={4} lg={4} md={6} sm={6} xs={12}>
        <Box sx={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: '8px'}}>
        <Typography className="t_oktelo_medium size_16 t_purple">
          {index+1}.
        </Typography>
        <Typography className="t_oktelo_medium size_14 darkblue">
          {item.browser} - {item.deviceCategory} ({item.platform})
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Munkamenetek: {item.sessions}
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Új felhasználók: {item.newUsers}
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Elkötelezett munkamenetek: {item.engagedSessions}
        </Typography>
        </Box>
      </Grid>
    ))}
    </Grid>
  </Box>
</Grid>
<Grid item xl={12} xs={12}>
  <Box className="oktelo_card">
    <Typography className="t_oktelo_medium size_16 darkblue">
      Demográfiai adatok
    </Typography>
    <Grid container spacing={1} p={1}>
    {analyticsData?.demographics.map((item, index) => (
      <Grid item key={index} xl={4} lg={4} md={6} sm={6} xs={12}>
        <Box sx={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: '8px'}}>
        <Typography className="t_oktelo_medium size_16 t_purple">
          {index+1}.
        </Typography>
        <Typography className="t_oktelo_medium size_14 darkblue">
          {item.country} - {item.city}
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Munkamenetek: {item.sessions}
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Új felhasználók: {item.newUsers}
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Elkötelezett munkamenetek: {item.engagedSessions}
        </Typography>
        <Typography className="t_oktelo_normal size_14">
          Összes felhasználó: {item.totalUsers}
        </Typography>
        </Box>
      </Grid>
    ))}
    </Grid>
  </Box>
</Grid>
<Grid item xl={12} xs={12}>
  <Box className="oktelo_card">
    <Typography className="t_oktelo_medium size_16 darkblue">
      Legjobban teljesítő oldalak (top 10)
    </Typography>
    {analyticsData?.topPages.map((item, index) => {

      const totalSeconds = parseFloat(item.averageSessionDuration);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = Math.floor(totalSeconds % 60);

      const formattedDuration = `${minutes}p ${seconds}mp`;
      return (
        <Box sx={{ borderBottom: '1px solid #d9d9d9', borderRadius: '6px', padding: '8px', width: '100%'}}>
        <Stack direction="column" gap={1}>
        <Typography className="t_oktelo_medium size_16 darkblue">
          {item.pageTitle}
        </Typography>
        <a href={item.pageLocation} target="_blank">
        <Typography className="t_oktelo_normal size_14 t_purple" sx={{ ':hover': { color: '#ff925f !important'}, transition: 'all 0.3s'}}>
          {item.pageLocation}
        </Typography>
        </a>
        <Stack direction="row" gap={1} alignItems="center" width="100%" flex={1} flexWrap="wrap">
          <Stack direction="column" gap={1} p={2} flex={1} sx={{ backgroundColor: '#ede4ff', borderRadius: '4px'}}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Megtekintések
            </Typography>
            <Typography className="t_oktelo_semibold size_20 darkblue">
              {item.screenPageViews}
            </Typography>
          </Stack>
          <Stack direction="column" gap={1} p={2} flex={1} sx={{ backgroundColor: '#ede4ff', borderRadius: '4px'}}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Felhasználók
            </Typography>
            <Typography className="t_oktelo_semibold size_20 darkblue">
              {item.totalUsers}
            </Typography>
          </Stack>
          <Stack direction="column" gap={1} p={2} flex={1} sx={{ backgroundColor: '#ede4ff', borderRadius: '4px'}}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Események
            </Typography>
            <Typography className="t_oktelo_semibold size_20 darkblue">
              {item.eventCount}
            </Typography>
          </Stack>
          <Stack direction="column" gap={1} p={2} flex={1} sx={{ backgroundColor: '#ede4ff', borderRadius: '4px'}}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Átl. munkamenet időtartam
            </Typography>
            <Typography className="t_oktelo_semibold size_20 darkblue">
              {formattedDuration}
            </Typography>
          </Stack>
          <Stack direction="column" gap={1} p={2} flex={1} sx={{ backgroundColor: '#ede4ff', borderRadius: '4px'}}>
            <Typography className="t_oktelo_medium size_12 darkblue">
            Visszafordulási arány
            </Typography>
            <Typography className="t_oktelo_semibold size_20 darkblue">
            {(Number(item.bounceRate) * 100).toFixed(2)}%
            </Typography>
          </Stack>
        </Stack>
        </Stack>
        </Box>
    )})}
    </Box>
</Grid>
{analyticsData?.languages.map((item, index) => (
      <Grid item xl={3} lg={3} md={6} sm={6} xs={12} key={index+item.language}>
        <Box className="oktelo_card" px={1.5}>
          <Stack direction="column" gap={1}>
          <Typography className="t_oktelo_medium size_12 miscgray">
            Nyelvek (top {index+1})
        </Typography>
            <Typography className="t_oktelo_medium size_16 darkblue">
            {item.language}
          </Typography>

          <Typography className="t_oktelo_normal size_14 darkblue">
            Felhasználók: {item.totalUsers}
          </Typography>
          <Typography className="t_oktelo_normal size_14 darkblue">
            Új felhasználók: {item.newUsers}
          </Typography>
          <Typography className="t_oktelo_normal size_14 darkblue">
            Munkamenetek: {item.sessions}
          </Typography>
          </Stack>
        </Box>
      </Grid>
    ))}

    {analyticsData?.sources.map((item, index) => (
      <Grid item xl={4} lg={4} md={6} sm={6} xs={12} key={index+item.sessionSourceMedium}>
        <Box className="oktelo_card" px={1.5}>
        <Typography className="t_oktelo_medium size_12 miscgray">
      Felhasználószerzés források és médium
    </Typography>
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Stack direction="column" gap={1}>
{/*             <Tag color="orange" className="tag_oktelo t_oktelo_medium size_12">
              Top {index+1} munkamenet forrás
            </Tag> */}
            <Typography className="t_oktelo_medium size_16 darkblue">
            {item.sessionSourceMedium}
          </Typography>

          <Typography className="t_oktelo_normal size_14 darkblue">
            Új felhasználó forrása: {item.firstUserSource}
          </Typography>
          <Typography className="t_oktelo_normal size_14 darkblue">
            Felhasználók: {item.totalUsers}
          </Typography>
          <Typography className="t_oktelo_normal size_14 darkblue">
            Új felhasználók: {item.newUsers}
          </Typography>
          <Typography className="t_oktelo_normal size_14 darkblue">
            Munkamenetek: {item.sessions}
          </Typography>
          </Stack>
            <SourcePieChart value={Number(item.totalUsers)} maxValue={totalUsersWithinPeriod}/>
          </Stack>
          
        </Box>
      </Grid>
    ))}
      </Grid>)}
    </Box>
  );
};


const SummaryBox: React.FC<SummaryBoxProps> = ({ title, value, icon }) => {
  return (
    <Box className="chart-box">
      <Stack direction="column" justifyContent="space-between" gap={0} width="100%" height="100%">
        <Avatar sx={{ backgroundColor: '#f9f9f9', width: '36px', height: '36px', borderRadius: '6px' }}>
          {icon}
        </Avatar>
        <Typography className="t_oktelo_medium size_14 darkblue" mt={1.5}>
          {title}
        </Typography>
        <Typography className="text-monster t_oktelo_bold size_32">
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};


export default Websites;
