import Stack from "@mui/material/Stack";
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import React, { useState } from "react";

interface SourcePieChartProps {
    maxValue: number;
    value: number;
}


const SourcePieChart: React.FC<SourcePieChartProps> = ({ maxValue, value }) => {
      
      const percentage = value / maxValue * 100;
      const remainingPercentage = 100 - percentage;
      
      // Format percentages with one decimal point
      const formattedPercentage = percentage.toFixed(1)+ '%';
      const formattedRemainingPercentage = remainingPercentage.toFixed(1) + '%';
      
      // Series data
      const cseries = [parseFloat(formattedPercentage), parseFloat(formattedRemainingPercentage)];

// Chart options
const options: ApexOptions = {
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: false,
            name: {
              show: false
            },
            value: {
              show: false,
              fontSize: '14px'
            }
          }
      },
      expandOnClick: false,
      customScale: 1
      }
    },
    chart: {
      type: 'donut',
      events: {
        click: function(event, chartContext, config) { return; },
        dataPointSelection: function(event, chartContext, config) { return; },
      }
    },
    colors: ['#925FFF', '#d3d3d3'],
    legend: { show: false },
    labels: ['Felhasználók (%)', ``],
    dataLabels: { enabled: false },
    responsive: [
      {
        breakpoint: 0, 
        options: {
          chart: {
            width: '0' 
          }
        }
      },
      {
        breakpoint: 596, 
        options: {
          chart: {
            width: '0' 
          }
        }
      },
      {
        breakpoint: 786, 
        options: {
          chart: {
            width: '120px' 
          }
        }
      },
      {
        breakpoint: 1200, 
        options: {
          chart: {
            width: '120px'
          }
        }
      },
      {
      breakpoint: 1400,
        options: {
          chart: {
            width: '140px'
          }
        }
      },
      {
        breakpoint: 1600,
          options: {
            chart: {
              width: '160px'
            }
          }
        },
        {
          breakpoint: 2000,
          options: {
            chart: {
              width: '160px'
            }
          }        
        }
    ]
  };

  return (
    <div>
        <ReactApexChart
        type="donut"
        options={options}
        series={cseries}
        />
    </div>

  )

}

export default SourcePieChart