import ReactApexChart from "react-apexcharts";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ArrowCircleUpRounded from "@mui/icons-material/ArrowCircleUpRounded";
import dayjs from "dayjs";
import { TotalRevenueOptions, TotalRevenueSeries } from "./chart.config";

import { ApexOptions } from "apexcharts";
import { useState, useEffect } from "react";
import { ArrowCircleDownOutlined } from "@mui/icons-material";

interface MetricData {
    end_time: string;
    value: number;
  }
  
  interface ChartData {
    [metricName: string]: MetricData[];
  }

  interface TwentyEightProps {
    chartData: ChartData | undefined;
    beforePoints: number;
}


  const TwentyEight: React.FC<TwentyEightProps> = ({ chartData, beforePoints }) => {
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});
    const [points, setPoints] = useState<number>(0);
    const [percentageChange, setPercentageChange] = useState<number>(0);
    const [b_points, setBPoints] = useState<number>(0);


  const metricNameMapping: { [key: string]: string } = {
        page_actions_post_reactions_like_total: "Reactions",
        page_impressions: "Impressions",
        page_views_total: "Views",
        impressions: "Impressions",
        reach: "Reach",
    };

    useEffect(() => {
        if (chartData) {
            // Extracting series data
            const seriesData = Object.keys(chartData).map(metricName => ({
                name: metricNameMapping[metricName] || metricName,
                data: chartData[metricName].map(item => item.value),
            }));
    
            // Extracting options data
            const firstMetricValues = chartData[Object.keys(chartData)[0]];
            const optionsData: ApexOptions = {
                xaxis: {
                    categories: firstMetricValues.map(value => dayjs(value.end_time).format('MMM DD.')),
                },
                yaxis: {
                    title: {
                        text: "Total count",
                    },
                },
                fill: {
                    opacity: 1,
                },
                colors: ["#925FFF", "#CF9FFF", "#CFC8FF"],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                        columnWidth: "55%",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    show: false,
                },
                stroke: {
                    colors: ["transparent"],
                    width: 4,
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                tooltip: {
                    y: {
                        formatter(val: number) {
                            return `${val}`;
                        },
                    },
                },
            };
    
            setSeries(seriesData);
            setOptions(optionsData);

            let totalSum = 0;

            // Iterate over each metric in chartData
            Object.values(chartData).forEach(metricData => {
                // Iterate over each data item for the current metric
                metricData.forEach(item => {
                    totalSum += item.value;
                });
            });
            
            // Log the total sum of all metric values
            setPoints((totalSum*0.2/10)); 

            if(beforePoints === 0){
                const beforePointsValue = 0;
                setBPoints(0);
                const percentageChange = beforePointsValue === 0 ? 1 : ((totalSum * 0.2 / 10 - beforePointsValue) / beforePointsValue) * 100;
                setPercentageChange(percentageChange);
            }
            else{
                const beforePointsValue = (beforePoints*0.2/10);
                setBPoints(beforePointsValue);
                const percentageChange = ((totalSum*0.2 / 10 - beforePointsValue) / beforePointsValue) * 100;
                setPercentageChange(percentageChange);
            }

        }
    }, [chartData]);

    return (
        <Box
            p={3}
            flex={1}
            bgcolor="#ffffff"
            id="chart"
            display="flex"
            flexDirection="column"
            borderRadius="12px"
        >
            <Typography className="t_oktelo t_main_title" color="#11142d">
                Weekly Social Summary
            </Typography>

            {/* Placeholder content */}
            <Stack my="20px" direction="row" gap={4} flexWrap="wrap" alignItems="center">
                <Typography className="t_oktelo t_secondary_title" sx={{lineHeight: '18px !important'}} color="#11142d">
                    {points.toFixed(0)} points<br></br><span style={{ fontWeight: '300', fontSize: '12px'}}>this week</span>
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                    {percentageChange > 0 ? (
                    <ArrowCircleUpRounded
                    sx={{ fontSize: 32, color: "#925FFF" }}
                />
                    ) : (
                        <ArrowCircleDownOutlined
                        sx={{ fontSize: 32, color: "#ffa39e" }}
                    />
                    )}
                    <Stack>
                        <Typography className="t_oktelo t_misc" color={percentageChange > 0 ? '#925fff !important' : '#ffa39e !important'}>
                            {percentageChange.toFixed(2)}%
                        </Typography>
                        <Typography className="t_oktelo t_misc">
                            Than Last Week ({b_points.toFixed(0)} points)
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            {/* Render the chart with dynamic series and options */}
            <ReactApexChart
                series={series}
                type="bar"
                height={310}
                options={options}
            />
        </Box>
    );
};

export default TwentyEight;