import React, { useState, useEffect } from "react";
import {Box, Stack, Grid, Typography, Button, CircularProgress} from "@mui/material"
import { Select, Space } from 'antd';
import AI_Charts from "components/charts/AI_Charts";

import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import { FacebookOutlined, Instagram,  ZoomOutMapOutlined } from "@mui/icons-material";
import axios from "axios";
import { StarTwoTone } from "@ant-design/icons";
import { PropertyProps } from "interfaces/common";
import AnalyticsModal from "./AI_AnalyticsModal";
import RenderWorldMap from "components/charts/WorldMap";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";

const { Option } = Select;

interface PageData {
    page_fans: number;
    page_fans_country: Record<string, number>;
    page_impressions_by_age_gender_unique: Record<string, number>;
    page_impressions_unique: number;
    page_post_engagements: number;
    page_views_total: number;
    total_interactions: number;
    instagram_basic: Record<string, number>;
    page_fans_online: Record<string, number>;
}

const AI_ChartGrid = () => {
   
    const [userPages, setUserPages] = useState<any>(null);
    const [selectedPage, setSelectedPage] = useState<string | null>(localStorage.getItem('sp') || null);

    const [pageData, setPageData] = useState<PageData>();
    const [isEnoughFans, setEnoughFans] = useState<Boolean>(false);
    const [isEnoughImps, setEnoughImps] = useState<Boolean>(false);

    const [getPageType, setPageType] = useState<string>("");
    const [getUserPosts, setUserPosts] = useState<any>(null);
    const [selectedPost, setSelectedPost] = useState<any>();
    const [modalVisible, setModalVisible] = useState(false);
    const [aiGetBrief, aiSetBrief] = useState<string>('');
    const [aiGetTips, aiSetTips] = useState<string>('');
    const [loading, setLoading] = useState<Boolean>(false);
    const [getUserTokens, setUserTokens] = useState<number>(0);

    //Get all Facebook and Instagram page (Names) -> Page load / component mount
    useEffect(() => {
        async function fetchUserData() {
            try {
                setLoading(true);
                const pageId = localStorage.getItem('sp')?? selectedPage ?? null;
                const response = await axios.get(`http://localhost:8080/api/v1/users/`, {
                    headers: {
                        "user_request_type": "get_ai_user",
                        "the_page_id": pageId
                    },
                }); 
                const allFacebookPages = response.data.allFacebook.map((page: any) => ({ id: page.id, name: page.name }));
                const allInstagramPages = response.data.allInstagram.map((page: any) => ({ id: page.id, name: page.name }));
                const allTokens = response.data.tokens;
                const allUserPosts = response.data.allProperties || [];
                const allPages = [...allFacebookPages, ...allInstagramPages];
            
                // Extract both names (or usernames) and ids from all pages
                const allNames = allPages.map((page: any) => ({ id: page.id, name: page.name}));
            
                const userPagesData = {
                    allNames: allNames,
                };
                setUserPages(userPagesData);
                setUserTokens(allTokens);
                setUserPosts(allUserPosts);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchUserData();

    }, [selectedPage]);

    //Get the Analytics data for social media page -> On selectedPage is changed
    useEffect(() => {
        async function getSocialData() {
            try {
                const response = await axios.get(`http://localhost:8080/api/v1/ai/get/`, {
                    params: {
                        selectedPage: selectedPage
                    }
                });

                setPageData(response.data.summary);
                setPageType(response.data.page_type);

                if (selectedPage && userPages) {

                    const selectedPageData = userPages.allNames.find((page: any) => page.id === selectedPage);
                
                    if (selectedPageData) {
                        localStorage.setItem('sp', selectedPage);
                        localStorage.setItem('spn', selectedPageData.name);
                        dispatchEvent(new Event("storage"));
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        getSocialData();
    
    }, [selectedPage]);


    useEffect(() => {
        if (userPages && !selectedPage) {
          const storedPage = localStorage.getItem('sp');
          if (storedPage !== null) {
            setSelectedPage(storedPage);
          } else {
            // Check if userPages is an array and has elements
            if (Array.isArray(userPages.allFacebook) && userPages.allFacebook.length > 0) {
              setSelectedPage(userPages.allFacebook[0].id);
            } else {
              setSelectedPage(null);
            }
          }
        }
      }, [selectedPage, userPages]);

    const handleSelectChange = (postId: string) => {
        const selectedPost = getUserPosts.find((post: any) => post._id === postId);
        setSelectedPost(selectedPost); // Set the selected post
      };



    // Helper function to convert age-gender format to desired format
    const formatAgeGender = (ageGender: string): string => {
        const [gender, ageRange] = ageGender.split('.');
        const ageRanges: Record<string, string> = {
            '13-17': '13-17',
            '18-24': '18-24',
            '25-34': '25-34',
            '35-44': '35-44',
            '45-54': '45-54',
            '55-64': '55-64',
            '65+': '65+',
            'U': 'Unknown',
        };
        const formattedAgeRange = ageRanges[ageRange] || 'Unknown';
        const formattedGender = gender === 'F' ? 'Female' : gender === 'M' ? 'Male' : 'Unknown';
        return `${formattedGender} (${formattedAgeRange})`;
    };

    //Helper function to make the instagram demographics data to desired Format ('_' => ' ' & Uppercase first character)
    const formatTitle = (metric: string) => {
        return metric.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    //Initialize variables
    let demoChart: any | null = null; //The chart for FB Demographics or Insta Activity (rendered)
    let canRenderDemoChart = false; //Decider to can the Demographic Chart can be rendered.
    const maxImpressions: number = pageData?.page_impressions_unique || 0; //Get the maxImpressions (for chart percentage(%)) -> Fb Demographics
    const maxInteractions: number = pageData?.total_interactions || 0; //Get the maxInteractions (for chart percentage(%)) -> Insta Activity
    const maxFans: number = pageData?.page_fans || 0; //Get the maxFans (for chart percentage(%)) -> Country
    let ageGenderImpressions: { ageGender: string; impressions: number; percentage: number }[] = []; //Will get all the entries of this data
    let instaBasic: { metric: string; count: number; percentage: number }[] = []; //Will get all the entries of this data
    let countries: { country: string; count: number; percentage: number }[] = []; //Will get all the entries of this data -> Locations Chart

    if(pageData){
        countries = Object.entries(pageData.page_fans_country)
        .map(([country, count]) => ({
            country,
            count: count as number,
            percentage: Math.round((count as number / maxFans) * 1000) / 10
        }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);
    }

    const renderTheChart = (type: string, data: any[]) => {

        if (type === 'instaBasic')
            {

            demoChart = data.map(({ metric, count, percentage }) => (
                <AI_Charts key={metric} title={`${formatTitle(metric)}:`} percentage={percentage} count={count} color="#925fff" />
            ));
            
            //If all data (count) is zero, then 'Not available'
            if (data.every(({ count }) => count === 0))
                {
                canRenderDemoChart = false;
            }
            else
            {
                canRenderDemoChart = true;
            }
        }

        else if(type === 'ageGender')
        {

            demoChart = data.map(({ ageGender, impressions, percentage }) => (
                <AI_Charts key={ageGender} title={`${formatTitle(ageGender)}:`} percentage={percentage} count={impressions} color="#925fff" />
            ));

            //If all data (count) is zero, or no data -> 'No available'
            if((data.length === 0) || (data.every(({ impressions }) => impressions === 0)))
            {
                canRenderDemoChart = false;
            }
            else
            {
                canRenderDemoChart = true;
            }
        }

        else
        {
            canRenderDemoChart = false;
        }

    return canRenderDemoChart? demoChart : <Typography className="t_misc t_oktelo">Sajnálom! Nincs elérhető adat. Lehet, hogy az oldalad nem ért el elegendő adatmennyiséget.</Typography>;
    };

    const mapColors: { [key: number]: string } = {
        0: 'brightness(0) saturate(100%) invert(26%) sepia(37%) saturate(3596%) hue-rotate(246deg) brightness(88%) contrast(93%);',
        1: 'brightness(0) saturate(100%) invert(31%) sepia(93%) saturate(681%) hue-rotate(228deg) brightness(98%) contrast(84%);',
        2: 'brightness(0) saturate(100%) invert(74%) sepia(76%) saturate(4011%) hue-rotate(216deg) brightness(85%) contrast(87%);',
        3: 'brightness(0) saturate(100%) invert(71%) sepia(39%) saturate(729%) hue-rotate(207deg) brightness(92%) contrast(85%);',
        4: 'brightness(0) saturate(100%) invert(100%) sepia(89%) saturate(5679%) hue-rotate(183deg) brightness(98%) contrast(86%);'
      };

      useEffect(() => {
        //*** Get all the location data (for the location chart) ***//
        if (pageData && pageData.page_fans_country) {
    
            if (countries) {
                // Iterate over the countries array
                countries.forEach((countryData, index) => {
                    // Get the country code and count from the countryData object
                    let { country } = countryData;

                    let formattedCountry = `#${country}`;

                    if (country === 'US'){
                        formattedCountry = '.United_States'
                    }
                    console.log(formattedCountry);
    
                    // Get the color based on the count range
                    const color = mapColors[index] || '#ffffff'; // Default to white for other countries
    
                    // Generate CSS selector for the country code
                    const selector = `.oktelo_worldmap ${formattedCountry}`;
    
                    // Generate CSS rule for the country code with dynamically assigned color based on count
                    const cssRule = `${selector} {
                        filter: ${color} !important; 
                    }`;
    
                    // Apply the CSS rule to the document dynamically
                    const style = document.createElement('style');
                    style.type = 'text/css';
                    style.setAttribute('data-country-styles', 'true'); // Set a custom attribute to identify the stylesheet
                    style.appendChild(document.createTextNode(cssRule));
                    document.head.appendChild(style);
                });
            }
        }
    }, [pageData]);
    
    useEffect(() => {
        // Remove the styles when the component unmounts or when pageData changes
        return () => {
            const stylesheets = document.querySelectorAll('style[data-country-styles]');
            stylesheets.forEach(stylesheet => {
                if (stylesheet.parentNode) {
                    stylesheet.parentNode.removeChild(stylesheet);
                }
            });
        };
    }, [pageData]);

    // Create AI_Charts components for the top 3 countries
    const top3Charts = countries.map(({ country, count, percentage }) => (
        <AI_Charts key={country} title={`${country}:`} percentage={percentage} count={count} color="#1e90ff" />
    ));

    // Check if countries array is empty to render "no data" typography
    const renderTop3Charts = countries.length > 0 ? top3Charts : <Typography className="t_misc t_oktelo">Sajnálom! Nincs elérhető adat. Lehet, hogy az oldalad nem ért el elegendő adatmennyiséget.</Typography>;


    //*** Get all the Age Gender data FACEBOOK ***//
    if (pageData && pageData.page_impressions_by_age_gender_unique) {
        ageGenderImpressions = Object.entries(pageData.page_impressions_by_age_gender_unique)
        .map(([ageGender, impressions]) => ({
            ageGender: formatAgeGender(ageGender),
            impressions: impressions as number,
            percentage: Math.round((impressions as number / maxImpressions) * 1000) / 10
        }))
        .sort((a, b) => b.impressions - a.impressions) // Sort by descending
        .slice(0, 5); // Select top 3 age-gender groups
    }

    //*** Get all the Activity data INSTAGRAM ***//
    else if (pageData && pageData.instagram_basic) {
        instaBasic = Object.entries(pageData.instagram_basic)
        .map(([name, count]) => ({
            metric: name as string,
            count: count as number,
            percentage: Math.round((count as number / maxInteractions) * 1000) / 10
        }))
        .sort((a, b) => b.count - a.count) // Sort by descending
        .slice(0, 3); // Select top 3 metrics
    }

    //On data change, check if Data can be rendered (Fans / Impressions)
    useEffect(() => {
        if (ageGenderImpressions.length > 0 || !(instaBasic.every(({ count }) => count === 0))) {
            setEnoughImps(true);
        } else {
            setEnoughImps(false);
        }

        if (countries.length > 0) {
            setEnoughFans(true);
        } else {
            setEnoughFans(false);
        }
    }, [ageGenderImpressions, instaBasic, countries, pageData]);
    
    //Handle modal close button (AI Analyize Modal)
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    // AI Analytics requests:
const analyzeWithAi = async (type: string) => {
    let sendData = {};

    if (type === 'summary') {
        sendData = {
            data: {
                type: type,
                fans: pageData?.page_fans,
                impressions: pageData?.page_impressions_unique,
                views: pageData?.page_views_total,
                engagement: pageData?.page_post_engagements
            },
        }
    }
    else if (type === 'demographics') {

        let countryData;
        let ageData;
        let onlineData;

        if(pageData)
            {
                if (pageData && pageData.page_fans_country) {
                    countryData = Object.entries(pageData.page_fans_country)
                        .map(([country, count]) => ({
                            country,
                            count: count as number,
                        })) 
                        .sort((a, b) => b.count - a.count) 
                        .slice(0, 2); 
                }
                else
                {
                    countryData = 'No data available.'
                }
                if (pageData && pageData.page_fans_online){
                    onlineData = Object.entries(pageData.page_fans_online)
                        .map(([time, count]) => ({
                            time,
                            count: count as number,
                        }))
                        .sort((a, b) => b.count - a.count)
                        .slice(0,2);
                }
                else{
                    onlineData = 'No data available.'
                }
                if (pageData && pageData.page_impressions_by_age_gender_unique) {
                    ageData = Object.entries(pageData.page_impressions_by_age_gender_unique)
                        .map(([ageGender, impressions]) => ({
                            ageGender: formatAgeGender(ageGender),
                            impressions: impressions as number,
                        })) 
                        .sort((a, b) => b.impressions - a.impressions) 
                        .slice(0, 2); 
                }
                else if (pageData && pageData.instagram_basic)
                    {
                    ageData = Object.entries(pageData.instagram_basic)
                    .map(([name, count]) => ({
                        metric: name as string,
                        count: count as number,
                    }))
                    .sort((a, b) => b.count - a.count) 
                    .slice(0, 3); 
                    
                    }

                else
                {
                    ageData = 'No data available.'
                }
            }
            else
            {
                return;
            }

            sendData = {
                data: {
                    countryData,
                    ageData,
                    onlineData,
                    type: type,
                }
            }
    }
    else if (type === 'post')
        {
            if (selectedPost) //Ha van selected post
                {
                    if (pageData) //Ha van page data
                    {
                        let countryData;
                        let ageData;

                        if (pageData.page_impressions_by_age_gender_unique) {
                            ageData = Object.entries(pageData.page_impressions_by_age_gender_unique)
                                .map(([ageGender, impressions]) => ({
                                    ageGender: formatAgeGender(ageGender),
                                    impressions: impressions as number,
                                })) 
                                .sort((a, b) => b.impressions - a.impressions) 
                                .slice(0, 1); // Select top 1
                        }
                        else { ageData = 'Unknown'}

                        if (pageData.page_fans_country) {
                            countryData = Object.entries(pageData.page_fans_country)
                                .map(([country, count]) => ({
                                    country,
                                    count: count as number,
                                })) 
                                .sort((a, b) => b.count - a.count)
                                .slice(0, 1); // Select top 1
                        }
                        else { countryData = 'Unknown'}

                        if(countryData && ageData){
                            sendData = {
                                data: {
                                    description: selectedPost.description,
                                    platform: selectedPost.propertyType,
                                    country: countryData,
                                    age: ageData,
                                    type: type,
                                }
                            }
                        }
                    } else 
                    {
                        sendData = {
                            data: {
                                description: selectedPost.description,
                                platform: selectedPost.propertyType,
                                type: type,
                            }
                        }
                    }
                }
                else{
                    return;
                }
        }
    else {
        return;
    }


    try {
        setLoading(true);
        const response = await axios.post('http://localhost:8080/api/v1/ai/analyze/', sendData);

        if(response && response.data){
            aiSetBrief(response.data.brief);
            aiSetTips(response.data.tips);
            setModalVisible(true);
        }

    } catch (error) {
        console.error('Error:', error);
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
      setLoading(true); 
  }, []);

  useEffect(() => {
    console.log(selectedPost); 
}, [selectedPost]);


return (
    

    <Grid container spacing={1}>
      
        <Grid item xs={12} md={12} lg={6} p={2}>
            <Box className="oktelo_card oktelo_card_hover" display="flex" flexDirection="column" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1} mb={3}>
                <Typography className="t_oktelo t_important t_highlight">
                ⚙️ Válassz egy oldalt!
                </Typography>
            </Stack>
            <Stack direction="column" gap={1} alignItems="flex-start" width="80%">
                <Typography className="t_oktelo t_important"  mb={3} textAlign="justify">
                    Oktelo Tokenek: <span className="t_purple">{getUserTokens}</span> <a className="t_misc t_oktelo t_link" href="/help">Hogyan működik?</a>
                </Typography>
                <Typography className="t_oktelo t_misc  "  mb={3} textAlign="justify">
                Kezdésként válaszd ki azt az oldalt, amelyet AI elemezni szeretnél.
                </Typography>
                <Select 
                    showSearch={false} 
                    maxCount={1}  
                    maxTagCount={1} 
                    allowClear 
                    defaultValue={selectedPage} 
                    style={{ width: '50%'}} 
                    className="ai_page_selector" 
                    dropdownStyle={{ background: 'rgba(255,255,255, 0.95)', color: '#28282B'}} 
                    onChange={(selectedPageId) => setSelectedPage(selectedPageId)}>
                    {userPages?.allNames.map((page: any, index: number) => (
                        <Option key={index} value={page.id}>
                            <Space>
                                <StarTwoTone twoToneColor="#925fff"/>
                                <Typography className="t_oktelo t_desc">{page.name}</Typography>
                            </Space>
                        </Option>
                    ))}
                </Select>
            </Stack>         
            </Box>
        </Grid>
        {loading && (
      <Box 
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
      >
        <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
      </Box>
    )}
        <Grid item xs={12} md={12} lg={6} p={2}> {/* Weekly summary  */}
            <Box className="oktelo_card oktelo_card_hover" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1} mb={3}>
                    <Typography className="t_oktelo t_important t_highlight">
                    📈 Heti összegzés
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={4} justifyContent="space-around" mb={2} width="100%" flexWrap="wrap">
                    <Stack direction="column" alignItems="flex-start">
                        <Typography className="t_secondary_title  ">{pageData?.page_fans.toLocaleString()}</Typography>
                        <Typography className="t_desc t_oktelo  ">
                            oldal kedvelők
                        </Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-start">
                        <Typography className="t_secondary_title  ">{pageData?.page_impressions_unique.toLocaleString()}</Typography>
                        <Typography className="t_desc t_oktelo  ">
                            impressziók
                        </Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-start">
                        <Typography className="t_secondary_title  ">{pageData?.page_views_total.toLocaleString()}</Typography>
                        <Typography className="t_desc t_oktelo  ">
                            profil megtekintések
                        </Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-start">
                        <Typography className="t_secondary_title  ">{pageData?.page_post_engagements.toLocaleString()}</Typography>
                        <Typography className="t_desc t_oktelo  ">
                            elköteleződött felhasználók
                        </Typography>
                    </Stack>             
                </Stack>
                <Box>
                <Button onClick={() => analyzeWithAi('summary')} sx={{  width: 'auto' }} className="oktelo_card_button" disabled={selectedPage===null}>OkteloAI elemzés<AutoAwesomeOutlinedIcon sx={{ marginLeft: '6px'}}/></Button>
                </Box>
            </Box>
        </Grid>
        <AnalyticsModal visible={modalVisible} brief={aiGetBrief} tips={aiGetTips} onClose={handleCloseModal}/>
        <Grid item xs={12} md={12} lg={4} p={2}> {/* Functions pl. Post generator */}
            <Box className="oktelo_card  oktelo_worldmap"  height="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" sx={{ overflow: 'hidden'}}>
            <Stack direction="row" alignItems="center" gap={1} mb={3}>
                    <Typography className="t_oktelo t_important t_highlight">
                    🌍 Követő térkép
                    </Typography>
                </Stack>
                <RenderWorldMap/>
                {isEnoughFans && (
                <Box className="oktelo_map_buttons">    
                        <Button><ArrowLeftIcon/></Button><Button><ZoomOutMapOutlined/></Button><Button><ArrowRightIcon/></Button>
                        </Box>
                    )}    
            </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={4} p={2}> {/* Oktelo AI overview */}
        <Box className="oktelo_card b_wp_ap oktelo_card_hover" height="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="row" alignItems="center" gap={1} mb={3}>
                <Typography className="t_oktelo t_important t_highlight">
                🚀 Speciális elemző
                </Typography>
            </Stack>
            <Stack direction="column" gap={1} alignItems="flex-start" width="80%">
                <Typography className="t_oktelo t_misc  "  mb={3}>
                    Hozzáférhetsz közösségimédia-profilod speciális oldalmutatóihoz, 20 fontos mérőszámmal 4 különböző időszakban.
                </Typography>
                
            </Stack>
            <Button sx={{ width: 'auto' }} className="oktelo_card_button">Tovább<ArrowRightAltRoundedIcon sx={{ marginLeft: '6px'}}/></Button>
        </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={4} p={2} height="auto"> {/* Post Analytics */}
        <Box className="oktelo_card oktelo_card_hover" height="100%" display="flex" flexDirection="column" justifyContent="space-between"  alignItems="flex-start">
            <Stack direction="row" alignItems="center" gap={1} mb={3}>
                <Typography className="t_oktelo t_important t_highlight">
                📝 Bejegyzés elemző
                </Typography>
            </Stack>
            <Stack direction="column" gap={1} alignItems="flex-start">
                <Typography className="t_oktelo t_misc  "  mb={3}>
                 Szerezz átfogó elemzést Oktelo-bejegyzéseidről.
                </Typography>
                <Select showSearch={true} maxCount={1} maxTagCount={1} allowClear placeholder="Select a post.." style={{ width: '100%'}} className="s_ai_select" onChange={handleSelectChange}>
                    {getUserPosts && getUserPosts.map((post: PropertyProps) => (
                        <Option key={post._id} value={post._id}>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <Typography>{post.propertyType === 'instagram' ? <Instagram sx={{fontSize: '14px', color: '#e66de5'}}/> : post.propertyType === 'facebook' ? <FacebookOutlined sx={{fontSize: '14px', color: '#007eff'}}/> : 'Error'}</Typography>
                        <Typography className="t_oktelo t_desc">{post.title}</Typography>
                    </Stack>
                        </Option>
                    ))}
                </Select>
            </Stack>
            <Button sx={{  width: 'auto' }} className="oktelo_card_button" onClick={() => analyzeWithAi('post')}>OkteloAI elemzés<AutoAwesomeOutlinedIcon sx={{ marginLeft: '6px'}}/></Button>
        </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} p={2}> {/* Audience: Country, City, Language  */}
            <Box className="oktelo_card oktelo_card_hover" display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                    <Typography className="t_oktelo t_important t_highlight">
                        🙋‍♂️ Helyek & {getPageType === "Facebook"? 'Demográfia' : 'Aktivitás'}
                    </Typography>
                    <Typography className="t_misc t_oktelo">Követők / Heti {getPageType === "Facebook"? 'impressziók' : 'interakciók'}</Typography>
                </Stack>
                {pageData && pageData.page_fans_online && pageData.page_fans_online.length > 1 && 
                <Typography className="t_oktelo t_misc" color="#5D3FD3 !important">
                    Követői többnyire online vannak ekkor: {" "}
                    {Object.entries(pageData.page_fans_online)
                        .sort((a, b) => b[1] - a[1]) // Sort by value in descending order
                        .slice(0, 2) // Get the top 2 times
                        .map(([hour]) => `${hour}:00`) // Extract hour and format
                        .join(" and ")}.
                </Typography>}

                <Grid container spacing={4}>
                    <Grid item lg={6} md={12} xs={12}>
                        <Box>
                            {renderTop3Charts}
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Box>
                            {getPageType === "Facebook"?
                                renderTheChart('ageGender', ageGenderImpressions)                  
                                :
                                renderTheChart('instaBasic', instaBasic)
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box>
                {isEnoughFans && isEnoughImps ?
                (
                    <Button
                    className="oktelo_card_button"
                    onClick={() => analyzeWithAi('demographics')}
                    sx={{ width: 'auto' }}>
                        OkteloAI elemzés<AutoAwesomeOutlinedIcon sx={{ marginLeft: '6px'}}/>
                    </Button>
                )
                :
                (
                    <Button
                    className="oktelo_card_button b_oktelo_disabled"
                    disabled
                    onClick={() => analyzeWithAi('demographics')}
                    sx={{ width: 'auto' }}>
                        Nem elérhető<ErrorOutlineRoundedIcon sx={{ marginLeft: '6px'}}/>
                    </Button>
                )}
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} p={2}> {/* Results */}
        <Box className="oktelo_card" height="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="row" alignItems="center" gap={1} mb={3}>
                <Typography className="t_oktelo t_important t_highlight">
                ⚡ Legutóbbi AI válaszod
                </Typography>
            </Stack>
            <Stack direction="column" gap={1} alignItems="flex-start" width="100%" >
                {aiGetBrief &&
                <>
                <Typography className="t_oktelo t_misc"  textAlign="justify">
                    <span className="t_purple t_desc">Összegző</span> {aiGetBrief}
                </Typography>
                <Typography className="t_oktelo t_misc"  textAlign="justify">
                <span className="t_purple t_desc">Tippek</span> {aiGetTips}
                </Typography>
                </>} 
                {!aiGetBrief && <Typography className="t_oktelo t_misc">Még nem kértél Oktelo AI elemzést.</Typography>}          
            </Stack>
        </Box>
        </Grid>

    </Grid>
)
};

export default AI_ChartGrid;