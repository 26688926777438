import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Stack, Typography, Grid, Button } from "@mui/material";
import dayjs from "dayjs";
import {  Progress, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@refinedev/core";
import { Input, Select } from "antd";
import { SearchRounded, Delete, Visibility, FileCopy, Circle, AddCircleOutlineOutlined, FormatListBulleted, Window } from "@mui/icons-material";
import "./preview.css";
import { okt_placeholer } from "assets";
import apiconfig from "config";

const { Option } = Select;

interface OkteloApproval {
    _id: string;
    title: string;
    description: string;
    status: string;
    access: {
        everyone: boolean;
        secret: string | null;
    };
    comments: OkteloComment[];
    posts: OkteloPost[];
    progress: number;
    createdAt: Date;
    updatedAt: Date;
}

interface OkteloComment {
    postId: string;
    from: string;
    message: string;
    date: string;
    type: string;
}

interface OkteloPost {
    _id: string;
    message: string;
    attachment: string | null;
    approval: {
        status: string;
        from: string | null;
        date: string | null;
    };
    parent: string | null;
    platform: string;
}



const PreviewList = () => {
    const [approvals, setApprovals] = useState<OkteloApproval[]>([]);
    const [sortOrder, setSortOrder] = useState("desc");
    const [isListView, setListView] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>('all');
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [sortedPreviews, setSortedPreviews] = useState<OkteloApproval[]>([]);
    const [filteredPreviews, setFilteredPreviews] = useState<OkteloApproval[]>([]);
    const navigate = useNavigate();
    const { open } = useNotification();
    document.title = "Jóváhagyás lista | Oktelo App"

 const copyUrl = (previewId: string) => {
    const url = `${window.location.origin}/previews/show/${previewId}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        open?.({
            type: 'success',
            message: 'URL sikeresen kimásolva',
        })
      }) 
  };

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code)
    .then(() => {
        open?.({
            type: 'success',
            message: 'Kód sikeresen kimásolva'
        })
    })
  }

  const getAllPreviews = async () => {
    try {
        const response = await axios.get(`${apiconfig.BACKEND_URI}/previews`);
        setApprovals(response.data.approvals);
    } catch (error) {
        console.log(error);
    }
};

const deletePreview = async (previd: string) => {
    try {
        const response = await axios.post(`${apiconfig.BACKEND_URI}/previews/delete/${previd}`);
        if (response.status === 200) {
            open?.({
                type: 'success',
                message: 'Sikeresen törölted a jóváhagyást.'
            });
        } else {
            open?.({
                type: 'error',
                message: 'Sikertelen művelet'
            });
        }
    } catch (error) {
        console.log(error);
    } finally {
        getAllPreviews();
    }
};

    const openInNewTab = (previewId: string) => {
        const url = `${window.location.origin}/previews/show/${previewId}`;
        window.open(url, '_blank');
      };

      const handleSortToggle = () => {
        setSortOrder(prevOrder => prevOrder === "asc" ? "desc" : "asc");
    };

    const handleStatusChange = (value: any) => {
        setSelectedStatus(value as string);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(event.target.value);
    };

    const filterAndSortData = () => {
        let filtered = [...approvals];

        if (selectedStatus !== 'all') {
            filtered = filtered.filter(preview => preview.status === selectedStatus);
        }

        if (searchKeyword.trim() !== '') {
            const keyword = searchKeyword.toLowerCase();
            filtered = filtered.filter(preview => preview.title.toLowerCase().includes(keyword));
        }

        const sorted = filtered.sort((a, b) => {
            const dateA = new Date(a.updatedAt).getTime();
            const dateB = new Date(b.updatedAt).getTime();
            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });

        setSortedPreviews(sorted);
    };

    useEffect(() => {
        getAllPreviews();
    }, []);

    useEffect(() => {
        filterAndSortData();
    }, [approvals, selectedStatus, searchKeyword, sortOrder]);


    return (
        <Box width="100%">
            <Grid container spacing={2}>
                <Grid item xl={12}>
                    <Box className="approval-filter-holder">
                        <Grid container spacing={1.5} alignItems="center">
                            <Grid item xl={4}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                <button className="home-action-button b9d9"
                                onClick={() => setListView(!isListView)}
                                style={{ height: '40px' }}>
                                    {isListView ?
                                        <FormatListBulleted/>
                                    :
                                        <Window/>
                                    }    
                                </button>
                                <Input
                                className="approval-filter-input"
                                placeholder="Keresés..."
                                prefix={<SearchRounded sx={{ color: "#d3d3d3" }} />}
                                value={searchKeyword}
                                onChange={handleSearchChange}
                                allowClear
                                />
                                </Stack>
                            </Grid>
                            <Grid item xl={3}>
                                <Select
                                variant="filled"
                                size="middle"
                                className="approval-filter-input"
                                onChange={handleStatusChange}
                                defaultValue="all"
                                >
                                    <Option value="all">Összes</Option>
                                    <Option value="pending">Függőben</Option>
                                    <Option value="allApproved">Elfogadva</Option>
                                </Select>
                            </Grid>
                            <Grid item xl={2}>
                                <button
                                className="approval-sortby-button"
                                onClick={handleSortToggle}
                                >
                                    {`Módosítva ${sortOrder === "asc" ? "↑" : "↓"}`}
                                </button>
                            </Grid>
                            <Grid item xl={3}>
                            <button
                                className="home-action-button b925"
                                style={{ width: '100%', height: '40px' }}
                                onClick={() => navigate('/approvals/create')}
                                >
                                    <AddCircleOutlineOutlined/>
                                    Új hozzáadása
                            </button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} my={3}>
            {sortedPreviews.map((approval) => (
                    <Grid item xl={isListView ? 12 : 6} key={approval._id}>
                        <Box className="approval-grid-box" width="100%">
                            <Stack direction="column" gap={1.5} width="100%" justifyContent="space-between" height="100%">
                                {/* Approval Title and Description */}
                                <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                                    <Stack direction="column" gap={0.5}>
                                        <Typography className="t_oktelo_medium size_18 darkblue">
                                            {approval.title}
                                        </Typography>
                                        <Typography className="t_oktelo_light size_14 miscgray">
                                            {approval.description}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" gap={1} alignItems="center">
                                        <button className="manage-button manage-purple" onClick={() => openInNewTab(approval._id)}>
                                            <Visibility />
                                        </button>
                                        <button className="manage-button manage-purple" onClick={() => copyUrl(approval._id)}>
                                            <FileCopy />
                                        </button>
                                        <button className="manage-button manage-red" onClick={() => deletePreview(approval._id)}>
                                            <Delete />
                                        </button>
                                    </Stack>
                                </Stack>

                                {/* Access Information */}
                                <Stack direction="column" gap={0.5}>
                                    <Typography className="t_oktelo_medium size_14 darkblue">
                                        Hozzáférés
                                    </Typography>
                                    <Typography className="t_oktelo_light size_14 miscgray">
                                        {approval.access.secret || 'No secret code'}
                                    </Typography>
                                </Stack>

                                {/* Status and Progress */}
                                <Stack direction="column" gap={0.5} width="100%">
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography className="t_oktelo_medium size_14 darkblue">
                                            Állapot
                                        </Typography>
                                        <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-end">
                                            <Typography className="t_oktelo_light size_14 miscgray">
                                                {approval.progress}/{approval.posts.length}
                                            </Typography>
                                            <Typography className="t_oktelo_medium size_14 darkblue">
                                                {Math.round((approval.progress / approval.posts.length) * 100)}%
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Progress
                                        percent={(approval.progress / approval.posts.length) * 100}
                                        showInfo={false}
                                        strokeColor={(approval.progress / approval.posts.length) * 100 === 100 ? '#89e85d' : '#925FFF'}
                                        size={['100%', 12]}
                                    />
                                </Stack>

                                {/* Posts List */}
                                <Stack direction="column" gap={1} width="100%" sx={{ maxHeight: '200px', overflowY: 'scroll', padding: '4px'}}>
                                    {approval.posts.map((post, index) => (
                                        <Box key={index} className="approval-item-box" onClick={() => navigate(`/posts/show/${post._id}`)}>
                                            <Stack direction="row" gap={0} alignItems="center" justifyContent="space-between" width="100%">
                                            <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-start">
                                                <Circle sx={{ color: post.approval.status === 'approved' ? '#89e85d' : post.approval.status === 'pending' ? '#5d84e8' : '#e85D7e' }} />
                                                {post.attachment && <img src={post.attachment || okt_placeholer} alt="attachment" />}
                                                <Typography className="t_oktelo_normal size_14 darkblue">
                                                    {post.message.slice(0,36)}...
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" gap={1} justifyContent="flex-end" alignItems="center">
                                                <Typography className="t_oktelo_light size_14 miscgray">
                                                    {dayjs(post.approval.date).format('YYYY.MM.DD HH:mm') || 'Nincs dátum'}
                                                </Typography>
                                                <Box className={`approval-tag ${post.approval.status === 'approved' ? 'success-tag' : post.approval.status === 'pending' ? 'comment-tag' : 'reject-tag'}`}>
                                                    {post.approval.status === 'approved' ? 'Jóváhagyva' : post.approval.status === 'pending' ? 'Függőben' : 'Elutasítva'}
                                                </Box>
                                            </Stack>
                                            </Stack>
                                    {/* Render Comments */}
                                    {approval.comments && approval.comments
                                        .filter(comment => comment.postId === post._id) // Filter comments by post ID
                                        .map((comm, commentIndex) => (
                                            <Box key={`${comm.date}-${comm.from}-${commentIndex}`} className="approval-item-comment"
                                            sx={{ backgroundColor: comm.type === 'comment' ? '#e7edff' : comm.type === 'reject' ? '#ffebf0' : '#eaffe0'}}>
                                                <Typography className="t_oktelo_normal size_12 darkblue">
                                                    <span className="t_oktelo_medium">{comm.from}</span> - {dayjs(comm.date).format('YYYY.MM.DD HH:mm')} - {comm.type === 'reject' ? 'Elutasítás' : comm.type === 'comment' ? 'Megjegyzés' : 'Jóváhagyás'}
                                                </Typography>
                                                <Typography className="t_oktelo_normal size_14 miscgray">
                                                    {comm.message}
                                                </Typography>
                                            </Box>
                                    ))}
                                    </Box>
                                    ))}
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )

}

export default PreviewList;