import React, { useState, useEffect, ReactElement, useMemo, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AnalyticsBarChart from "../../components/charts/SiteData/AnalyticsBarChart";
import AnalyticsTreemapChart from "../../components/charts/SiteData/AnalyticsTreemapChart";
import AnalyticsLineChart from "../../components/charts/SiteData/AnalyticsLineChart";
import _PieChart from "../../components/charts/SiteData/_PieChart";
import StackedBarChart from "../../components/charts/SiteData/StackedBarChart";

import _LineChart from "../../components/charts/SiteData/_LineChart";
import _AreaChart from "../../components/charts/SiteData/AnalyticsAreaChart";
import _HeatmapChart from "../../components/charts/SiteData/_HeatmapChart";
import "./sitedata.css";
import locale from 'antd/locale/hu_HU';
import { ConfigProvider, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import {
  Grid,
  Box,
  Stack,
  Typography,
  CircularProgress,
  LinearProgress,
  Avatar,
} from "@mui/material";
import { Input, Tag, Tooltip } from "antd";
import { AdsClick, Apartment, CellTower, Dataset, DatasetLinked, EmojiEmotions, Flag, Groups, House, InsightsOutlined, LocationCity, ManageAccounts, Mouse, PersonAdd, PlayCircle, Public, Search, SsidChart, Translate, Videocam, Visibility,  } from "@mui/icons-material";
import AnalyticsAreaChart from "../../components/charts/SiteData/AnalyticsAreaChart";
import apiconfig from "config";

const { RangePicker } = DatePicker;

type MetricData = { name: string; values: { date: string; value: number | Record<string, number> }[] };
type MetricDataValues = { date: string; value: number | Record<string, number> };
type MetricGroup = {
  name: string;
  icon: ReactElement;
  metrics: Record<string, MetricDataValues[]>;
};

interface SingleSumMetricContent {
  name: string;
  value: number;
}

// Define MetricValue for different metric types
interface LMetricValue {
  total?: number;
  paid?: number;
  unpaid?: number;
  mention?: number;
  page_post?: number;
  checkin?: number;
  coupon?: number;
  event?: number;
  question?: number;
  user_post?: number;
  other?: number;
  fan?: number;
  [key: string]: number | undefined; // Index signature
}

// Define MetricData
interface LMetricData {
  date: string;
  value: LMetricValue;
}

// Define ChartData for handling multiple metrics
interface LChartData {
  [metricName: string]: LMetricData[];
}

// Update MetricGroup to use ChartData
interface LMetricGroup {
  name: string;
  icon: ReactElement;
  metrics: LChartData;
}

// Type for individual metric values
interface SBMetricValue {
  [category: string]: number;
}

// Type for each metric entry
interface SBMetricData {
  value: SBMetricValue;
  date: string;
}

// Type for transformed chart data
interface SBMetricDataValues {
  [date: string]: SBMetricValue;
}

interface SBMetricGroup {
  name: string;
  icon: ReactElement;
  metrics: SBMetricDataValues;
}

interface SBChartData {
  [key: string]: { name: string; data: number[] }[];
}

interface SingleSumMetric {
  id: string;
  content: {
    name: string;
    extra?: string;
    tooltip: string;
    value: number;
  }[];
}

interface SingleSumMetricsComponentProps {
  index: number;
  singleSumMetrics: SingleSumMetric[];
}

interface MetricComponents {
  [key: string]: React.ComponentType<{ title: string; data: any }>;
}

interface LTMetricData {
  name: string;
  period: string;
  values: { value: Record<string, number>; end_time: string }[];
}

interface LTAggregatedData {
  [key: string]: number;
}

interface LTMetricGroup {
  name: string;
  icon: ReactElement;
  topvalue: number;
  metrics: Record<string, number>;
}

interface PageInfo {
  name: string;
  picture: string;
}

const SiteData: React.FC = () => {
  const { pageid } = useParams<{ pageid: string }>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [isLoading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState<any[]>([]);
  const [areaChartMetricsGroups, setAreaChartMetricsGroups] = useState<
    MetricGroup[]
  >([]);
  const [heatmapChartMetricsGroups, setHeatmapChartMetricsGroups] = useState<
    MetricGroup[]
  >([]);
  const [barChartMetricsGroups, setBarChartMetricsGroups] = useState<
    MetricGroup[]
  >([]);
  const [treemapChartMetricsGroups, setTreemapChartMetricsGroups] = useState<
    MetricGroup[]
  >([]);
  const [singleSumMetrics, setSingleSumMetrics] = useState<SingleSumMetric[]>(
    []
  );
  const [lineChartMetricsGroups, setLineChartMetricsGroups] = useState<
    LMetricGroup[]
  >([]);
  const [stackedBarChartMetricsGroups, setStackedBarChartMetricsGroups] =
    useState<SBMetricGroup[]>([]);
  const [longTypeMetrics, setLongTypeMetrics] = useState<LTMetricGroup[]>([]);
  const [videoMetrics, setVideoMetrics] = useState<MetricGroup[]>([]);


  const [since, setSince] = useState<string | null>(null);
  const [until, setUntil] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

  
  
  const [preset, setPreset] = useState<string | null>(null);
  const [aggregate, setAggregate] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');

  const metricsList = useMemo(() => ({
    country: metrics.find((metric) => metric.name === "page_impressions_by_country_unique"),
    locale: metrics.find((metric) => metric.name === "page_impressions_by_locale_unique"),
    city: metrics.find((metric) => metric.name === "page_impressions_by_city_unique")
  }), [metrics]);

  const aggregateValues = useCallback((data: LTMetricData | undefined): LTAggregatedData => {
    if (!data) return {};
    return data.values.reduce((acc, entry) => {
      Object.entries(entry.value).forEach(([key, value]) => {
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key] += value;
      });
      return acc;
    }, {} as LTAggregatedData);
  }, []);

  const getTop10 = useCallback((aggregatedData: LTAggregatedData): LTAggregatedData => {
    return Object.entries(aggregatedData)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as LTAggregatedData);
  }, []);

  const addLineChartMetricsToGroup = useCallback(() => {
    const metricGroups: LMetricGroup[] = [
      {
        name: "Napi követőszerzés típus szerint",
        icon: <Dataset />,
        metrics: {
          online: metrics
            .find((metric) => metric.name === "page_fan_adds_by_paid_non_paid_unique")
            ?.values.map((value: any) => ({
              date: value.date,
              value: {
                total: value.value?.total ?? 0,
                paid: value.value?.paid ?? 0,
                unpaid: value.value?.unpaid ?? 0,
              },
            })) || [],
        },
      },
      {
        name: "Napi elérés tartalom típusa szerint",
        icon: <DatasetLinked />,
        metrics: {
          online: metrics
            .find((metric) => metric.name === "page_impressions_by_story_type_unique")
            ?.values.map((value: any) => {
              const valueObj = value.value;
              const normalizedValue = (key: string) => {
                return valueObj[key] ?? 0;
              };
              return {
                date: value.date,
                value: {
                  mention: normalizedValue("mention"),
                  page_post: normalizedValue("page post"),
                  checkin: normalizedValue("checkin"),
                  coupon: normalizedValue("coupon"),
                  event: normalizedValue("event"),
                  question: normalizedValue("question"),
                  user_post: normalizedValue("user post"),
                  other: normalizedValue("other"),
                  fan: normalizedValue("fan"),
                },
              };
            }) || [],
        },
      },
    ];
    setLineChartMetricsGroups(metricGroups);
  }, [metrics]);

  const addStackedBarChartMetricsToGroup = useCallback(() => {
    const metric = metrics.find((metric) => metric.name === "page_impressions_by_age_gender_unique");
    const transformedData = metric ? transformData(metric.values) : {};

    const metricGroups: SBMetricGroup[] = [
      {
        name: "Elérés nem és kor alapján",
        icon: <ManageAccounts />,
        metrics: transformedData,
      },
    ];

    setStackedBarChartMetricsGroups(metricGroups);
  }, [metrics]);

  const calculateReachOrganic = useCallback((
    allValues: { date: string; value: number }[],
    paidValues: { date: string; value: number }[]
  ): { date: string; value: number }[] => {
    // Create a map for paid impressions by date
    const paidMap = new Map<string, number>(
      paidValues.map((entry) => [entry.date, entry.value])
    );

    // Calculate organic reach by subtracting paid impressions from total impressions
    return allValues.map((entry) => ({
      date: entry.date,
      value: entry.value - (paidMap.get(entry.date) || 0),
    }));
  }, []);

  const reachAllData = useMemo(() => 
    metrics.find((metric) => metric.name === "page_impressions_unique")?.values || []
  , [metrics]);

  const reachPaidData = useMemo(() => 
    metrics.find((metric) => metric.name === "page_impressions_paid_unique")?.values || []
  , [metrics]);

  const impressionsAllData = useMemo(() => 
    metrics.find((metric) => metric.name === "page_impressions")?.values || []
  , [metrics]);

  const impressionsPaidData = useMemo(() => 
    metrics.find((metric) => metric.name === "page_impressions_paid")?.values || []
  , [metrics]);

  const reachOrganicData = useMemo(() => 
    calculateReachOrganic(reachAllData, reachPaidData)
  , [calculateReachOrganic, reachAllData, reachPaidData]);

  const impressionsOrganicData = useMemo(() => 
    calculateReachOrganic(impressionsAllData, impressionsPaidData)
  , [calculateReachOrganic, impressionsAllData, impressionsPaidData]);

  const areaMetricGroups: MetricGroup[] = useMemo(() => [
    {
      name: "Impressziók alakulása",
      icon: <Visibility />,
      metrics: {
        impressions: metrics.find((metric) => metric.name === "page_impressions")?.values || [],
        impressionsOrganic: impressionsOrganicData || [],
        impressionsPaid: metrics.find((metric) => metric.name === "page_impressions_paid")?.values || [],
        impressionsViral: metrics.find((metric) => metric.name === "page_impressions_viral")?.values || [],
      } as Record<string, MetricDataValues[]>,
    } ,
    {
      name: "Engagement és Akciók",
      icon: <Mouse />,
      metrics: {
        postEngagements: metrics.find((metric) => metric.name === "page_post_engagements")?.values || [],
        totalActions: metrics.find((metric) => metric.name === "page_total_actions")?.values || [],
        consumptionsUnique: metrics.find((metric) => metric.name === "page_consumptions_unique")?.values || [],
        negativeFeedback: metrics.find((metric) => metric.name === "page_negative_feedback")?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Követések és Kikövetések",
      icon: <PersonAdd />,
      metrics: {
        dailyFollowsUnique: metrics.find((metric) => metric.name === "page_fan_adds")?.values || [],
        dailyUnfollowsUnique: metrics.find((metric) => metric.name === "page_fan_removes")?.values || [],
        dailyNewFollowers: metrics.find((metric) => metric.name === "page_fan_adds_unique")?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Összes követő és kedvelő",
      icon: <Groups />,
      metrics: {
        totalFollows: metrics.find((metric) => metric.name === "page_follows")?.values || [],
        totalLikes: metrics.find((metric) => metric.name === "page_fans")?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Videó Megtekintések",
      icon: <PlayCircle />,
      metrics: {
        videoViews: metrics.find((metric) => metric.name === "page_video_views")?.values || [],
        videoViewsPaid: metrics.find((metric) => metric.name === "page_video_views_paid")?.values || [],
        videoViewsOrganic: metrics.find((metric) => metric.name === "page_video_views_organic")?.values || [],
        videoViewsUnique: metrics.find((metric) => metric.name === "page_video_views_unique")?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Elérés és Elköteleződés",
      icon: <AdsClick />,
      metrics: {
        engagement: metrics.find((metric) => metric.name === "page_post_engagements")?.values || [],
        reach: metrics.find((metric) => metric.name === "page_impressions_unique")?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Fizetett és organikus elérés",
      icon: <SsidChart />,
      metrics: {
        reach_all: metrics.find((metric) => metric.name === "page_impressions_unique")?.values || [],
        reach_paid: metrics.find((metric) => metric.name === "page_impressions_paid_unique")?.values || [],
        reach_organic: reachOrganicData,
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Oldalmegtekintések",
      icon: <House />,
      metrics: {
        viewAll: metrics.find((metric) => metric.name === "page_views_total")?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
  ], [metrics, impressionsOrganicData, reachOrganicData]);

  useEffect(() => {
    setAreaChartMetricsGroups(areaMetricGroups);
  }, [areaMetricGroups]);

  const heatMetricGroups = useMemo(() => [
    {
      name: "Online követők idő szerinti eloszlása",
      icon: <Public />,
      metrics: {
        online: metrics.find(
          (metric: MetricData) => metric.name === "page_fans_online"
        )?.values || [],
      },
    },
  ], [metrics]);

  const addHeatmapChartMetricsToGroup = useCallback(() => {
    setHeatmapChartMetricsGroups(heatMetricGroups);
  }, [heatMetricGroups]);

  useEffect(() => {
    addHeatmapChartMetricsToGroup();
  }, [addHeatmapChartMetricsToGroup]);


  const videoMetricGroups = useMemo(() => [
    {
      name: "Videóstatisztikák (általános)",
      icon: <Videocam />,
      metrics: {
        autoplay: metrics.find(
          (metric: MetricData) => metric.name === "page_video_views_autoplayed"
        )?.values || [],
        click_to_play: metrics.find(
          (metric: MetricData) => metric.name === "page_video_views_click_to_play"
        )?.values || [],
        repeat_views: metrics.find(
          (metric: MetricData) => metric.name === "page_video_repeat_views"
        )?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Videóstatisztikák (30mp fölötti megtekintés)",
      icon: <Videocam />,
      metrics: {
        complete_views_30: metrics.find(
          (metric: MetricData) => metric.name === "page_video_complete_views_30s"
        )?.values || [],
        complete_views_30_paid: metrics.find(
          (metric: MetricData) => metric.name === "page_video_complete_views_30s_paid"
        )?.values || [],
        complete_views_30_organic: metrics.find(
          (metric: MetricData) => metric.name === "page_video_complete_views_30s_organic"
        )?.values || [],
        complete_views_30_unique: metrics.find(
          (metric: MetricData) => metric.name === "page_video_complete_views_30s_unique"
        )?.values || [],
      }
    }
  ], [metrics]);

  const addVideoChartMetricsToGroup = useCallback(() => {
    setVideoMetrics(videoMetricGroups);
  }, [videoMetricGroups]);

  useEffect(() => {
    addVideoChartMetricsToGroup();
  }, [addVideoChartMetricsToGroup]);

  const barMetricGroups = useMemo(() => [
    {
      name: "Napi reakciók eloszlása",
      icon: <EmojiEmotions />,
      metrics: {
        like_care: metrics.find(
          (metric: MetricData) => metric.name === "page_actions_post_reactions_like_total"
        )?.values || [],
        love: metrics.find(
          (metric: MetricData) => metric.name === "page_actions_post_reactions_love_total"
        )?.values || [],
        wow: metrics.find(
          (metric: MetricData) => metric.name === "page_actions_post_reactions_wow_total"
        )?.values || [],
        haha: metrics.find(
          (metric: MetricData) => metric.name === "page_actions_post_reactions_haha_total"
        )?.values || [],
        sorry: metrics.find(
          (metric: MetricData) => metric.name === "page_actions_post_reactions_sorry_total"
        )?.values || [],
        anger: metrics.find(
          (metric: MetricData) => metric.name === "page_actions_post_reactions_anger_total"
        )?.values || [],
      } as Record<string, MetricDataValues[]>,
    },
    {
      name: "Követőid száma, akik az adott napon online voltak",
      icon: <CellTower />,
      metrics: {
        impressions_type: metrics.find(
          (metric: MetricData) => metric.name === "page_fans_online_per_day"
        )?.values || [],
      },
    }
  ], [metrics]);

  const addBarChartMetricsToGroup = useCallback(() => {
    setBarChartMetricsGroups(barMetricGroups);
  }, [barMetricGroups]);

  useEffect(() => {
    addBarChartMetricsToGroup();
  }, [addBarChartMetricsToGroup]);

  const getMetricValue = useCallback((metricName: string, index: number): number => {
    const metric = metrics.find((metric: MetricData) => metric.name === metricName);
    if (!metric || metric.values.length === 0) {
      return 0;
    }
    const valueIndex = index >= 0 ? index : metric.values.length + index; // Handling negative index for end value
    return metric.values[valueIndex]?.value || 0;
  }, [metrics]);

  const singleMetricGroups = useMemo(() => [
    {
      id: "0_impressions",
      content: [
        {
          name: "Impressziók",
          tooltip:
            "Azon alkalmak száma, amikor az oldaladról vagy az oldaladról származó tartalom megjelent egy személy képernyőjén. Ide tartoznak a bejegyzések, történetek, hirdetések, valamint az oldaladon található egyéb tartalom vagy információ.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Fizetett",
          tooltip:
            "Azon alkalmak száma, amikor az oldaladról vagy az oldaladról származó fizetett tartalom (pl. hirdetések) megjelent egy személy képernyőjén.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions_paid")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Organikus",
          tooltip:
            "Nem fizetett alkalmak száma, amikor az oldaladról vagy az oldaladról származó tartalom megjelent egy személy képernyőjén. Ide tartoznak a bejegyzések, történetek, valamint az oldaladon található egyéb tartalom vagy információ.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) -
            metrics.find((metric: MetricData) => metric.name === "page_impressions_paid")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Viral",
          tooltip:
            "Azon alkalmak száma, amikor az oldaladról vagy az oldaladról származó VIRAL tartalom megjelent egy személy képernyőjén. Ide tartoznak a bejegyzések, amelyeket a felhasználók ismerősei lájkoltak, megjegyzéseket fűztek hozzá vagy megosztottak.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions_viral")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
      ],
    },
    {
      id: "1_engagement",
      content: [
        {
          name: "Poszt elköteleződés",
          tooltip:
            "Azoknak az interakcióknak a száma, amelyeket az emberek az oldaladról vagy az oldaladról származó posztokkal végeztek. Ide tartoznak a lájkok, megjegyzések, megosztások és egyéb elköteleződési formák.",
          value: metrics.find((metric: MetricData) => metric.name === "page_post_engagements")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "CTA akciók",
          tooltip:
            "Az oldalad kapcsolatfelvételi adataira és a cselekvésre ösztönző gombra leadott kattintások száma.",
          value: metrics.find((metric: MetricData) => metric.name === "page_total_actions")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Elköt. felhasználók",
          tooltip:
            "Az egyedi felhasználók száma, akik valamilyen módon interakcióba léptek az oldaladról származó tartalommal.",
          value: metrics.find((metric: MetricData) => metric.name === "page_consumptions_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Negatív akciók",
          tooltip:
            "Az oldaladról vagy az oldaladról származó tartalommal kapcsolatos negatív visszajelzések száma, például rejtett bejegyzések, leiratkozások vagy jelentések.",
          value: metrics.find((metric: MetricData) => metric.name === "page_negative_feedback")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
      ],
    },
    {
      id: "2_follows",
      content: [
        {
          name: "Kikövetések",
          tooltip:
            "Azon Fiókközpont-fiókok száma, amelyek kikövették az oldalt a kiválasztott időszakban. Ez a mutató becsült.",
          value: metrics.find((metric: MetricData) => metric.name === "page_daily_unfollows_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Bekövetések",
          tooltip:
            "Azon Fiókközpont-fiókok száma, amelyek bekövették az oldalát a kiválasztott időszakban. Ez a mutató becsült.",
          value: metrics.find((metric: MetricData) => metric.name === "page_daily_follows_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Új követők",
          tooltip:
            "Azon Fiókközpont-fiókok száma, amelyeknek első alkalommal tetszett az oldala. Ez a mutató becsült.",
          value: metrics.find((metric: MetricData) => metric.name === "page_fan_adds_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
      ],
    },
    {
      id: "3_followers",
      content: [
        {
          name: "Követők (kezdete)",
          tooltip:
            "(Időszak kezdete) Facebook-oldalad vagy profilod követőinek száma. Ezt úgy számítjuk ki, hogy a követések számából levonjuk a kikövetések számát a Facebook-oldalad vagy -profilod élettartama során.",
          value: getMetricValue("page_follows", 0), // Start value
        },
        {
          name: "Követők (vége)",
          extra: `${
            getMetricValue("page_follows", -1) - getMetricValue("page_follows", 0)
          }`,
          tooltip:
            "(Időszak vége) Facebook-oldalad vagy profilod követőinek száma. Ezt úgy számítjuk ki, hogy a követések számából levonjuk a kikövetések számát a Facebook-oldalad vagy -profilod élettartama során.",
          value: getMetricValue("page_follows", -1), // End value
        },
        {
          name: "Kedvelők (kezdete)",
          tooltip:
            "(Időszak kezdete) Azon emberek teljes száma, akik kedvelték az oldaladat.",
          value: getMetricValue("page_fans", 0), // Start value
        },
        {
          name: "Kedvelők (vége)",
          extra: `${
            getMetricValue("page_fans", -1) - getMetricValue("page_fans", 0)
          }`,
          tooltip:
            "(Időszak vége) Azon emberek teljes száma, akik kedvelték az oldaladat.",
          value: getMetricValue("page_fans", -1), // End value
        },
      ],
    },
    {
      id: "4_videos",
      content: [
        {
          name: "Nézési idő (perc)",
          tooltip:
            "Az oldalad videóinak teljes megtekintési ideje percekben.",
          value: (
            metrics.find((metric: MetricData) => metric.name === "page_video_view_time")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) / 60000
          ).toFixed(2) || "0",
        },
        {
          name: "Videó megtekintések",
          tooltip:
            "Azon alkalmak száma, ahányszor az oldalad videóit legalább 3 másodpercig lejátszották, vagy ha 3 másodpercnél rövidebbek, majdnem teljes hosszukig. A videó egyetlen lejátszása során kizárjuk a videó újrajátszásával töltött időt.",
          value: metrics.find((metric: MetricData) => metric.name === "page_video_views")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Fizetett",
          tooltip:
            "Azon alkalmak száma, ahányszor az oldalad reklámozott videóit legalább 3 másodpercig lejátszották, vagy ha 3 másodpercnél rövidebbek, majdnem teljes hosszukig. A videó minden egyes megjelenítésénél külön számoljuk a videómegtekintéseket, és kizárjuk a videó újrajátszására fordított időt.",
          value: metrics.find((metric: MetricData) => metric.name === "page_video_views_paid")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Organikus",
          tooltip:
            "Azon alkalmak száma, ahányszor az oldalad videóit legalább 3 másodpercig, vagy ha 3 másodpercnél rövidebbek, majdnem teljes hosszukig lejátszották, organikus elérés alapján. A videó egyetlen lejátszása során kizárjuk a videó újrajátszásával töltött időt.",
          value: metrics.find((metric: MetricData) => metric.name === "page_video_views_organic")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
      ],
    },
    {
      id: "5_reachengage",
      content: [
        {
          name: "Elköteleződések",
          tooltip:
            "Azon alkalmak száma, amikor az emberek reagáltak a bejegyzéseidre reakciók, megjegyzések, megosztások és egyebek révén.",
          value: metrics.find((metric: MetricData) => metric.name === "page_post_engagements")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Elérés",
          tooltip:
            "Azon emberek száma, akiknek tartalma volt az oldaláról vagy az oldaláról, beléptek a képernyőjükre. Ide tartoznak a bejegyzések, a bejelentkezések, a hirdetések, az oldaladdal interakcióba lépő személyek közösségi információi és még sok más. Ez a mutató becsült.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Elköteleződés aránya",
          tooltip:
            "Az elérésből és az elköteleződésekből kalkulált arány számmal és százalékkal.",
          value: (
            metrics.find((metric: MetricData) => metric.name === "page_post_engagements")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) /
            metrics.find((metric: MetricData) => metric.name === "page_impressions_unique")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0)
          ) || 0,
          extra: (
            (metrics.find((metric: MetricData) => metric.name === "page_post_engagements")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) /
            metrics.find((metric: MetricData) => metric.name === "page_impressions_unique")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0)) * 100
          ).toFixed(2) + "%" || "0",
        },
      ],
    },
    {
      id: "6_reachtypes",
      content: [
        {
          name: "Összes",
          tooltip:
            "Azon emberek száma, akiknek az oldalad tartalmai beléptek a képernyőjükre. Ide tartoznak a bejegyzések, a bejelentkezések, a hirdetések, az oldaladdal interakcióba lépő személyek közösségi információi és még sok más. Ez a mutató becsült.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Fizetett",
          tooltip:
            "Azon Fiókközpont-fiókok száma, amelyek legalább egyszer látták a hirdetéseit. Az elérés eltér a megjelenítésektől, amelyek a hirdetéseinek több megtekintését is magukban foglalhatják ugyanazon Fiókközpont-fiókok által. Ez a mutató becsült.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions_paid_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
        {
          name: "Organikus",
          tooltip:
            "Azon emberek száma, akiknek az oldalad tartalmai beléptek a képernyőjükre organikus módon. Ide tartoznak a bejegyzések, a bejelentkezések, a hirdetések, az oldaladdal interakcióba lépő személyek közösségi információi és még sok más.",
          value: metrics.find((metric: MetricData) => metric.name === "page_impressions_unique")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) -
            metrics.find((metric: MetricData) => metric.name === "page_impressions_paid_unique")
              ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
      ],
    },
    {
      id: "7_views",
      content: [
        {
          name: "Oldal látogatások",
          tooltip:
            "Ahányszor egy oldal profilját megtekintették a bejelentkezett és kijelentkezett személyek.",
          value: metrics.find((metric: MetricData) => metric.name === "page_views_total")
            ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        },
      ],
    },
  ], [metrics, getMetricValue]);

    const addSingleSumMetrics = useCallback(() => {
      setSingleSumMetrics(singleMetricGroups);
    }, [barMetricGroups]);
  
    useEffect(() => {
      addSingleSumMetrics();
    }, [addSingleSumMetrics]);

    const aggregatedCityData: { [key: string]: number } = useMemo(() => {
      const cityMetrics = metrics.find((metric: MetricData) => metric.name === "page_impressions_by_city_unique")?.values || [];
      const aggregatedData: { [key: string]: number } = {};
      
      cityMetrics.forEach((entry: any) => {
        if (typeof entry.value === "object") {
          Object.entries(entry.value).forEach(([city, value]) => {
            const numericValue = value as number;
            aggregatedData[city] = (aggregatedData[city] || 0) + numericValue;
          });
        }
      });
      
      return aggregatedData;
    }, [metrics]);
  
    const aggregatedCountryData: { [key: string]: number } = useMemo(() => {
      const countryMetrics = metrics.find((metric: MetricData) => metric.name === "page_impressions_by_country_unique")?.values || [];
      const aggregatedData: { [key: string]: number } = {};
      
      countryMetrics.forEach((entry: any) => {
        if (typeof entry.value === "object") {
          Object.entries(entry.value).forEach(([country, value]) => {
            const numericValue = value as number;
            aggregatedData[country] = (aggregatedData[country] || 0) + numericValue;
          });
        }
      });
      
      return aggregatedData;
    }, [metrics]);
  
    const topCities = useMemo(() => 
      Object.entries(aggregatedCityData)
        .map(([city, value]) => ({ date: city, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 20),
      [aggregatedCityData]
    );
  
    const topCountries = useMemo(() => 
      Object.entries(aggregatedCountryData)
        .map(([country, value]) => ({ date: country, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 20),
      [aggregatedCountryData]
    );
  
    const addTreemapChartMetricsToGroup = useCallback(() => {
      const metricGroups: MetricGroup[] = [
        {
          name: "Top 20 elérés város szerint",
          icon: <LocationCity />,
          metrics: { city: topCities },
        },
        {
          name: "Top 20 elérés ország szerint",
          icon: <Flag />,
          metrics: { country: topCountries },
        },
      ];
  
      setTreemapChartMetricsGroups(metricGroups);
    }, [topCities, topCountries]);
  
    // UseEffect to call addTreemapChartMetricsToGroup when metrics change
    useEffect(() => {
      if (metrics.length) {
        addTreemapChartMetricsToGroup();
      }
    }, [metrics, addTreemapChartMetricsToGroup]);


  useEffect(() => {


/*     const calculateSum = (metricName: string): number => {
      return metrics
        .find((metric) => metric.name === metricName)
        ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0;
    };
 */
    const aggregatedTop10Countries = getTop10(aggregateValues(metricsList.country));
    const aggregatedTop10Locales = getTop10(aggregateValues(metricsList.locale));
    const aggregatedTop10Cities = getTop10(aggregateValues(metricsList.city));

    const metricGroups = [
      {
        name: "Elérések ország szerint",
        icon: <Public />,
        topvalue: metrics
          .find((metric) => metric.name === "page_impressions_unique")
          ?.values.reduce((sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        metrics: aggregatedTop10Countries
      },
      {
        name: "Elérések nyelv szerint",
        icon: <Translate />,
        topvalue: metrics
          .find((metric) => metric.name === "page_impressions_unique")
          ?.values.reduce( (sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        metrics: aggregatedTop10Locales
      },
      {
        name: "Elérések város szerint",
        icon: <Apartment />,
        topvalue: metrics
          .find((metric) => metric.name === "page_impressions_unique")
          ?.values.reduce( (sum: number, entry: { value: number }) => sum + entry.value, 0) || 0,
        metrics: aggregatedTop10Cities
      }
    ];

    setLongTypeMetrics(metricGroups);

    if (metrics.length) {

      addHeatmapChartMetricsToGroup();
      addBarChartMetricsToGroup();
/*       addTreemapChartMetricsToGroup(); */
      addLineChartMetricsToGroup();
      addStackedBarChartMetricsToGroup();
/*       addLongTypeChartMetricsToGroup(); */
      addVideoChartMetricsToGroup();
      addSingleSumMetrics();
    }
  }, [metrics, metricsList]);

  const transformData = (metrics: SBMetricData[]): SBMetricDataValues => {
    const chartData: SBMetricDataValues = {};

    // Collect all unique age/gender groups
    const categories = new Set<string>();
    metrics.forEach((metric) => {
        Object.keys(metric.value).forEach((category) => categories.add(category));
    });

    // Convert Set to Array
    const categoriesArray = Array.from(categories);

    // Initialize chartData with empty objects for each date
    const dates = Array.from(new Set(metrics.map(metric => metric.date)));
    dates.forEach(date => {
        chartData[date] = categoriesArray.reduce((acc, category) => {
            acc[category] = 0; // Initialize each category to 0
            return acc;
        }, {} as SBMetricValue);
    });

    // Fill chartData with actual data
    metrics.forEach((metric) => {
        const date = metric.date;
        Object.keys(metric.value).forEach((category) => {
            chartData[date][category] = (chartData[date][category] || 0) + metric.value[category];
        });
    });

    return chartData;
};



  const fetchInsights = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiconfig.BACKEND_URI}/insights/analyze/${pageid}`,
        {
          since: since || dayjs().subtract(7, 'days'),
          until: until || dayjs(),
          period: aggregate || 'day',
          preset: preset || null
        }
      );
      if (response.status === 200 && response.data){
        setPageInfo(response.data.info);
        setMetrics(response.data.data);
      }
    } catch (error: any) {
      console.error("Error fetching insights:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageid) {
      fetchInsights();
    }
  }, [pageid, dateRange, preset, aggregate]);

  
  const getFlagClass = useCallback((key: string, index: number) => {
    if (index === 0) {
      // Country code format, e.g., "HU"
      return `fi fi-${key.toLowerCase()}`;
    } else if (index === 1) {
      // Locale format, e.g., "en_GB"
      // Convert locale to country code or language code, for example:
      const code = key.split("_")[1].toLowerCase(); // "en" from "en_GB"
      return `fi fi-${code}`;
    }
    return "";
  }, []);

  const onRangeChange = useCallback((dates: (Dayjs | null)[] | null, dateStrings: [string, string]) => {
    if (dates) {
      // Handle the case where dates is an array of [Dayjs | null, Dayjs | null]
      setSince(dates[0] ? dateStrings[0] : null); // Use dateStrings only if dates is not null
      setUntil(dates[1] ? dateStrings[1] : null); // Use dateStrings only if dates is not null
      setDateRange(dates as [Dayjs | null, Dayjs | null]);
    } else {
      // Handle the case where dates is null
      setSince(null);
      setUntil(null);
      setDateRange([null, null]);
    }
  }, []);

  const onPresetChange = useCallback((value: string | undefined) => {
    if (value) {
      setPreset(value);
    } else {
      setPreset(null);
    }
  }, []);

  const onAggregateChange = useCallback((value: string | undefined) => {
    if (value) {
      setAggregate(value);
    } else {
      setAggregate(null);
    }
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.toLowerCase());
  }, []);

  const filterMetrics = useCallback(<T extends { name: string }>(
    metrics: T[],
    searchQuery: string
  ): T[] => {
    return metrics.filter(group =>
      group.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, []);

  if (isLoading) {
    return (
      <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "9999",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
          <Typography className="t_oktelo_medium size_18 t_purple">
            Egy pillanat! Adatok betöltése...
          </Typography>
        </Stack>
      </Box>
    );
  }

  if (!metrics) { return <Box>Nicsenek adatok</Box>}

  return (
    <Box width="100%">
    <Typography className="t_oktelo_semibold size_24 darkblue">
      Oktelo Analytics
    </Typography>
    <Typography className="t_oktelo_normal size_14 darkblue" mb={3}>
      Részletes elemzés a kiválasztott közösségi média oldalad adatai alapján.
      Használd a szűrőket a dátum, sablon és az aggregálás változtatásához.
    </Typography>
    <Box className="analytics-filter-box" my={2}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3}>
          <Stack direction="column" alignItems="flex-start" justifyContent="center" p={1} gap={0.5}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Kártya keresése
            </Typography>
            <Input placeholder="Keresés"
            prefix={<Search sx={{ fontSize: '16px', color: '#d9d9d9'}}/>}
            style={{ width: '100%', backgroundColor: '#fff !important' }}
            value={search}
            onChange={handleSearchChange}
            />
          </Stack>
        </Grid>
        <Grid item xl={3} lg={3}>
        <Stack direction="column" alignItems="flex-start" justifyContent="center" p={1} gap={0.5}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Elemzés intervalluma
            </Typography>
            <ConfigProvider locale={locale}>
            <RangePicker
                style={{ width: '100%' }}
                onChange={onRangeChange}
                value={dateRange[0] && dateRange[1] ? [dateRange[0], dateRange[1]] : null} // Use null when no range is selected
                maxDate={dayjs().add(1, 'day')}
                minDate={dayjs().subtract(90, 'day')}
                showTime={false}
              />

            </ConfigProvider>
          </Stack>
        </Grid>
        <Grid item xl={3} lg={3}>
        <Stack direction="column" alignItems="flex-start" justifyContent="center" p={1} gap={0.5}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Elemzés sablonok
            </Typography>
            <Select
              placeholder="Sablonok"
              style={{ width: '100%' }}
              value={preset}
              allowClear
              onChange={onPresetChange}
              options={[
                { value: 'this_month', label: 'Hónap' },
                { value: 'last_month', label: 'Előző hónap' },
                { value: 'this_quarter', label: 'Negyedév' },
                { value: 'last_3d', label: 'Előző 3 nap' },
                { value: 'last_7d', label: 'Előző 7 nap' },
                { value: 'last_14d', label: 'Előző 14 nap' },
                { value: 'last_28d', label: 'Előző 28 nap' },
                { value: 'last_30d', label: 'Előző 30 nap' },
                { value: 'last_90d', label: 'Előző 90 nap' },
                { value: 'last_week_mon_sun', label: 'Előző hét' },
                { value: 'this_week_mon_today', label: 'Hétfőtől máig' },
                { value: 'last_quarter', label: 'Előző negyedév' },
              ]}
            />
          </Stack>
        </Grid>
        <Grid item xl={3} lg={3}>
        <Stack direction="column" alignItems="flex-start" justifyContent="center" p={1} gap={0.5}>
            <Typography className="t_oktelo_medium size_12 darkblue">
              Aggregálás
            </Typography>
          <Select
              placeholder="Aggregálás"
              style={{ width: '100%' }}
              value={aggregate}
              allowClear
              onChange={onAggregateChange}
              options={[
                { value: 'day', label: 'Nap' },
                { value: 'week', label: 'Hét' },
                { value: 'days_28', label: '28 nap' },
              ]}
            />
          </Stack>
        </Grid>
      </Grid>
      </Box>
      <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-start" mt={3} mb={1}>
        <Avatar sx={{ width: '40px', height: '40px', padding: '8px', backgroundColor: '#fff', border: '1px solid #1e90ff' }}
        src={pageInfo?.picture}/>
        <Typography className="t_oktelo_medium size_14 darkblue">
          {pageInfo?.name}<br></br><span className="t_oktelo_light">{dayjs().format('YYYY.MMMM.DD (ddd) HH:mm:ss')}</span>
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {filterMetrics(areaChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <SingleSumMetricsComponent
                  index={index}
                  singleSumMetrics={singleSumMetrics}
                />
                <AnalyticsAreaChart title={group.name} chartData={group.metrics} />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(videoMetrics, search).map((group, index) => (
          <Grid item xl={6} lg={6} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <_AreaChart title={group.name} chartData={group.metrics} />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(heatmapChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={12} xs={12} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <_HeatmapChart title={group.name} chartData={group.metrics} />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(barChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={6} lg={6} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <AnalyticsBarChart
                  title={group.name}
                  chartData={group.metrics}
                />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(treemapChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={6} lg={6} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <AnalyticsTreemapChart
                  title={group.name}
                  chartData={group.metrics} 
                />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(lineChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={6} lg={6} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <AnalyticsLineChart
                  title={group.name}
                  chartData={group.metrics}
                />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(stackedBarChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={12} xs={12} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <StackedBarChart title={group.name} chartData={group.metrics} />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(longTypeMetrics, search).map((group, index) => (
          <Grid item xl={4} lg={4} key={index + group.name}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                {Object.entries(group.metrics).map(([key, value]) => (
                  <Box key={key}>
                    <Typography className="t_oktelo_semibold size_14 darkblue">
                      {index === 0 || index === 1 ? (
                        <span className={getFlagClass(key, index)}> </span>
                      ) : null}{" "}
                      {key}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="info"
                      value={(value / group.topvalue) * 100}
                    />
                    <Typography className="t_oktelo_normal size_14 darkblue" mb={1} mt={0.3}>
                      Elérések: <span className="t_oktelo_semibold t_purple">
                        {value}
                        </span>
                      {" "}Arány: <span className="t_oktelo_medium">
                      {`${((value / group.topvalue) * 100).toFixed(2)}%`}
                      </span>
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const SingleSumMetricsComponent: React.FC<SingleSumMetricsComponentProps> = ({
  index,
  singleSumMetrics,
}) => {
  const metricMap: { [key: number]: string } = {
    0: "0_impressions",
    1: "1_engagement",
    2: "2_follows",
    3: "3_followers",
    4: "4_videos",
    5: "5_reachengage",
    6: "6_reachtypes",
    7: "7_views",
  };

  const metricId = metricMap[index];
  const metrics = singleSumMetrics.find((m) => m.id === metricId);

  if (!metrics) return null;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {metrics.content.map((display, idx) => (
        <Tooltip
          title={display.tooltip}
          key={`${idx}-${display.name}`}
          placement="bottom"
        >
          <Box
            className="chart-box hover-chart-info"
            sx={{ border: "1px solid #d9d9d9" }}
          >
            <Typography className="t_oktelo_normal size_12 darkblue">
              {display.name}
            </Typography>
            <Stack direction="row" gap={0.5} alignItems="center" flexWrap="wrap">
              <Typography className="t_oktelo_semibold size_20 darkblue">
                {display.value.toLocaleString()}
              </Typography>
{/*               {display.extra && (
                <Tag
                  className="tag_oktelo size_12 t_oktelo_medium"
                  color="orange-inverse"
                  bordered={false}
                >
                  <InsightsOutlined sx={{ fontSize: "14px" }} />
                  {display.extra}
                </Tag>
              )} */}
            </Stack>
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default SiteData;
