import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { AI_ChartGrid } from 'components';

const OkteloAI = () => {
  document.title = "Oktelo AI | Oktelo";

  return (

    <Box width="100%">
    <Box>
      <AI_ChartGrid/>
    </Box>
    </Box>
  );
};

export default OkteloAI;
