import React, { useEffect, useState } from "react";
import { HomeSummaryProps } from "interfaces/home";
import { Stack, Typography } from "@mui/material";
import { Tag } from "antd";
import { CheckCircleTwoTone, WarningTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import ShowTooltip from "./ToolTips";
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import { CheckRounded } from "@mui/icons-material";

interface Tip {
    id: number;
    title: string;
    value: number;
    description: string;
    importance: number;
    canDisplay: boolean;
    suffix: string;
}

interface Recommend {
    text: string;
    canDisplay: boolean;
}

const HomeSummary = ({maxScore, beforeValues, posts, actualValues}: HomeSummaryProps) => {

    const [displayedTips, setDisplayedTips] = useState<Tip[]>([]);
    const [displayedRecommend, setDisplayedRecommend] = useState<Recommend[]>([]);

  //  console.log(posts);

    //Importance: 1 = Success | 2 = "Warning" | 3 = "Error"
    const allTips = [
        { id: 0, title: "Page Engagement Rate", value: 0, description: "", importance: 1, canDisplay: false, suffix: "" }, //0
        { id: 1, title: "Engagement Per Post", value: 0, description: "", importance: 1, canDisplay: false, suffix: "" }, //1
        { id: 2, title: "Page Visit Conversion Rate", value: 0, description: "", importance: 1, canDisplay: false, suffix: "" }, //2
        { id: 3, title: "Reach rate", value: 0, description: "", importance: 1, canDisplay: false, suffix: "" }, //3
        { id: 4, title: "Page Follower Growth", value: 0, description: "", importance: 1, canDisplay: false, suffix: "%"}, //4
        { id: 5, title: "Page Impression Growth", value: 0, description: "", importance: 1, canDisplay: false, suffix: "%"}, //5
        { id: 6, title: "Page Engagement Growth", value: 0, description: "", importance: 1, canDisplay: false, suffix: "%"}, //6
        { id: 7, title: "Page View Growth", value: 0, description: "", importance: 1, canDisplay: false, suffix: "%"}, //7
        { id: 8, title: "Average Comments", value: 0, description: "", importance: 1, canDisplay: false, suffix: ""}, //8  
        { id: 9, title: "Average Reactions", value: 0, description: "", importance: 1, canDisplay: false, suffix: ""}, //9
        { id: 10, title: "Impression Per Post", value: 0, description: "", importance: 1, canDisplay: false, suffix: ""}, //10
    ];


    function getThreeRecommend(displayedTips: Tip[]) {
        if (displayedTips && displayedTips.length > 0) {
            const lowImportanceTips = displayedTips.filter(tip => tip.importance === 3);
            const recommendations = [];
    
            if (lowImportanceTips.some(tip => tip.id === 0) && lowImportanceTips.some(tip => tip.id === 1)) {
                // If both Page Engagement Rate and Engagement Per Post are of low importance
                recommendations.push(
                    <Stack direction ="row" justifyContent="flex-start" alignItems="center" gap={1} key="engagement" borderBottom="1px solid #f6f6f6" py={1}>
                    <WarningTwoTone twoToneColor="#f0ad4e" />
                    <Typography className="t_oktelo t_misc">Consider creating more emotional headlines, videos, and use visually appealing attachments.</Typography>
                    </Stack>
                );
            } 
            if (lowImportanceTips.some(tip => [4, 5, 6, 7].includes(tip.id))) {
                // If Page Follower Growth, Page Impression Growth, Page Engagement Growth, or Page View Growth are of low importance
                recommendations.push(
                    <Stack direction ="row" justifyContent="flex-start" alignItems="center" gap={1} key="growth" borderBottom="1px solid #f6f6f6" py={1}>
                    <WarningTwoTone twoToneColor="#f0ad4e" />
                    <Typography className="t_oktelo t_misc" key="growth">Focus on improving your overall content strategies to increase your weekly page growth.</Typography>
                    </Stack>
                );
            } 
            if ((lowImportanceTips.some(tip => tip.id === 10) || lowImportanceTips.some(tip => tip.id === 3)) || lowImportanceTips.some(tip => tip.id === 5) ) {
                // If impression growth and impressions per post
                recommendations.push(
                    <Stack direction ="row" justifyContent="flex-start" alignItems="center" gap={1} key="impressions" borderBottom="1px solid #f6f6f6" py={1}>
                    <WarningTwoTone twoToneColor="#f0ad4e" />
                    <Typography className="t_oktelo t_misc" key="impressions">Focus on shareable content and consider promoting your posts.</Typography>
                    </Stack>
                );
            }
            if ((lowImportanceTips.some(tip => tip.id === 7)) ) {
                // If page view only
                recommendations.push(
                    <Stack direction ="row" justifyContent="flex-start" alignItems="center" gap={1} key="impressions" borderBottom="1px solid #f6f6f6" py={1}>
                    <WarningTwoTone twoToneColor="#f0ad4e" />
                    <Typography className="t_oktelo t_misc" key="impressions">Focus on shareable content and consider promoting your posts.</Typography>
                    </Stack>
                );
            }
            if (lowImportanceTips.some(tip => tip.id === 8) || lowImportanceTips.some(tip => tip.id === 9)) {
                // If Average Comments or Average Reactions are of low importance
                recommendations.push(
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1} key="engagementMetrics" borderBottom="1px solid #f6f6f6" py={1}>
                        <WarningTwoTone twoToneColor="#f0ad4e" />
                        <Typography className="t_oktelo t_misc">Encourage user interaction through engaging questions, polls, or contests to increase comments and reactions.</Typography>
                    </Stack>
                );
            }
            if (lowImportanceTips.some(tip => tip.id === 4) && lowImportanceTips.some(tip => tip.id === 6)) {
                // If Page Follower Growth and Page Engagement Growth are of low importance
                recommendations.push(
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1} key="followerEngagement" borderBottom="1px solid #f6f6f6" py={1}>
                        <WarningTwoTone twoToneColor="#f0ad4e" />
                        <Typography className="t_oktelo t_misc">Create targeted content that resonates with your audience to drive both follower growth and engagement.</Typography>
                    </Stack>
                );
            }
    
            return recommendations.length > 0 ? recommendations.slice(0, 5) : null;
        } else {
            return null;
        }
    }

    function getShortenedTitle(title: string) {
        // Split the title into words
        const words = title.split(' ');
      
        // Map over each word and extract the first letter if it's capitalized
        const initials = words.map(word => word[0]).filter(letter => letter === letter.toUpperCase());
      
        // Join the extracted initials together
        return initials.join('');
      }

    // Calculation function for Engagement Rate
    const calculatePageEngagementRate = () => {
        if (actualValues && actualValues.engagement && actualValues.impression) {
            const result = (actualValues.engagement / actualValues.impression);
            allTips[0].value = Number(result.toFixed(2)); // Update value with 2 decimal places
            allTips[0].description = "Default";
            allTips[0].canDisplay = true; 
            if (result >= 0.4) { allTips[0].importance = 1; allTips[0].description = "A high Page Engagement Rate reflects strong audience engagement with your content, indicating that your posts are effectively capturing attention and driving meaningful interactions despite the number of impressions. Continue to produce high-quality, engaging content that sparks conversations, elicits reactions, and encourages sharing among your audience. Foster an active and inclusive community by responding to comments, acknowledging feedback, and facilitating discussions. Additionally, leverage analytics to track performance metrics and refine your content strategy for sustained engagement growth."; }
            else if (result < 0.4 && result >= 0.2) { allTips[0].importance = 2; allTips[0].description = "A normal Page Engagement Rate indicates that your content is receiving an average level of engagement relative to the number of impressions, suggesting room for improvement but not necessarily cause for alarm. To enhance engagement, analyze the performance of your posts to identify patterns and insights into what resonates best with your audience. Experiment with different content types, posting frequencies, and engagement strategies to optimize engagement levels. Additionally, consider leveraging user-generated content and collaborations to diversify your content mix and stimulate interaction."; }
            else if (result < 0.2) { allTips[0].importance = 3; allTips[0].description = "A low Page Engagement Rate suggests that your content is not effectively capturing the interest or attention of your audience, resulting in minimal engagement despite the number of impressions. To improve engagement, focus on creating more compelling and relevant content that resonates with your target audience. Experiment with different content formats, storytelling techniques, and calls-to-action to encourage interactions. Additionally, actively engage with your audience by responding to comments, initiating discussions, and fostering a sense of community.";}
        }
    };

    const calculateImpressionPerPost = () => {
        if (posts.length > 0 && actualValues) {
            const followers = actualValues.fan;
            let totalImpression = 0;
            posts.forEach(post => {
                totalImpression += post.insights.post_impressions || 0;
            });
            const result = totalImpression / posts.length;
            allTips[10].value = Number(result.toFixed(2));
            allTips[10].description = "Default";
            allTips[10].canDisplay = true;
            if (result >= (followers * 0.1)) { allTips[10].importance = 1; allTips[10].description = "A high Average Impressions Per Post reflects strong visibility and reach for the social media page's content among its follower base. With a significant number of impressions per post, the content enjoys widespread exposure and engagement, driving brand awareness, and fostering community interaction. This high level of visibility indicates effective content strategies, audience engagement, and brand resonance. Maintain and enhance the high average impressions per post by consistently delivering valuable, relevant, and engaging content to followers. Continue to optimize content strategies based on audience feedback, engagement metrics, and industry trends. Encourage active participation and sharing among followers to extend the reach and impact of content. Leverage data analytics and insights to identify opportunities for further growth and expansion. Monitor impression metrics closely to track performance and adjust strategies as needed to sustain and build upon the page's visibility and reach."; }
            else if (result < (followers * 0.1) && result >= (followers * 0.05)) { allTips[10].importance = 2; allTips[10].description = "A normal Average Impressions Per Post indicates moderate visibility and reach for the social media page's content among its follower base. While the content receives a reasonable number of impressions per post, there is room for improvement to increase visibility and engagement further. This level of visibility suggests some degree of interest and engagement among followers but may not fully leverage the page's potential reach. Enhance the average impressions per post by optimizing content strategies to maximize visibility and engagement among followers. Experiment with different content types, posting schedules, and engagement tactics to capture users' attention and increase post impressions. Encourage followers to share content with their networks, amplifying reach and exposure. Analyze audience demographics and interests to tailor content to their preferences and needs. Monitor impression metrics closely to identify trends and opportunities for improvement."; }
            else if (result < (followers * 0.05)) { allTips[10].importance = 3; allTips[10].description = "A low Average Impressions Per Post indicates limited visibility and reach for the social media page's content among its follower base. With fewer impressions per post, the content may struggle to reach a wider audience and attract meaningful engagement. This low level of visibility could hinder the page's ability to build brand awareness, drive traffic, and foster community engagement. Increase the average impressions per post by implementing strategies to expand the page's reach and visibility among followers. Focus on growing the page's follower base through targeted advertising, engaging content, and strategic partnerships. Optimize post timing and frequency to ensure maximum exposure and visibility. Experiment with different content formats, hashtags, and captions to attract attention and increase post impressions. Monitor post performance regularly and adjust strategies based on audience feedback and engagement metrics.";}
        }
    }

    const calculateEngagementPerPost = () => {
        if (posts.length > 0 && actualValues) {
            let totalEngagement = 0;
            const impressions = allTips[10].value || 0;
            posts.forEach(post => {
                totalEngagement += post.insights.post_engaged_users || 0;
            });
            const result = totalEngagement / posts.length;
            allTips[1].value = Number(result.toFixed(2));
            allTips[1].description = "Default";
            allTips[1].canDisplay = true;
            if (result >= (impressions * 0.4)) { allTips[1].importance = 1; allTips[1].description = "A high Engagement Per Post reflects strong audience engagement with your content, indicating that your posts are resonating well and driving meaningful interactions. Continue to create and promote engaging content, while also nurturing relationships with your audience by responding to comments, fostering discussions, and actively participating in community engagement. Consider leveraging user-generated content and collaborations to further boost engagement levels.";}
            else if (result < (impressions * 0.4) && result >= (impressions * 0.1)) { allTips[1].importance = 2; allTips[1].description = "A normal Engagement Per Post suggests that your content is receiving an average level of interaction from your audience, indicating room for improvement but not necessarily cause for concern. To enhance engagement, focus on analyzing the types of posts that generate the most interaction and replicate their elements in future content. Experiment with different content formats, posting times, and audience targeting to optimize engagement levels.";}
            else if (result < (impressions * 0.1)) { allTips[1].importance = 3; allTips[1].description = "A low Engagement Per Post indicates that your content is not resonating well with your audience, leading to minimal interaction per post. To improve engagement, consider diversifying your content strategy, including more visually appealing media, asking questions to prompt audience interaction, and engaging directly with your followers through comments and messages.";}  
        }
    };

    const calculateVisitConversion = () => {
        if (actualValues && actualValues.view && actualValues.impression){
            const result = (actualValues.view / actualValues.impression);
            allTips[2].value = Number(result.toFixed(2));
            allTips[2].description = "Default"
            allTips[2].canDisplay = true;
            if (result >= 0.5) { allTips[2].importance = 1; allTips[2].description = "A high Page Visit Conversion Rate reflects strong engagement and interaction with your social media page, indicating that a significant percentage of users are actively engaging with your content, following your page, or taking other desired actions. Capitalize on your success by maintaining a consistent posting schedule and delivering valuable, relevant content that resonates with your audience. Foster a sense of community and belonging by encouraging user-generated content, hosting contests or giveaways, and showcasing customer testimonials or success stories. Continuously monitor engagement metrics and adapt your strategy to capitalize on emerging trends, maximize reach, and sustain high levels of interaction and conversion over time.";}
            else if (result < 0.5 && result >= 0.2) { allTips[2].importance = 2; allTips[2].description = "A normal Page Visit Conversion Rate suggests that a moderate percentage of users are engaging with your content or taking desired actions, indicating room for improvement but not necessarily cause for concern. To enhance conversion, continue to refine your content strategy by regularly posting high-quality content that resonates with your audience's interests and preferences. Encourage interaction and participation by asking questions, running polls, and soliciting feedback from your followers. Utilize social media analytics to gain insights into audience behavior and preferences, and adjust your approach accordingly to optimize engagement and conversion rates."; }
            else if (result < 0.2) { allTips[2].importance = 3; allTips[2].description = "A low Page Visit Conversion Rate indicates that a small percentage of users are taking further actions, such as following the page, engaging with posts, or exploring additional content, resulting in minimal interaction despite the number of impressions. To improve conversion, focus on enhancing the attractiveness and relevance of your page content, including compelling visuals, engaging captions, and clear calls-to-action. Actively engage with your audience by responding to comments, messages, and inquiries promptly. Experiment with different content formats and posting schedules to identify what resonates best with your audience and encourages more interaction."}
        }
    }

    const calculateReachRate = () => {
        if (actualValues && actualValues.impression && posts.length > 0) {
            const impressions = actualValues.impression;
            let totalReach = 0;
            let totalImp = 0;
            posts.forEach(post => {
                totalReach += post.insights.reach || 0; // Accumulate total reach
            });
            posts.forEach(post => {
                totalImp += post.insights.post_impressions || 0; // Accumulate total reach
            });
            const averageReachRate = totalReach / posts.length; // Calculate average reach rate
            const result = (averageReachRate / impressions); 
            allTips[3].value = Number(result.toFixed(2));
            allTips[3].description = "Default";
            allTips[3].canDisplay = true;
            if (totalReach >= (totalImp * 0.7)) { allTips[3].importance = 1; allTips[3].description = "A high Post Reach Rate reflects strong visibility and reach for the content posted on the social media page, indicating that a significant portion of the audience is being exposed to the content and potentially engaging with it. Capitalize on your success by continuing to deliver high-quality, relevant content that resonates with your audience's interests and preferences. Leverage social media advertising tools to amplify the reach of your posts and target specific audience segments effectively. Monitor post reach metrics closely and identify top-performing content to inform future content creation efforts and maintain high levels of engagement and reach over time."; }
            else if (totalReach < (totalImp * 0.7) && totalReach >= (totalImp * 0.5)) { allTips[3].importance = 2; allTips[3].description = "A normal Post Reach Rate suggests that the content posted on the social media page is reaching a moderate percentage of the audience, indicating room for improvement but not necessarily cause for concern. To enhance post reach, continue to diversify your content and explore different content formats, such as videos, images, infographics, and text-based posts, to appeal to a broader audience. Encourage engagement and sharing by creating interactive content, asking questions, and encouraging user-generated content. Monitor post performance metrics regularly and adjust your content strategy based on insights to optimize reach and engagement."; }
            else if (totalReach < (totalImp * 0.5)) { allTips[3].importance = 3; allTips[3].description = "A low Post Reach Rate indicates that the content posted on the social media page is not reaching a significant portion of the audience, resulting in limited visibility and potential engagement opportunities. To improve post reach, focus on optimizing your content strategy by creating engaging, shareable content that resonates with your target audience. Experiment with different posting times and frequencies to determine the optimal times when your audience is most active. Utilize hashtags, tagging, and cross-promotion with relevant influencers or partner pages to increase the visibility of your posts and expand your reach."; }  
        }
    };



    const calculateTrends = () => {
        if (actualValues && beforeValues) {
            if (actualValues.fan && beforeValues.fan) {
                const result = (((actualValues.fan - beforeValues.fan) /  beforeValues.fan) * 100);
                allTips[4].value = Number(result.toFixed(2));
                allTips[4].description = "Default";
                allTips[4].canDisplay = true;
                if (result >= 3) { allTips[4].importance = 1; allTips[4].description = "A high Page Fan Growth % reflects robust growth in the number of followers of the social media page over a given week, indicating strong audience engagement, effective content strategies, and successful audience acquisition efforts. Capitalize on the momentum of high fan growth by continuing to deliver engaging, relevant content that resonates with both existing and potential followers. Leverage social media advertising tools to target specific audience segments and expand the reach of your page. Encourage active participation and advocacy among followers by fostering a sense of community, responding promptly to comments and messages, and rewarding loyal followers with exclusive perks or rewards."; }
                else if (result < 3 && result >= 1) { allTips[4].importance = 2; allTips[4].description = "A normal Page Fan Growth % suggests steady, moderate growth in the number of followers of the social media page over a given week, indicating consistent audience acquisition and engagement efforts. Maintain a proactive approach to audience engagement by regularly posting high-quality content that resonates with your target audience and encourages interaction and sharing. Utilize audience insights to identify key demographics and interests and tailor content accordingly to attract new followers. Monitor follower acquisition metrics and adjust strategies as needed to sustain healthy growth rates over time."; }
                else if (result < 1) { allTips[4].importance = 3; allTips[4].description = "A low Page Fan Growth % indicates minimal growth in the number of followers of the social media page over a given week. This slow growth rate may suggest a lack of engagement with the page's content or ineffective audience acquisition strategies. To improve page fan growth, focus on increasing visibility and engagement through targeted advertising campaigns, influencer partnerships, and cross-promotion with related pages or brands. Encourage existing followers to invite their networks to like the page and share content with their social circles. Provide incentives such as exclusive offers or contests to incentivize new followers to join the community."; }
            }
            if (actualValues.impression && beforeValues.impression){
                const result = (((actualValues.impression - beforeValues.impression) /  beforeValues.impression) * 100);
                allTips[5].value = Number(result.toFixed(2));
                allTips[5].description = "Default";
                allTips[5].canDisplay = true;  
                if (result >= 5) { allTips[5].importance = 1; allTips[5].description = "A high Page Impression Growth % reflects significant growth in the number of impressions generated by the social media page's content compared to the previous week, indicating strong audience engagement, content virality, or successful promotion efforts. Capitalize on the momentum of high impression growth by doubling down on content strategies that have proven successful in driving engagement and visibility. Utilize audience insights to identify trending topics, interests, and preferences and tailor content accordingly to maintain relevance and resonance with your audience. Implement targeted advertising campaigns to amplify the reach of top-performing content and attract new followers. Continuously monitor performance metrics and iterate on strategies to sustain growth over time."; }
                else if (result < 5 && result >= 1) { allTips[5].importance = 2; allTips[5].description = "A normal Page Impression Growth % suggests stable or moderate growth in the number of impressions generated by the social media page's content compared to the previous week, indicating consistent audience engagement and content performance. Maintain a consistent posting schedule and continue to deliver high-quality, relevant content that resonates with your target audience. Monitor engagement metrics and adjust content strategies based on audience feedback and trends. Explore opportunities to collaborate with influencers or partners to expand the reach of your content and attract new followers. Utilize analytics tools to track performance and identify areas for improvement."; }
                else if (result < 1) { allTips[5].importance = 3; allTips[5].description = "A low Page Impression Growth % indicates minimal growth in the number of impressions generated by the social media page's content compared to the previous week. This stagnant or declining trend may suggest a lack of visibility, engagement, or relevance of the page's content to its target audience. To improve page impression growth, focus on diversifying content formats and topics to maintain audience interest and engagement. Experiment with different posting times and frequency to optimize visibility and reach. Utilize paid advertising campaigns to boost post visibility and attract new audiences. Analyze audience engagement metrics and adjust content strategies accordingly to align with audience preferences and trends."; }        
            }
            if (actualValues.engagement && beforeValues.engagement){
                const result = (((actualValues.engagement - beforeValues.engagement) /  beforeValues.engagement) * 100);
                allTips[6].value = Number(result.toFixed(2));
                allTips[6].description = "Default";
                allTips[6].canDisplay = true;        
                if (result >= 8) { allTips[6].importance = 1; allTips[6].description = "A high Page Engagement Growth % reflects significant growth in the overall engagement levels generated by the social media page's content compared to the previous week, indicating strong audience interaction, content virality, or successful engagement initiatives. Capitalize on the momentum of high engagement growth by continuing to deliver high-quality, relevant content that resonates with your audience. Encourage active participation and conversation by asking questions, soliciting feedback, and encouraging user-generated content. Leverage trending topics, hashtags, and current events to spark conversations and drive engagement. Invest in community-building efforts such as hosting live events, webinars, or virtual meetups to foster deeper connections with your audience. Monitor engagement metrics closely and adapt strategies to maintain and enhance engagement levels over time."; }
                else if (result < 8 && result >= 3) { allTips[6].importance = 2; allTips[6].description = "A normal Page Engagement Growth % suggests stable or moderate growth in the overall engagement levels generated by the social media page's content compared to the previous week, indicating consistent audience interaction and content performance.Maintain a proactive approach to engaging with your audience and fostering meaningful interactions. Continue to diversify content types and topics to cater to different audience interests and preferences. Encourage user-generated content and participation through contests, challenges, and user polls. Monitor engagement trends and adapt content strategies based on audience feedback and behavior. Collaborate with influencers or brand ambassadors to amplify engagement and reach new audiences. Utilize analytics tools to track engagement metrics and identify opportunities for optimization. "; }
                else if (result < 3) { allTips[6].importance = 3; allTips[6].description = "A low Page Engagement Growth % indicates minimal growth in the overall engagement levels generated by the social media page's content compared to the previous week. This stagnant or declining trend may suggest a lack of audience interaction, relevance, or effectiveness of the page's content in driving meaningful interactions. To improve page engagement growth, focus on creating more engaging and interactive content that encourages audience participation and conversation. Experiment with different content formats such as polls, quizzes, and interactive stories to stimulate user engagement. Actively respond to comments, messages, and mentions to foster a sense of community and encourage further interaction. Collaborate with influencers or partners to co-create content and leverage their audiences to increase engagement. Analyze engagement metrics regularly to identify areas for improvement and adjust content strategies accordingly."; }  
            }
            if (actualValues.view && beforeValues.view){
                const result = (((actualValues.view - beforeValues.view) /  beforeValues.view) * 100);
                allTips[7].value = Number(result.toFixed(2));
                allTips[7].description = "Default";
                allTips[7].canDisplay = true;        
                if (result >= 10) { allTips[7].importance = 1; allTips[7].description = "A high Page View Growth % reflects significant growth in the number of times the social media page's profile has been viewed by users, both logged in and logged out, compared to the previous week, indicating strong visibility, interest, and engagement with the page's content. Capitalize on the momentum of high page view growth by continuing to optimize content and engagement strategies to maximize visibility and attract new audiences. Utilize compelling visuals, compelling captions, and storytelling techniques to capture users' attention and encourage exploration of the page's content. Implement SEO best practices to improve the page's discoverability and ranking in search results. Foster community engagement by responding to comments, messages, and mentions promptly and authentically. Monitor page view metrics closely and adapt strategies to sustain and build upon the growth trajectory over time."; }
                else if (result < 10 && result >= 5) { allTips[7].importance = 2; allTips[7].description = "A normal Page View Growth % suggests stable or moderate growth in the number of times the social media page's profile has been viewed by users, both logged in and logged out, compared to the previous week, indicating consistent visibility and interest in the page's content. Maintain a consistent posting schedule and focus on creating compelling, relevant content that resonates with the target audience. Use analytics tools to identify peak times for user activity and optimize posting schedules accordingly. Experiment with different content formats, such as videos, carousels, and infographics, to keep content fresh and engaging. Encourage user interaction and sharing by asking questions, running polls, and hosting interactive Q&A sessions. Monitor page view metrics regularly to track growth trends and adjust strategies to maintain momentum."; }
                else if (result < 5) { allTips[7].importance = 3; allTips[7].description = "A low Page View Growth % indicates minimal growth in the number of times the social media page's profile has been viewed by users, both logged in and logged out, compared to the previous week. This stagnant or declining trend may suggest a lack of visibility, relevance, or attractiveness of the page's content to potential viewers. To improve page view growth, focus on increasing the visibility and attractiveness of the page's profile and content. Optimize the page's bio, profile picture, and cover photo to accurately reflect the brand identity and capture users' attention. Regularly update content with fresh, engaging posts, stories, and highlights to encourage repeat visits and attract new followers. Utilize hashtags, geotags, and mentions strategically to expand the reach and discoverability of the page's content. Collaborate with influencers or partners to co-promote the page and leverage their audiences to increase visibility. Monitor page view metrics and adjust content strategies based on audience behavior and preferences."; }  
            }
        }
    }

    const calculateAvgComments = () => {
        if(posts && posts.length > 0){
            let totalComments = 0;   
                if (posts[0].platform === "Instagram" && actualValues){
                    posts.forEach(post => {
                        totalComments += post.comment_count || 0;
                    })
                    const result = totalComments / posts.length;
                    const pageEngagement = actualValues.engagement;
                    allTips[8].value = Number(result.toFixed(2));
                    allTips[8].description = "Default";
                    allTips[8].canDisplay = true;
                    if (result >= (pageEngagement * 0.25)) { allTips[8].importance = 1; allTips[8].description = "A high Average Comments Count reflects robust engagement and interaction with the social media page's content, with a significant number of comments left by users on posts. This high level of engagement indicates strong community involvement, active participation, and a deep connection with the content and brand. Capitalize on the high level of engagement by continuing to foster meaningful conversations and interactions with followers. Encourage user-generated content, such as testimonials, reviews, and user stories, to showcase the community's involvement and enthusiasm. Host live Q&A sessions, discussions, or virtual events to facilitate real-time engagement and interaction. Recognize and reward active participants with shout-outs, featured posts, or exclusive perks to further incentivize engagement. Monitor comment metrics closely to track engagement trends and adapt strategies to sustain and build upon the high level of community involvement."; }
                    else if (result < (pageEngagement * 0.25) && result >= (pageEngagement * 0.10)) { allTips[8].importance = 2; allTips[8].description = "A normal Average Comments Count suggests moderate engagement and interaction with the social media page's content, with a consistent number of comments left by users on posts. This level of engagement indicates some degree of interaction and interest among followers, contributing to a sense of community and connection. Maintain regular engagement with followers by responding to comments, questions, and feedback in a timely manner. Continue to create content that sparks conversation and encourages user participation. Experiment with different types of content, such as polls, quizzes, and user-generated content challenges, to stimulate engagement and foster a sense of ownership among followers. Monitor comment metrics regularly to identify trends and opportunities for improvement.";}
                    else if (result < (pageEngagement * 0.10)) { allTips[8].importance = 3; allTips[8].description = "A low Average Comments Count indicates minimal engagement and interaction with the social media page's content, with few comments left by users on posts. This lack of engagement may suggest a disconnect between the page and its audience, potentially leading to decreased visibility and relevance. To improve the average comments count, focus on creating content that encourages meaningful interaction and conversation among followers. Ask thought-provoking questions, solicit feedback, and invite users to share their opinions and experiences in the comments section. Respond promptly and thoughtfully to comments to foster a sense of community and encourage further engagement. Actively monitor post performance and adjust content strategies based on audience feedback and engagement patterns.";}   
                }
                else if (posts[0].platform === "Facebook" && actualValues){
                    posts.forEach(post => {
                        totalComments += post.comments_count || 0;
                    })
                    const result = totalComments / posts.length;
                    const pageEngagement = actualValues.engagement;
                    allTips[8].value = Number(result.toFixed(2));
                    allTips[8].description = "Default";
                    allTips[8].canDisplay = true;  
                    if (result >= (pageEngagement * 0.25) && result !== 0) { allTips[8].importance = 1; allTips[8].description = "A high Average Comments Count reflects robust engagement and interaction with the social media page's content, with a significant number of comments left by users on posts. This high level of engagement indicates strong community involvement, active participation, and a deep connection with the content and brand. Capitalize on the high level of engagement by continuing to foster meaningful conversations and interactions with followers. Encourage user-generated content, such as testimonials, reviews, and user stories, to showcase the community's involvement and enthusiasm. Host live Q&A sessions, discussions, or virtual events to facilitate real-time engagement and interaction. Recognize and reward active participants with shout-outs, featured posts, or exclusive perks to further incentivize engagement. Monitor comment metrics closely to track engagement trends and adapt strategies to sustain and build upon the high level of community involvement."; }
                    else if (result < (pageEngagement * 0.25) && result >= (pageEngagement * 0.10 ) && result !== 0) { allTips[8].importance = 2; allTips[8].description = "A normal Average Comments Count suggests moderate engagement and interaction with the social media page's content, with a consistent number of comments left by users on posts. This level of engagement indicates some degree of interaction and interest among followers, contributing to a sense of community and connection. Maintain regular engagement with followers by responding to comments, questions, and feedback in a timely manner. Continue to create content that sparks conversation and encourages user participation. Experiment with different types of content, such as polls, quizzes, and user-generated content challenges, to stimulate engagement and foster a sense of ownership among followers. Monitor comment metrics regularly to identify trends and opportunities for improvement.";}
                    else if (result < (pageEngagement * 0.10) || result === 0) { allTips[8].importance = 3; allTips[8].description = "A low Average Comments Count indicates minimal engagement and interaction with the social media page's content, with few comments left by users on posts. This lack of engagement may suggest a disconnect between the page and its audience, potentially leading to decreased visibility and relevance. To improve the average comments count, focus on creating content that encourages meaningful interaction and conversation among followers. Ask thought-provoking questions, solicit feedback, and invite users to share their opinions and experiences in the comments section. Respond promptly and thoughtfully to comments to foster a sense of community and encourage further engagement. Actively monitor post performance and adjust content strategies based on audience feedback and engagement patterns.";}  
                }
        }
    }

    const calculateAvgReactions = () => {
        if(posts && posts.length > 0 && actualValues){
            let totalReactions = 0;
            const pageEngagement = actualValues.engagement;
            if(posts[0].platform === "Instagram"){
                posts.forEach(post => {
                    totalReactions += post.like_count || 0;
                })
                const result = totalReactions / posts.length;
                allTips[9].value = Number(result.toFixed(2));
                allTips[9].description = "Default";
                allTips[9].canDisplay = true;   
                if (result >= (pageEngagement * 0.5) && result !== 0) { allTips[9].importance = 1; allTips[9].description = "A high Average Post Reactions Count reflects robust engagement and interaction with the social media page's content, with a significant number of reactions (such as likes, hearts, etc.) left by users on posts. This high level of engagement indicates strong community involvement, active participation, and a deep connection with the content and brand. Capitalize on the high level of engagement by continuing to create content that resonates with followers and prompts them to react positively. Celebrate milestones, achievements, and user-generated content to foster a sense of belonging and appreciation among followers. Encourage users to share their reactions and experiences with others, further amplifying engagement and reach. Monitor reaction metrics closely to track engagement trends and adapt strategies to sustain and build upon the high level of community involvement."; }
                else if (result < (pageEngagement * 0.5) && result >= (pageEngagement * 0.3) && result !== 0) { allTips[9].importance = 2; allTips[9].description = "A normal Average Post Reactions Count suggests moderate engagement and interaction with the social media page's content, with a consistent number of reactions left by users on posts. This level of engagement indicates some degree of interest and interaction among followers, contributing to a sense of community and connection. Maintain a steady level of engagement with followers by regularly posting content that resonates with their interests and preferences. Encourage users to react to posts by creating content that elicits emotional responses, sparks curiosity, or provides value and entertainment. Experiment with different types of content formats, such as polls, quizzes, and interactive stories, to stimulate engagement and encourage reactions. Monitor reaction metrics closely to identify trends and opportunities for improvement."; }
                else if (result < (pageEngagement * 0.3) || result === 0) { allTips[9].importance = 3; allTips[9].description = "A low Average Post Reactions Count indicates minimal engagement and interaction with the social media page's content, with few reactions (such as likes, hearts, etc.) left by users on posts. This lack of engagement may suggest a disconnect between the page and its audience, potentially leading to decreased visibility and relevance. To improve the average post reactions count, focus on creating compelling and relevant content that resonates with the target audience. Use eye-catching visuals, compelling captions, and engaging storytelling techniques to capture users' attention and encourage them to react to posts. Prompt followers to react to posts by asking questions, inviting opinions, or encouraging them to express their emotions through emojis. Monitor post performance regularly and adjust content strategies based on audience feedback and engagement patterns."; }  
            } else if(posts[0].platform === "Facebook"){
                let count = 0;
                posts.forEach(post =>{
                    if (post.insights && post.insights.post_reactions_by_type_total) {
                        Object.values(post.insights.post_reactions_by_type_total).forEach(reactionCount => {
                            count += reactionCount;
                        })
                     }
                    totalReactions += count;
                })
                const result = totalReactions / posts.length;
                allTips[9].value = Number(result.toFixed(2));
                allTips[9].description = "Default";
                allTips[9].canDisplay = true;   
                if (result >= (pageEngagement * 0.5) && result !== 0) { allTips[9].importance = 1; allTips[9].description = "A high Average Post Reactions Count reflects robust engagement and interaction with the social media page's content, with a significant number of reactions (such as likes, hearts, etc.) left by users on posts. This high level of engagement indicates strong community involvement, active participation, and a deep connection with the content and brand. Capitalize on the high level of engagement by continuing to create content that resonates with followers and prompts them to react positively. Celebrate milestones, achievements, and user-generated content to foster a sense of belonging and appreciation among followers. Encourage users to share their reactions and experiences with others, further amplifying engagement and reach. Monitor reaction metrics closely to track engagement trends and adapt strategies to sustain and build upon the high level of community involvement."; }
                else if (result < (pageEngagement * 0.5) && result >= (pageEngagement * 0.3) && result !== 0) { allTips[9].importance = 2; allTips[9].description = "A normal Average Post Reactions Count suggests moderate engagement and interaction with the social media page's content, with a consistent number of reactions left by users on posts. This level of engagement indicates some degree of interest and interaction among followers, contributing to a sense of community and connection. Maintain a steady level of engagement with followers by regularly posting content that resonates with their interests and preferences. Encourage users to react to posts by creating content that elicits emotional responses, sparks curiosity, or provides value and entertainment. Experiment with different types of content formats, such as polls, quizzes, and interactive stories, to stimulate engagement and encourage reactions. Monitor reaction metrics closely to identify trends and opportunities for improvement."; }
                else if (result < (pageEngagement * 0.3) || result === 0) { allTips[9].importance = 3; allTips[9].description = "A low Average Post Reactions Count indicates minimal engagement and interaction with the social media page's content, with few reactions (such as likes, hearts, etc.) left by users on posts. This lack of engagement may suggest a disconnect between the page and its audience, potentially leading to decreased visibility and relevance. To improve the average post reactions count, focus on creating compelling and relevant content that resonates with the target audience. Use eye-catching visuals, compelling captions, and engaging storytelling techniques to capture users' attention and encourage them to react to posts. Prompt followers to react to posts by asking questions, inviting opinions, or encouraging them to express their emotions through emojis. Monitor post performance regularly and adjust content strategies based on audience feedback and engagement patterns."; }  
            }
        }
    }
    
    useEffect(() => {
        const updatedTips: Tip[] = []; // Array to store updated tips with canDisplay set to true

        if (actualValues) {
            //Same for every PAGE
            calculatePageEngagementRate();
            calculateVisitConversion();
            if (beforeValues) {
                calculateTrends();
            }
            if (posts && posts.length > 0) { //Same for every POST
                calculateImpressionPerPost();
                calculateEngagementPerPost();
                calculateAvgComments();
                calculateAvgReactions();
                if (posts[0].platform === "Instagram") { //Only instagram page post
                    calculateReachRate();
                }
            }
        }
        // Add tips with canDisplay set to true to the updatedTips array
        allTips.forEach(tip => {
            if (tip.canDisplay) {
                updatedTips.push(tip);
            }
        });

        // Update the displayed tips state
        setDisplayedTips(updatedTips);
    }, [actualValues, posts, beforeValues]);


    // Function to render tips
    const drawTips = (tip: Tip) => (       
        <ShowTooltip title={tip.title} content={tip.description} key={tip.title}>
        <Tag
        icon={tip.importance === 1? <CheckCircleTwoTone twoToneColor="#22bb33" /> : tip.importance === 2? <WarningTwoTone twoToneColor="#f0ad4e" /> : <CloseCircleTwoTone twoToneColor="#bb2124"/>} 
        color={tip.importance === 1? "green" : tip.importance === 2? "orange" : "red"}
        bordered={false}
        >
            {getShortenedTitle(tip.title)}: {tip.value}{tip.suffix}
        </Tag>
        </ShowTooltip>
    );

    return (
        <Stack direction="column" justifyContent="space-between" height="100%" gap={2}>
        <Stack direction="row" gap={1} flexWrap="wrap" flex={1}>
            {displayedTips.map(tip => drawTips(tip))}
        </Stack>
        <Stack direction="column" gap={1} mt={0} height="100%">
            {getThreeRecommend(displayedTips)}
        </Stack>
        </Stack>
    );

    
};

export default HomeSummary;