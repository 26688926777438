import React from "react";
import { Box, Stack, Typography, Button } from '@mui/material';
import { OkteloCardProps } from "interfaces/oktelo";
import { Link } from 'react-router-dom';
import { Tag } from "antd";

const OkteloCard = ({
    title,
    icon,
    content,
    clickable,
    desc,
    style,
    redirect,
    hasButton,
    buttonTitle,
    buttonIcon,
    handleButton,
    handleBoxClick,
    hasTag,
    tagTitle,
    tagColor,
    bgcolor
}: OkteloCardProps) => {
    return(
        <Box className={`oktelo_card ${clickable ? "oktelo_card_hover" : ""}`}
        style={style}
        component={clickable? Link : "div"}
        to={clickable ? redirect : undefined}
        sx={{ paddingTop: hasButton? '50px' : '30px'}}
        onClick={handleBoxClick}
        gap={2}
        >
        {hasTag &&
            <Tag color={tagColor || 'purple'} bordered={false} className="oktelo_card_tag">{tagTitle}</Tag>
        }
            <Stack direction="column" gap={1} flexWrap='wrap'>
                <Stack direction="row" gap={1} alignItems="center">
                    {icon}
                    <Typography className="t_oktelo t_important t_highlight">{title}</Typography>
                </Stack>

                <Typography className="t_oktelo t_misc">{desc}</Typography>
            </Stack>
            <Stack direction="column" gap={1} flexWrap='wrap'>
                    {content}
            </Stack>
        {hasButton &&
            <Stack direction="column" gap={1} flexWrap='wrap'>
                    <Button className="oktelo_card_button" onClick={handleButton}>{buttonIcon?? buttonIcon} {buttonTitle}</Button>
            </Stack>}
        </Box>
    );
}

export default OkteloCard;