import { useState } from 'react';
import { useGetIdentity, useActiveAuthProvider } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { useNotification } from "@refinedev/core";
import  CampaignForm  from '../components/common/CampaignForm';
import { useEffect } from 'react';
import { useRef } from 'react';

const CampaignEditor = () => {
  document.title = "Edit Campaign | Oktelo";
    const { id } = useParams();
    const { open, close } = useNotification();
    const navigate = useNavigate();
    const { data: user } = useGetIdentity<IUser>();
    const {  refineCore: { onFinish, formLoading, queryResult }, register, handleSubmit } = useForm();
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [selectedCampaignPosts, setSelectedCampaignPosts] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isAddedQuerySchedule, setAddedQuerySchedule] = useState(false);
  const [isAddedQueryPost, setAddedQueryPost] = useState(false);


  useEffect(() => {

    if (queryResult) //If user got a queryResult
    {
      if (queryResult.data && queryResult.data.data && queryResult.fetchStatus === 'idle' && queryResult.status === 'success')
      {
        if (!isAddedQuerySchedule)
        {

          setDateValue(queryResult.data.data.schedule);   
          setAddedQuerySchedule(true);  

        }
      }

      if (queryResult.data && queryResult.data.data && queryResult.data.data.posts && queryResult.fetchStatus === 'idle' && queryResult.status === 'success') //Check the query result's posts
      {

        if(!isAddedQueryPost) //Only add the query results to the form, when the form first loads
        {

          setSelectedCampaignPosts(queryResult.data.data.posts);
          setAddedQueryPost(true);

        }
      }

    }

  }, [queryResult]);
  
  const updateSelectedCampaignPosts = (updatedSelectedPosts: string[]) => {
    if(isAddedQueryPost){
      setSelectedCampaignPosts(updatedSelectedPosts);
    }
  };

  const handleDateChange = (selectedDate: Dayjs | null) => {
    if(isAddedQuerySchedule){
    setDateValue(selectedDate);
    }
  };

if (loading) {
  return <div>Loading...</div>;
}
if (error){
  return <div>Error</div>;
}
  
  type FormData = {
    email: any;
    schedule?: Dayjs | null;
    posts: string[];
  };
  
  type IUser = {
    email: string;
};

  
  const onFinishHandler = async (data: FieldValues) => {
  
      const campaignData: FormData = {
        ...data,
        email: user?.email,
        schedule: dateValue,
        posts: selectedCampaignPosts,
      };
    

      if (dateValue !== null) {
        campaignData.schedule = dateValue;
      }  

      console.log("Save data:", campaignData);
  
      try {
        const response = await axios.put(`http://localhost:8080/api/v1/campaign/${id}`, campaignData);
        if(response.status === 200){
          open?.({
            type: "success",
            message: "Successfully edited a campaign.",
            description: "Well done!",
          });
          navigate("/campaign/#")
        }
        
    } catch (error) {
        console.error('Error creating campaign:', error);
    }
    finally{
      setAddedQueryPost(false);
      setAddedQuerySchedule(false);
    }
  };


  return (
    <CampaignForm
      type="Edit"
      register={register}
      selectedCampaignPosts={selectedCampaignPosts}
      onFinish={onFinish}
      updateSelectedCampaignPosts={updateSelectedCampaignPosts}
      onFinishHandler={onFinishHandler}
      formLoading={formLoading}
      handleSubmit={handleSubmit}
      onDateChange={handleDateChange}
      dateValue={dateValue}
    />
  )
  
}

export default CampaignEditor 