import { ApexOptions } from "apexcharts";

export const TotalRevenueSeries = [
    {
        name: "Interactions",
        data: [183, 124, 115, 85, 143, 143, 96],
    },
    {
        name: "Impressions",
        data: [95, 84, 72, 44, 108, 108, 47],
    },
    {
        name: "Views",
        data: [43, 64, 88, 42, 151, 43, 84],
    },
];

export const TotalRevenueOptions: ApexOptions = {
    chart: {
        type: "bar",
        toolbar: {
            show: false,
        },
    },
    colors: ["#925FFF", "#CFC8FF", "#CF9FFF"],
    plotOptions: {
        bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: "55%",
        },
    },
    dataLabels: {
        enabled: false,
    },
    grid: {
        show: false,
    },
    stroke: {
        colors: ["transparent"],
        width: 4,
    },
    xaxis: {
        categories: ["Apr. 1", "Apr. 2", "Apr. 3", "Apr. 4", "Apr. 5", "Apr. 6", "Apr. 7"],
    },
    yaxis: {
        title: {
            text: "Total count",
        },
    },
    fill: {
        opacity: 1,
    },
    legend: {
        position: "top",
        horizontalAlign: "right",
    },
    tooltip: {
        y: {
            formatter(val: number) {
                return `${val}`;
            },
        },
    },
};