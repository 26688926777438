import React, {useState} from "react";
import { Grid, Box, Stack, Typography, LinearProgress, Button } from "@mui/material";
import AI_PostWriteForm from "components/common/AI_PostWriteForm";
import { useNotification } from "@refinedev/core";
import axios from "axios";
import { okt_placeholer } from "assets";
import { DeleteOutline, FavoriteBorderOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CreateAI = () => {
    document.title = "AI Bejegyzés | Oktelo";
    const sessionUser = localStorage.getItem('user');
    let user: any = null;
    
    if (sessionUser) {
      try {
        user = JSON.parse(sessionUser);
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
    const { open } = useNotification();
    const [isPostSaving, setPostSaving] = useState<Boolean>(false);
    const [aiResponseData, setAiResponseData] = useState<any>(null);
    const [aiFormData, setAiFormData] = useState<any>(null);
    const [loading, setLoading] = useState<Boolean>(false);
    const navigate = useNavigate();

    //Set the AI response data from the Form (child component)
    const handleAiResponse = (data: any) => {
        console.log('Data received in parent:', data);
        setAiResponseData(data);
    };

    //Set the Form Data from the Form (child component)
    const handleFormSubmit = (data: any) => {
        setAiFormData(data);
    };

    //Handle the form Save Button -> Save to My Posts (backend)
    const saveAiPost = async (data: any) => {
        setPostSaving(true);
            if (aiResponseData && aiFormData && user && user.email){
                const sendToBody = {
                    title: aiResponseData.title,
                    description: aiResponseData.content,
                    propertyType: aiFormData.platform,
                    location: aiFormData.businessName,
                    scheduled_at: null, // Modify as needed
                    photo: aiResponseData.photo_idea,
                    email: user.email,
                };
        
                try {
                    setLoading(true);
                    const send_post = await axios.post(`http://localhost:8080/api/v1/ai/save/`, sendToBody);
                    if(send_post.status===200)
                        {
                            open?.({
                                type: "success",
                                message: "Bejegyzés elmentve.",
                                description: "Szép munka!",
                              });
                        }
    
                } catch (error) {
                    console.error("Error saving AI post:", error);
                }
                finally{
                    setPostSaving(false);
                    setLoading(false);
                }
            } else {
                console.error("Required data is missing for saving AI post.");
            }
        };

    //Remove current generated post (set data null)
    const removeAiResponse = () =>{
        setAiResponseData(null);
        setAiFormData(null);
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button className="oktelo_card_button" onClick={()=> navigate('/create')}>- Vissza a menübe</Button>
            </Grid>
            <Grid item xs={12} md={12} lg={7} p={2}> {/* Functions pl. Post generator */}
                <Box className="oktelo_card">
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography className="t_secondary_title  ">
                            🤖 AI Poszt készítő
                        </Typography>
                        <Button className="oktelo_card_button">Segítség kell?</Button>
                    </Stack>
                    <Typography className="t_oktelo t_misc" mb={2}>Az eredmény az űrlapon megadott pontok alapján jön létre. Ha nem tetszik az eredmény, próbálkozz más kulcsszavakkal vagy címekkel.</Typography>
                    <AI_PostWriteForm onAiResponse={handleAiResponse} onFormSubmit={handleFormSubmit} />
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={5}  p={2}> {/* Functions pl. Post generator */}
                <Box className="b_wp b_wp_o">
                    <Stack direction="row" alignItems="center" gap={1} mb={3}>
                        <Typography className="t_secondary_title  ">
                            Eredmény
                        </Typography>
                    </Stack>
                    <Stack direction="column" gap={1.5} alignItems="flex-start">
                        {aiResponseData?
                        (<>
                        <Typography className="t_oktelo t_highlight  ">
                            {aiResponseData.title}
                        </Typography>
                        <img src={aiResponseData.photo_idea? aiResponseData.photo_idea : okt_placeholer} width="100%"/>
                        <Typography className="t_oktelo t_desc  " textAlign="justify">
                            {aiResponseData.content}
                        </Typography>
                        <Typography className="t_oktelo t_purple">
                            {aiResponseData.hashtags}
                        </Typography>
                        </>
                        ) : (
                        <Typography className="t_oktelo t_misc  "  mb={3}>
                            Generáltass egy posztot az eredmény előnézetéhez.
                        </Typography>        
                        )
                        }
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Button sx={{ backgroundColor: 'none', color: ' #1E1E47', borderRadius: '12px', width: 'auto', textTransform: 'none' }}
                    className={`t_oktelo t_highlight ${aiResponseData ? 't_purple' : 't_highlight_gray'}`}
                    onClick={() => saveAiPost(aiResponseData)}
                    disabled={isPostSaving || !aiResponseData ? true : false}>
                        <FavoriteBorderOutlined sx={{ marginLeft: '6px'}}/>
                    </Button>
                    <Button sx={{ backgroundColor: 'none', color: ' #1E1E47', borderRadius: '12px', width: 'auto', textTransform: 'none' }}
                    className={`t_oktelo t_highlight ${aiResponseData ? 't_purple' : 't_highlight_gray'}`} onClick={removeAiResponse}
                    disabled={isPostSaving || !aiResponseData ? true : false}>
                        <DeleteOutline sx={{ marginLeft: '6px'}}/>
                    </Button>
                    </Stack>
                    <Box>
                        {isPostSaving &&
                    <Stack direction="column" gap={1}>
                    <Typography className="t_oktelo t_misc  ">⏳ Posztod mentése..</Typography>
                    <LinearProgress color="primary" />
                    </Stack>}
                    </Box>
                </Box>
                </Grid>
        </Grid>
    );
}

export default CreateAI;