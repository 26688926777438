import { ConfigProvider, Tour } from "antd";
import { TourProps } from "antd/lib";
import React from "react";
import { Typography, Box, Stack } from "@mui/material";

interface ConnectTourProps {
    isOpen: boolean;
    handleClose: () => void;
}


const steps: TourProps['steps'] = [
    {
      title: <Typography className="t_oktelo_normalsize_20 darkblue">Közösségi Kapcsolatok</Typography>,
      description: 
      <Box width="100%" py={3} px={1.5}>
        <Stack direction="column" gap={1.5} width="100%">
            <Typography className="t_oktelo_light miscgray size_16">
                Az Oktelo lehetővé teszi, hogy közvetlenül kommunikálj a Facebook és Instagram oldalaiddal egy helyről.
            </Typography>
            <Typography className="t_oktelo_light miscgray size_16">
                Fontos tudni, hogy az oldalaid kezelése teljes mértékben a te irányításod alatt marad. Az Oktelo csak az általad megadott utasításokat hajtja végre.
            </Typography>
            <Typography className="t_oktelo_light darkblue size_16">
                Kattints a "Következő" gombra a folytatáshoz!
            </Typography>
        </Stack>
      </Box>,
      nextButtonProps : {
        children : (
          <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
        ),
      },
    },
    {
      title: <Typography className="t_oktelo_medium size_20 darkblue">Kezdő Lépések</Typography>,
      description: 
      <Box width="100%" py={3} px={1.5}>  
        <Stack direction="column" gap={1.5} width="100%">
          <Typography className="t_oktelo_light miscgray size_16">
              Lépj be a <a href="https://www.facebook.com/pages/?category=your_pages" target="_blank">Facebook oldal beállításaidba</a> és ellenőrizd, mely oldalaid vannak összekapcsolva.
          </Typography>
          <Typography className="t_oktelo_light miscgray size_16">
              Az itt megjelenő Facebook oldalaidat fogod tudni összekötni az Oktelo-val.
          </Typography>
          <Typography className="t_oktelo_light darkblue size_16">
              Fontos: Mielőtt továbblépsz, győződj meg róla, hogy az Instagram Business fiókod is összekapcsoltad a megfelelő Facebook oldallal.
          </Typography>
      </Stack>
    </Box>,
      nextButtonProps : {
        children : (
          <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
        ),
      },
      prevButtonProps: {
        children: (
          <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
        ),
      },
    },
    {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Instagram Fiókok</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Csak az Instagram Business fiókokat lehet csatlakoztatni az Oktelo-hoz. Ha még nincs Instagram Business fiókod, <a href="https://help.instagram.com/502981923235522" target="_blank">kattints ide</a>, hogy megtudd, hogyan tudsz átváltani.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Az Instagram Business fiókokat hozzá kell kapcsolnod a megfelelő Facebook oldalakhoz. Lépj be az egyik Facebook oldaladba adminisztrátorként, majd látogass el a <a href="https://www.facebook.com/settings/?tab=linked_profiles" target="_blank">Hozzákapcsolt fiókok</a> oldalra.
                </Typography>
                <Typography className="t_oktelo_light darkblue size_16">
                    Ezzel biztosítod, hogy az Oktelo megfelelően tudjon kommunikálni az Instagram fiókoddal.
                </Typography>
            </Stack>
        </Box>,
        nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
        },
        prevButtonProps: {
            children: (
                <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
            ),
        },
    },
    {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Limitációk</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Válaszd ki azokat a Facebook oldalakat, amelyek összhangban vannak az előfizetésedben foglalt lehetőségekkel.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Ha túlléped az előfizetésedben foglalt kapcsolatok számát, az Oktelo automatikusan megszünteti a túllépést okozó oldal kapcsolódását.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Az előfizetéseddel kapcsolatos információkat a <span className="t_purple">Beállítások/Előfizetés</span> oldalon találod.
                </Typography>
            </Stack>
        </Box>,
        nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
        },
        prevButtonProps: {
            children: (
                <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
            ),
        },
    },
    {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Segítségek 🎓</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Ha bármiben elakadtál, segítséget kérhetsz a menü alján található <span className="t_purple">Segítség</span> menüpontban.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    A segítség központban részletes leírásokat és oktatóanyagokat találsz, amelyek segítenek eligazodni a folyamatokban.
                </Typography>
                <Typography className="t_oktelo_light darkblue size_16">
                    Kérdésed van? Nyugodtan vedd fel velünk a kapcsolatot a Segítség oldalon keresztül.
                </Typography>
            </Stack>
        </Box>,
        nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Bezárás</Typography>
            ),
        },
        prevButtonProps: {
            children: (
                <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
            ),
        },
    },
];


export const ConnectMetaTour: React.FC<ConnectTourProps> = ({ isOpen, handleClose }) => (
    <ConfigProvider
        theme={{
            components: {
            Tour: {
                primaryNextBtnHoverBg: '#1e1e47'
            },
            },
            token: {
                colorPrimary: '#925FFF'
            }
        }}
        >
        <Tour
        open={isOpen}
        onClose={handleClose}
        onFinish={handleClose}
        steps={steps}
        placement="center"
        zIndex={9999}
        indicatorsRender={(current, total) => (
        <span>
            {current + 1} / {total}
        </span>
        )}
        />
    </ConfigProvider>
)
