import { useState } from 'react';
import { useGetIdentity, useActiveAuthProvider } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { useNotification } from '@refinedev/core';
import  CForm  from '../components/common/Form';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';


const Create = () => {
  document.title = "Edit Post | Oktelo";
  const { id } = useParams();
  const { open, close } = useNotification();
  const navigate = useNavigate();
  const authProvider = useActiveAuthProvider();
  const [ postImage, setPostImage ] = useState({ name: '', url: '' });
  const [isAddedQuerySchedule, setAddedQuerySchedule] = useState(false);
  const [isAddedQueryPlatform, setAddedQueryPlatform] = useState(false);
  const [isAddedQueryLocation, setAddedQueryLocation] = useState(false);
  const [isAddedQueryPhoto, setAddedQueryPhoto] = useState(false);
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [platformValue, setPlatformValue] = useState<string>('');
  const [locationValue, setLocationValue] = useState<string | null>('');
  const { refineCore: { onFinish, formLoading, queryResult }, register, handleSubmit } = useForm();
  const sessionUser = localStorage.getItem('user');
  let user: any = null;
  
  if (sessionUser) {
    try {
      user = JSON.parse(sessionUser);
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }
 

useEffect(() => {

  if (queryResult) //If user got a queryResult
  {
    if (queryResult.data && queryResult.data.data && queryResult.fetchStatus === 'idle' && queryResult.status === 'success')
    {
      if (!isAddedQuerySchedule)
      {

        setDateValue(queryResult.data.data.scheduled_at);   
        setAddedQuerySchedule(true);  

      }
      if (!isAddedQueryPlatform)
      {
        setPlatformValue(queryResult.data.data.propertyType);
        setAddedQueryPlatform(true);
      }
    }
    if (queryResult.data && queryResult.data.data && queryResult.data.data.location && queryResult.fetchStatus === 'idle' && queryResult.status === 'success')
    {
      if (!isAddedQueryLocation)
      {
        setLocationValue(queryResult.data.data.location);
        setAddedQueryLocation(true);
      }     
    }
    if (queryResult.data && queryResult.data.data && queryResult.data.data.photo && queryResult.fetchStatus === 'idle' && queryResult.status === 'success')
    {
      if (!isAddedQueryPhoto)
      {
        setPostImage({ name: 'Post image uploaded', url: queryResult.data.data.photo });
        setAddedQueryPhoto(true);
      }     
    }
  }

}, [queryResult]);

const handleImageChange = (file: File) => {
  const reader = (readFile: File) =>
      new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(readFile);
      });

  reader(file).then((result: string) =>
      setPostImage({ name: file?.name, url: result }),
  );
};

const handleDateChange = (selectedDate: Dayjs | null) => {
  setDateValue(selectedDate);
};

type FormData = {
  photo: string;
  email: any;
  scheduled_at?: Dayjs | null;
};


const onFinishHandler = async (data: FieldValues) => {
  if (!postImage.url) return alert("Please select an image");

  const postData: FormData = {
    ...data,
    photo: postImage.url,
    email: user.email,
    scheduled_at: dateValue,
  };

  if (dateValue !== null) {
    postData.scheduled_at = dateValue;
  }


  try {
    const response = await axios.patch(`http://localhost:8080/api/v1/userposts/${id}`, postData);
    if(response.status === 200){
      open?.({
        type: "success",
        message: "Successfully edited a post.",
        description: "Nice one!",
      });
      navigate("/userposts/#") 
    }
    
} catch (error) {
    console.error('Error creating post:', error);
}
};

  return (
    <CForm
      type="Edit"
      register={register}
      onFinish={onFinish}
      handleImageChange={handleImageChange}
      onFinishHandler={onFinishHandler}
      formLoading={formLoading}
      handleSubmit={handleSubmit}
      postImage={postImage}
      onDateChange={handleDateChange}
      dateValue={dateValue}
      platformValue={platformValue}
      locationValue={locationValue}
    />
  )
}

export default Create