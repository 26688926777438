import React, { useEffect, useState, useCallback } from "react";
import { Box, Stack, Typography, Grid, CircularProgress, LinearProgress } from "@mui/material";
import axios from "axios";
import { Design, MediaItem } from "interfaces/canva";
import "./canva.css"
import { AddCircle, ArrowDownward, ArrowUpward, AutoFixHigh, Cloud, Delete, Home, OpenInNew, PlayCircleOutline, Publish, Refresh, Search, Settings, SettingsSuggest } from "@mui/icons-material";
import { Tooltip, Drawer, Select, Slider, Modal, Input } from "antd";
import apiconfig from "config";
import { InputNumberProps } from "antd/lib";
import { useNotification } from "@refinedev/core";
import dayjs from "dayjs";
import { okt_placeholer } from "assets";

interface ContinuationProps {
    available: boolean;
    query: string;
}

interface SelectedMedia {
  url: string;
  type: string;
}

const MyMedia = () => {
    const { open } = useNotification();
    const [ designs, setDesigns ] = useState<Design[]>([]);
    const [ localMedia, setLocalMedia ] = useState<MediaItem[]>([]);
    const [ isImportDrawer, setImportDrawer ] = useState(false);
    const [ designToImport, setDesignToImport ] = useState<Design>(); 
    const [ format, setFormat ] = useState<string | null>('png');
    const [ quality, setQuality] = useState(80);
    const [ exportQuality, setExportQuality ] = useState<string | null>('regular');
    const [ videoQuality, setVideoQualty ] = useState<string | null>('horizontal_1080p')
    const [isMediaModalOpen, setMediaModalOpen] = useState(false);
    const [selectedMediaUrl, setSelectedMediaUrl] = useState<SelectedMedia>({url: '', type: ''});
    const [ canvaMediaSort, setCanvaMediaSort] = useState('desc');
    const [filteredDesigns, setFilteredDesigns] = useState<Design[]>(designs);
    const [ searchTerm, setSearchTerm ] = useState<string>('');
    const [ continuation, setContinuation] = useState<ContinuationProps>({available: false, query: ''});
    const [ isImporting, setImporting ] = useState(false);
    const [itemsPerPage] = useState<number>(6);
    const [mediaSort, setMediaSort] = useState<'asc' | 'desc'>('desc');
    const [nextCursor, setNextCursor] = useState(null);
    const [ isLoading, setLoading ] = useState(false);
  
    const handleSelectedMedia = (mediaUrl: string, type: string) => {
      setSelectedMediaUrl({ url: mediaUrl, type: type });
      setMediaModalOpen(true);
  };

  const handleMediaModalClose = () => {
      setMediaModalOpen(false);
  };

    const onChange: InputNumberProps['onChange'] = (newValue) => {
        setQuality(newValue as number);
      };


    const onFormatChange = useCallback((value: string | undefined) => {
        if (value) {
            setFormat(value);
        } else {
            setFormat(null);
        }
      }, []);

      const onVideoQualtyChange = useCallback((value: string | undefined) => {
        if (value) {
            setVideoQualty(value);
        } else {
            setVideoQualty(null);
        }
      }, []);

      const onExpQChange = useCallback((value: string | undefined) => {
        if (value) {
            setExportQuality(value);
        } else {
            setExportQuality(null);
        }
      }, []);


    const getCanvaData = async () => { 
        try {  
            const response = await axios.get('http://127.0.0.1:8080/api/v1/integration/canvadesigns', {
                headers: {
                    'canva_type': 'designs',
                }
            });
 
            if (response.data) {
                setDesigns(response.data.data.designs.items);
                setContinuation({
                    available: !response.data.data.designs.continuation ? false : true,
                    query: response.data.data.designs.continuation || '',
                })
            }

        } catch (error) {
            console.log("Error getting canva data", error);
        }
    }

    const getCanvaDataContinuation = async () => { 
        try {  

            if (!continuation.query || continuation.query === '') return;

            const response = await axios.get('http://127.0.0.1:8080/api/v1/integration/canvadesigns', {
                headers: {
                    'canva_type': 'designs',
                    'continuation': continuation.query
                }
            });
 
            if (response.data) {
                setDesigns(prevData => [
                    ...prevData, 
                    ...response.data.data.designs.items
                ]);
                setContinuation({
                    available: !response.data.data.designs.continuation ? false : true,
                    query: response.data.data.designs.continuation || '',
                })
            }

        } catch (error) {
            console.log("Error getting canva data", error);
        }
    }

    const canvaLogin = async () => {
        try {
            
            const response = await axios.get('http://127.0.0.1:8080/api/v1/integration/canvaconnect', { withCredentials: true });

            const { authUrl, canvaVerifier, state } = response.data;
            localStorage.setItem('canvaVerifier', canvaVerifier);
            window.location.href = authUrl;
    
        } catch (error) {
            console.error('Error with canva login:', error);        
        }
    } 

    const importToOktelo = async () => {
        try {
            setImporting(true);
            setImportDrawer(false);
            if (!designToImport || !designToImport.id) return open?.({ type: 'error', message: 'Nincs dizájn kiválasztva.'})

            const formatImport = {
                designId: designToImport.id,
                quality: format !== 'mp4' ? quality : videoQuality,
                export_quality: exportQuality,
                format: format
            }

            const response = await axios.post(`${apiconfig.BACKEND_URI}/integration/canvaexport`, formatImport);

            if (response.status === 200) {
                return open?.({
                    type: 'success',
                    message: 'sikeres feltöltés'
                })
            }


        } catch (error) {
            return open?.({
                type: 'error',
                message: 'sikertelen feltöltés'
            })
        } finally {
            setDesignToImport(undefined);
            setImporting(false);

            setTimeout(() =>{
                setLocalMedia([]);
                getUserMedia(itemsPerPage, null);
            }, 4000)
        }
    }

    useEffect(() => {
        getCanvaData();
    }, []);

    useEffect(() => {
      if (!isMediaModalOpen) {
          setSelectedMediaUrl({ url: '', type: '' });
      }
  }, [isMediaModalOpen]);


    useEffect(() => {
        const filterDesigns = () => {
            if (searchTerm && searchTerm !== '') {
                const filtered = designs.filter((design) => {
                    // Check if the title exists before calling toLowerCase
                    return design.title?.toLowerCase().includes(searchTerm.toLowerCase());
                });
                setFilteredDesigns(filtered);
            } else {
                setFilteredDesigns(designs);
            }
        };

        filterDesigns();
    }, [searchTerm, designs]);
    
    // Sort by date
    const sortDesignsByDate = (designsArray: Design[], sortOrder: string) => {
        return designsArray.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.created_at - b.created_at;
            } else {
                return b.created_at - a.created_at;
            }
        });
    };

    // Apply sorting on the filtered designs
    const sortedDesigns: Design[] = sortDesignsByDate(filteredDesigns, canvaMediaSort);

    const getUserMedia = useCallback(async (limit: number, cursor: string | null) => {
        try {
          setLoading(true);
          const response = await axios.get(`${apiconfig.BACKEND_URI}/integration/usermedia`, {
            params: {
              limit: limit,
              nextCursor: cursor,
            }
          });
    
          if (response.status === 200) {
            setLocalMedia(prevMedia => [...prevMedia, ...response.data.resources]);
            setNextCursor(response.data.nextCursor);
          }
        } catch (error) {
          console.error("Error getting user media", error);
        } finally {
          setLoading(false);
        }
      }, []);
    
      useEffect(() => {
        setLocalMedia([]);
        getUserMedia(itemsPerPage, null);
      }, [getUserMedia, itemsPerPage]);
    
      const handleLoadMore = () => {
        if (nextCursor) {
          getUserMedia(itemsPerPage, nextCursor);
        }
      };
      


return (
    <Box width="100%">
        {isImporting && (
            <Box 
                sx={{ 
                    position: 'fixed', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)',  // Centering both horizontally and vertically
                    width: '90%', 
                    maxWidth: '500px', 
                    zIndex: 1300,  // Ensure it appears above other elements
                    textAlign: 'center',  // Center the content within the box
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    borderRadius: '8px',
                    padding: '20px',
                    boxShadow: 'rgba(0,0,0,0.2) 0px 0px 60px -10px'
                }}
            >
                <Stack direction="column" gap={1} alignItems="center" justifyContent="center" width="100%">
                    <img src="https://res.cloudinary.com/duhpymkbk/image/upload/v1724976476/nvpuiuzklbxbrncrqsus.webp"
                        style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'contain'
                        }}/>
                    <Typography className="t_oktelo_normal size_18 t_purple">
                        Importáljuk a Canva dizjánod...
                    </Typography>
                </Stack>
            </Box>
        )}

        <Drawer 
        closable
        destroyOnClose
        title={
            <Typography className="t_oktelo_medium size_16 darkblue">
            Importálás az Oktelo-ba
            </Typography>
        }
        zIndex={99999}
        placement="right"
        open={isImportDrawer}
        onClose={() => {setDesignToImport(undefined); setImportDrawer(false); }}
        >
        {designToImport &&
        <Stack direction="column" gap={2}>
            <img src={designToImport.thumbnail && designToImport.thumbnail.url? designToImport.thumbnail.url : okt_placeholer} style={{ width: '100%', height: '300px', objectFit: 'cover' }}/>
            <Typography className="t_oktelo_medium size_12 darkblue">
                {designToImport.title || designToImport.id}
            </Typography>
            <Stack direction="column" gap={0.5} width="100%">
                <Typography className="t_oktelo_normal size_12 darkblue">
                    Formátum
                </Typography>
                <Select
                    placeholder="Formátum"
                    style={{ width: "100%" }}
                    value={format}                  
                    allowClear
                    onChange={onFormatChange}
                    options={[
                        { value: "jpg", label: "Kép (JPG)" },
                        { value: "png", label: "Kép (PNG)"},
                        { value: "gif", label: "Mozgókép (GIF)" },
                        { value: "mp4", label: "Videó (MP4)" }, 
                    ]}
                />
            </Stack>
            <Stack direction="column" gap={0} width="100%">
                <Typography className="t_oktelo_normal size_12 darkblue">
                    Minőség ({quality})
                </Typography>
                {format !== 'mp4' ?
                <Slider
                    min={1}
                    max={100}
                    onChange={onChange}
                    value={typeof quality === 'number' ? quality : 0}
                />
                :
                <Select
                    placeholder="Videó minőség"
                    style={{ width: "100%" }}
                    value={videoQuality}                  
                    allowClear
                    onChange={onVideoQualtyChange}
                    options={[
                        { value: "horizontal_480p", label: "Fekvő 480p" },
                        { value: "horizontal_720p", label: "Fekvő 720"},
                        { value: "horizontal_1080p", label: "Fekvő 1080p" },
                        { value: "vertical_480p", label: "Álló 480p" }, 
                        { value: "vertical_720p", label: "Álló 720p" }, 
                        { value: "vertical_1080p", label: "Álló 1080p" }, 
                    ]}
                />
                }
            </Stack>
            <Stack direction="column" gap={0.5} width="100%">
                <Typography className="t_oktelo_normal size_12 darkblue">
                    Canva profil
                </Typography>
                <Select
                    placeholder="Normál"
                    style={{ width: "100%" }}
                    value={exportQuality}                  
                    allowClear
                    onChange={onExpQChange}
                    options={[
                        { value: "regular", label: "Canva ingyenes" },
                        { value: "pro", label: "Canva Pro"},
                    ]}
                />
            </Stack>
            <button className="home-action-button b925" onClick={() => importToOktelo()}>
                Importálás
            </button>
        </Stack>
        }
        </Drawer>
        <button onClick={canvaLogin}>Login</button>
        <Grid container spacing={2} mb={3} mt={3} pb={3}>
        <Grid item xs={12}>
          <Box className="oktelo_card" width="100%" sx={{ boxShadow: 'none' }}>
            <Stack direction="row" gap={1} width="100%" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" className="t_oktelo_medium darkblue">
              Oktelo médiatárad
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
{/*             <button className="home-action-button b9d9" onClick={() => setMediaSort(mediaSort === 'desc' ? 'asc' : 'desc')}>
                Feltöltés dátuma {mediaSort === "desc" ? <ArrowDownward/> : <ArrowUpward/>}
            </button> */}
            <button className="home-action-button bc6a" onClick={() => {/* getUserMedia() */}}
                style={{ height: '40px'}}>
                Frissítés {<Refresh/>}
            </button>
            </Stack>
            </Stack>

            <Grid container spacing={2} mt={2}>
            {localMedia.length > 0 ? (
                localMedia.map((media) => (
                  <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={media.asset_id}>
                    <Box className="canva-item-box" sx={{ position: 'relative' }}>

                        {media.resource_type === 'image' ? (
                        <Box className="canva-item-box-image" sx={{ position: 'relative', cursor: 'pointer', ':hover': { opacity: 0.9} }} onClick={() => handleSelectedMedia(media.secure_url, 'image')}>
                          <img
                            src={media.secure_url}
                            alt={`Image from canva ${media.uploaded_at}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                            onClick={() => handleSelectedMedia(media.secure_url, 'image')}
                          />
                        </Box>
                        ) : (
                          <Box sx={{ position: 'relative', cursor: 'pointer', ':hover': { opacity: 0.9} }}  className="canva-item-box-image"/*  onClick={() => handleSelectedMedia(media.secure_url, 'video')} */>
                            <img
                              src={`${media.secure_url.replace('.mp4', '.jpg')}?time=1s`}
                              alt="Video Thumbnail"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                              onClick={() => handleSelectedMedia(media.secure_url, 'video')}
                            />
                            <PlayCircleOutline
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: 40,
                                color: '#fff',
                                opacity: 0.8,
                              }}
                            />
                          </Box>
                        )}
             
                      <Box sx={{ mt: 1, p: 1 }}>
                        <Tooltip title="Hozzáadás dátuma">
                          <Typography className="t_oktelo_normal size_14 darkblue">
                            {dayjs(media.uploaded_at).format('YYYY.MM.DD')} - #{media.asset_id.slice(0,8)}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Stack direction="row" gap={1} px={1} pb={1} alignItems="center" className="canva-item-buttons">
                      <Tooltip title="Megnyitás teljes nézetben">
                                <button className="home-action-button b925"
                                onClick={() => window.open(media.secure_url, '_blank')}>
                                    <OpenInNew/>
                                </button>
                                </Tooltip>
                        <Tooltip title="Poszt készítés">
                                <button className="home-action-button bccf"
                                onClick={() => {}}>
                                    <AddCircle/>
                                </button>
                                </Tooltip>
                        <Tooltip title="Törlés a médiatárból">
                                <button className="home-action-button be44"
                                onClick={() => {}}>
                                    <Delete/>
                                </button>
                                </Tooltip>
                      </Stack>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Typography className="t_oktelo_normal size_14 darkblue">Nincs találat.</Typography>
              )}
               <Grid item xl={12} xs={12}>
               {/* Pagination Controls */}
          <Stack direction="row" justifyContent="center" alignItems="center" mt={2}>
          <Stack justifyContent="flex-end" width={'100%'} flexDirection="row">
                {continuation.available &&
                    <button
                    className={`home-action-button ${!nextCursor ? 'b9d9' : 'b925'}`}
                    style={{ width: 'auto' }}
                    onClick={() => handleLoadMore()}
                    disabled={!nextCursor}>
                        Továbbiak betöltése <ArrowDownward/>
                    </button>
                }
              </Stack>

          </Stack>
                </Grid> 
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {designs && designs.length > 0 ? (
        <Grid container spacing={2} mb={3} mt={3} pb={3}>
        <Grid item xs={12}>
          <Box className="oktelo_card" width="100%" sx={{ boxShadow: 'none' }}>
            <Stack direction="row" gap={1} width="100%" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" className="t_oktelo_medium darkblue">
              Canva dizájnok
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
            <Input placeholder="Név keresése.." onChange={(e) => setSearchTerm(e.target.value)} 
            prefix={<Search sx={{ color: "#d3d3d3" }}/>}
            className="approval-filter-input"/> 
            <button className="home-action-button b9d9" onClick={() => setCanvaMediaSort(canvaMediaSort === 'desc' ? 'asc' : 'desc')}
                style={{ height: '40px'}}>
                Létrehozva {canvaMediaSort === "desc" ? <ArrowDownward/> : <ArrowUpward/>}
            </button>
            <button className="home-action-button bc6a" onClick={() => getCanvaData()}
                style={{ height: '40px'}}>
                Frissítés {<Refresh/>}
            </button>
            </Stack>
            </Stack>
            <Grid container spacing={2} mt={2}>
          {sortedDesigns.map((item) => (
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={item.id}>
              <Box className="canva-item-box">
                <Box className="canva-item-box-image">
                  <img
                    src={item.thumbnail && item.thumbnail.url ? item.thumbnail.url : okt_placeholer}
                    alt={item.title || 'Nincs elnevezve'}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Stack sx={{ p: 1 }} alignItems="flex-start" height="auto">
                  <Tooltip title="A dizájn elnevezése">
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {item.title || 'Nincs elnevezve'}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Stack direction="row" gap={1} px={1} pb={1} alignItems="center" className="canva-item-buttons">
                <Tooltip title="Megnyitás Canva-ban">
                                <button className="home-action-button b925"
                                onClick={() => window.open(item.urls.view_url, '_blank')}>
                                    <OpenInNew/>
                                </button>
                                </Tooltip>
                  <Tooltip title="Hozzáadás az Oktelo-hoz">
                                <button className="home-action-button bccf"
                                onClick={() => {setDesignToImport(item); setImportDrawer(true)}}>
                                    <Publish/>
                                </button>
                                </Tooltip>
                  <Tooltip title="Szerkesztés Canva-ban">
                        <button className="home-action-button bc6a"
                        onClick={() => window.open(item.urls.edit_url, '_blank')}>
                            <AutoFixHigh/>
                        </button>
                    </Tooltip>
                </Stack>
              </Box>
            </Grid>
          ))}
          </Grid>
          <Stack justifyContent="flex-end" width={'100%'} flexDirection="row">
                {continuation.available &&
                    <button
                    className="home-action-button b925"
                    style={{ width: 'auto' }}
                    onClick={() => getCanvaDataContinuation()}>
                        Továbbiak betöltése <ArrowDownward/>
                    </button>
                }
              </Stack>
          </Box>

        </Grid>
    </Grid>
      ) : (
        <Box className="oktelo_card" width="100%" sx={{ boxShadow: 'none' }}>
        <Stack direction="column" gap={1.5} p={2} justifyContent="flex-start" alignItems="flex-start">
          <Typography className="t_oktelo_medium size_20 darkblue">
            Nem találtunk Canva dizájnokat!
          </Typography>
          <Typography className="t_oktelo_light size_16 miscgray">
            Lehet, hogy még nem csatlakoztattad a Canva fiókodat, vagy nem készítettél terveket.
          </Typography>
          <Stack direction="row" gap={1} mt={1.5} alignItems="center">
            <button className="home-action-button b925">
              Integrációk <Cloud/>
            </button>
            <button className="home-action-button b1e9">
              Canva megnyitása <OpenInNew/>
            </button>
          </Stack>

        </Stack>
        </Box>
      )}

      {/* Ant Design Modal for video playback */}
      <Modal
            open={isMediaModalOpen}
            onCancel={handleMediaModalClose}
            footer={null}
            title="Előnézet"
            centered
            style={{ maxWidth: '90vw', maxHeight: '90vh', width: '80%' }} // Dynamic width and height
            wrapClassName="cinema-modal"
        >
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '0',
                    paddingBottom: '56.25%', // Aspect ratio for 16:9
                }}
            >
                {selectedMediaUrl.type === 'video' ? (
                    <video
                        controls
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain', // Maintain aspect ratio and fit within the container
                        }}
                    >
                        <source src={selectedMediaUrl.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img
                        src={selectedMediaUrl.url}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain', // Maintain aspect ratio and fit within the container
                        }}
                        alt="Media content"
                    />
                )}
            </div>
        </Modal>
    </Box>

);
}

export default MyMedia;