import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { useNotification } from "@refinedev/core";
import { Avatar, Box, Grid, Stack, Typography, Select, MenuItem } from '@mui/material';
import { AccessTimeOutlined, LinkedIn, ArrowDownward, ArrowUpward, ArticleOutlined, AutoFixHighOutlined, CalendarMonthOutlined, CampaignOutlined, CancelOutlined, CheckCircleOutline, CloseOutlined, CollectionsOutlined, Facebook, FormatAlignCenterOutlined, Instagram, LabelOutlined, LinkOutlined, LocalCafeOutlined, OfflineBoltOutlined, OndemandVideoOutlined, PendingOutlined, PhotoCameraBackOutlined, SaveAsOutlined, SearchOutlined, SettingsOutlined, VisibilityOutlined } from '@mui/icons-material';
import { DatePicker, Input, Tag } from 'antd';
import "./campaign.css";
import { OkteloPostProps } from 'interfaces/oktelo';
import { LinkPlaceholder, TextPlaceholder, VideoPlaceholder, okt_placeholer } from 'assets';
import 'dayjs/locale/hu';
import { SendOutlined, PoweroffOutlined } from "@ant-design/icons";
import apiconfig from 'config';


const { TextArea } = Input;


dayjs.locale('hu');

type UserTag = {
  id: string;
  title: string;
  color: string;
  posts: number;
};

interface ScheduleInterval {
  index: number;
  value: string;
  title: string;
  duration: number;
}

type PageInfo = {
  id: string;
  name: string;
  photo: string;
  platform: string;
};

type SocialData = {
  pages: PageInfo[];
};


const CampaignCreator = () => {
  document.title = "Új kampány | Oktelo";
  const { open, close } = useNotification();
  const navigate = useNavigate();
  const [userTags, setUserTags] = useState<UserTag[]>([]);
  const [userPosts, setUserPosts] = useState<OkteloPostProps[]>([]);
  const [messageFilter, setMessageFilter] = useState<string>('');
  const [interval, setInterval] = useState<ScheduleInterval>({index: 12, value: '_day', title: '1 naponta (24 óra)', duration: 24 * 60 });
  const [scheduledPosts, setScheduledPosts] = useState<OkteloPostProps[]>([]);
  const [pageFilter, setPageFilter] = useState<string>('');

  /* values */
  const [campaignTitle, setCampaignTitle] = useState<string>('');
  const [campaignDesc, setCampaignDesc] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [pages, setPages] = useState<SocialData>();
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>('');

  const getUserTags = async () => {
    try {
      const response = await axios.get(`${apiconfig.BACKEND_URI}/tags/`);

      if (response.status === 200){
        setUserTags(response.data);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const getUserPosts = async () => {
    try {
      const response = await axios.get(`${apiconfig.BACKEND_URI}/data/ncposts`);

      if (response.status === 200){
        setUserPosts(response.data);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const getSocialPages = async () => {
    try {
      const response = await axios.get(
        `${apiconfig.BACKEND_URI}/data/socials`
      );

      if (response.status === 200) {
        const allFacebook = response.data.facebookPages;
        const allInstagram = response.data.instagramPages;
        const allLinkedin = response.data.linkedinPages;

        const merge: SocialData = {
          pages: [...allFacebook, ...allInstagram, ...allLinkedin],
        };

      setPages(merge);

      }

    } catch (error) {
      console.log(error);
    }
  }

  const saveCampaign = async (type: string) => {
    try {
      
      const formattedCampaing = {
        title: campaignTitle,
        description: campaignDesc,
        startdate: startDate,
        enddate: scheduledPosts[scheduledPosts.length-1].campaignSchedule,
        tag: selectedTag,
        posts: scheduledPosts.map(post => ({
          id: post._id,
          schedule: post.campaignSchedule,
        })),
        type: type,
        interval: interval.title,
      }

      const response = await axios.post(`${apiconfig.BACKEND_URI}/campaign/create`, formattedCampaing);

      if(response.status === 200){
        open?.({
          type: 'success',
          message: 'Sikeresen létrehoztad a kampányt'
        })
      } else {
        open?.({
          type: 'error',
          message: 'Hiba történt'
        })
      }


    } catch (error) {
      console.error('Error saving campaign:', error);
    } finally {
      navigate('/campaigns')
    }
  }

  const ScheduleTypeMap: ScheduleInterval[] = [
    { index: 0, value: '_minute', title: '25 percenként', duration: 25 },
    { index: 1, value: '_minute', title: '30 percenként', duration: 30 },
    { index: 2, value: '_minute', title: '45 percenként', duration: 45 },
    { index: 3, value: '_hour', title: '1 óránként', duration: 60 },
    { index: 4, value: '_hour', title: '2 óránként', duration: 2 * 60 },
    { index: 5, value: '_hour', title: '3 óránként', duration: 3 * 60 },
    { index: 6, value: '_hour', title: '4 óránként', duration: 4 * 60 },
    { index: 7, value: '_hour', title: '5 óránként', duration: 5 * 60 },
    { index: 8, value: '_hour', title: '6 óránként', duration: 6 * 60 },
    { index: 9, value: '_hour', title: '8 óránként', duration: 8 * 60 },
    { index: 10, value: '_hour', title: '12 óránként', duration: 12 * 60 },
    { index: 11, value: '_hour', title: '16 óránként', duration: 16 * 60 },
    { index: 12, value: '_day', title: '1 naponta (24 óra)', duration: 24 * 60 },
    { index: 13, value: '_day', title: '2 naponta (48 óra)', duration: 2 * 24 * 60 },
    { index: 14, value: '_day', title: '3 naponta (72 óra)', duration: 3 * 24 * 60 },
    { index: 15, value: '_day', title: '4 naponta (96 óra)', duration: 4 * 24 * 60 },
    { index: 16, value: '_day', title: '5 naponta (120 óra)', duration: 5 * 24 * 60 },
    { index: 17, value: '_week', title: '1 hetente', duration: 7 * 24 * 60 },
    { index: 18, value: '_week', title: '2 hetente', duration: 2 * 7 * 24 * 60 },
    { index: 19, value: '_week', title: '3 hetente', duration: 3 * 7 * 24 * 60 },
    { index: 20, value: '_week', title: '4 hetente', duration: 4 * 7 * 24 * 60 }
  ];


  // Handler for when the interval is selected
  const handleIntervalChange = (event: any) => {
    const _newinterval = ScheduleTypeMap.find(i => i.index === event);
    if (_newinterval){
      setInterval(_newinterval);
    }
  };

  const handlePostSelect = (id: string) => {
      // Create a copy of the current state to avoid direct mutation
      const updatedPosts = [...selectedPosts];
  
      if (updatedPosts.includes(id)) {
          // Remove the ID from the array
          const index = updatedPosts.indexOf(id);
          if (index > -1) {
              updatedPosts.splice(index, 1);
          }
      } else {
          // Add the ID to the array
          updatedPosts.push(id);
      }
  
      // Update the state with the modified array
      setSelectedPosts(updatedPosts);
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if(!url) return false
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const iconMap: Record<string, JSX.Element> = {
    textOnly: <FormatAlignCenterOutlined />,
    video: <OndemandVideoOutlined />,
    photo: <PhotoCameraBackOutlined />,
    webUrl: <LinkOutlined />,
    story: <LocalCafeOutlined />,
    carousel: <CollectionsOutlined />,
    default: <ArticleOutlined />,
  };

  const approvalMap: Record<string, JSX.Element> = {
    approved: <CheckCircleOutline sx={{ color: "#00991f" }} />,
    pending: <PendingOutlined sx={{ color: "#3268bd" }} />,
    rejected: <CancelOutlined sx={{ color: "#a50000" }} />,
    noNeed: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
    default: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
  };

  const returnIcon = (type: string) => iconMap[type] || iconMap.default;
  const returnApproval = (status: string) => approvalMap[status] || approvalMap.default;

  useEffect(() => {
    getUserTags();
    getUserPosts();
    getSocialPages();
  }, [])

  // Update scheduled posts when selected posts change
  useEffect(() => {
    const _selected = userPosts.filter(post => selectedPosts.includes(post._id));
    setScheduledPosts(calculateScheduleDates(_selected));
  }, [selectedPosts, userPosts, startDate, interval]);

  const calculateScheduleDates = (posts: OkteloPostProps[]) => {
    return posts.map((post, index) => ({
      ...post,
      campaignSchedule: startDate && startDate.add(index * interval.duration, 'minute').toDate()
    }));
  };
  
   // Move a post up in the list
   const movePostUp = (index: number) => {
    if (index > 0) {
      const newPosts = [...scheduledPosts];
      [newPosts[index - 1], newPosts[index]] = [newPosts[index], newPosts[index - 1]];
      setScheduledPosts(calculateScheduleDates(newPosts));
    }
  };

  // Move a post down in the list
  const movePostDown = (index: number) => {
    if (index < scheduledPosts.length - 1) {
      const newPosts = [...scheduledPosts];
      [newPosts[index + 1], newPosts[index]] = [newPosts[index], newPosts[index + 1]];
      setScheduledPosts(calculateScheduleDates(newPosts));
    }
  };


  if(!userPosts) return <Box>A kampányhoz kellenek posztok!</Box>

  const filteredPosts = userPosts.filter((post) => {
    return (
      (!messageFilter || 
        post.message?.toLowerCase().includes(messageFilter.toLowerCase())) &&
      (!pageFilter || 
        post.parent && post.parent.id === pageFilter)
    );
  });
  

  return (
    <Box>
{/*       <Typography className="t_oktelo_bold size_24 darkblue" mb={2}>
        Kampány létrehozása
      </Typography> */}
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={12}>
          <Box className="oktelo-box campaign-create-container">
            <Stack direction="row" alignItems="center" gap={1} mb={2}>
              <Avatar
                sx={{ width: "30px", height: "30px", backgroundColor: "#edecfe" }}
              >
                <SettingsOutlined sx={{ fontSize: "16px", color: "#322793" }} />
              </Avatar>
              <Typography className="t_oktelo_semibold size_16 darkblue">
                Általános
              </Typography>
            </Stack>
            <Stack direction="column" gap={1} my={1.5}>
              <Typography className="t_oktelo_medium size_14 darkblue">
                Kampány címe
              </Typography>
              <Input
               count={{
                show: true,
                max: 48,
               }}
               onChange={(e) => setCampaignTitle(e.target.value)}
               />
            </Stack>
            <Stack direction="column" gap={1} my={1.5}>
              <Typography className="t_oktelo_medium size_14 darkblue">
                Kampány leírása
              </Typography>
              <TextArea
                count={{
                  show: true,
                  max: 120,
               }}
               rows={2}
               onChange={(e) => setCampaignDesc(e.target.value)}
               />
            </Stack>
            <Stack direction="column" gap={1} my={1.5}>
              <Typography className="t_oktelo_medium size_14 darkblue">
                Címke
              </Typography>
               <Select
               displayEmpty
               fullWidth
               onChange={(e: any) => setSelectedTag(e.target.value)}
               renderValue={(selected) => {
                if (selected) {
                  const selectedTag = userTags.find(tag => tag.id === selected);
                  return selectedTag ? (
                    <Stack direction="row" p={0.5} ml={1} alignItems="center" gap={1}>
                      <Typography className="t_oktelo_normal darkblue size_14">
                        {selectedTag.title}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" p={0.5} ml={1} alignItems="center" gap={1}>
                      <Typography className="t_oktelo_normal darkblue size_14">
                        Nincs címke
                      </Typography>
                    </Stack>
                  );
                } else {
                  return (
                    <Stack direction="row" p={0.5} ml={1} alignItems="center" gap={1}>
                      <Typography className="t_oktelo_normal darkblue size_14">
                        Nincs címke
                      </Typography>
                    </Stack>
                  );
                }
              }}
            >
                <MenuItem value="" key="notag">
                  <Stack direction="row" p={0.5} alignItems="center">
                    <Typography className="t_oktelo_normal darkblue size_14">
                      Nincs címke
                    </Typography>
                  </Stack>
                </MenuItem>
                {userTags &&
                  userTags.map(tag => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Stack direction="row" p={0.5} alignItems="center" gap={1}>
                        <LabelOutlined sx={{ color: tag.color, fontSize: '16px' }}/>
                        <Typography className="t_oktelo_normal darkblue size_14">
                          {tag.title}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))
                }
               </Select>
            </Stack>
          </Box>
          <Box className="oktelo-box campaign-create-container" mt={1.5}>
            <Stack direction="row" alignItems="center" gap={1} mb={2}>
              <Avatar
                sx={{ width: "30px", height: "30px", backgroundColor: "#feedec" }}
              >
                <ArticleOutlined sx={{ fontSize: "16px", color: "#dc4a3b" }} />
              </Avatar>
              <Typography className="t_oktelo_semibold size_16 darkblue">
                Bejegyzések
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
            <Input 
              prefix={<SearchOutlined sx={{ fontSize: '16px', color: '#d9d9d9'}}/>}
              onChange={(e) => setMessageFilter(e.target.value)}
              placeholder='Szöveg keresése...'
            />
            <Select
              displayEmpty
              fullWidth
              value={!pageFilter ? "" : pageFilter}
              placeholder="Oldal választása..."
              onChange={(e) => setPageFilter(e.target.value)}
              renderValue={(selected) => {
                if (!selected || selected === "" || selected === null) {
                  return (
                    <Typography className='t_oktelo_normal size_14 darkblue' p={2}>
                      Oldal szűrő...
                    </Typography>
                  );
                }
                const selectedPage = pages?.pages.find(p => p.id === selected);
                return selectedPage ? (
                  <Typography className='t_oktelo_normal size_14 darkblue' p={2}>
                    {selectedPage.name}
                  </Typography>
                ) : (
                  <Typography className='t_oktelo_normal size_14 darkblue' p={2}>
                    Oldal szűrő...
                  </Typography>
                );
              }}
            >
              <MenuItem value="">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Egyik sem
                </Typography>
              </MenuItem>

                {pages && pages.pages && pages.pages.length > 0 &&
                pages.pages.map((p: PageInfo) => (
                  <MenuItem key={p.id} value={p.id}>
                    <Stack direction="row" gap={1} alignItems="center">
                    {p.platform === "facebook" ? (
                      <Facebook sx={{ fontSize: "16px", color: '#1e90ff' }} />
                    ) : p.platform === "instagram" ? (
                      <Instagram sx={{ fontSize: "16px", color: '#E4405F' }} />
                    ) : (
                      <LinkedIn sx={{ fontSize: "16px" }} />
                    )}
                    <img
                      src={p.photo}
                      style={{
                        objectFit: "contain",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography className="posts-select-item">
                      {p.name}
                    </Typography>
                  </Stack>
                  </MenuItem>
                ))}
            </Select>
            </Stack>

            <Grid container spacing={1} mt={1.5} height="450px" sx={{ overflowY: 'scroll' }}>
                {filteredPosts && filteredPosts.map(post => (
                  <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={post._id}>
                    <Box className={`campaign-post-holder ${selectedPosts.includes(post._id) ? 'selected-campaign-post' : ''}`} onClick={() => handlePostSelect(post._id)}
                      >
                      <img
                        src={hImageSrc(
                          post.attachment.type,
                          post.attachment.url
                        )}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      {post.message && 
                      <Box className="floatmessage">
                        <Typography className="t_oktelo_normal t_white size_12 post-message-wrap">
                          {post.message.length > 200 ? post.message.slice(0,200)+'...' : post.message}
                        </Typography>
                      </Box>}
                      <Box className="post-grid-type-box">
                        {returnIcon(post.attachment.type)}
                        {post.tag &&
                          <Typography className="size_12 darkblue t_oktelo_medium">
                            {post.tag.title}
                          </Typography>
                          }
                      </Box>
                      {post.approval.isNeeded && (
                        <Box className="post-grid-approval-box">
                          {returnApproval(post.approval.status)}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
            </Grid>
            </Box>

            <Box className="oktelo-box campaign-create-container" mt={1.5}>
            <Stack direction="row" alignItems="center" gap={1} mb={2}>
              <Avatar
                sx={{ width: "30px", height: "30px", backgroundColor: "#d9e7fd" }}
              >
                <AccessTimeOutlined sx={{ fontSize: "16px", color: "#275293" }} />
              </Avatar>
              <Typography className="t_oktelo_semibold size_16 darkblue">
                Időzítés
              </Typography>
            </Stack>
            <Stack direction="column" gap={1} my={1.5}>
              <Stack direction="row" alignItems="center" width="100%" gap={1.5}>
                <Stack direction="column" width="100%" gap={1}>
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Kezdés
                  </Typography>
                  <DatePicker
                  showHour
                  showMinute
                  showTime
                  needConfirm={false}
                  placeholder='Kampány indulása..'
                  format={'YYYY.MM.DD HH:mm'}
                  minDate={dayjs()}
                  onChange={(e) => setStartDate(e)}
                  />
                </Stack>
                <Stack direction="column" width="100%" gap={1}>
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Befejezés
                  </Typography>
                  <DatePicker
                  showHour
                  disabled
                  value={dayjs(scheduledPosts[scheduledPosts.length - 1]?.campaignSchedule)}
                  showMinute
                  placeholder='Kampány vége..'
                  format={'YYYY.MM.DD HH:mm'}
                  minDate={startDate? startDate.add(3, 'day') : dayjs()}
                  />
                </Stack>
              </Stack>
              <Stack direction="column" gap={1} my={1.5}>
              <Typography className="t_oktelo_medium size_14 darkblue">
                Időköz, közzététel intervallum
              </Typography>
              <Select
      displayEmpty
      fullWidth
      value={interval.index}
      onChange={(e) => handleIntervalChange(e.target.value)}
      renderValue={(selected) => {
        if (!selected || selected === 99) {
          return (
            <Stack direction="row" p={0.5} ml={1} alignItems="center" gap={1}>
              <Typography className="t_oktelo_normal darkblue size_14">
                Nincs időköz kiválasztva
              </Typography>
            </Stack>
          );
        } else {
          const selectedType = ScheduleTypeMap.find(type => type.index === selected);
          return (
            <Stack direction="row" p={0.5} ml={1} alignItems="center" gap={1}>
              <Typography className="t_oktelo_normal darkblue size_14">
                {selectedType ? selectedType.title : ''}
              </Typography>
            </Stack>
          );
        }
      }}
    >
      <MenuItem value={99} key="nodate">
        <Stack direction="row" p={0.5} alignItems="center">
          <Typography className="t_oktelo_normal darkblue size_14">
            Nincs
          </Typography>
        </Stack>
      </MenuItem>

      {ScheduleTypeMap.map(type => (
        <MenuItem value={type.index} key={type.value+type.index}>
          <Stack direction="row" p={0.5} alignItems="center">
            <Typography className="t_oktelo_normal darkblue size_14">
              {type.title}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
            </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={12}>
          <Box className="oktelo-box">
            <Stack direction="column" gap={1.5} alignItems="flex-start">
            <Avatar
                sx={{ width: "30px", height: "30px", backgroundColor: "#e7ffee" }}
              >
                <CampaignOutlined sx={{ fontSize: "16px", color: "#0db241" }} />
              </Avatar>
               <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" gap={2}>
               <Typography className="size_20 t_oktelo_semibold darkblue">
                {campaignTitle ? campaignTitle : 'Új kampány'}
                </Typography>
                {selectedTag && selectedTag !== '' &&
               <Tag color={userTags.find(tag => tag.id === selectedTag)?.color}
               className='tag_oktelo size_14 t_oktelo_medium'>
                {userTags.find(tag => tag.id === selectedTag)?.title}
                </Tag>
               }

               </Stack>

              <Typography className="size_14 t_oktelo_normal darkblue">
                {campaignDesc}
              </Typography>
                {scheduledPosts.map((post, index) =>
                  <Stack
                  key={post._id}
                  gap={1.5}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className='post-campaign-list'
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar
                    sx={{ width: '30px', height: '30px'}}
                    src={hImageSrc(post.attachment.type, post.attachment.url)}
                    />
                    <Stack direction="column" gap={0}>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {post.message?.slice(0,32)}
                    </Typography>
                    <Typography className="t_oktelo_medium size_14 t_purple">
                      {post.campaignSchedule ? dayjs(post.campaignSchedule).format('YYYY.MM.DD HH:mm') : ''}
                    </Typography>
                    </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                    <button 
                    className='move-button'
                    onClick={() => window.open(`/posts/show/${post._id}`)}>
                      <VisibilityOutlined/>
                    </button>

                      {index > 0 &&
                    <button 
                    className='move-button'
                    onClick={() => movePostUp(index)}>
                      <ArrowUpward/>
                    </button>}
                        { index < scheduledPosts.length-1 &&
                    <button
                    className='move-button'
                    onClick={() => movePostDown(index)}>
                      <ArrowDownward/>
                    </button>}
                    <button
                    className='move-button'
                    style={{ backgroundColor: '#ffeee7'}}
                    onClick={() => handlePostSelect(post._id)}>
                      <CloseOutlined sx={{ color: '#ff5e13'}}/>
                    </button>
                    </Stack>
                  </Stack>
                )}
                    <Typography className="t_oktelo_medium size_14 darkblue">
                        Időtartam:
                    </Typography>
                    <Stack direction="row" gap={1} alignItems="center">
                    {startDate &&
                    <Tag
                    className='tag_oktelo size_14 t_oktelo_normal'
                    color="purple"
                    bordered={false}
                    icon={<SendOutlined/>}
                    >
                      {dayjs(startDate).format('YYYY.MM.DD HH:mm') || ''}
                    </Tag>}
                    {scheduledPosts.length > 0 && selectedPosts.length > 0 && 
  scheduledPosts[scheduledPosts.length - 1]?.campaignSchedule && (
    <Tag
      className='tag_oktelo size_14 t_oktelo_normal'
      color="gold"
      icon={<PoweroffOutlined />}
      bordered={false}
    >
      {dayjs(scheduledPosts[scheduledPosts.length - 1].campaignSchedule).format('YYYY.MM.DD HH:mm') || ''}
    </Tag>
)}
                    </Stack>

            </Stack>
          </Box>
          <Box className="oktelo-box campaign-create-container" mt={1.5}>
            <Stack direction="row" alignItems="center" gap={1} mb={2}>
              <Avatar
                sx={{ width: "30px", height: "30px", backgroundColor: "#fef6ec" }}
              >
                <SaveAsOutlined sx={{ fontSize: "16px", color: "#dc9b3b" }} />
              </Avatar>
              <Typography className="t_oktelo_semibold size_16 darkblue">
                Mentés
              </Typography>
            </Stack>
            <Typography className="t_oktelo_normal size_12 darkblue">
              <span className='t_purple'>Vázlat:</span> A kampány és a beállításai mentésre kerülnek, de nem lesz elindítva, így bejegyzések sem lesznek közzétéve / ütemezve.
            </Typography>
            <Typography className="t_oktelo_normal size_12 darkblue" my={1.2}>
            <span className='t_purple'>Mentés és indítás:</span> A kampány mentésre kerül és a kezdési dátumon elindul. A kampányba került posztjaid ütemezési ideje felülírásra fog kerülni a sorrend alapján.
            </Typography>
            <Stack direction="row" gap={1} alignItems="center" width="100%">
              <button className="home-action-button bc6a" style={{ width: '100%' }}
              onClick={() => saveCampaign('draft')}>
                Vázlat
              </button>
              <button className="home-action-button b925" style={{ width: '100%' }}>
                Mentés és indítás
              </button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default CampaignCreator 