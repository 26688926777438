import React from "react";
import { useRouterContext, useLink, useRouterType } from "@refinedev/core";
import type { RefineLayoutThemedTitleProps } from "@refinedev/mui";

import { logo, okteloWhite } from 'assets';
import { Button, Stack } from "@mui/material";
import { HamburgerMenu } from "./hamburgerMenu";


export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
  wrapperStyles,
  icon = logo,
  text = okteloWhite,
}) => {
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
 

  return (
           !collapsed ? (
            <Button fullWidth variant="text" disableRipple sx={{margin: '0 !important', padding: '0 !important', backgroundColor: 'transparent !important'}}>
              <Link to="/">
             <img src={okteloWhite} width="120px" alt="Oktelo logo"/> 
             </Link>
            </Button>
          ) :
          <Button fullWidth variant="text" disableRipple sx={{margin: '0 !important', padding: '0 !important', backgroundColor: 'transparent !important'}}>
          <Link to="/">
            <img src={logo} width="32px" alt="Oktelo logo"/> 
         </Link>
        </Button>

  );
};
