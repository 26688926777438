import React from 'react';
import { Box, Typography, Card, Grid } from '@mui/material';
import { Progress, Tag } from 'antd';
import dayjs from 'dayjs';
import type { ProgressProps } from 'antd';


interface ProgressChartData {
  name: string;
  period?: string;
  values: { value: { [key: string]: number }; end_time: string }[];
  title: string;
  description: string;
  maxValue: number;
}

interface Props {
  metrics: ProgressChartData[];
}

const ProgressChart: React.FC<Props> = ({ metrics }) => {
  const getTopValues = (values: [string, number][]) => {
    return values
      .sort((a, b) => b[1] - a[1]) // Sort values in descending order based on the number value
      .slice(0, 10); // Get the top 10 values
  };

  const twoColors: ProgressProps['strokeColor'] = {
    '0%': '#CCCCFF',
    '100%': '#925FFF',
  };

  // Helper function to get the hour range in a more user-friendly format
  const getHourRange = (hour: string) => {
    const hourInt = parseInt(hour);
    if (hourInt === 0) return '12 AM';
    else if (hourInt < 12) return `${hourInt} AM`;
    else if (hourInt === 12) return '12 PM';
    else return `${hourInt - 12} PM`;
  };

   

    // Filter metrics to include only the latest non-empty data for each metric up until yesterday
    const filteredMetrics = metrics.map(metric => { 
      const latestNonEmptyValue = metric.values
        .filter(item => Object.keys(item.value).length > 0 && new Date(item.end_time))
        .sort((a, b) => new Date(b.end_time).getTime() - new Date(a.end_time).getTime())
        .find(item => Object.keys(item.value).length > 0);

      return { ...metric, values: latestNonEmptyValue ? [latestNonEmptyValue] : [] };
    });

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 4 }}
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      {filteredMetrics.map((metric, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={4}
          key={index}
          height="auto"
        >
          <Card sx={{
            borderRadius: '12px',
            backgroundColor: '#FFFFFF',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
            padding: '8px',
            height: "100%"
          }}>
            <Box mb={3} className="b_wp" height="100%">
            <Typography className="t_oktelo t_secondary_title" mb={1}>{metric.title}</Typography>
              {metric.values.map((item, idx) => (
                <Box key={idx} mt={1}>
                  <Typography className="t_oktelo t_misc" mb={2}>{metric.description}</Typography>
                  <Tag>Last updated: {dayjs(item.end_time).format('LLL')}</Tag>
                  {getTopValues(Object.entries(item.value)).map(([key, value]) => (
                    <Box key={key} mt={1}>
                      <Typography>
                        {metric.title === 'Daily Liked and Online' ? <b>{getHourRange(key)}:</b> : <b>{key}:</b>} {value.toLocaleString()} users ({metric.maxValue !== 0 ? (value / metric.maxValue).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'})
                      </Typography>
                      {/* Display the progress based on the maximum value */}
                      {metric.maxValue !== 0 && (
                        <Progress
                          percent={(value / metric.maxValue) * 100}
                          size="small"
                          type="line"
                          strokeColor={twoColors}
                          showInfo={false}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
                      }  

export default ProgressChart;
