import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNotification } from "@refinedev/core";
import { Grid, Stack, Typography, Box, Avatar } from "@mui/material";
import { ColorPicker, Input, Tag } from "antd";
import {
  AddOutlined,
  CheckOutlined,
  CloseOutlined,
  Delete,
  LocalOfferOutlined,
  TagOutlined,
} from "@mui/icons-material";
import { NumberOutlined } from "@ant-design/icons";
import apiconfig from "config";

type UserTags = {
  id: string;
  title: string;
  color: string;
  posts: number;
};

type UserHashtags = {
  id: string;
  title: string;
  hashtags: string[];
  posts: number;
};

const TagsHome = () => {
  const { open } = useNotification();
  const [tagColor, setTagColor] = useState("");
  const [tagModalVisible, setTagModalVisible] = useState(false);
  const [hashtagModalVisible, setHashtagModalVisible] = useState(false);
  const [tagTitle, setTagTitle] = useState("");
  const [userTags, setUserTags] = useState<UserTags[]>([]);
  const [userHashtsgs, setUserHashtags] = useState<UserHashtags[]>([]);
  const [hashtagTitle, setHashtagTitle] = useState("");
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [hashtagContent, setHashtagContent] = useState<string>("");
  const [hashtagCount, setHashtagCount] = useState(0);

  const handleCreateNewTag = async () => {
    try {
      const response = await axios.post(
        `${apiconfig.BACKEND_URI}/tags/create`,
        { title: tagTitle, color: tagColor }
      );

      if (response.status === 201) {
        open?.({
          type: "success",
          message: "Sikeresen létrehoztál egy címkét",
        });
      } else {
        open?.({
          type: "error",
          message: "Hiba történt.",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      getUserTags();
      setTagColor("");
      setTagTitle("");
    }
  };

  const handleRemoveTag = async (id: string) => {
    try {
      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/tags/remove/${id}`
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeresen töröltél egy címkét",
        });
      } else {
        open?.({
          type: "error",
          message: "Hiba történt.",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      getUserTags();
    }
  };

  const getUserTags = async () => {
    try {
      const _tags = await axios.get(`${apiconfig.BACKEND_URI}/tags`);

      if (_tags.status === 200) {
        setUserTags(_tags.data);
      } else {
        setUserTags([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserHashtags = async () => {
    try {
      const _hashtags = await axios.get(
        `${apiconfig.BACKEND_URI}/tags/hashtags`
      );

      if (_hashtags.status === 200) {
        setUserHashtags(_hashtags.data);
      } else {
        setUserHashtags([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNewHashtag = async () => {
    try {
      if (hashtagCount < 20) {
        open?.({
          type: "error",
          message: "Túl sok hashtaget adtál hozzá (maximum 20).",
        });
      }
      if (!hashtagTitle || hashtagTitle === "" || hashtagTitle.length < 2) {
        open?.({
          type: "error",
          message: "Az elnevezés legyen minimum 2 karakter.",
        });
      }
      if (hashtagCount < 1 || !hashtags || hashtags.length < 1) {
        open?.({
          type: "error",
          message: "Minimum 1 hashtaget kell hozzáadnod.",
        });
      }

      const response = await axios.post(
        `${apiconfig.BACKEND_URI}/tags/create-hashtag`,
        { title: hashtagTitle, tags: hashtags }
      );

      if (response.status === 201) {
        open?.({
          type: "success",
          message: "Sikeresen létrehoztál egy címkét",
        });
      } else {
        open?.({
          type: "error",
          message: "Hiba történt.",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setHashtagTitle("");
      setHashtagContent("");
      setHashtags([]);
      setHashtagCount(0);
      getUserHashtags();
    }
  };

  const handleRemoveHashtag = async (id: string) => {
    try {
      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/tags/remove-hashtag/${id}`
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeresen töröltél egy hashtag sablont",
        });
      } else {
        open?.({
          type: "error",
          message: "Hiba történt.",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      getUserHashtags();
    }
  };

  const showTagModal = () => {
    setTagModalVisible(!tagModalVisible);
  };

  const handleColor = (color: any) => {
    const hexColor = color.toHexString();
    setTagColor(hexColor);
  };

  const handleInputChange = (e: any) => {
    setHashtagContent(e.target.value);
  };

  const handleInputKeyPress = (e: any) => {
    if (e.key === " " || e.key === "," || e.key === "Enter") {
      addHashtag(hashtagContent.trim());
      setHashtagContent("");
    }
  };

  const addHashtag = (tag: any) => {
    if (tag && !hashtags.includes(tag) && hashtagCount < 20) {
      setHashtags([...hashtags, `#${tag}`]);
      setHashtagCount(hashtagCount + 1);
    }
  };

  const showHashtagModal = () => {
    setHashtagModalVisible(!hashtagModalVisible);
  };

  const handleClose = (removedTag: string) => {
    setHashtags(hashtags.filter((tag) => tag !== removedTag));
    setHashtagCount(hashtagCount - 1);
  };

  useEffect(() => {
    getUserTags();
    getUserHashtags();
  }, []);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box className="oktelo-box">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1.5}
            >
              <Typography className="t_oktelo_bold size_20 darkblue">
                Címkék{" "}
                <span className="t_oktelo_normal">({userTags.length})</span>
              </Typography>
              <button
                className="social-select-button"
                style={{ color: "#1E1E47" }}
                onClick={() => {
                  showTagModal();
                }}
              >
                {!tagModalVisible ? <AddOutlined /> : <CloseOutlined />}
              </button>
            </Stack>
            <Typography className="t_oktelo_normal size_14 darkblue">
              Ezek az egyedi címkéid, amiket később hozzáadhatsz posztokhoz,
              hogy könnyebben megkülönböztethesd őket.
            </Typography>
            {tagModalVisible && (
              <>
                <Typography
                  className="t_oktelo_medium size_12 darkblue"
                  mt={1.5}
                >
                  Címke hozzáadása
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                  sx={{
                    border: "1px solid #d3d3d3",
                    backgroundColor: "#faf4fd",
                    borderRadius: "6px",
                  }}
                  gap={2}
                >
                  <ColorPicker
                    defaultFormat="hex"
                    defaultValue={"#925FFF"}
                    onChangeComplete={(color) => handleColor(color)}
                  />
                  <Input
                    placeholder="Ide írd a címkéd nevét"
                    maxLength={20}
                    showCount
                    onChange={(e) => setTagTitle(e.target.value)}
                  />
                  <button
                    className="social-select-button"
                    style={{ color: "#267105" }}
                    onClick={() => {
                      handleCreateNewTag();
                    }}
                  >
                    <CheckOutlined />
                  </button>
                </Stack>
              </>
            )}
            <Stack direction="column" gap={2} mt={2}>
              {userTags.map((tag) => (
                <Stack
                  key={tag.id}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "6px",
                    padding: "12px",
                    borderLeft: "2px solid",
                    borderColor: tag.color
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar
                      sx={{
                        backgroundColor: "#f9f9f9",
                        height: "24px",
                        width: "24px",
                      }}
                    >
                      <LocalOfferOutlined
                        sx={{ fontSize: "16px", color: tag.color }}
                      />
                    </Avatar>
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      {tag.title}{" "}
                      <span className="t_oktelo_light">
                        ({tag.posts} poszt)
                      </span>
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Delete
                      onClick={() => handleRemoveTag(tag.id)}
                      sx={{ fontSize: "18px", cursor: "pointer", ":hover": { color: "#925FFF"} }}
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box className="oktelo-box" width="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1.5}
            >
              <Typography className="t_oktelo_bold size_20 darkblue">
                Hashtag-ek{" "}
                <span className="t_oktelo_normal">({userHashtsgs.length})</span>
              </Typography>
              <button
                className="social-select-button"
                style={{ color: "#1E1E47" }}
                onClick={() => {
                  showHashtagModal();
                }}
              >
                {!hashtagModalVisible ? <AddOutlined /> : <CloseOutlined />}
              </button>
            </Stack>
            <Typography className="t_oktelo_normal size_14 darkblue" mb={1}>
              Hashtag sablonok, amiket a posztkészítésnél tudsz felhasználni.
            </Typography>
            {hashtagModalVisible && (
              <>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  p={1}
                  sx={{
                    border: "1px solid #d3d3d3",
                    backgroundColor: "#faf9ff",
                    borderRadius: "6px",
                  }}
                  gap={2}
                >
                  <Typography
                    className="t_oktelo_medium size_14 darkblue"
                    mt={1}
                  >
                    Hashtag sablon címe
                  </Typography>
                  <Input
                    placeholder="Ide írd a sablon elnevezését"
                    maxLength={20}
                    value={hashtagTitle}
                    showCount
                    onChange={(e) => setHashtagTitle(e.target.value)}
                  />
                  <Typography
                    className="t_oktelo_medium size_14 darkblue"
                    mt={1}
                  >
                    Nyomj entert vagy szőközt a hozzáadáshoz
                  </Typography>
                  <Input
                    placeholder="Nyaralás"
                    value={hashtagContent}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyPress}
                    maxLength={24}
                    showCount
                    prefix={<NumberOutlined style={{ marginRight: "6px" }} />}
                  />
                  <Typography
                    className="t_oktelo_medium size_14 darkblue"
                    mt={1}
                  >
                    Lista: ({hashtagCount} / 20)
                  </Typography>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    {hashtags.map((tag) => (
                      <Tag
                        key={tag}
                        closable
                        onClose={() => handleClose(tag)}
                        className="tag_oktelo size_14 t_oktelo_normal"
                        color="purple"
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Stack>
                  <button
                    className="home-action-button b925"
                    style={{ color: "#fff" }}
                    onClick={() => {
                      handleCreateNewHashtag();
                    }}
                  >
                    <CheckOutlined sx={{ marginRight: "6px" }} /> Mentés
                  </button>
                </Stack>
              </>
            )}
            <Stack direction="column" gap={2} mt={2}>
              {userHashtsgs.map((tag) => (
                <Stack
                  key={tag.id}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "6px",
                    padding: "12px",
                  }}
                >
                  <Stack direction="column" alignItems="flex-start" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Avatar
                        sx={{
                          backgroundColor: "#f9f9f9",
                          height: "24px",
                          width: "24px",
                        }}
                      >
                        <TagOutlined
                          sx={{ fontSize: "20px", color: "#925FFF" }}
                        />
                      </Avatar>
                      <Typography className="t_oktelo_medium size_14 darkblue">
                        {tag.title}{" "}
                        <span className="t_oktelo_light">
                          ({tag.posts} poszt)
                        </span>
                        :
                      </Typography>
                      </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {tag.hashtags.map((t: string) => (
                        <span key={t}>{t} </span>
                      ))}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Delete
                      onClick={() => handleRemoveHashtag(tag.id)}
                      sx={{ fontSize: "18px", cursor: "pointer", ":hover": { color: "#925FFF"} }}
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TagsHome;
