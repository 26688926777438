// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media only screen and (max-width: 600px) {
    .okt_mobile_invisible {
      display: none !important;
    }
    .t_main_title {
      font-size: 18px !important;
    }
    .t_secondary_title {
      font-size: 16px !important;
    }
    .size_24 {
      font-size: 18px !important;
    }
    .size_32 {
      font-size: 22px !important;
    }
  }

  @media screen and (max-width: 900px) {
    .my_profile-bg {
        width: 100%;
        border-radius: 15px;
    }
  
    .my_profile_user-img {
        margin-left: 0px;
        margin-top: -64px;
    }
  
    .property_details-img {
        width: 100%;
        height: auto;
    }
  }


  @media only screen and (max-width: 600px)  {
    .css-1p51e29-MuiPaper-root-MuiCard-root{
      width: 100% !important;
      
    }
    .css-k97ujd{
      justify-content: center;
    }
    
  }

  @media only screen and (max-width: 800px) {
    .post-grid-card img {
      height: 250px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/styles/responsive.css"],"names":[],"mappings":";AACA;IACI;MACE,wBAAwB;IAC1B;IACA;MACE,0BAA0B;IAC5B;IACA;MACE,0BAA0B;IAC5B;IACA;MACE,0BAA0B;IAC5B;IACA;MACE,0BAA0B;IAC5B;EACF;;EAEA;IACE;QACI,WAAW;QACX,mBAAmB;IACvB;;IAEA;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;EACF;;;EAGA;IACE;MACE,sBAAsB;;IAExB;IACA;MACE,uBAAuB;IACzB;;EAEF;;EAEA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":["\n@media only screen and (max-width: 600px) {\n    .okt_mobile_invisible {\n      display: none !important;\n    }\n    .t_main_title {\n      font-size: 18px !important;\n    }\n    .t_secondary_title {\n      font-size: 16px !important;\n    }\n    .size_24 {\n      font-size: 18px !important;\n    }\n    .size_32 {\n      font-size: 22px !important;\n    }\n  }\n\n  @media screen and (max-width: 900px) {\n    .my_profile-bg {\n        width: 100%;\n        border-radius: 15px;\n    }\n  \n    .my_profile_user-img {\n        margin-left: 0px;\n        margin-top: -64px;\n    }\n  \n    .property_details-img {\n        width: 100%;\n        height: auto;\n    }\n  }\n\n\n  @media only screen and (max-width: 600px)  {\n    .css-1p51e29-MuiPaper-root-MuiCard-root{\n      width: 100% !important;\n      \n    }\n    .css-k97ujd{\n      justify-content: center;\n    }\n    \n  }\n\n  @media only screen and (max-width: 800px) {\n    .post-grid-card img {\n      height: 250px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
