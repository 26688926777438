import { ProfileProps } from "interfaces/common";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack, Grid, Avatar, Button, Drawer } from "@mui/material";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useNotification } from "@refinedev/core";
import dayjs from "dayjs";
import { EditOutlined } from "@mui/icons-material";
import ProfileModal from "./ProfileModal";
import { OkteloPostProps } from "interfaces/oktelo";
import { useNavigate } from "react-router-dom";
import apiconfig from "config";



const Profile = ({ type, username, avatar, email, properties, fb_pages, company, insta_pages, role, tokens, regdate }: ProfileProps) => {
    const { open } = useNotification();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false); // State to control form visibility

    const roleDisplay = (role: number) => {
      if (role === 0){
        return "Ingyenes csomag"
      }
      else if ( role === 1){
        return "Kezdő csomag"
      }
      else if ( role === 2 ){
        return "Haladó csomag"
      }
      else if ( role === 3 ){
        return "Prémium csomag"
      }
      else if ( role === 4 ){
        return "Support"
      }
    }

    const tokenDisplay = (role: number) => {
      if (role === 0){
        return "2 per nap"
      }
      else if ( role === 1){
        return "20 per nap"
      }
      else if ( role === 2 ){
        return "50 per nap"
      }
      else if ( role === 3 ){
        return "120 per nap"
      }
      else if ( role === 4 ){
        return "999 per nap"
      }
    }
    
    const handleProfileUpdate = async (values: any) => {
      try {
        const checkValues = values;

        console.log(checkValues);

        const sendValues = {
          username: '',
          email: '',
          company: '',
          newpass: '',
        }; 
    
        if (checkValues.username !== null && checkValues.username !== "" && checkValues.username !== undefined) {
          const isValidUsername = /^[a-zA-Z0-9]+$/.test(checkValues.username);
          if(checkValues.username.length > 24 || !isValidUsername) {
            return open?.({
              type: "error",
              message: "Username is too long or it contains forbidden characters.",
              description: "Username error.",
                })
          }
          if (checkValues.username === username){
            sendValues.username = '';
            return open?.({
              type: "error",
              message: "Username is the same!",
              description: "Username error."  
              })
          } else if (checkValues.username !== username && checkValues.username.length <= 24 && isValidUsername){
                sendValues.username = checkValues.username;
            } 
        } else{
          sendValues.username = '';
        }
    
        if (checkValues.email !== null && checkValues.email !== "" && checkValues.email !== undefined) {
          const isValidEmail = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(checkValues.email);
          if(!isValidEmail || checkValues.email === email){
            sendValues.email = '';
            return open?.({
              type: "error",
              message: "Email is not in the correct format or not changed",
              description: 'Email error'
            })
          }
          else {
            sendValues.email = checkValues.email;
          }
        } else {
          sendValues.email = '';
        }
    
        if (checkValues.company !== null && checkValues.company !== "" && checkValues.company !== undefined) {
          sendValues.company = checkValues.company !== company ? checkValues.company : '';
        }
    
        if (checkValues.new_password !== null && checkValues.new_password !== "" && checkValues.new_password !== undefined) {
          sendValues.newpass = checkValues.new_password;
        }

        console.log("send values:" , sendValues);
    
        const response = await axios.put(`${apiconfig.BACKEND_URI}/users/edit/`, sendValues, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
    
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }


    const handleEditClick = () => {
        if(!showForm){

      setShowForm(true); 
        }
        else{
      setShowForm(false);
        }
    };

    return (
        <Box>

          <Grid container spacing={3} p={0}>
            <Grid item xl={12} xs={12}>
                <Stack direction="row" alignItems="center" gap={2} justifyContent="space-evenly" bgcolor="#fff" p={2} flexWrap="wrap">
                  <Button className="notransform">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    Felhasználó
                  </Typography>
                  </Button>
                  <Button className="notransform">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    Előfizetés
                  </Typography>
                  </Button>
                  <Button className="notransform">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    Közösségi kapcsolatok
                  </Typography>
                  </Button>
                  <Button className="notransform">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    Weboldal kapcsolatok
                  </Typography>
                  </Button>
                  <Button className="notransform">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    Barát meghívások
                  </Typography>
                  </Button>
                  <Button className="notransform">
                  <Typography className="t_oktelo_medium darkblue size_14">
                    Partnerprogram
                  </Typography>
                  </Button>
                </Stack>
            </Grid>
            <Grid item xl={12}>
              <Stack direction="column" gap={2}>
                <Typography className="t_oktelo_bold size_16 darkblue">
                  {username}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box bgcolor={'#f8f8f8'} sx={{ position: 'relative' }} p={3}>
                      <Stack direction="column" gap={2} alignItems="flex-start" alignContent="flex-start" width="100%">
                        <Stack direction="row" alignItems="center" gap={1} mb={2}>
                            <Avatar
                            alt={username}
                            sx={{ backgroundColor: "#925FFF !important", color: '#fff' }}
                          >{username.charAt(0).toUpperCase()}
                        </Avatar>
                        <Stack direction="column" gap={0}>
                        <Typography className="t_oktelo t_highlight t_important">
                          {username} <span className="t_oktelo t_misc">({company? company : 'Nincs cég'})</span>
                        </Typography>
                        <Typography className="t_oktelo t_misc" sx={{ fontSize: '12px !important' }}>
                          {email} 
                        </Typography>
                        </Stack>
                        </Stack>
                        <Typography className="t_oktelo t_misc">
                          Regisztráltál: {dayjs(regdate).format('LLL')}
                        </Typography>
                        <Typography className="t_oktelo t_misc"> 
                          Oktelo Tokenek: {tokens} (Frissül: {tokenDisplay(role.type)}) <a href="" className="t_purple">Több Tokent szeretnék</a>
                        </Typography>
                        <Typography className="t_oktelo t_misc">
                          Oktelo posztok: {properties.length}
                        </Typography>
                        <Typography className="t_oktelo t_misc">
                          Facebook oldalak: {fb_pages?.length}
                        </Typography>
                        <Typography className="t_oktelo t_misc">
                          Instagram oldalak: {insta_pages?.length} 
                        </Typography>
                        <Stack width="100%" alignItems="flex-end" position="absolute" right={'16px'} bottom={'16px'}>
                        <Button className="oktelo_card_button"  onClick={()=> handleEditClick()}><EditOutlined sx={{ color: '#925FFF', float: 'right', fontSize: '16px', marginRight:'4px'}}/> Szerkesztés</Button>
                        </Stack>
                        </Stack>
                        
                </Box>
                <ProfileModal defaultValues={{username, email, company}} visible={showForm} onCancel={handleEditClick} handleProfileSave={handleProfileUpdate}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Box className="oktelo_card" height="100%" sx={{ border: '1px solid #925fff'}}  p={3}>
                  <Stack direction="column" gap={2} alignItems="flex-start">
                    <Typography className="t_oktelo t_important t_highlight">
                    💜 Oktelo tagságod
                    </Typography>
                    <Typography className="t_oktelo t_highlight">
                     {roleDisplay(role.type)}
                    </Typography>
                    <Typography className="t_oktelo t_misc">
                      Aktiválás dátuma: {dayjs(role.start).format('LLL')}
                    </Typography>
                    <Typography className="t_oktelo t_misc">
                      Lejár/hosszabítás: {role.end ? dayjs(role.end).format('LLL') : '-'}
                    </Typography>
                    <Button className="b_oktelo_action" sx={{ width: '100%' }} onClick={() => navigate('/subscription')}>Tagság beállítások</Button>
                  </Stack>
                </Box>
              </Grid>

            </Grid>
        </Box>
      );
      

};

export default Profile;