import React, {  useEffect, useState } from "react";
import {
  pickNotDeprecated,
  useGetIdentity,
} from "@refinedev/core";
import { HamburgerMenu } from "./hamburgerMenu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import NotificationSystem from "components/common/NotificationSystem";
import { useNavigate } from "react-router-dom";
import HeaderText from "./headerText";
import { okt_placeholer } from "assets";
import { OkteloUserIdentity } from "interfaces/user";
import { TokenTwoTone } from "@mui/icons-material";

export const ThemedHeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
  sticky,
}) => {
  const navigate = useNavigate();
  const { data: identity, refetch } = useGetIdentity<OkteloUserIdentity>();
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [spn, setSpn] = useState(localStorage.getItem("spn") || "Oktelo");
  const [spp, setSpp] = useState(localStorage.getItem("spp") || okt_placeholer);



  useEffect(() => {
    const handleStorageChange = () => {
        setSpn(localStorage.getItem('spn') || 'Oktelo');
        setSpp(localStorage.getItem('spp') || okt_placeholer);
        console.log('Storage changed, calling refetch...');
        refetch()
            .then(() => {
                console.log('Complete');
            })
            .catch(err => {
                console.error('Error', err);
            });
    };

    // Listen for changes in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener
    return () => {
        window.removeEventListener('storage', handleStorageChange);
    };
}, [refetch]);

  const prefferedSticky = pickNotDeprecated(sticky, isSticky) ?? true;

  return (
    <AppBar
      position={prefferedSticky ? "sticky" : "relative"}
      elevation={0}
      sx={{ background: "#fff", color: "#1E1E47", display: {xs: 'flex', sm: 'flex', md: 'none'} }}
    >
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
        >
            <HamburgerMenu />
            {/* <NotificationSystem /> */}
{/*           <Stack>
           <HeaderText spn={spn} spp={spp} /> 
          </Stack> */}
        </Stack>
      </Toolbar>
{/*       {notificationsVisible && <NotificationSystem />} */}
    </AppBar>
  );
};
