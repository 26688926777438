import logo from './oktelo-favicon-2024.png';
import oktelo from './Oktelo-logo-2024-full.png';
import okteloWhite from "./Oktelo-logo-2024-full-white.png";
import meta_logo from './meta_logo.svg';
import disconnect_logo from './disconnect.svg'

import okt_angryicon from './icons/angry-icon.png';
import okt_hahaicon from './icons/haha-icon.png';
import okt_hearticon from './icons/heart-icon.png';
import okt_likeicon from './icons/like-icon.png';
import okt_sadicon from './icons/sad-icon.png';
import okt_wowicon from './icons/wow-icon.png';
import okt_placeholer from './post_placeholder.webp';

import chart_test from './chart_up.png';
import chart_down from './chart_down.png';
import chart_normal from './chart_normal.png';

import worldMap from './world.svg';

import HomeCardOne from './home_card_1.jpg';

import LinkPlaceholder from './placeholders/link_attachment.jpg';
import TextPlaceholder from './placeholders/text_attachment.jpg';
import VideoPlaceholder from './placeholders/video_attachment.jpg';

export {
  logo,
  oktelo,
  okt_angryicon,
  okt_hahaicon,
  okt_hearticon,
  okt_likeicon,
  okt_sadicon,
  okt_wowicon,
  okt_placeholer,
  meta_logo,
  disconnect_logo,
  chart_test,
  chart_down,
  chart_normal,
  worldMap,
  HomeCardOne,
  VideoPlaceholder,
  TextPlaceholder,
  LinkPlaceholder,
  okteloWhite
};
