// FacebookSDKContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

// Define the shape of the context value
interface FacebookSDKContextType {
  isFBInitialized: boolean;
  isFBSdkLoaded: boolean;
}

const FacebookSDKContext = createContext<FacebookSDKContextType>({
  isFBInitialized: false,
  isFBSdkLoaded: false,
});

interface FacebookSDKProviderProps {
  children: ReactNode;
}

const APP_ID = '690285639938286'; // Consider storing sensitive info like App ID in environment variables
const SDK_VERSION = 'v2.7'; // Keep this updated as needed

export const FacebookSDKProvider: React.FC<FacebookSDKProviderProps> = ({ children }) => {
  const [isFBInitialized, setIsFBInitialized] = useState<boolean>(false);
  const [isFBSdkLoaded, setIsFBSdkLoaded] = useState<boolean>(false);

  useEffect(() => {
    const initializeFacebookSDK = () => {
      if (typeof window.FB !== 'undefined') {
        console.log("Initializing Facebook SDK...");
        window.FB.init({
          appId: APP_ID,
          cookie: true,
          xfbml: true,
          version: SDK_VERSION,
        });

        window.FB.AppEvents.logPageView();
        console.log("Facebook SDK Initialized");
        setIsFBInitialized(true);
      }
    };

    const loadFacebookSDK = () => {
      // Load the Facebook SDK script
      console.log("Loading FB SDK script...");
      (function (d, s, id) {
        if (d.getElementById(id)) {
          console.log("FB SDK script already present");
          setIsFBSdkLoaded(true);
          initializeFacebookSDK();
          return;
        }
        const js = d.createElement(s) as HTMLScriptElement; // Explicitly type as HTMLScriptElement
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js'; // Ensure correct script source
        js.onload = () => {
          console.log("FB SDK script loaded");
          setIsFBSdkLoaded(true);
          initializeFacebookSDK();
        };
        js.onerror = () => {
          console.error('Failed to load Facebook SDK.');
          setIsFBSdkLoaded(false);
        };
        const fjs = d.getElementsByTagName(s)[0];
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };

    if (typeof window.FB !== 'undefined') {
      console.log("FB SDK already loaded");
      setIsFBSdkLoaded(true);
      initializeFacebookSDK();
    } else {
      loadFacebookSDK();
    }

    // Cleanup: Optionally remove the script if the component is unmounted
    return () => {
      const script = document.getElementById('facebook-jssdk');
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <FacebookSDKContext.Provider value={{ isFBInitialized, isFBSdkLoaded }}>
      {children}
    </FacebookSDKContext.Provider>
  );
};

export const useFacebookSDK = () => useContext(FacebookSDKContext);
