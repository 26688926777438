import React, { useState, useEffect } from "react";
import axios from "axios";
import apiconfig from "config";
import { Box, Stack, Grid, Typography} from "@mui/material";

interface Schedule {
    _id: string;
    message: string;
    parent: string;
    platform: string;
    attachment: {
        type: string;
        url: {
            photoUrl: string | undefined;
            videoUrl: string | undefined;
            thumbnailUrl: string | undefined;
            webUrl: string | undefined;
            carouselUrls: string[] | undefined;
        };
    };
    scheduleDate: Date;
    isPublished: boolean;
    fbPublish: boolean;
    type: string;
}

const ScheduledPosts = () => {

    const [schedules, setSchedules] = useState<Schedule[]>([]);

    const getSchedules = async () => {
        try {
            const response = await axios.get(`${apiconfig.BACKEND_URI}/data/schedules`);
        
            if (response.status === 200){
                setSchedules(response.data);
            }

        } catch (error) {
            console.log(error);      
        }
    };

    useEffect(() => {
        getSchedules();
    }, [])

    return (
        <Box width="100%">
            <Grid container spacing={2}>
                {(!schedules || schedules.length <= 0) &&
                <Grid item xl={12}>
                    <Typography className="t_oktelo_normal size_16 darkblue">
                        Nincsenek ütemezéseid.
                    </Typography>
                </Grid>
                }
            </Grid>
        </Box>
    )
}

export default ScheduledPosts;