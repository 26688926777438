import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CampaignProps, CampaignTrackProps, PostMetricsProps } from "interfaces/common";
import axios from "axios";
import { Box, Stack, Typography, Grid, Avatar } from "@mui/material";
import {
  AccessTimeOutlined,
  CampaignOutlined,
  ChecklistOutlined,
  Circle,
  DeleteOutline,
  Facebook,
  Insights,
  Instagram,
  PauseOutlined,
  PlayArrowOutlined,
  ScheduleOutlined,
  StopOutlined,
  TipsAndUpdatesOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  TagOutlined,
} from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { Calendar, Tag } from "antd";
import { customLocale } from "utils/customLocale";
import "./campaign.css";
import "dayjs/locale/hu";
import {
  LinkPlaceholder,
  okt_placeholer,
  TextPlaceholder,
  VideoPlaceholder,
} from "assets";
import { useNotification } from "@refinedev/core";
import apiconfig from "config";
dayjs.locale("hu");



const CampaignDisplay = () => {
  const { id } = useParams();
  const [campaign, setCampaign] = useState<CampaignProps>();
  const [postTrack, setPostTrack] = useState<PostMetricsProps[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {open} = useNotification();
  const navigate = useNavigate();

  const getCampaign = async () => {
    try {
      const response = await axios.get(
        `${apiconfig.BACKEND_URI}/data/campaign/${id}`
      );
      if (response.status === 200) {
        setCampaign(response.data.campaign);
        setPostTrack(response.data.metrics);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProperty = async (campaign_id: string) => {
    try {
      const response = await axios.delete(
        `${apiconfig.BACKEND_URI}/campaign/${campaign_id}`
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeres törlés",
        });
      }
    } catch (error) {
    } finally {
      navigate('/campaigns')
    }
  };

  useEffect(() => {
    setLoading(true);
    getCampaign();
  }, []);

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <Tag
            bordered={false}
            icon={<ExclamationCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="default"
          >
            Várakozik
          </Tag>
        );
      case "ongoing":
        return (
          <Tag
            bordered={false}
            icon={<ExclamationCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="warning"
          >
            Folyamatban
          </Tag>
        );
      case "scheduled":
        return (
          <Tag
            bordered={false}
            icon={<ClockCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="processing"
          >
            Ütemezett
          </Tag>
        );
      case "completed":
        return (
          <Tag
            bordered={false}
            icon={<CheckCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="success"
          >
            Véget ért
          </Tag>
        );
      default:
        return (
          <Tag
            bordered={false}
            icon={<ClockCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="default"
          >
            Várakozik
          </Tag>
        );
    }
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if (!url) return false;
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const calStyle: React.CSSProperties = {
    border: "1px solid #d9d9d9",
  };

  if (campaign) {
    const getPostsForDate = (date: Dayjs) => {
      return campaign.posts.filter((post) =>
        dayjs(post.calendarDate).isSame(date, "day")
      );
    };

    const dateCellRender = (date: Dayjs) => {
      const posts = getPostsForDate(date);
      const isEndDate = dayjs(date).isSame(campaign.endDate, "day");
      const isStartDate = dayjs(date).isSame(campaign.startDate, "day");

      let cellStyle = {};
      if (isStartDate || isEndDate) {
        if (isStartDate) {
          cellStyle = {
            backgroundColor: "rgba(85, 229, 131, 0.3)",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            padding: "4px",
            borderRadius: "4px",
            alignItems: "center",
            justifyContent: "center",
          };
        }
        if (isEndDate) {
          cellStyle = {
            backgroundColor: "rgba(229, 85, 183, 0.3)",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            padding: "4px",
            borderRadius: "4px",
            alignItems: "center",
            justifyContent: "center",
          };
        }
      } else {
        if (
          posts.some((post) => dayjs(post.calendarDate).isSame(date, "day"))
        ) {
          cellStyle = {
            backgroundColor: "rgba(164, 171, 255, 0.3)",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            padding: "4px",
            borderRadius: "4px",
            alignItems: "center",
            justifyContent: "center",
          };
        }
      }

      return (
        <div style={cellStyle}>
          {posts.length > 0 &&
            posts.map((post, index) => (
              <Avatar
                sx={{ width: "16px", height: "16px", objectFit: "cover" }}
                src={hImageSrc(post.attachment.type, post.attachment.url)}
                key={index}
              />
            ))}
        </div>
      );
    };

    return (
      <Box width="100%">
        <Grid container spacing={2} mb={2}>
        <Grid item xl={12} xs={12}>
                <Box className="oktelo-box">
                    <Stack direction="row" gap={1.5} alignItems="center" flexWrap="wrap">
                        <button className="home-action-button b925">
                            <PlayArrowOutlined/>
                            Indítás azonnal
                        </button>
                        <button className="home-action-button b925">
                            <StopOutlined/>
                            Leállítás
                        </button>
                        <button className="home-action-button b925">
                            <PauseOutlined/>
                            Szüneteltetés
                        </button>
                        <button className="home-action-button b25f">
                            <UpdateOutlined/>
                            Újraütemezés
                        </button>
                        <button className="home-action-button bf7b" onClick={() => handleDeleteProperty(campaign._id)}>
                            <DeleteOutline/>
                            Törlés
                        </button>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xl={8}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#e8f9f9",
                    }}
                  >
                    <CampaignOutlined
                      sx={{ fontSize: "18px", color: "#148b8b" }}
                    />
                  </Avatar>
                  <Typography className="t_oktelo_bold size_20 darkblue">
                    {campaign.title}
                  </Typography>
                </Stack>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {campaign.description}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  {getStatusTag(campaign.status)}
                  {campaign.tag && (
                    <Tag
                      color="default"
                      style={{
                        border: "1px solid",
                        borderColor: campaign.tag.color,
                        color: campaign.tag.color,
                      }}
                      className="tag_oktelo size_14 t_oktelo_medium"
                      icon={<TagOutlined />}
                    >
                      {campaign.tag.title}
                    </Tag>
                  )}
                </Stack>
              </Stack>
            </Box>
            <Box className="oktelo-box" mt={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#f5eeff",
                  }}
                >
                  <AccessTimeOutlined
                    sx={{ fontSize: "18px", color: "#8f4fe5" }}
                  />
                </Avatar>
                <Typography className="t_oktelo_semibold size_18 darkblue">
                  Ütemezés
                </Typography>
              </Stack>
              <Grid container spacing={2} mt={1}>
                <Grid item xl={12}>
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Stack direction="row" gap={1} alignItems="center">
                        <Circle
                          sx={{
                            color: "rgba(85, 229, 131, 0.3)",
                            fontSize: "16px",
                          }}
                        />
                        <Typography className="t_oktelo_normal size_14 darkblue">
                          <span className="t_oktelo_medium">Kezdés:</span>{" "}
                          {dayjs(campaign.startDate).format("YYYY.MM.DD HH:mm")}
                        </Typography>
                      </Stack>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Circle
                          sx={{
                            color: "rgba(229, 85, 183, 0.3)",
                            fontSize: "16px",
                          }}
                        />
                        <Typography className="t_oktelo_normal size_14 darkblue">
                          <span className="t_oktelo_medium">Befejezés:</span>{" "}
                          {dayjs(campaign.endDate).format("YYYY.MM.DD HH:mm")}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      <span className="t_oktelo_medium">Intervallum:</span>{" "}
                      {campaign.scheduleInterval}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xl={12} className="cal-holder">
                  <Calendar
                    fullscreen={false}
                    style={calStyle}
                    cellRender={dateCellRender}
                    locale={customLocale}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="oktelo-box" mt={2}>
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#fceef7",
                    }}
                  >
                    <TipsAndUpdatesOutlined
                      sx={{ fontSize: "18px", color: "#ce4ca4" }}
                    />
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_18 darkblue">
                    Napló ({campaign.logCount})
                  </Typography>
                </Stack>

                {campaign.logs && campaign.logs.length > 0 ? (
                  campaign.logs.map((log) => (
                    <Stack
                      direction="column"
                      gap={0.5}
                      p={1}
                      key={dayjs(log.date).format("LLL")}
                      mb={2}
                      sx={{ borderRadius: "6px", backgroundColor: "#f6f6f6" }}
                    >
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography className="t_oktelo_semibold size_14 darkblue">
                          {log.email || "Ismeretlen"}
                        </Typography>
                        <Typography
                          className="t_oktelo_light size_12"
                          sx={{ color: "#606060" }}
                        >
                          {dayjs(log.date).format("YYYY.MM.DD HH:mm")}
                        </Typography>
                      </Stack>
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        {log.message}
                      </Typography>
                    </Stack>
                  ))
                ) : (
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    {" "}
                    Nincsenek történések.{" "}
                  </Typography>
                )}
              </Stack>
            </Box>
          </Grid>
          
          <Grid item xl={4} md={12}>
            <Box className="oktelo-box">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#e6eef5",
                    }}
                  >
                    <ChecklistOutlined
                      sx={{ fontSize: "18px", color: "#0a4f8c" }}
                    />
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_18 darkblue">
                    Posztok ({campaign && campaign.posts ? campaign.posts.length : 0})
                  </Typography>
                </Stack>
                {campaign.posts.map((post) => (
                  <Stack
                    key={post._id}
                    direction="column"
                    alignItems="flex-start"
                    gap={1.5}
                    sx={{
                      border: "1px solid",
                      borderColor:
                        post.parent && post.parent.id ? "#d9d9d9" : "#ec5e90",
                      borderRadius: "4px",
                      padding: "12px",
                      backgroundColor:
                        post.parent && post.parent.id
                          ? "transparent"
                          : "#fdf3f6",
                    }}
                  >
                    {post.platform === "instagram" ? (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Instagram
                          sx={{ fontSize: "14px", color: "#E4405F" }}
                        />
                        <Typography
                          className="size_12 t_oktelo_medium"
                          sx={{
                            color:
                              post.parent && post.parent.id
                                ? "#1e1e47"
                                : "#ec5e90",
                          }}
                        >
                          {post.parent?.name || "Nincs kapcsolva"}
                        </Typography>
                      </Stack>
                    ) : post.platform === "facebook" ? (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Facebook sx={{ fontSize: "14px", color: "#1e90ff" }} />
                        <Typography
                          className="size_12 t_oktelo_medium"
                          sx={{
                            color:
                              post.parent && post.parent.id
                                ? "#1e1e47"
                                : "#ec5e90",
                          }}
                        >
                          {post.parent?.name || "Nincs kapcsolva"}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography className="size_12 darkblue t_oktelo_medium">
                        Egyéb
                      </Typography>
                    )}
                    <Typography className="t_oktelo_semibold size_14 darkblue">
                      {post.message
                        ? post.message.slice(0, 48) + "..."
                        : "Nincs szöveg"}
                    </Typography>
                    <Stack direction="row" gap={1} alignItems="center">
                      <ScheduleOutlined
                        sx={{ fontSize: "14px", color: "#925FFF" }}
                      />
                      <Typography className="t_oktelo_normal size_14 t_purple">
                        {dayjs(post.publish.date ? post.publish.date : post.calendarDate).format("YYYY.MM.DD HH:mm")}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Grid>
          <Grid item xl={12}>
            <CampaignTrack posts={campaign.posts} track={postTrack}/>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    if (error) {
      return <>Hiba történt</>;
    } else if (loading) {
      return <>Betöltés..</>;
    } else {
      return <>Oktleo</>;
    }
  }
};

const CampaignTrack: React.FC<CampaignTrackProps> = ({ posts, track }) => {

  const sumTrackValues = {
    impressions: track.map(t => t.metrics.impressions).reduce((sum, value) => sum + value, 0) || 0,
    clicks: track.map(t => t.metrics.clicks).reduce((sum, value) => sum + value, 0) || 0,
    reach: track.map(t => t.metrics.reach).reduce((sum, value) => sum + value, 0) || 0,
    reactions: track.map(t => t.metrics.reactions).reduce((sum, value) => sum + value, 0) || 0,
    shares: track.map(t => t.metrics.shares).reduce((sum, value) => sum + value, 0) || 0,
  };

  const rateTrackValues = {
    engagementRate: (sumTrackValues.reactions / sumTrackValues.reach) * 100 || 0,
    clickRate: (sumTrackValues.clicks / sumTrackValues.impressions) * 100 || 0,
    viralityRate: (sumTrackValues.shares / sumTrackValues.impressions) * 100 || 0,
    frequency: (sumTrackValues.impressions / sumTrackValues.reach) || 0,
    reachEfficency: (sumTrackValues.reach / sumTrackValues.impressions) * 100 || 0,
  }
  

  return (
    <Box className="oktelo-box" mt={2}>
      <Stack direction="row" gap={1} alignItems="center">
        <Avatar
          sx={{
            width: "30px",
            height: "30px",
            backgroundColor: "#f5eeff",
          }}
        >
          <Insights
            sx={{ fontSize: "18px", color: "#8f4fe5" }}
          />
        </Avatar>
        <Typography className="t_oktelo_semibold size_18 darkblue">
          Kampány aggregált teljesítmény
        </Typography>
      </Stack>
      <Grid container spacing={2} mt={2}>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kampány impressziók
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {sumTrackValues.impressions}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kampány reakciók
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {sumTrackValues.reactions}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kampány elérés
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {sumTrackValues.reach}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kampány interakciók
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {sumTrackValues.clicks}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kampány megosztások
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {sumTrackValues.shares}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={12}>
            <Typography className="t_oktelo_medium size_16 darkblue">
              Kalkulált mutatók
            </Typography>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Reakció ráta
              </Typography>
              <Typography className="t_oktelo_light miscgray size_12">
                reakciók / elérés
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {rateTrackValues.engagementRate.toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kattintási ráta
              </Typography>
              <Typography className="t_oktelo_light miscgray size_12">
                interakciók / impressziók
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {rateTrackValues.clickRate.toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Virality ráta
              </Typography>
              <Typography className="t_oktelo_light miscgray size_12">
                megosztások / impressziók
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {rateTrackValues.viralityRate.toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Frekvencia
              </Typography>
              <Typography className="t_oktelo_light miscgray size_12">
                impressziók / elérés
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {rateTrackValues.frequency.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.4}>
            <Box sx={{ padding: '18px', border: '1px solid #d9d9d9' }}>
              <Typography className="t_oktelo_medium size_12 darkblue">
                Elérési hatékonyság
              </Typography>
              <Typography className="t_oktelo_light miscgray size_12">
                elérések / impressziók
              </Typography>
              <Typography className="t_oktelo_semibold size_24 darkblue">
                 {rateTrackValues.reachEfficency.toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
      </Grid>
    </Box>
  )
}

export default CampaignDisplay;
