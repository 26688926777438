import { Box, Typography, Button, Stack } from "@mui/material";
import { oktelo } from "assets";
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
const navigate = useNavigate();
    return (
        <Box width="100%" display="flex" alignContent="center" alignItems="center" justifyContent="center" height="80vh">
            <Stack direction="column" gap={3} alignContent="center" alignItems="center">
                <img src={oktelo} width="240px"/>
                <Typography className="t_main_title">
                    Az oldal nem található!
                </Typography>
                <Typography className="t_oktelo t_desc">
                    Hoppá! A keresett oldalt nem található. 
                </Typography>
                <Button className="oktelo_card_button" onClick={()=>navigate('/home')}>
                    Vezérlőpult
                </Button>
            </Stack>
        </Box>
    )
}

export default ErrorPage;