import React from 'react';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import { Card, Typography, Box, Grid } from '@mui/material';
import { Tag } from 'antd';

interface Metric {
    title: string;
    description: string;
    name: string;
  }
  
  interface ChartValue {
    end_time: string;
    value: number | null | { [key: string]: number } | { [key: string]: null };
  }
  
  interface MetricCardsProps {
    metric: Metric;
    index: number;
    sortedValues: ChartValue[];
  }

  const MetricCards: React.FC<MetricCardsProps> = ({ metric, index, sortedValues }) => {
    const calculateDifference = (values: ChartValue[]) => {
        const initialValue = values[0].value;
        const finalValue = values[values.length - 1].value;
      
        if (initialValue !== null && finalValue !== null) {
          if (finalValue > initialValue) {
            return "green";
          }
          if (finalValue < initialValue) {
            return "red";
          }
        }
        return "gray"; // Default color
      };

      const calculatePercentageChange = (values: ChartValue[]) => {
        const initialValue = values[0].value;
        const finalValue = values[values.length - 1].value;
      
        if (initialValue !== null && finalValue !== null && typeof initialValue === 'number' && typeof finalValue === 'number') {
          if (initialValue === 0) {
            // Prevent division by zero
            return finalValue === 0 ? "0.00" : "Infinity";
          } else {
            const percentageChange = ((finalValue - initialValue) / initialValue) * 100;
            return percentageChange.toFixed(2);
          }
        }
        return 'N/A';
      };

  return (

      <Card
        sx={{
          padding: '8px',
          borderRadius: '12px',
           backgroundColor: "#FFFFFF",
          border: '0',
          boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
          "&:hover": {
            boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
          }, 
          cursor: "pointer",
          height: "100% !important",
          display: "flex", // Ensure card content is flex
          flexDirection: "column", // Stack card content vertically
          
        }}
      >
        <Box p={2} flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch"  className="b_wp" height="100%">
          <Typography className="t_oktelo t_secondary_title" mb={1}>
            {metric.title && metric.name === "page_video_view_time"? "Total Video View Time (Seconds)" : metric.title}
          </Typography>
          <Typography className="t_oktelo t_misc" mb={2}>
            {metric.description}
          </Typography>
          {sortedValues.length > 0 && (
            <Box display="flex" justifyContent="flex-start">
              <Tag>{dayjs(sortedValues[0].end_time).format('LLL')}</Tag>
              <Tag>{dayjs(sortedValues[sortedValues.length - 1].end_time).format('LLL')}</Tag>
              <Tag color={calculateDifference(sortedValues)}>{calculatePercentageChange(sortedValues)}%</Tag>
            </Box>
          )}
          {sortedValues.length > 0 && (
            <Chart
              options={{
                chart: {
                  id: `chart-${index}`,
                },
                xaxis: {
                  type: 'datetime',
                },
                yaxis: {
                  show: true,
                  axisTicks: {
                    show: true,
                    color: '#78909C',
                    width: 6,
                    offsetX: 0,
                    offsetY: 0
                  },
                  title: {
                    text: undefined,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: '12px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                  crosshairs: {
                    show: true,
                    position: 'back',
                    stroke: {
                      color: '#989898',
                      width: 1,
                      dashArray: 1,
                    },
                  },
                  tooltip: {
                    enabled: true,
                    offsetX: 0,
                  },
                  labels: {
                    formatter: function (val) {
                      return val.toFixed(0);
                    },
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: ['#925FFF'],
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 400,
                      cssClass: 'apexcharts-yaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                  },
                },
                colors: ['#925FFF'],
              }}
              series={[
                {
                  name: 'Value',
                  data: sortedValues.map((item) => ({
                    x: new Date(item.end_time).getTime(),
                    y: metric.name === "page_video_view_time"
    ? (typeof item.value === 'object' && item.value !== null && Object.values(item.value)[0] !== null
        ? Math.round((Object.values(item.value)[0] as number) / 1000)
        : typeof item.value === 'number' && item.value !== null
            ? Math.round(item.value / 1000)
            : 0
    )
    : typeof item.value === 'object' && item.value !== null && Object.values(item.value)[0] !== null
        ? Math.round(Object.values(item.value)[0] as number)
        : typeof item.value === 'number' && item.value !== null
            ? Math.round(item.value)
            : 0


                
                  })),
                  color:
                    metric.title === "Daily Unlikes" ? "#f36788" :
                      metric.title === "Lifetime Total Likes" ? "#1fd9ad" :
                        metric.title === "Daily Total Video Views" ? "#ffc106" :
                          "#925FFF",
                },
              ]}
              type="line"
            />
          )}
        </Box>
      </Card>

  );
};

export default MetricCards;
