import React, { useState, useEffect, ReactNode, useRef } from "react";
import {
  Grid,
  Box,
  Stack,
  Typography,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./postanalytics.css";
import {
  VisibilityOutlined,
  PeopleOutline,
  ThumbUpOutlined,
  CommentOutlined,
  TouchAppOutlined,
  FavoriteBorderOutlined,
  AttachMoney,
  PhotoCamera,
  PlayCircleOutline,
  LinkOutlined,
  ShareOutlined,
  MoreHorizOutlined,
  PersonOutline,
  PageviewOutlined,
  EventOutlined,
  BookmarkAddOutlined,
  ExpandMoreOutlined,
  CheckOutlined,
  WarningOutlined,
  ErrorOutline,
  ReplyRounded,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  AutoFixHigh,
  EditOutlined,
  DeleteOutline,
  TagOutlined,
  NotesOutlined,
  BoltOutlined,
  Settings,
  SaveOutlined,
  AddReactionOutlined,
  VideoCameraFrontOutlined,
  WatchLaterOutlined,
  RestoreOutlined,
} from "@mui/icons-material";
import {
  okt_angryicon,
  okt_hahaicon,
  okt_hearticon,
  okt_likeicon,
  okt_sadicon,
  okt_wowicon,
} from "assets";
import { Input, Popconfirm, Tooltip } from "antd";
import dayjs from "dayjs";
import { useNotification } from "@refinedev/core";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import apiconfig from "config";

const { TextArea } = Input;

type MainPostData = {
  id: string;
  message: string;
  created_time: string;
};

type MainMetrics = {
  impressions: number;
  reach: number;
  engagement: number;
  clicks: number;
  reactions: number;
  comments: number;
};

type MainAttachments = {
  title?: string; //sometimes have a title
  type: string; //the type of the attachment
  urls: string[]; //[0] if single img/video [..] for album etc
};

type MainReactions = {
  like: number;
  haha: number;
  love: number;
  wow: number;
  anger: number;
  sorry: number;
};

interface ClickType {
  link_clicks: number;
  photo_view: number;
  video_play: number;
  like: number;
  comment: number;
  share: number;
  other_clicks: number;
  profile: number;
  page: number;
  event: number;
  link_preview: number;
  play_inline: number;
  view_permalink: number;
  view_photos: number;
  save: number;
  see_more: number;
}

interface ClickTypeConfig {
  label: string;
  bgcolor: string;
  icon: ReactNode;
  avatarColor: string;
}

type ImpressionsType = {
  total: number;
  paid: number;
  unpaid: number;
};

type MiscMetrics = {
  engaged_fan: number;
  impressions_fan: number;
  impressions_type: ImpressionsType;
  click_type: Partial<ClickType>;
};

type MetricType = "engagementRate" | "ctr" | "reactionRate" | "commentRate";

interface Thresholds {
  [key: string]: {
    good: number;
    medium: number;
    bad: number;
  };
}

interface CommentAttachment {
  media: {
    image?: {
      height: number;
      src: string;
      width: number;
    };
    source?: string;
  };
  target?: {
    id?: string;
    url: string;
  };
  title?: string;
  type: string;
  url?: string;
}

interface Reply {
  timestamp: string;
  text: string;
  id: string;
}

interface Comment {
  comment_count?: number;
  like_count?: number;
  parent?: Comment;
  attachment?: CommentAttachment;
  from: {
    name: string;
    id: string;
    username?: string;
  };
  created_time: string;
  timestamp?: string;
  message: string;
  text?: string;
  id: string;
  replies?: { data: Reply[] };
}

interface CommentData {
  comments: Comment[];
}


const COMMENTS_PER_PAGE = 5;

const SocialPostAnalytics = () => {
  const location = useLocation();
  const { post, sp, platform } = location.state; //post => only need message, created_time, id
  const [isLoading, setLoading] = useState(false);
  const [mainPostInfo, setMainPostInfo] = useState<MainPostData>();
  const [mainMetrics, setMainMetrics] = useState<MainMetrics | null>(null);
  const [mainReactions, setMainReactions] = useState<MainReactions>();
  const [mainHashtags, setMainHashtags] = useState<string>("");
  const [mainAttachments, setMainAttachments] = useState<MainAttachments>();
  const [miscMetrics, setMiscMetrics] = useState<MiscMetrics | null>(null);
  const [commentData, setCommentData] = useState<CommentData>();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [newPostMessage, setNewPostMessage] = useState<string>('');
  const [isMessageEditOpen, setMessageEditOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<Comment>();
  const [commentAction, setCommentAction] = useState<string>("");
  const [replyMessage, setReplyMessage] = useState<string>("");
  const [nrf, snrf] = useState(false);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { open } = useNotification();
  const navigate = useNavigate();
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const mainMetricsMap: Record<
    string,
    { label: string; icon: JSX.Element; bgcolor: string }
  > = {
    impressions: {
      label: "Impressziók",
      icon: <VisibilityOutlined sx={{ color: "#e583be" }} />,
      bgcolor: "#ffdef2",
    },
    reach: {
      label: "Elérés",
      icon: <PeopleOutline sx={{ color: "#bb87e5" }} />,
      bgcolor: "#f2e2ff",
    },
    engagement: {
      label: "Elköteleződés",
      icon: <FavoriteBorderOutlined sx={{ color: "#87ade5" }} />,
      bgcolor: "#e2eeff",
    },
    clicks: {
      label: "Kattintások",
      icon: <TouchAppOutlined sx={{ color: "#82e5dc" }} />,
      bgcolor: "#ddfffc",
    },
    follows: {
      label: "Bekötvetések",
      icon: <TouchAppOutlined sx={{ color: "#82e5dc" }} />,
      bgcolor: "#ddfffc",
    },
    reactions: {
      label: "Reakciók",
      icon: <ThumbUpOutlined sx={{ color: "#cccc78" }} />,
      bgcolor: "#ffffe3",
    }, 
    comments: {
      label: "Kommentek",
      icon: <CommentOutlined sx={{ color: "#8be588" }} />,
      bgcolor: "#e5ffe4",
    },
    video_views: {
      label: "Videó megtekintések",
      icon: <VideoCameraFrontOutlined sx={{ color: "#cc843c" }} />,
      bgcolor: "#fff9f4",
    },
    saved: {
      label: "Mentés",
      icon: <BookmarkAddOutlined sx={{ color: "#3ccc84" }} />,
      bgcolor: "#edfff6",
    }, 
    avg_watch_time: {
      label: "Átl. nézési idő (mp)",
      icon: <RestoreOutlined sx={{ color: "#3ccc84" }} />,
      bgcolor: "#edfff6",
    }, 
    total_watch_time: {
      label: "Teljes nézési idő (mp)",
      icon: <WatchLaterOutlined sx={{ color: "#3ccc84" }} />,
      bgcolor: "#edfff6",
    }, 
    plays: {
      label: "Lejátszások",
      icon: <PlayCircleOutline sx={{ color: "#3ccc84" }} />,
      bgcolor: "#edfff6",
    },
  }; 

  const clickTypeConfig: Record<keyof ClickType, ClickTypeConfig> = {
    link_clicks: {
      label: "Link kattintás",
      bgcolor: "#e2eeff",
      icon: <LinkOutlined sx={{ color: "#87ade5" }} />,
      avatarColor: "#e2eeff",
    },
    photo_view: {
      label: "Fotó megnézés",
      bgcolor: "#fff5e2",
      icon: <PhotoCamera sx={{ color: "#e5b087" }} />,
      avatarColor: "#fff5e2",
    },
    video_play: {
      label: "Video lejátszás",
      bgcolor: "#e2ffee",
      icon: <PlayCircleOutline sx={{ color: "#87e5ad" }} />,
      avatarColor: "#e2ffee",
    },
    like: {
      label: "Like",
      bgcolor: "#e2eeff",
      icon: <FavoriteBorderOutlined sx={{ color: "#87ade5" }} />,
      avatarColor: "#e2eeff",
    },
    // Add more click types as needed
    comment: {
      label: "Komment",
      bgcolor: "#ffebee",
      icon: <CommentOutlined sx={{ color: "#e57373" }} />,
      avatarColor: "#ffebee",
    },
    share: {
      label: "Megosztás",
      bgcolor: "#e8f5e9",
      icon: <ShareOutlined sx={{ color: "#66bb6a" }} />,
      avatarColor: "#e8f5e9",
    },
    other_clicks: {
      label: "Egyéb",
      bgcolor: "#f3e5f5",
      icon: <MoreHorizOutlined sx={{ color: "#ab47bc" }} />,
      avatarColor: "#f3e5f5",
    },
    profile: {
      label: "Profil megnézés",
      bgcolor: "#fce4ec",
      icon: <PersonOutline sx={{ color: "#ec407a" }} />,
      avatarColor: "#fce4ec",
    },
    page: {
      label: "Oldal megnézés",
      bgcolor: "#e3f2fd",
      icon: <PageviewOutlined sx={{ color: "#42a5f5" }} />,
      avatarColor: "#e3f2fd",
    },
    event: {
      label: "Esemény",
      bgcolor: "#fff3e0",
      icon: <EventOutlined sx={{ color: "#ffb74d" }} />,
      avatarColor: "#fff3e0",
    },
    link_preview: {
      label: "Link előnézet",
      bgcolor: "#e0f7fa",
      icon: <LinkOutlined sx={{ color: "#00acc1" }} />,
      avatarColor: "#e0f7fa",
    },
    play_inline: {
      label: "Lejátszás",
      bgcolor: "#ede7f6",
      icon: <PlayCircleOutline sx={{ color: "#7e57c2" }} />,
      avatarColor: "#ede7f6",
    },
    view_permalink: {
      label: "Permalink megnézés",
      bgcolor: "#f3e5f5",
      icon: <LinkOutlined sx={{ color: "#ab47bc" }} />,
      avatarColor: "#f3e5f5",
    },
    view_photos: {
      label: "Fotók megnézése",
      bgcolor: "#e1f5fe",
      icon: <PhotoCamera sx={{ color: "#29b6f6" }} />,
      avatarColor: "#e1f5fe",
    },
    save: {
      label: "Mentés",
      bgcolor: "#f9fbe7",
      icon: <BookmarkAddOutlined sx={{ color: "#cddc39" }} />,
      avatarColor: "#f9fbe7",
    },
    see_more: {
      label: "Továbbiak mutatása",
      bgcolor: "#fffde7",
      icon: <ExpandMoreOutlined sx={{ color: "#ffeb3b" }} />,
      avatarColor: "#fffde7",
    },
  };

  const mainReactionsMap: Record<
    string,
    { label: string; icon: JSX.Element; bgcolor: string }
  > = {
    like: {
      label: "Like",
      icon: <img src={okt_likeicon} alt="like reaction"/>,
      bgcolor: "#e7f0ff",
    },
    haha: {
      label: "Vicces",
      icon: <img src={okt_hahaicon} alt="haha reaction" />,
      bgcolor: "#ffffe7",
    },
    love: {
      label: "Szív",
      icon: <img src={okt_hearticon} alt="love reaction"/>,
      bgcolor: "#fff3fc",
    },
    wow: { label: "Hűha", icon: <img src={okt_wowicon} alt="wow reaction"/>, bgcolor: "#F3FFE7" },
    sorry: {
      label: "Szomorú",
      icon: <img src={okt_sadicon} alt="sad reaction"/>,
      bgcolor: "#f3e7ff",
    },
    anger: {
      label: "Dühítő",
      icon: <img src={okt_angryicon} alt="anger reaction"/>,
      bgcolor: "#fff3e7",
    },
  };

  const thresholds: Thresholds = {
    engagementRate: {
      good: 20, // Good if engagement rate is 20% or above
      medium: 10, // Medium if engagement rate is between 10% and 19.9%
      bad: 0, // Bad if engagement rate is below 10%
    },
    ctr: {
      good: 2, // Good if CTR is 2% or above
      medium: 1, // Medium if CTR is between 1% and 1.99%
      bad: 0, // Bad if CTR is below 1%
    },
    reactionRate: {
      good: 5,
      medium: 2,
      bad: 0,
    },
    commentRate: {
      good: 2,
      medium: 1,
      bad: 0,
    },
  };

  const getIndicator = (value: number, type: MetricType) => {
    const { good, medium } = thresholds[type];
    if (value >= good) {
      return {
        color: "#8be588",
        icon: <CheckOutlined sx={{ color: "#8be588" }} />,
      }; // Use a green check icon for good
    } else if (value >= medium) {
      return {
        color: "#ff925f",
        icon: <WarningOutlined sx={{ color: "#ff925f" }} />,
      }; // Use an orange warning icon for medium
    } else {
      return {
        color: "#ff5f7b",
        icon: <ErrorOutline sx={{ color: "#ff5f7b" }} />,
      }; // Use a red cross icon for bad
    }
  };

  const rMainMentrics = (metric: string) =>
    mainMetricsMap[metric] || { label: "", icon: null, color: "" };
  const rMainReactions = (reaction: string) =>
    mainReactionsMap[reaction] || { label: "", icon: null, color: "" };

  useEffect(() => {
    const fetchData = async () => {
      if (post) {
        try {
          /* setLoading(true); */

          const response = await axios.post(
            `${apiconfig.BACKEND_URI}/socialdata/postanalytics`,
            {
              postid: post.id,
              page: sp,
              platform: platform,
            }
          );

          const _hashtags = getHashtags(post.message);
          setMainHashtags(_hashtags);

          setMainPostInfo({
            id: post.id,
            message: post.message,
            created_time: post.created_time,
          });
          setNewPostMessage(post.message);
          const comments = response.data.comments;
          setCommentData({ comments });
          setMainMetrics(response.data.mainMetrics);
          setMainAttachments(response.data.attachment);
          setMainReactions(response.data.mainReactions);
          setMiscMetrics(response.data.miscMetrics);

          if (comments) {
            const tp = Math.ceil(
              comments.filter((comment: any) => !comment.parent).length /
                COMMENTS_PER_PAGE
            );
            setTotalPages(tp);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [post, sp, platform, nrf]);

  const getHashtags = (message: string) => {
    const hashtagRegex = /#([\p{L}\p{N}_]+)/gu;
    const hashtags = message.match(hashtagRegex);
    if (hashtags && hashtags.length > 0) {
      return hashtags.map((tag) => tag.toLowerCase()).join(", ");
    } else {
      return "Nincs hashtag";
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const emojiToAdd = emojiData.emoji;
    setNewPostMessage((prevValue) => prevValue + emojiToAdd);
  };

  if (!post) {
    return <div>Hiba. A poszt nem található.</div>; // Handle case where post is not defined
  }

  const deletePost = async () => {
    try {
      // Send delete request to backend
      const response = await axios.post(`${apiconfig.BACKEND_URI}/socialdata/deletepost`, {
        post_id: post.id,
        page_id: sp,
        platform: platform,
      });
  
      // Check response status
      if (response.status === 200) {
        // Show success message and navigate to another page
        open?.({
          type: 'success',
          message: 'Post deleted successfully!',
        });
        return navigate('/posts/social');
      }  else {
        // Show generic error message
        open?.({
          type: 'error',
          message: 'An unexpected error occurred!',
        });
      }
  
    } catch (error: any) {
      // Handle network or unexpected errors
      console.error('Error deleting post:', error);
      
      // Show error message based on the error type
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400 && data.message) {
          open?.({
            type: 'error',
            message: data.message,
          });
        } else {
          open?.({
            type: 'error',
            message: 'Váratlan hiba történt.',
          });
        }
      } else {
        open?.({
          type: 'error',
          message: 'Lassú a Facebook kapcsolat, hiba történt.',
        });
      }
    }
  }
  
  const modifyPostText = async () => {
    try {
      
      const response = await axios.post(`${apiconfig.BACKEND_URI}/socialdata/modifypost`, {
        post_id: post.id,
        page_id: sp,
        platform: platform,
        message: newPostMessage
      });

      if (response.status === 200) {
        return open?.({
          type: 'success',
          message: 'Frissítetted a poszt szövegét!'
        })
      }

      else {
        return open?.({
          type: 'error',
          message: 'Hiba történt!'
        })
      }

    } catch (error) {
      console.log(error);
    } finally {
      snrf(!nrf);
      setEmojiPickerOpen(false);
      setMessageEditOpen(false);
    }
  }

  const postCommentReply = async (type: string, sc: string) => {
/*     setLoading(true); */
    let send_data = {};
    if(selectedComment && sp && post && post.id && platform && replyMessage )
    {
        send_data = {
            msg_content: replyMessage,
            comment_id: selectedComment.id,
            page_id: sp,
            post_platform: platform,
            post_id: post.id, 
            comment_type: type,
        }
    }
    else {
      return open?.({
        type: "error",
        message: "Hiba történt",
      });
    }
    
    try {
        const response = await axios.post(`${apiconfig.BACKEND_URI}/socialdata/postcomment`, send_data);
        
        if(response.status===200)
            {
                open?.({
                    type: "success",
                    message: "Sikeresen válaszoltál egy kommentre!",
                    description: "Jó hírek!",
                  });
            }
    } catch (error) {
        // Handle errors
        console.error('Error:', error);
    }
    finally{
        setReplyMessage('');
        setLoading(false);
        snrf(!nrf);
        setCommentAction("");
        setSelectedComment(undefined);
    }
};

  const handleDeleteComment = async (sc: string) => {
    try {
      /* setLoading(true); */
      let send_data = {};

      console.log(sc, post, sp);

      if (sc && post && sp) {
        send_data = {
          comment_id: sc,
          page_id: sp,
        };
      } else {
        return open?.({
          type: "error",
          message: "Nincs komment kiválasztva",
        });
      }

      const response = await axios.post(
        `${apiconfig.BACKEND_URI}/socialdata/delcomment`,
        send_data
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeresen töröltél egy kommentet.",
          description: "Siker!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setSelectedComment(undefined);
      setCommentAction("");
      snrf(!nrf);
    }
  };

  const renderComments = (comments: Comment[]) => {
    const mainComments = comments.filter((comment) => !comment.parent);
    if (!mainComments || mainComments.length <= 0) {
      return (
        <Typography className="t_oktelo_normal size_14 darkblue" p={3}>
          Nincsenek megjegyzések.
        </Typography> 
      ); 
    }

    const startIndex = (currentPage - 1) * COMMENTS_PER_PAGE;
    const endIndex = startIndex + COMMENTS_PER_PAGE;
    const paginatedComments = mainComments.slice(startIndex, endIndex);

    return paginatedComments.map((mainComment, mainId) => (
      <Grid item xl={12} xs={12} p={2} key={mainId}>
        <Stack
          direction="column"
          mb={1}
          width="100%"
          alignItems="flex-start"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            border: selectedComment && selectedComment.id === mainComment.id ? "2px solid" : "0",
            borderLeft: "2px solid",
            transition: "border 0.1s",
            borderColor: "#6c28ff",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
            gap={1}
          >
            <Stack direction="column" gap={0}>
              <Typography className="size_14 t_oktelo_medium darkblue">
                {mainComment.from
                  ? mainComment.from.name ||
                    mainComment.from.username ||
                    "Felhasználó"
                  : "Felhasználó"}
              </Typography>
              <Typography className="size_12 t_oktelo_normal t_purple">
                {mainComment.created_time
                  ? dayjs(mainComment.created_time).format("YYYY.MM.DD HH:mm")
                  : mainComment.timestamp
                  ? dayjs(mainComment.timestamp).format("YYYY.MM.DD HH:mm")
                  : "N/A"}
              </Typography>
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="center">
              <button
                className="home-action-button bc6a"
                onClick={() => setSelectedComment(mainComment)}
              >
                <Settings />
              </button>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
            sx={{ marginTop: "8px" }}
          >
            {mainComment.attachment?.media?.image?.src && (
              <img
                src={mainComment.attachment.media.image.src}
                alt="attachment"
                className="attachment-comment"
              />
            )}
            <Typography
              className="t_oktelo_normal size_14 darkblue"
              sx={{ textAlign: "left" }}
            > 
              {mainComment.message || mainComment.text}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={0.5}
            alignItems="center"
            mt={1.5}
            justifyContent="flex-start"
            sx={{
              backgroundColor: "#f3f7ff",
              borderRadius: "6px",
              padding: "2px 8px",
              width: "auto",
            }}
          >
            <ThumbUpOutlined sx={{ color: "#1e90ff", fontSize: "14px" }} />
            <Typography className="size_12 darkblue t_oktelo_medium">
              {mainComment.like_count}
            </Typography>
          </Stack>
        </Stack>
        {mainComment.replies
          ? mainComment.replies.data.map((comment, id) => (
              <Stack key={id} m={2}>
                <Stack direction="column" gap={2}>
                  <Stack direction="column" gap={0}>
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      Felhasználó
                    </Typography>
                    <Typography className="size_12 t_oktelo_medium t_purple">
                      {comment.timestamp
                        ? dayjs(comment.timestamp).format("YYYY.MM.DD HH:mm")
                        : "N/A"}
                    </Typography>
                  </Stack>
                  <Typography
                    className="t_oktelo_normal size_14 darkblue"
                    sx={{ textAlign: "left" }}
                  >
                    {comment.text || ""}
                  </Typography>
                </Stack>
              </Stack>
            ))
          : renderReplies(mainComment.id, comments)}
      </Grid>
    ));
  };

  const renderReplies = (parentId: string, comments: Comment[]) => {
    const replies = comments.filter(
      (comment) => comment.parent && comment.parent.id === parentId
    );
  
    return (
      <>
        {replies.map((reply, replyId) => (
          <React.Fragment key={reply.id}> {/* Using React.Fragment with unique key */}
            <Stack
              direction="column"
              alignItems="flex-start"
              mt={1}
              ml={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                border: selectedComment && selectedComment.id === reply.id ? "2px solid" : "0",
                borderLeft: "2px solid",
                borderColor: "#d5c0ff",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                width="100%"
              >
                <Stack direction="column" gap={0}>
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <ReplyRounded sx={{ fontSize: "14px" }} />
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      {reply.parent && reply.parent.from
                        ? reply.parent.from.name
                        : reply.from
                        ? reply.from.name
                        : "Felhasználó"}
                    </Typography>
                  </Stack>
                  <Typography className="t_oktelo_normal size_12 t_purple">
                    {dayjs(reply.created_time).format("YYYY.MM.DD HH:mm")}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={0.5} alignItems="center">
                  <button
                    className="home-action-button bc6a"
                    onClick={() => setSelectedComment(reply)}
                  >
                    <Settings /> 
                  </button>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
                sx={{ marginTop: "8px" }}
              >
                {reply.attachment?.media?.image?.src && (
                  <img
                    src={reply.attachment.media.image.src}
                    width="100px"
                    alt="attachment"
                  />
                )}
                <Typography
                  className="t_oktelo_normal size_14 darkblue"
                  sx={{ textAlign: "justify" }}
                >
                  {reply.message}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                gap={0.5}
                alignItems="center"
                mt={1.5}
                justifyContent="flex-start"
                sx={{
                  backgroundColor: "#f3f7ff",
                  borderRadius: "6px",
                  padding: "2px 8px",
                  width: "auto",
                }}
              >
                <ThumbUpOutlined sx={{ color: "#1e90ff", fontSize: "14px" }} />
                <Typography className="size_12 darkblue t_oktelo_medium">
                  {reply.like_count}
                </Typography>
              </Stack>
            </Stack>
            <Box ml={2}>
            {renderReplies(reply.id, comments)}
            </Box>
          </React.Fragment>
        ))}
      </>
    );
  };
  
  
  if (isLoading) {
    return (
      <div>
        <LinearProgress />
        Betöltés
      </div>
    );
  }

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        {mainMetrics &&
          Object.keys(mainMetrics).map((metric) => {
            const { label, icon, bgcolor } = rMainMentrics(metric);
            const count = mainMetrics[metric as keyof MainMetrics];

            return (
              <Grid item xl={2} lg={2} md={4} sm={6} xs={6} key={metric}>
                <Box className="analytics-card">
                  <Stack direction="column" gap={1}>
                    <Typography className="size_14 t_oktelo_normal darkblue">
                      {label}
                    </Typography>
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {count} 
                    </Typography>
                  </Stack>
                  <Avatar
                    className="card-avatar"
                    sx={{ backgroundColor: bgcolor }}
                  >
                    {icon}
                  </Avatar>
                </Box>
              </Grid>
            );
          })}
        {platform === "facebook" &&
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Box className="analytics-card">
            <Stack direction="column" gap={2.5}>
              <Typography className="size_14 t_oktelo_normal darkblue">
                Impressziók típusa (Fizetett/Organikus)
              </Typography>
              {miscMetrics && miscMetrics.impressions_type ? (
                <Stack direction="row" gap={2} alignItems="center">
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                      backgroundColor: "#fff",
                      padding: "4px 8px",
                      borderRadius: "4px",
                    }}
                  >
                    <AttachMoney />
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {miscMetrics.impressions_type.paid}
                    </Typography>
                    <Typography className="size_16 t_oktelo_medium t_purple">
                      {(
                        (miscMetrics.impressions_type.paid /
                          miscMetrics.impressions_type.total) *
                        100
                      ).toFixed(1) || "0"}
                      %
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                      backgroundColor: "#fff",
                      padding: "4px 8px",
                      borderRadius: "4px",
                    }}
                  >
                    <BoltOutlined />
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {miscMetrics.impressions_type.unpaid}
                    </Typography>
                    <Typography className="size_16 t_oktelo_medium t_purple">
                      {(
                        (miscMetrics.impressions_type.unpaid /
                          miscMetrics.impressions_type.total) *
                        100
                      ).toFixed(1) || "0"}
                      %
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Nincs elérhető adat.
                </Typography>
              )}
            </Stack>
          </Box>
        </Grid>}
        {platform === "facebook" &&
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Box className="analytics-card">
            <Stack direction="column" gap={2.5}>
              <Typography className="size_14 t_oktelo_normal darkblue">
                Követők (Impressziók/Elköteleződés)
              </Typography>
              {miscMetrics &&
  miscMetrics.engaged_fan !== undefined &&
  miscMetrics.engaged_fan !== null &&
  miscMetrics.impressions_fan !== undefined &&
  miscMetrics.impressions_fan !== null ? (
                <Stack direction="row" gap={2} alignItems="center">
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                      backgroundColor: "#fff",
                      padding: "4px 8px",
                      borderRadius: "6px",
                    }}
                  >
                    <VisibilityOutlined />
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {miscMetrics.impressions_fan}
                    </Typography>
                    <Typography className="size_16 t_oktelo_medium t_purple">
                      {(
                        (miscMetrics.impressions_fan /
                          miscMetrics.impressions_type.total) *
                        100
                      ).toFixed(1) || "0"}
                      %
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                      backgroundColor: "#fff",
                      padding: "4px 8px",
                      borderRadius: "6px",
                    }}
                  >
                    <FavoriteBorderOutlined />
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {miscMetrics.engaged_fan}
                    </Typography>
                    {mainMetrics && mainMetrics.engagement && (
                      <Typography className="size_16 t_oktelo_medium t_purple">
                        {(
                          (miscMetrics.engaged_fan / mainMetrics?.engagement) *
                          100
                        ).toFixed(1) || "0"}
                        %
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Nincs elérhető adat.
                </Typography>
              )}
            </Stack>
          </Box>
        </Grid>}
        {platform === "facebook" &&
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Box className="analytics-card" height="100%">
            <Stack direction="column" gap={2.5}>
              <Typography className="size_14 t_oktelo_normal darkblue">
                Kattintások eloszlása
              </Typography>
              {miscMetrics &&
              miscMetrics.click_type &&
              Object.keys(miscMetrics.click_type).length > 0 ? (
                <Stack direction="row" gap={2} alignItems="center">
                  {Object.keys(miscMetrics.click_type).map((key) => {
                    const formattedKey = key.replace(
                      /\s+/g,
                      "_"
                    ) as keyof ClickType;
                    const clickData = clickTypeConfig[formattedKey];
                    if (!clickData) return null;

                    return (
                      <Tooltip title={clickData.label} key={key}>
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          flexWrap="wrap"
                          sx={{
                            backgroundColor: "#fff",
                            padding: "4px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: "30px",
                              height: "30px",
                              backgroundColor: clickData.avatarColor,
                            }}
                          >
                            {clickData.icon}
                          </Avatar>
                          <Typography className="size_20 t_oktelo_bold darkblue">
                            {miscMetrics.click_type[formattedKey]}
                          </Typography>
                          {mainMetrics && mainMetrics.clicks && (
                            <Typography className="size_16 t_oktelo_medium darkblue">
                              {(
                                (miscMetrics.click_type[formattedKey]! /
                                  mainMetrics.clicks) *
                                100
                              ).toFixed(1) || "0"}
                              %
                            </Typography>
                          )}
                        </Stack>
                      </Tooltip>
                    );
                  })}
                </Stack>
              ) : (
                <Typography className="t_oktelo_semibold size_14 darkblue">
                  Nincs elérhető adat.
                </Typography>
              )}
            </Stack>
          </Box>
        </Grid>}
      </Grid>
      <Grid container spacing={2} mt={0}>
        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
          <Box className="oktelo_card" height="auto">
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                {mainAttachments ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AttachmentRenderer attachment={mainAttachments} />
                  </Box>
                ) : (
                  <Box>
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      Nincs megjeleníthető média
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  gap={2}
                  height="100%"
                >
                  <Stack direction="column" gap={1}>
                    {mainPostInfo && mainPostInfo.message ? (
                      <Stack
                        direction="column"
                        gap={0.5}
                        sx={{ padding: "8px" }}
                      >
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          justifyContent="flex-start"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            padding: "4px 8px",
                            width: "auto",
                            borderRadius: "4px",
                          }}
                        >
                          <NotesOutlined
                            sx={{ color: "#1E1E47", fontSize: "14px" }}
                          />
                          <Typography className="t_oktelo_medium size_12 darkblue">
                            Üzenet ({mainPostInfo.message.length} karakter)
                          </Typography>
                        </Stack>
                        <Typography
                          className="t_oktelo_normal size_14 darkblue"
                          p={1}
                        >
                          {mainPostInfo.message}
                        </Typography>
                        {isMessageEditOpen &&
                        <Stack direction="column" gap={1}>
                        <TextArea
                        ref={textAreaRef}
                        value={newPostMessage}
                        placeholder={
                          mainPostInfo.message ||
                          "Módosítás.."
                        }
                        rows={5}
                        count={{
                          show: true,
                          max: 500,
                        }}
                        maxLength={500}
                        onChange={(e) => setNewPostMessage(e.target.value)}
                      />
                      <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
                      <button
                          className="emoji-button-f"
                          onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                        >
                          <AddReactionOutlined sx={{ fontSize: "20px" }} />
                        </button>
                        <button
                        className="home-action-button bc6a"
                        onClick={() => modifyPostText()}
                      >
                        <SaveOutlined/>
                        Módosítás
                      </button>
                      </Stack>

                        {emojiPickerOpen && (
                        <EmojiPicker
                          open={emojiPickerOpen}
                          lazyLoadEmojis={true}
                          width="100%"
                          onEmojiClick={handleEmojiClick}
                          style={{ zIndex: "999" }}
                        />
                      )}
                      </Stack>
                        }
                      </Stack>
                    ) : (
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        A bejegyzéshez nem tartozik szöveg.
                      </Typography>
                    )}
                    {mainHashtags ? (
                      <Stack
                        direction="column"
                        gap={0.5}
                        sx={{ padding: "8px" }}
                      >
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          justifyContent="flex-start"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            padding: "4px 8px",
                            width: "auto",
                            borderRadius: "4px",
                          }}
                        >
                          <TagOutlined
                            sx={{ color: "#1E1E47", fontSize: "14px" }}
                          />
                          <Typography className="t_oktelo_medium size_12 darkblue">
                            Hashtagek (
                            {mainHashtags.match(/#[\w]+/g)?.length || 0} db)
                          </Typography>
                        </Stack>
                        <Typography
                          className="t_oktelo_normal size_14 darkblue"
                          p={1}
                        >
                          {mainHashtags}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack direction="row" gap={1} sx={{ padding: "8px" }}>
                    {mainReactions &&
                      Object.keys(mainReactions).map((reaction) => {
                        const { label, icon, bgcolor } =
                          rMainReactions(reaction);
                        const count =
                          mainReactions[reaction as keyof MainReactions];

                        return (
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={0.5}
                            key={reaction}
                            sx={{
                              border: "1px solid #f6f6f6",
                              padding: "4px 8px",
                              borderRadius: "20px",
                            }}
                          >
                            <Tooltip title={label}>
                            <Avatar
                              sx={{
                                backgroundColor: bgcolor,
                                objectFit: "contain",
                              }}
                              className="reactions-avatar"
                            >
                              {icon}
                            </Avatar>
                            </Tooltip>
                            <Typography className="t_oktelo_normal size_12 darkblue">
                              {count}
                            </Typography>
                          </Stack>
                        );
                      })}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Box className="analytics-card">
            <Stack direction="column" gap={2.5} width="100%">
              <Typography className="size_14 t_oktelo_normal darkblue">
                Teljesítmény
              </Typography>
              { mainMetrics ? (
                <Stack direction="column" gap={1.5} width="100%">
                  {/* Engagement Rate */}
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      {
                        getIndicator(
                          (mainMetrics.engagement / mainMetrics.reach) * 100,
                          "engagementRate"
                        ).icon
                      }

                      <Typography className="size_14 t_oktelo_medium darkblue">
                        Elköteleződés aránya
                      </Typography>
                    </Stack>
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {(
                        (mainMetrics.engagement / mainMetrics.reach) *
                        100
                      ).toFixed(1)}
                      %
                    </Typography>
                  </Stack>

                  {/* Click-Through Rate (CTR) */}
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      {
                        getIndicator(
                          ((mainMetrics.clicks || mainMetrics.engagement) / mainMetrics.impressions) * 100,
                          "ctr"
                        ).icon
                      }

                      <Typography className="size_14 t_oktelo_medium darkblue">
                        Kattintási arány (CTR)
                      </Typography>
                    </Stack>
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {(
                        ((mainMetrics.clicks || mainMetrics.engagement) / mainMetrics.impressions) *
                        100
                      ).toFixed(1)}
                      %
                    </Typography>
                  </Stack>

                  {/* Reaction Rate */}
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      {
                        getIndicator(
                          (mainMetrics.reactions / mainMetrics.impressions) *
                            100,
                          "reactionRate"
                        ).icon 
                      }
                      <Typography className="size_14 t_oktelo_medium darkblue">
                        Reakció arány
                      </Typography>
                    </Stack>
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {(
                        (mainMetrics.reactions / mainMetrics.impressions) *
                        100
                      ).toFixed(1)}
                      %
                    </Typography>
                  </Stack>

                  {/* Comment Rate */}
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      {
                        getIndicator(
                          (mainMetrics.comments / mainMetrics.impressions) *
                            100,
                          "commentRate"
                        ).icon
                      }
                      <Typography className="size_14 t_oktelo_medium darkblue">
                        Hozzászólás arány
                      </Typography>
                    </Stack>
                    <Typography className="size_20 t_oktelo_bold darkblue">
                      {(
                        (mainMetrics.comments / mainMetrics.impressions) *
                        100
                      ).toFixed(1)}
                      %
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Nincs elérhető adat.
                </Typography>
              )}
            </Stack>
          </Box>
          <Box className="analytics-card" mt={2}>
            <Stack direction="column" gap={1.5} width="100%">
              <Typography className="size_14 t_oktelo_normal darkblue">
                Poszt műveletek
              </Typography>
              <button className="home-action-button b925">
                <AutoFixHigh />
                Oktelo AI elemzés
              </button>
              <button className="home-action-button b25f"
              disabled={platform === "instagram"}
              onClick={() => setMessageEditOpen(!isMessageEditOpen)}>
                <EditOutlined />
                Szöveg átírása
              </button>
              <button className="home-action-button bf7b"
              onClick={() => setOpenDeleteDialog(!isOpenDeleteDialog)}
              disabled={platform === "instagram"}>
                <DeleteOutline />
                Törlés
              </button>
            </Stack>
             {isOpenDeleteDialog && 
                  <Dialog
                  open={isOpenDeleteDialog}
                  onClose={() => setOpenDeleteDialog(!isOpenDeleteDialog)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  sx={{ backgroundColor: '#f9f9f9 !important'}}
                  >
                  <DialogTitle className="t_oktelo_medium size_18 darkblue">
                    Bejegyzés törlés megerősítése
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText className="t_oktelo_normal size_14 darkblue">
                      Biztosan törölni szeretnéd a bejegyzést? A művelet nem vonható vissza.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <button
                  className="home-action-button bc6a" 
                  onClick={() => setOpenDeleteDialog(!isOpenDeleteDialog)} >
                      Mégsem
                    </button>
                    <button
                    className="home-action-button b925" 
                    onClick={() => deletePost()} autoFocus>Megerősítés</button>
                  </DialogActions>
                </Dialog>
             } 
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
          <Box /* className="analytics-content" */>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              mb={2}
            >
              <Stack direction="column" gap={0}>
                <Typography className="size_20 t_oktelo_bold darkblue">
                  Kommentek ({mainMetrics?.comments})
                </Typography>
                {/*         <Typography className="size_14 t_oktelo_normal darkblue">
          A(z) 50 legfrissebb megjegyzést tudod kezelni.
        </Typography> */}
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                pr={2}
              >
                <button
                  className={`home-action-button ${
                    currentPage === 1 ? "bc6a" : "b925"
                  }`}
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ArrowLeftOutlined />
                </button>
                <Typography className="size_14 t_oktelo_medium darkblue">
                  {currentPage} / {totalPages}
                </Typography>
                <button
                  className={`home-action-button ${
                    currentPage === totalPages || totalPages === 0 ? "bc6a" : "b925"
                  }`}
                  disabled={currentPage === totalPages || totalPages === 0}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ArrowRightOutlined />
                </button>
              </Stack>
            </Stack>

            <Grid container spacing={1}>
              {commentData && renderComments(commentData.comments)}
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Stack direction="column" gap={0} mb={2}>
            <Typography className="size_20 t_oktelo_bold darkblue">
              Megjegyzéskezelő
            </Typography>
            {/*         <Typography className="size_14 t_oktelo_normal darkblue">
          Válasz, módosítás és törlés.
        </Typography> */}
          </Stack>
          {mainMetrics && mainMetrics.comments > 0 ? (
          <Box className="analytics-content">
            {selectedComment ? (
              <Stack direction="column" gap={1}>
                {/*             <Typography className="t_oktelo_normal size_14 darkblue">
              Kiválasztott megjegyzés
            </Typography> */}
                <Typography className="t_oktelo_normal size_14 darkblue">
                  <span className="t_oktelo_medium">
                    {selectedComment.from?.name ?? "Felhasználó"}
                  </span>
                  :{" "}
                  {selectedComment.message?.slice(0, 64) ||
                    selectedComment.text?.slice(0, 64) ||
                    ""}
                </Typography>
                {selectedComment.attachment?.media?.image?.src && (
                  <img
                    src={selectedComment.attachment.media.image.src}
                    alt="attachment-edit"
                    style={{
                      width: "48px",
                      borderRadius: "4px",
                      height: "48px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Stack
                  direction="row"
                  gap={0.5}
                  alignItems="center"
                  mt={1}
                  mb={2}
                >
                  <button
                    className="home-action-button b925"
                    onClick={() => setCommentAction("reply")}
                    style={{ opacity: commentAction === "reply" ? 0.7 : 1 }}
                  >
                    Válasz
                  </button>
                  <button
                    className="home-action-button b25f"
                    onClick={() => setCommentAction("update")}
                    style={{ opacity: commentAction === "update" ? 0.7 : 1 }}
                  >
                    Módosítás
                  </button>
                  <button
                    className="home-action-button bf7b"
                    onClick={() => setCommentAction("delete")}
                  >
                    Törlés
                  </button>
                </Stack>
                {commentAction === "reply" && (
                  <Stack direction="column" gap={1}>
                    <TextArea
                      value={replyMessage}
                      placeholder="Válasz írása a megjegyzéshez.."
                      rows={3}
                      count={{
                        show: true,
                        max: 300,
                      }}
                      maxLength={300}
                      onChange={(e) => setReplyMessage(e.target.value)}
                    />
                    <button
                      className="home-action-button bc6a"
                      disabled={replyMessage.length < 1}
                      onClick={() => postCommentReply('reply', selectedComment.id)}
                    >
                      Küldés
                    </button>
                  </Stack>
                )}
                {commentAction === "update" && (
                  <Stack direction="column" gap={1}>
                    <TextArea
                      value={replyMessage}
                      placeholder={
                        selectedComment.message ||
                        selectedComment.text ||
                        "Módosítás.."
                      }
                      rows={3}
                      count={{
                        show: true,
                        max: 300,
                      }}
                      maxLength={300}
                      onChange={(e) => setReplyMessage(e.target.value)}
                    />
                    <button
                      className="home-action-button bc6a"
                      disabled={replyMessage.length < 1}
                    >
                      Küldés
                    </button>
                  </Stack>
                )}
                {commentAction === "delete" && (
                  <Stack direction="column" gap={1}>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      Biztosan törölni szeretnéd?
                    </Typography>
                    <button
                      className="home-action-button bc6a"
                      onClick={() => handleDeleteComment(selectedComment.id)}
                    > 
                      Megerősítés 
                    </button>
                  </Stack>
                )}
              </Stack>
            ) : (
              <Typography className="t_oktelo_normal size_14 darkblue">
                Válassz egy kommentet
              </Typography>
            )}
          </Box>)
          : <Typography className="t_oktelo_normal size_14 darkblue">
            Nincsenek megjegyzések.
            </Typography>}
        </Grid>
      </Grid>
    </Box>
  );
};

const AttachmentRenderer: React.FC<{ attachment: MainAttachments }> = ({
  attachment,
}) => {
  switch (attachment.type) {
    case "share":
    case "image_share":
    case "checkin":
    case "profile_media":
    case "photo":
    case "image":
    case "cover_photo":
    case "map":
    case "animated_image_share":
      return (
        <img
          src={attachment.urls[0]}
          alt={attachment.title || "Photo"}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "4px",
          }}
        />
      );

    case "video_inline":
    case "video":
    case "video_autoplay":
      return (
        <video controls style={{ width: "100%", height: '100%' }}>
          <source src={attachment.urls[0]} type="video/mp4" />
        </video>
      );

    case "album":
    case "carousel_album":
    case "multiple":
      return (
        <Grid container spacing={1}>
          
          {attachment.urls.map((url, index) => (
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}> 
            <img
              src={url} 
              alt={attachment.title || `Photo ${index + 1}`}
              style={{ 
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "4px", 
              }} 
            /> 
            </Grid>
          ))}  
        </Grid> 
      );   

    case "link":
      return (
        <Box>
          <a
            href={attachment.urls[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.title || attachment.urls[0]}
          </a>
        </Box>
      );

    case "animated_image_autoplay":
      return (
        <img
          src={attachment.urls[0]}
          alt={attachment.title || "Animated Image"}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "4px",
          }}
        />
      );

    case "event":
      return (
        <Box>
          <Typography variant="h6">Event</Typography>
          <a
            href={attachment.urls[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.title || "Event Link"}
          </a>
        </Box>
      );

    case "music":
      return (
        <Box>
          <Typography variant="h6">Music</Typography>
          <a
            href={attachment.urls[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.title || "Music Link"}
          </a>
        </Box>
      );

    case "note":
      return (
        <Box>
          <Typography variant="h6">Note</Typography>
          <a
            href={attachment.urls[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.title || "Note Link"}
          </a>
        </Box>
      );

    case "offer":
      return (
        <Box>
          <Typography variant="h6">Offer</Typography>
          <a
            href={attachment.urls[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.title || "Offer Link"}
          </a>
        </Box>
      );

    case "status":
      return (
        <Box>
          <Typography>{attachment.title || "Status"}</Typography>
        </Box>
      );

    default:
      return (
        <Typography className="t_oktelo_medium size_14 darkblue">
          Nincs megjeleníthető média ({attachment.type})
        </Typography>
      );
  }
};

export default SocialPostAnalytics;
