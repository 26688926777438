import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { WarningTwoTone } from '@ant-design/icons';

import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';

import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useRef } from "react";

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { Popconfirm, Tag } from 'antd';

import { Grid } from "@mui/material";
import { FormProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { Facebook, ImageOutlined, Instagram } from "@mui/icons-material";
import InstagramPreview from "./previews/InstagramPreview";
import FacebookPreview from "./previews/FacebookPreview";
import { useNotification } from "@refinedev/core";

const CForm = ({
  type,
  register,
  handleSubmit,
  handleImageChange,
  formLoading,
  onFinishHandler,
  postImage,
  onDateChange,
  dateValue,
  platformValue,
  locationValue
}: FormProps) => {
  document.title = "Create Post | Oktelo"
  const { control } = useForm();
  const {open} = useNotification()
  const getUserData = localStorage.getItem('user');
  const userData = getUserData ? JSON.parse(getUserData) : null;
  const sessionToken = userData ? userData.session : '';

  const [textFieldValue, setTextFieldValue] = useState('');
  const [areaFieldValue, setAreaFieldValue] = useState<string>('');
  const [selectedPlatform, setSelectedPlatform] = useState<string>(platformValue);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [selectedPage, setSelectedPage] = useState<string | null>(locationValue);
  const [ pageName, setPageName ] = useState<string | undefined>();

  const [data, setData] = useState<any>();

// Make Axios request
const fetchData = async () => {
  try {
    setLoading(true);
    const response = await axios.get('http://localhost:8080/api/v1/users', {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        user_request_type: 'get_all_pages'
      }
    });
  

    setData(response.data);
    console.log(response.data.allFacebook);

  } catch (error) {
    // Handle errors
    console.error('Error fetching data:', error);
  }
  finally{
    setLoading(false);
  }
};

  const platformOptions = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'twitter', label: 'Twitter/X' },
    { value: 'pinterest', label: 'Pinterest' },
    { value: 'linkedin', label: 'LinkedIn' }
  ];

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const emojiToAdd = emojiData.emoji;
    setAreaFieldValue(prevValue => prevValue + emojiToAdd);
  };
  
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior of Enter key (e.g., form submission)
      setAreaFieldValue(prevValue => prevValue + '\n'); // Insert newline character into textarea value
    }
  };
 
  const handleMenuItemClick = (page_id: any) => {
    if (page_id === null) {
      setPageName(undefined);
      setSelectedPage(null); 
    } else {
      if (data) {
        const selectedFBPage = data.allFacebook?.find((page: any) => page.id === page_id);
        setSelectedPage(page_id);
  
        if (selectedFBPage) {
          setPageName(selectedFBPage.name);
        } else {
          const selectedIGPage = data.allInstagram?.find((page: any) => page.id === page_id);
          setPageName(selectedIGPage?.username);
        }
      }
    }
  };

  const handlePlatformChange = (platform: any) => {
    setSelectedPlatform(platform);
  };

  const handleEmojiPickerToggle = () => {
    setEmojiPickerOpen(!emojiPickerOpen);
  };

  useEffect(() => {
    fetchData();
  }, []); // Refetch when component mounts

  useEffect(() => {
    if (platformValue) {
      setSelectedPlatform(platformValue);
    }
  }, [platformValue]);

  useEffect(() => {
    if (locationValue) {
      setSelectedPage(locationValue);
    }
  }, [locationValue]);


  const generateAiContent = async (type: string) => {

    try {
      setLoading(true);
        let response;

        if(type === "content"){
          response = await axios.post('http://localhost:8080/api/v1/ai/generate/', {title: textFieldValue, platform: 'post-creator', AiModel: 'Oktelo_v2_Pro'});
        }
        else if(type === "image"){
          response = await axios.post('http://localhost:8080/api/v1/ai/generate/', {title: textFieldValue, platform: 'post-creator-image', AiModel: 'Oktelo_v2_Pro'});  
        }

        if (response){
          const ai_answer = response.data.content;
          if(ai_answer && type === "content"){
            setAreaFieldValue(prevValue => prevValue + '\n' + ai_answer);
          }
          else if(ai_answer && type === "image"){
            console.log(ai_answer);
            handleImageChange(ai_answer);
          }

            if (response.status === 500 || response.status === 404){
              open?.({
                type: "error",
                message: "Something went wrong.",
                description: "Bad news!",
              });
            } else {
              open?.({
                type: "success",
                message: "Successfully added the AI content.",
                description: "Good news!",
              });
            }
          }


    } catch (error) {
        console.error('Error:', error);
    }
    finally {
        setLoading(false);
    }
};

  const currentDate = dayjs();


  return(
    
  <Grid container spacing={{xl: 2, md: 3, sm: 3, xs: 3}}>

    <Grid item xs={12} md={12} lg={12} xl={8} p={{xl: 2, md: 0}}>
    <Box display="flex" flexDirection="column" className="b_wp_nohover oktelo_create_form">
    <Typography className="t_secondary_title">
    😎 {type} Oktelo Post
      </Typography>
      <form
        style={{
          marginTop: "20px",
          width: "100%", 
          display: "flex",
          flexDirection: "column",
          gap: "12px"
        }}
        onSubmit={handleSubmit(onFinishHandler)}
      >
        <FormControl>
          <FormHelperText className="t_oktelo t_highlight">
            Let's give your post a fancy title!
          </FormHelperText>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
            <TextField
              className="oktelo_form_element"
              required
              id="input-with-icon-textfield"
              sx={{ width: '100% !important' }}
              color="info"
              variant="outlined" 
              /* label="Let's give your post a fancy title" */
              InputProps={{
                minLength: 1,
                maxLength: 64,
              }}
              {...register("title", {
                required: true,
                maxLength: 64,
                minLength: 1,
                value: textFieldValue,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => { setTextFieldValue(e.target.value)}
              })}
            />
            <FormHelperText id="component-helper-text">
              This will help you identify your posts later on. Character limit: 
              {textFieldValue.length > 64 ? (
                <span style={{ color: "red" }}> {textFieldValue.length} / 64. </span>
              ) : (
                <span> {textFieldValue.length} / 64. </span>
              )}
            </FormHelperText>
          </Box>
        </FormControl>

        <FormControl>
          <FormHelperText  className="t_oktelo t_highlight">
            Write the post content
          </FormHelperText>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative'}}>
            <TextareaAutosize
              className="oktelo_form_element"
              ref={textareaRef}
              minRows={8}
              required
              placeholder=""
              color="info"
              value={areaFieldValue? areaFieldValue : undefined}
              style={{
                width: "100%",
                borderColor: "rgba(0,0,0,0.23)",
                borderRadius: 6,
                padding: 10,
                fontWeight: '400',
                color: '#1E1E47',
                position: 'relative'
              }}
              {...register("description", { required: true,
                maxLength: 2200,
                minLength: 0,
                value: areaFieldValue,
                onChange: (e: any) => { setAreaFieldValue(e.target.value)},
                onkeydown: handleKeyDown,
              })}
            />
            <FormHelperText id="component-helper-text">
              This is your actual post content. Character limit: 
              {areaFieldValue.length > 2000 ? (
                <span style={{ color: "red" }}> {areaFieldValue.length} / 2200. </span>
              ) : (
                <span> {areaFieldValue.length} / 2200. </span>
              )}
            </FormHelperText>       
          </Box>
          {emojiPickerOpen && <EmojiPicker open={true} lazyLoadEmojis={true} width="100%" onEmojiClick={handleEmojiClick} />}
        </FormControl>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" gap={1}>
              <Stack direction="row" gap={1} alignItems="center"  flexWrap="wrap">
              <Button sx={{  width: 'auto' }} className="t_oktelo t_highlight b_oktelo_purple"  onClick={handleEmojiPickerToggle}>
                <EmojiEmotionsOutlinedIcon sx={{ marginRight: '6px', fontSize: '18px'}}/>Emoji picker
                </Button>
                <Popconfirm
                  title="Confirm AI Generation"
                  placement="top"
                  description="Content will be generated based on your post title. Please verify that the current title describes your post. You can change it after the generation. The content will be added under your existing content, so you can modify it, while keeping the original content."
                  onConfirm={() => generateAiContent('content')}
                  okText="Generate"
                  className="oktelo_popover"
               >
                <Button sx={{  width: 'auto' }} className="t_oktelo t_highlight b_oktelo_purple" disabled={textFieldValue.length <= 20? true : false}>
                  <AutoFixHighIcon sx={{ marginRight: '6px', fontSize: '18px'}}/>Generate content with AI
                </Button>
                </Popconfirm>
                {textFieldValue.length <= 20 ? (
              <Tag color="error">
              Title must be more than 20 characters long.
              </Tag>  
              ) :
              (
                <Tag color="green">
                Oktelo AI is ready!
                </Tag>  
              )
              }
              </Stack>


            </Stack>

            <FormControl fullWidth>
              <FormHelperText  className="t_oktelo t_highlight">
                Select the platform 
              </FormHelperText>
              <Select
                className="oktelo_form_element"
                variant="outlined"
                color="info"
                displayEmpty
                required
                inputProps={{ "aria-label": "Without label" }}
                value={platformValue !== selectedPlatform ? selectedPlatform : platformValue}
                {...register("propertyType", {
                  required: true, 
                  value: platformValue !== selectedPlatform ? selectedPlatform : platformValue
                })}
              >
                {platformOptions.map(option => (
                <MenuItem key={option.value} value={option.value} onClick={() => handlePlatformChange(option.value)}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
              <Stack width="auto">
                {selectedPlatform != "instagram" && selectedPlatform != "facebook" &&
                <Tag color="gold" icon={<WarningTwoTone twoToneColor="#f0ad4e" />}  style={{ width: 'auto', textWrap: 'pretty' }}>Note: Oktelo currently does not support automatic posting / scheduling for {selectedPlatform}.</Tag>
                }
              </Stack>
            <FormControl fullWidth>
              <FormHelperText className="t_oktelo t_highlight">
                You can schedule it right away!
              </FormHelperText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="scheduled_at"
                  defaultValue={null}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      value={field.value ? dayjs(field.value) : dateValue? dayjs(dateValue) : null}
                      onChange={(newValue: Dayjs | null) => {
                        field.onChange(newValue);
                        onDateChange(newValue); // Call the function with the selected date
                      }}
                      referenceDate={currentDate}
                      className="oktelo_form_element"
                    />
                  )}
                  rules={{ required: false }} // Add rules if needed
                />
              </LocalizationProvider>
            </FormControl>


        <FormControl>
          <FormHelperText className="t_oktelo t_highlight">
            Connect with a social page
          </FormHelperText>
          <Select
            className="oktelo_form_element"
            fullWidth
            color="info"
            variant="outlined"
            //value={selectedPage ? (selectedPage.id === null ? "" : selectedPage.id) : ''}
            value={selectedPage ? (selectedPage !== locationValue ? selectedPage: locationValue) : ''}
            displayEmpty
            onChange={(event) => {
              const pageId = event.target.value;
              const selectedPageObj = data?.allFacebook.find((page: any) => page.id === pageId) || null;
              handleMenuItemClick(selectedPageObj);
            }}
            inputProps={{ "aria-label": "Select Facebook Page" }}
            {...register("location", {
              required: false,
              value: selectedPage ? (selectedPage !== locationValue ? selectedPage: locationValue) : '',
            })}
          >
           {/*  <MenuItem value="" onClick={() => handleMenuItemClick(undefined)}>Not connected</MenuItem> */}
            {data && data.allFacebook && data.allFacebook.map((page: any) => (
              <MenuItem
                key={page.id}
                value={page.id}
                onClick={() => handleMenuItemClick(page.id)}
              >
                <Stack flex={1} direction="row" gap={1} alignItems="center"> <Facebook sx={{fontSize: '14px', color: '#007eff'}}/>{page.name} </Stack>
              </MenuItem>
            ))}
              {data && data.allInstagram && data.allInstagram.map((page: any) => (
              <MenuItem
                key={page.id}
                value={page.id}
                onClick={() => handleMenuItemClick(page.id)}
              >
                <Stack flex={1} direction="row" gap={1} alignItems="center"> <Instagram sx={{fontSize: '14px', color: '#e66de5'}}/>{page.username} </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="column" gap={1} justifyContent="center" mb={2}>
          <Stack direction="column" gap={1}>
            <Typography className="t_oktelo t_highlight">
              Post media
            </Typography>
            <Button
              component="label"
              sx={{
                width: "fit-content",
                color: "#925fff",
                textTransform: "capitalize",
                fontSize: '16px',
              }}
            >
              Upload *
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleImageChange(e.target.files![0]);
                }}
              />
            </Button>
          </Stack>
          <Typography className="t_oktelo t_misc" sx={{ wordBreak: "break-all" }}>
            Image: {postImage?.name}
          </Typography>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" gap={1}>
              <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
              <Button sx={{  width: 'auto' }} className="t_oktelo t_highlight b_oktelo_purple" >
                <ImageOutlined sx={{ marginRight: '6px', fontSize: '18px'}}/>Search image library
                </Button>
                <Popconfirm
                  title="Confirm AI Generation"
                  placement="top"
                  description="Content will be generated based on your post title. Please verify that the current title describes your post. You can change it after the generation. The content will be added under your existing content, so you can modify it, while keeping the original content."
                  onConfirm={() => {generateAiContent('image')}}
                  okText="Generate"
                  className="oktelo_popover"
               >
                <Button sx={{  width: 'auto' }} className="t_oktelo t_highlight b_oktelo_purple" disabled={textFieldValue.length <= 20? true : false} >
                  <AutoFixHighIcon sx={{ marginRight: '6px', fontSize: '18px'}}/>Generate photo with AI
                </Button>
                </Popconfirm>
                {textFieldValue.length <= 20 ? (
              <Tag color="error">
              Title must be more than 20 characters long.
              </Tag>  
              ) :
              (
                <Tag color="green">
                Oktelo AI is ready!
                </Tag>  
              )
              }
              </Stack>
            
            </Stack>
        </Stack>
        
        <CustomButton
          type="submit"
          title={formLoading ? "Saving new post..." : "Save"}
          backgroundColor="#925FFF"
          color="#FFFFFF"
          fullWidth
        />
      </form>
    </Box>
  </Grid>
  {isLoading && 
      <Box
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
    </Box>
    }
  <Grid item xs={12} md={12} lg={12} xl={4} p={{xl: 2, md: 0}}>
    <Box className="b_wp_nohover">
  <Typography className="t_secondary_title" mb={4}>
        Preview ({selectedPlatform? selectedPlatform : 'facebook'})
      </Typography>  
  {(selectedPlatform === "facebook" || !selectedPlatform) && (
    <FacebookPreview selectedPage={selectedPage} areaFieldValue={areaFieldValue} postImage={postImage} pageName={pageName}/>
)}
{(selectedPlatform === "instagram") && (
    <InstagramPreview selectedPage={selectedPage} areaFieldValue={areaFieldValue} postImage={postImage} pageName={pageName}/>
)}
</Box>
  </Grid>
</Grid>

);
};

export default CForm;
