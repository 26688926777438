import { Tag, Tooltip } from 'antd';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { FallOutlined, RiseOutlined, WarningOutlined } from '@ant-design/icons';

import ReactApexChart from "react-apexcharts";

import { PieChartProps } from "interfaces/home";
import { ApexOptions } from 'apexcharts';
import { Avatar, Grid } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

const PieChart = ({ title, value, desc, change, before, icon }: PieChartProps) => {

  const getStatusTag = (value: number, before: number) => {

    let formattedChange = 0;
   
    if (before === 0 && value === 0){ //If nothing changed and both weeks are 0
      formattedChange = 0;
    }
    else if (before === 0 && value > before){ //If last week was 0, and now the value is > 0
      formattedChange = (value - 0) * 100;
    }
    else if (value === 0 && before > value){ //If this week is 0, and last week is > 0
      formattedChange = ((value - before) / before) * 100;
    }
    else if (value !== 0 && before !== 0){ //If nothing is 0, and can calculate change %
      formattedChange = ((value - before) / before) * 100;
    }


    if (formattedChange < 0) {
        return <Tag icon={<FallOutlined />} bordered={false} className="size_12 t_oktelo_medium tag_oktelo" color="#ff4141" style={{ color: '#fff'}}>{formattedChange.toFixed(2)}%</Tag>;
    } else if (formattedChange > 0 && formattedChange < 5000) {
        return <Tag icon={<RiseOutlined />} bordered={false} className="size_12 t_oktelo_medium tag_oktelo" color="#78f080" style={{ color: '#1e1e47'}}>{formattedChange.toFixed(2)}%</Tag>;
    } else if (formattedChange > 0 && formattedChange >= 5000){
      return <Tag icon={<RiseOutlined/>} bordered={false} className="size_12 t_oktelo_medium tag_oktelo" color="#78f080" style={{ color: '#1e1e47'}}>+N/a%</Tag>;
    } else {
        return <Tag icon={<WarningOutlined />} bordered={false} className="size_12 t_oktelo_medium tag_oktelo" color="#ffcc00" style={{ color: '#fff'}}>0.00%</Tag>;
    }
};

const getPercentage = (value: number, before: number) => {
  if (value < before) {
    return (value / before) * 100;
  } else if (value <= before * 1.1) {
    return ((value / (before * 1.1))) * 100;
  } else if (value === 0 && before === 0) {
    return 1; 
  }
  else {
    return 100;
  }
}


const percentage = getPercentage(value, before? before : 1);
const remainingPercentage = 100 - percentage;

// Format percentages with one decimal point
const formattedPercentage = percentage.toFixed(1)+ '%';
const formattedRemainingPercentage = remainingPercentage.toFixed(1) + '%';

// Series data
const cseries = [parseFloat(formattedPercentage), parseFloat(formattedRemainingPercentage)];

// Chart options
const options: ApexOptions = {
  plotOptions: {
    pie: {
      donut: {
        size: '75%',
        labels: {
          show: false,
          name: {
            show: false
          },
          value: {
            show: false,
            fontSize: '14px'
          }
        }
    },
    expandOnClick: false,
    customScale: 1
    }
  },
  chart: {
    type: 'donut',
    events: {
      click: function(event, chartContext, config) { return; },
      dataPointSelection: function(event, chartContext, config) { return; },
    }
  },
  colors: ['#925FFF', '#d3d3d3'],
  legend: { show: false },
  labels: ['Haladás (%)', `Cél (%)`],
  dataLabels: { enabled: false },
  responsive: [
    {
      breakpoint: 0, 
      options: {
        chart: {
          width: '0' 
        }
      }
    },
    {
      breakpoint: 596, 
      options: {
        chart: {
          width: '0' 
        }
      }
    },
    {
      breakpoint: 786, 
      options: {
        chart: {
          width: '120px' 
        }
      }
    },
    {
      breakpoint: 1200, 
      options: {
        chart: {
          width: '120px'
        }
      }
    },
    {
    breakpoint: 1400,
      options: {
        chart: {
          width: '140px'
        }
      }
    },
    {
      breakpoint: 1600,
        options: {
          chart: {
            width: '160px'
          }
        }
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '160px'
          }
        }        
      }
  ]
};


  return (

    <Box className={`chart-box`} sx={{ height: '100%' }}>
          <Stack direction="column" justifyContent="space-between" gap={0} width="100%" height="100%" alignItems="flex-start">
            <Stack direction="row" width="100%" gap={1} justifyContent="space-between" alignItems="flex-start">
            <Avatar sx={{ backgroundColor: '#f9f9f9', width: '36px', height: '36px', borderRadius: '6px' }}>
              {icon}
            </Avatar> 
            <Tooltip title={`Előző hét: ${before ? before : 'Nincs adat'}`}>
              {before !== undefined && before !== null && getStatusTag(value, before)}
              </Tooltip>
            </Stack>
            <Typography className={`t_oktelo_medium size_14 ${desc === 'followers' ? 't_white' : 'darkblue'}`} mt={1.5}>
              {title}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" justifyContent="flex-start">
            <Tooltip title={desc} placement='right'>
            <Typography
              className="text-monster t_oktelo_bold size_32"
              sx={{
                ':hover': {
                  color: '#925fff !important', // The color on hover
                },
                transition: 'all 0.3s',
              }}
            >
              {value.toFixed(0)}
            </Typography>
            </Tooltip>
            <Typography className={`t_oktelo_light size_12 ${desc === 'followers' ? 't_white' : 'darkblue'}`} sx={{ lineHeight: '14px !important'}}>
              Előző héten <br></br><span className='t_oktelo_normal'>{before ? before : 'Ismeretlen'}</span> darab
            </Typography>
            </Stack>
            {value && before &&
            <Stack direction="row" gap={0.5} alignItems="center">
            <Typography className="t_oktelo_light miscgray size_12">
              Haladás: <span className='t_oktelo_normal'>{value-before < 0 ? value-before : '+' + (value-before)}</span>
            </Typography>
            {value-before < 0 ?
              <ErrorOutline sx={{ fontSize: '12px', color: '#f37735'}}/>
              :
              <CheckCircleOutline sx={{ fontSize: '12px', color: '#78f080'}}/>
            }
            </Stack>}

          </Stack>
    </Box>


/*       <Box position="absolute" right={12} top={12}>
        <ShowTooltip question color='#E5E4E2' title={title} content={desc + " Last week: " + before}/>
      </Box>
     
 */


  );
};

export default PieChart;
