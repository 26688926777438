import React from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

const MobileCalendar = ( _data: any ) => {
  const today = dayjs().startOf('day');
  const daysInMonth = today.daysInMonth();
  const monthStart = today.startOf('month');

  const data = _data.data;

  const days = Array.from({ length: daysInMonth }, (_, i) => monthStart.add(i, 'day'));

  const renderEvents = (date: Dayjs) => {
    const formattedDate = date.format('YYYY-MM-DD');

    let events: any[] = [];

    if (data) {
      if (data.posts?.vazlat?.length > 0) {
        events = [
          ...events,
          ...data.posts.vazlat.filter((post: any) => dayjs(post.date).format('YYYY-MM-DD') === formattedDate),
        ];
      }

      if (data.posts?.utemezve?.length > 0) {
        events = [
          ...events,
          ...data.posts.utemezve.filter((post: any) => dayjs(post.date).format('YYYY-MM-DD') === formattedDate),
        ];
      }

      if (data.posts?.publikalt?.length > 0) {
        events = [
          ...events,
          ...data.posts.publikalt.filter((post: any) => dayjs(post.date).format('YYYY-MM-DD') === formattedDate),
        ];
      }

      if (data.notes?.length > 0) {
        events = [
          ...events,
          ...data.notes.filter((note: any) => dayjs(note.date).format('YYYY-MM-DD') === formattedDate),
        ];
      }

      if (data.campaigns?.length > 0) {
        events = [
          ...events,
          ...data.campaigns.filter((campaign: any) => dayjs(campaign.date).format('YYYY-MM-DD') === formattedDate),
        ];
      }

      if (data.approvals?.length > 0) {
        events = [
          ...events,
          ...data.approvals.filter((approval: any) => dayjs(approval.date).format('YYYY-MM-DD') === formattedDate),
        ];
      }
    }

    return events;
  };

  return (
    <div style={{ padding: '0px' }}>
      {days.map((day) => (
        <Card key={day.format('YYYY-MM-DD')} style={{ marginBottom: '14px', backgroundColor: day.isSame(today, 'day') ? '#f2ecff' : '#fff', }}>
          <CardContent>
            <Typography className='t_oktelo_semibold size_16 darkblue'>{day.format('YYYY.MM.DD')} {" "}<span className="t_oktelo_light size_14 t_purple">{day.format('dddd')}</span></Typography>
            <ul style={{ padding: 0, listStyleType: 'none' }} className="events">
              {renderEvents(day).map((event: any, index: number) => (
                <li key={index} style={{ margin: '8px 0' }} className="calendar-list-item">
                  <Stack direction="row" gap={1} alignItems="center">
                    {event.title.slice(0,20)} - {dayjs(event.date).format('MM.DD HH:mm')}
                  </Stack>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default MobileCalendar;
