// Global ToolTip Texts
// s_ = Stack b_ = Box btn_ = Button t_ = Typography i_ = Image

export const tts_content: { [key: string]: string } = {
    //Loop vars
    post_impressions: "The number of times your post entered a person's screen.",
    post_engaged_users: "The number of people who clicked anywhere in your posts.",
    total_interactions: "The number of likes, saves, comments and shares for your post, after deducting the number of unlikes, deletions from saves and the number of deleted comments.",
    reach: "The number of unique accounts that saw this post at least once. Reach and number of impressions are not the same, the latter can also include when the same account sees your post several times. This metric is an estimate.",
    impressions: "The number of times your post entered a person's screen.",

    //Not loop vars
    s_home_main: "Here, you'll find an overview of your insights for the selected Facebook page and its posts. It's important to note that the Page insights are based on full 24-hour days. Therefore, the data displayed may reflect activity up until the end of yesterday.",
    b_calendar_main: "Here, you can see all your scheduled posts displayed neatly on the calendar. You can also create custom notes for any date you like, and even pick a color to make them stand out. Simply hover over the post titles or notes to get more details.",
    t_home_noconnect: "You'll find your post and page insights here. To start, we need to verify what pages are you administrator of. Don't worry! It's just a single click in your profile settings.",
    t_no_ig_insights: "No insights are available for this post. This may occur if the content was posted before switching to an Instagram Business Account. Rest assured, any new posts will be tracked once you've switched to a Business Account.",
    s_campaign_form_posts: "This list below shows your recently created Oktelo posts. Click on the posts you want to add to this campaign. You can add more posts later on in the campaign settings.",
    s_page_analytics: "Page Analytics description",
    t_comment_count: "The total amount of comments this post recieved.",

    //BestPosts
    engagement_rate: "The rate indicator of how many impressions are converted to engagement. The higher the better.",
    ig_likes: "The total amount of likes this post has recieved so far.",
    by_oktelo: "This post was published using Oktelo 🥳",
    
    //AI FORM DESC
    t_ai_model: "Oktelo v1 generates plain text results based on your inputs, without emojis, creative ideas and tone/style. Recommended for straightforward content generation needs. Oktelo v2 is a more dynamic model, which utilizes emojis, emotions (uses the selected tone/style) and responds with more creative ideas.",

};

export const tts_title: { [key: string]: string } = {
    //Loop vars
    post_impressions: "Post impressions",
    impressions: "Post impressions",
    post_engaged_users: "Post Engagement",
    reach: "Post reach",
    total_interactions: "Post interactions",

    //Not loop vars
    s_home_main: "Dashboard",
    b_calendar_main: "Calendar",
    t_home_noconnect: "Connecting",
    t_no_ig_insights: "No data",
    s_campaign_form_posts: "Campaign posts",
    s_page_analytics: "Page analytics",
    t_comment_count: "Post comments",
    engagement_rate: "Engagement rate",

    ig_likes: "Post likes",

    by_oktelo: "Oktelo publish",

    //AI FORM TITLE
    t_ai_model: "Choosing the AI model",
};