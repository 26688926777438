import React, { useState } from "react";
import { useLogin, useNotification } from "@refinedev/core";
import { Form, Input, Checkbox } from "antd"
import { Typography, Box, Stack, Grid, Button } from "@mui/material";
import { oktelo } from "assets";
import { useNavigate } from "react-router-dom";
import "./authlayout.css";


export interface ILoginForm {
    email?: string | undefined;
    username: string | undefined;
    password: string;
    remember: boolean;
  }

export const ForgotPasswordPage: React.FC = () => {
  document.title = "Oktelo App | Jelszó visszaállítás";
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login } = useLogin<ILoginForm>();
    const { open } = useNotification();
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);

    const handleForgotPassword = async (values: any) => {
        try {
            // Call your API to send reset password email
            await fetch('/api/auth/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: values.email }),
            });
            setSubmitted(true);
        } catch (error) {
            console.error('Failed to send reset password email:', error);
        }
    };
  
  

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Box className="forgot-password-card" p={4} boxShadow="lg">
            <Typography>Forgot Password</Typography>
            {!submitted ? (
                <Form layout="vertical" onFinish={handleForgotPassword}>
                    <Form.Item
                        name="email"
                        label="Enter your email"
                        rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <button type="submit">
                        Send Reset Link
                    </button>
                </Form>
            ) : (
                <Typography>
                    If an account with that email exists, a reset link has been sent.
                </Typography>
            )}
        </Box>
    </Box>
);
  
}
