import { ApexOptions } from 'apexcharts';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface MetricData {
    date: string;
    value: number | Record<string, number>;
}

interface ChartData {
    [metricName: string]: MetricData[];
}

interface ChartProps {
    title: string;
    chartData: ChartData;
}

const AnalyticsheatMapChart: React.FC<ChartProps> = ({ title, chartData }) => {
    const [series, setSeries] = useState<{ name: string; data: { x: string; y: number }[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});

    useEffect(() => {
        if (chartData && chartData.online) {
            // Transform data into format suitable for heatmap
            const transformedData = chartData.online.reduce((acc: { [date: string]: { [hour: string]: number } }, item) => {
                const date = dayjs(item.date).format('YYYY-MM-DD'); // Ensure correct date format
                const hourlyData = item.value as Record<string, number>;
                Object.entries(hourlyData).forEach(([hour, value]) => {
                    if (!acc[date]) {
                        acc[date] = {};
                    }
                    // Use the correct format for the time conversion
                    const dateTime = `${date}T${hour.padStart(2, '0')}:00:00`;
                    try {
                        const localHour = dayjs.tz(dateTime, 'YYYY-MM-DDTHH:mm:ss', 'America/Los_Angeles')
                                               .tz('Europe/Budapest').hour();
                        acc[date][localHour] = value;
                    } catch (error) {
                        console.log(`Error converting ${dateTime}:`, error);
                    }
                });
                return acc;
            }, {});


            // Extract hours and dates
            const hours = Array.from(new Set(Object.values(transformedData).flatMap(dateData => Object.keys(dateData)))).sort((a, b) => parseInt(a) - parseInt(b));
            const dates = Object.keys(transformedData).sort();

            // Calculate min and max values
            const allValues = Object.values(transformedData).flatMap(dateData => Object.values(dateData));
            const minValue = Math.min(...allValues);
            const maxValue = Math.max(...allValues);

            // Define color ranges based on min and max values
            const lowThreshold = minValue + (maxValue - minValue) * 0.30;
            const mediumThreshold = minValue + (maxValue - minValue) * 0.60;
            const highThreshold = minValue + (maxValue - minValue) * 0.90;

            // Convert data to series format for heatmap
            const seriesData = dates.map(date => ({
                name: date,
                data: hours.map(hour => ({
                    x: hour,
                    y: dates.indexOf(date)
                }))
            }));

            // Map date values to heatmap data
            const heatmapData = dates.map((date, index) => {
                return {
                    name: date,
                    data: hours.map(hour => {
                        return {
                            x: hour,
                            y: transformedData[date][hour] || 0
                        };
                    })
                };
            });

            const optionsData: ApexOptions = {
                chart: {
                    type: 'heatmap',
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: hours,
                    title: {
                        text: 'Óra (CEST)'
                    }
                },
                yaxis: {
                    labels: {formatter: (value: number) => `${value}`},
                    title: {
                        text: 'Dátum'
                    }
                },
                tooltip: {
                    y: {
                        formatter: (value: number) => `${value}`
                    }
                },
                colors: ['#2b1c4c'],
                plotOptions: {
                    heatmap: {
                        colorScale: {
                            ranges: [
                                {
                                    from: 0,
                                    to: lowThreshold,
                                    name: 'Alacsony',
                                    color: '#decfff'
                                },
                                {
                                    from: lowThreshold + 1,
                                    to: mediumThreshold,
                                    name: 'Közepes',
                                    color: '#b39fe5'
                                },
                                {
                                    from: mediumThreshold + 1,
                                    to: highThreshold,
                                    name: 'Magas',
                                    color: '#925fff'
                                },
                                {
                                    from: highThreshold + 1,
                                    to: maxValue -1,
                                    name: 'Csúcsidő',
                                    color: '#573999'
                                }
                            ]
                        }
                    }
                },
                noData: {
                    text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        color: '#000',
                        fontSize: '14px',
                    }
                }
            };

            setSeries(heatmapData);
            setOptions(optionsData);
        }
    }, [chartData, title]);

    return (
        <ReactApexChart
            series={series}
            type="heatmap"
            height={450}
            options={options}
        />
    );
};

export default AnalyticsheatMapChart;
