import { ApexOptions } from 'apexcharts';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

// Define interfaces for chart data
interface MetricData {
    date: string;
    value: number | Record<string, number>;
}

interface MetricDataValues {
    [key: string]: MetricData[];
}

interface MetricGroup {
    name: string;
    metrics: MetricDataValues;
}

interface ChartData {
    [metricName: string]: MetricData[];
}

interface ChartProps {
    title: string;
    chartData: ChartData; // Adjusted to fit expected data structure
}

const metricNameMapping: { [key: string]: string } = {
    impressions: "Impressziók",
    impressionsPaid: "Fizetett impressziók",
    impressionsUnique: "Elérés",
    impressionsOrganic: "Organikus impressziók",
    impressionsViral: "Viral impressziók",
    reach: "Elérés",
    engagement: "Elköteleződések száma",
    profile_views: "Profil megtekintések",
    postEngagements: "Poszt elköteleződések",
    totalActions: "CTA akciók",
    consumptionsUnique: "Elköteleződött felhasználók",
    negativeFeedback: "Negatív visszajelzések",
    dailyFollowsUnique: "Napi követések",
    dailyUnfollowsUnique: "Napi kikövetések",
    dailyFollows: "Napi követések összesen",
    totalFollows: "Összes követő",
    videoViews: "Videó megtekintések",
    videoViewsPaid: "Videó megtekintések (fizetett)",
    videoViewsOrganic: "Videó megtekintések (organikus)",
    videoViewsUnique: "Videó megtekintések (felhasználók)",
    reach_organic: "Organikus elérés",
    reach_paid: "Fizetett elérés",
    reach_all: "Összes elérés",
    dailyNewFollowers: "Új követők",
    autoplay: "Automata lejátszás",
    click_to_play: "Kattintásos lejátszás",
    repeat_views: "Ismételt lejátszás",
    complete_views_30: "Összes",
    complete_views_30_paid: "Fizetett",
    complete_views_30_organic: "Organikus",
    complete_views_30_unique: "Felhasználók",
    viewAll: "Látogatások",
    emailClicks: 'E-mail kattintás',
    messageClicks: 'Üzenet kattintás',
    websiteClicks: 'Weboldal kattintás'
};

const AnalyticsAreaChart: React.FC<ChartProps> = ({ title, chartData }) => {
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});

    useEffect(() => {
        if (chartData) {
            // Extract series data
            const seriesData = Object.keys(chartData).map(metricName => ({
                name: metricNameMapping[metricName] || metricName,
                data: chartData[metricName].map(item => typeof item.value === 'number' ? item.value : 0),
            }));

            // Extract x-axis categories from the first metric
            const firstMetricValues = chartData[Object.keys(chartData)[0]];
            const optionsData: ApexOptions = {
                xaxis: {
                    categories: firstMetricValues.map(value => dayjs(value.date).format('MM.DD.')),
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.3,
                        opacityTo: 0.4,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'area',
                },
                colors: ["#8576FF", "#1C1678", "#7BC9FF", "#A3FFD6"],
                plotOptions: {
                    area: {
                        // Customize area chart options if needed
                    }
                },
                markers: {
                    size: 3
                },
                grid: {
                    show: false,
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 2,
                    dashArray: 0,
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                tooltip: {
                    y: {
                        formatter(val: number) {
                            return `${val}`;
                        },
                    },
                },
                noData: {
                    text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        color: '#000',
                        fontSize: '14px',
                    }
                }
            };

            setSeries(seriesData);
            setOptions(optionsData);
        }
    }, [chartData]);

    return (
        <ReactApexChart
            series={series}
            type="area"
            height={350}
            options={options}
        />
    );
};

export default AnalyticsAreaChart;
