import { TextField, TextFieldProps } from '@mui/material';

interface CustomTextFieldProps {
    margin?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    variant?: 'standard' | 'filled' | 'outlined';
    size?: 'small' | 'medium';
    fullWidth?: boolean;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
    margin,
    value,
    onChange,
    label,
    variant,
    size,
    fullWidth,
    ...textFieldProps
}) => {
    return (
        <TextField
            value={value}
            onChange={onChange}
            label={label}
            variant={variant}
            size={size}
            sx={{
                borderRadius: '12px',
                outline: '0 !important',
                border: '1px solid #E6E6FA',
                color: '#3B3B3B',
                backgroundColor: '#fff',
                minWidth: 130,
                fontSize: 16,
                fontWeight: 500,
                gap: "10px",
                margin,
                '& .MuiFilledInput-root': {
                    borderRadius: '12px',
                    backgroundColor: '#fff',
                },
                '& .MuiInputLabel-root': {
                    color: '#3B3B3B',
                    fontWeight: 500,
                },
                '& .MuiFilledInput-root::before': {
                    borderBottom: '1px solid #E6E6FA',
                    borderRadius: '12px'
                },
                '& .MuiFilledInput-root:hover::before': {
                    borderBottom: '1px solid #E6E6FA',
                    borderRadius: '12px'
                },
                '& .css-1820cpj-MuiInputBase-root-MuiFilledInput-root::after': {
                    borderBottom: '1px solid #E6E6FA',
                    borderRadius: '12px',
                },
                '&:focus': {
                    outline: 0,
                    border: '1px solid #925FFF',
                }
            }}
            {...textFieldProps}
        />
    );
};

export default CustomTextField;
