import React, { useState } from 'react';
import { Modal, Tag } from 'antd';
import { Stack, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';
import { MessageTwoTone, HeartTwoTone } from '@ant-design/icons';

interface StoryModalProps {
    story: PageStory | undefined;
    visible: boolean;
    onClose: () => void;
}

interface PageStory {
    media_url: string;
    story_url: string;
    id: string;
    story_status: string;
    media_id: string;
    like_count?: number;
    comment_count?: number;
    create_date?: number;
    media_type?: string;
    thumbnail?: string;
  }

const StoryModal: React.FC<StoryModalProps> = ({ story, visible, onClose }) => {


    const onOpen = () => {
        if(story?.story_url){
            window.open(story?.story_url, '_blank');
        }
        else {
            window.alert("This story have been archived. You can not open it.");
        }
        onClose();
    };

    const onCancel = () => {
        onClose();
    };

    if (!story) {
        return null; // If no story is provided, return null to render nothing
      }

    return (
        <Modal
            open={visible}
            title="Story details"
            cancelText="Close"
            okText="Open story"
            onOk={onOpen}
            onCancel={onCancel}
            width="600px"
        >
            <Stack direction="row" gap={2}>
                <Stack direction="column" gap={2}>
                {story.media_type === "image" &&
                    <img src={story.media_url || story.thumbnail} width="300px" height="auto" alt="Story Media" />
                }
                {story.media_type === "video" && story.story_status === "published"? (
                    <video controls width="300px" height="auto">
                        <source src={story.media_url} type="video/mp4"/>
                    </video>   ) : (<img src={story.media_url || story.thumbnail} width="300px" height="auto" alt="Story Media" />)
                }
                </Stack>
                <Stack direction="column" gap={2}>
                    <Typography className='t_oktelo t_highlight'>
                        Information
                    </Typography>
                    <Typography className='t_oktelo t_misc'>
                        Status: {story.story_status}
                    </Typography>
                    <Typography className='t_oktelo t_misc'>
                        Created: {story.create_date? dayjs(story.create_date).format('LLL') : 'unknown'}
                    </Typography>
                    <Typography className='t_oktelo t_misc'>
                        Type: {story.media_type?.toLocaleLowerCase() || 'unknown'}
                    </Typography>
                    <Stack direction="row" gap={1}>
                        <Tag color='magenta' icon={<HeartTwoTone twoToneColor="magenta"/>} bordered={false}>{story.like_count || '0'}</Tag>
                        <Tag color='blue' icon={<MessageTwoTone twoToneColor="#1e90ff"/>} bordered={false}>{story.comment_count || '0'}</Tag>
                    </Stack>
                </Stack>
            </Stack>

        </Modal>
    );
};

export default StoryModal;
