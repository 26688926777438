import React, { useEffect, useState } from 'react'
import {Box, Stack, Typography, Card, Grid} from '@mui/material'
import { Select, Tag } from 'antd';
import ShowTooltip from 'components/common/ToolTips'
import { tts_content, tts_title } from 'utils/toolTipTexts'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'antd';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import ProgressChart from 'components/charts/ProgressChart';
import { AccessTimeFilled, RefreshSharp } from '@mui/icons-material';
import TwentyEight from 'components/charts/TwentyEight';
import {  CalendarOutlined } from '@ant-design/icons';
import MetricCards from 'components/common/MetricCards';
import AI_Charts from 'components/charts/AI_Charts';
import MetricCardsIG from 'components/common/MetricCardsIG';
import apiconfig from 'config';

const { Option } = Select;


interface MetricData {
  name: string;
  values: { value: number; end_time: string }[];
  title: string;
  description: string;
}

interface SingleValueData {
  name: string;
  values: { value: { [key: string]: number }; end_time: string }[];
  title: string;
  description: string;
}

interface KeyValueData {
  name: string;
  values: { value: { [key: string]: number }; end_time: string }[];
  title: string;
  description: string;
  period?: string;
  maxValue: number;
}

interface MetricData {
  end_time: string;
  value: number;
}

interface ChartData {
  [metricName: string]: MetricData[];
}

interface DayData {
  name: string;
  title: string;
  description: string;
  total_value: {
      value: number;
  };
}

interface CustomData {
  name: string;
  description: string;
  total_value: {
      breakdowns: {
          dimension_keys: string[];
          results: {
              dimension_values: string[];
              value: number;
          }[];
      }[];
  };
}

const PageAnalytics = ()  => {
  document.title = "Page Analytics | Oktelo";
  const [customSummarize, setCustomSummarize] = useState<string>("day");
  const [customPeriod, setCustomPeriod] = useState<number>(7);
  const [singleMetrics, setSingleMetrics] = useState<SingleValueData[]>([]);
  const [keyedMetrics, setKeyedMetrics] = useState<KeyValueData[]>([]); 
  const [sinceValue, setSinceValue] = useState<string>(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [untilValue, setUntilValue] = useState<string>(dayjs().subtract(1, 'day').format('YYYY-MM-DD')); 
  const [pageFansValue, setPageFansValue] = useState<number>(0);
  const [chartDataRes, setChartDataRes] = useState<any>();
  const [beforePoints, setBeforePoints] = useState<number>(0);
  const [dayChartsT, setDayChartsT] = useState<any[]>([]);
  const [customChartsT, setCustomChartsT] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { id, type } = useParams();
  const [igFollowers, setIgFollowers] = useState<number>(0);


  const timeFrameValues = [
    { value: "day", name: "Daily" },
    { value: "week", name: "Weekly" },
    { value: "days_28", name: "28 Days" },
  ];

  const dateFrameValues = [
    { value: 7, name: "Last 7 days" },
    { value: 14, name: "Last 14 days" },
    { value: 30, name: "Last 30 days" },
    { value: 90, name: "Last 90 days" },
  ];


  const handleSummarizeItemClick = (time: string) => {
    setCustomSummarize(time);
  };

  const handleTimeFrameItemClick = (time: string | number) => {
    const selectedTime = typeof time === 'string' ? parseInt(time) : time;
    setCustomPeriod(selectedTime);
    const currentDate = dayjs(); 
    const sinceDate = currentDate.subtract(selectedTime, 'day'); 
    const formattedSinceDate = sinceDate.format('YYYY-MM-DD'); 
    setSinceValue(formattedSinceDate); 
    const untilDate = currentDate.subtract(1, 'day'); 
    const formattedUntilDate = untilDate.format('YYYY-MM-DD');
    setUntilValue(formattedUntilDate); 
  };


  let dayDataForIG: DayData[] = [];
  let customDataForIG: CustomData[] = [];


  const fetchAnalytics = async () => {  
    try {
      setLoading(true);
      const sinceDateTime = dayjs(sinceValue).startOf('day').toISOString(); // Format since date as datetime
      const untilDateTime = dayjs(untilValue).endOf('day').toISOString(); // Format until date as datetime
      const analytics_response = await axios.get(
       `${apiconfig.BACKEND_URI}/socialdata/analytics/`,
        {
          headers: {
            "Content-Type": "application/json",
            id: id,
            page_type: type,
            custom_period: customSummarize,
            since: sinceDateTime,
            until: untilDateTime,
          },
        }
      );

const responseData = analytics_response.data;

const day = responseData.day;
const custom = responseData.custom;
const chart = responseData.chart;
const b_points = responseData.chartBefore;

console.log(b_points);

if(type === 'instagram'){
  dayDataForIG = day;
  customDataForIG = custom;

  const followerCount = responseData.follower.followers_count;
  const mediaCount = responseData.follower.media_count;

  setIgFollowers(followerCount);
}



if (analytics_response && chart) {
  setChartDataRes(chart);
  console.log(chart);
}

if(analytics_response && b_points){
  setBeforePoints(b_points);
}

if (type === 'facebook') {

  const singleMetricsList = ['page_fans','page_impressions', 'page_total_actions', 'reach',
  'page_views_total', 'page_impressions_paid', 'page_fan_removes_unique', 'page_video_views', 'page_video_view_time',
  'page_video_views_paid', 'page_video_views_organic', 'page_video_complete_views_30s' , 'page_actions_post_reactions_like_total'];
  
  const keyedMetricsList = ['page_fans_online', 'page_fans_country', 'page_fans_city', 'page_fans_locale',
  'page_impressions_by_age_gender_unique', 'page_impressions_by_locale_unique', 'page_impressions_by_country_unique', 'page_impressions_by_story_type'];
  
  const daySingleValues = day.filter((item: { name: string }) => singleMetricsList.includes(item.name));
  const customSingleValues = custom.filter((item: { name: string }) => singleMetricsList.includes(item.name));
  
  const dayKeyedValues = day.filter((item: { name: string }) => keyedMetricsList.includes(item.name));
  const customKeyedValues = custom.filter((item: { name: string }) => keyedMetricsList.includes(item.name));
  
  const allSingleMetrics = [
    ...daySingleValues,
    ...customSingleValues,
  ];
  const allKeyedMetrics = [
    ...dayKeyedValues,
    ...customKeyedValues,
  ];
  
  const flattenedKeyedMetrics = [].concat(...allKeyedMetrics);
  const flattenedSingleMetrics: SingleValueData[] = [].concat(...allSingleMetrics);

  const metricDisplayOrder: Record<string, number> = {
    'page_fans': 1,
    'page_views_total': 2,
    'page_impressions': 3,
    'page_total_actions': 4,
    'page_fan_removes_unique': 5,
    'page_video_views': 6,
    'page_video_view_time': 7,
    'page_video_views_organic': 8,
    'page_video_complete_views_30s': 9,
    'page_video_views_paid': 10,
    'page_impressions_paid': 11,
    'page_actions_post_reactions_like_total' : 12,
  };
  
  const sortedSingleMetrics = flattenedSingleMetrics.sort((a, b) => {
    const orderA = metricDisplayOrder[a.name] || Number.MAX_SAFE_INTEGER;
    const orderB = metricDisplayOrder[b.name] || Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  setPageFansValue(allSingleMetrics.find((item: { name: string }) => item.name === "page_fans").values[0].value);
  setKeyedMetrics(flattenedKeyedMetrics);
  setSingleMetrics(sortedSingleMetrics);

  const calculateMaxValue = (metrics: KeyValueData[]) => {
    const maxValues: { [key: string]: number } = {};
  
    let maxFansCountryValue = 0; // Initialize maximum value for page_fans_country metric
  
    metrics.forEach((metric) => {
      let maxValue = 0;
  
      for (let i = metric.values.length - 1; i >= 0; i--) {
        const value = metric.values[i].value;
  
        if (Object.values(value).some(val => val !== null && val !== 0)) {
          maxValue = Math.max(maxValue, Math.max(...Object.values(value)));
          break; 
        }
      }
  
      if (metric.name === "page_fans_country") {
        if (maxValue > maxFansCountryValue) {
          maxFansCountryValue = maxValue;
        }
      }
  
      maxValues[metric.name] = maxValue;
    });
  
    if(pageFansValue){
    maxValues["page_fans_city"] = pageFansValue;
    maxValues["page_fans_online"] = pageFansValue;
    maxValues["page_fans_locale"] = pageFansValue;
    maxValues["page_fans_country"] = pageFansValue;

  }
  
    return maxValues;
};

  const maxValues = calculateMaxValue(flattenedKeyedMetrics);

  setKeyedMetrics(prevMetrics =>
      prevMetrics.map(metric => ({
        ...metric,
        maxValue: maxValues[metric.name],
      }))
    );

}
else if (type === 'instagram')
  {

    if (dayDataForIG && Array.isArray(dayDataForIG)) {
      setDayChartsT(dayDataForIG);
    }


    if (customDataForIG && Array.isArray(customDataForIG)) {
      const followerDemographicsData = customDataForIG.find(
        (metric: CustomData) => metric.name === 'follower_demographics'
      );
      if (followerDemographicsData) {
        const breakdowns = followerDemographicsData.total_value.breakdowns;
        if (breakdowns.length > 0) {
          const top7CountriesData = breakdowns[0].results.slice(0, 7);
          const saveCustom = top7CountriesData.map((countryData) => ({
            country: countryData.dimension_values[0], // Assuming the country is the first dimension value
            followers: countryData.value,
          }));
    
          setCustomChartsT(customDataForIG);
        }
      }
    }
    
}



  } catch (error)
  {
    console.log(error);
  } finally
  {
    setLoading(false);
  }
}

useEffect(() => {
  fetchAnalytics();
}, [customSummarize, id, customPeriod]);


  return (
<Box>
  {loading && (
    <Box
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
    </Box>
  )}
  <Box display="flex" flexDirection="column" alignItems="flex-start" mb={3}>

    <Box mt={2} display="flex" flexDirection="column" alignItems="center" gap={2}  flex={1} width="100%">
      
    <Stack direction="row" alignItems="center" gap={3} justifyContent="space-between" flex={1} width="100%"> 
    <Stack>
    <Stack direction="column" gap={1}>
        <Typography className="t_main_title">📈 Page Analyics</Typography>
      </Stack>
    <Stack direction="row" alignItems="center" gap={1}>
    <Typography className="t_desc t_oktelo">
      Advanced page metrics for your social page
    </Typography>
    <ShowTooltip
      question
      title={tts_title.s_page_analytics}
      content={tts_content.s_page_analytics}
    />
    </Stack>
    </Stack>
    <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-end" flex={1} width="100%"> 
    <Select
    style={{ width: 200 }}
    defaultValue={timeFrameValues[0].value}
    onChange={handleSummarizeItemClick}
  >
    <Option value="" disabled>
      Summarization...
    </Option>
    {timeFrameValues.map(item => (
      <Option key={item.value} value={item.value}>
        <Stack flex={1} direction="row" gap={1} alignItems="center">
          <AccessTimeFilled style={{ fontSize: '14px', color: '#925FFF' }} />
          {item.name}
        </Stack>
      </Option>
    ))}
  </Select>
  <Select
    style={{ width: 200 }}
    defaultValue={dateFrameValues[0].value}
    onChange={handleTimeFrameItemClick}
  >
    <Option value="" disabled>
    Metrics period...
    </Option>
    {dateFrameValues.map(item => (
      <Option key={item.value} value={item.value}>
        <Stack flex={1} direction="row" gap={1} alignItems="center">
          <CalendarOutlined style={{ fontSize: '14px', color: '#925FFF' }} />
          {item.name}
        </Stack>
      </Option>
    ))}
  </Select>
      <RefreshSharp
            sx={{  fontSize: "24px", cursor: 'pointer', color: '#925FFF' }}
            onClick={fetchAnalytics}
            />
            </Stack>
    </Stack>
    {pageFansValue < 100 &&
    <Stack width="100%">
        <Alert
          message="Information"
          description="Please note that comprehensive metrics are available for Facebook Pages with 100 followers/likes or more. As your page currently has fewer than 100 likes, only limited metrics will be displayed."
          type="warning"
          showIcon
          closable
        />
      
      </Stack>
      }
    </Box>

  </Box>
<Box mb={8}>
  {chartDataRes &&
 <TwentyEight chartData={chartDataRes ?? {}} beforePoints={beforePoints ?? 0} />
  }
</Box>
{type === 'facebook' && (
  <Grid
    container
    spacing={{ xs: 2, md: 4 }}
    direction="row"
    justifyContent="flex-start"
    alignItems="stretch"
  >
    {singleMetrics.map((metric, index) => {
      const sortedValues = metric.values.sort((a, b) => {
        const timeA = new Date(dayjs(a.end_time).toDate()).getTime();
        const timeB = new Date(dayjs(b.end_time).toDate()).getTime();
        return timeA - timeB;
      });

      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={4}
          key={index}
          sx={{ height: "auto" }}
        >
          <MetricCards
            key={index} 
            metric={metric}
            index={index}
            sortedValues={sortedValues}
          />
        </Grid>
      );
    })}
  </Grid>
  
)}
  <Box mt={2}>
    <ProgressChart metrics={keyedMetrics} />
  </Box>

  <Grid
    container
    spacing={{ xs: 2, md: 4 }}
    direction="row"
    justifyContent="space-between"
    alignItems="stretch"
  >
  {dayChartsT.map((metric: DayData) => ( 
          <Grid
          item
          xs={12}
          sm={12}
          md={4}
          key={metric.name}
          sx={{ height: "auto" }}
        >
        <Box sx={{ backgroundColor: '#fff', borderRadius: '12px', padding: '12px', minHeight: '200px'}} display="flex" justifyContent="center" alignItems="center"  className="b_wp">
        <MetricCardsIG
          key={metric.name}
          title={metric.title}
          count={metric.total_value?.value? metric.total_value.value : 0}
          desc={metric.description}
          since={sinceValue}
          until={untilValue}
          color="#925fff"
        />
        </Box>
        </Grid>
        
      ))};
  {customChartsT.map((countryData) => (
  <Grid
    item
    xs={12}
    sm={12}
    md={12}
    key={countryData.name} // Use country name as key
    sx={{ height: "100%" }}
  >
    <Box sx={{ backgroundColor: '#fff', borderRadius: '12px', padding: '12px', height: 'auto'}} className="b_wp">
      <Typography className="t_oktelo t_secondary_title" mt={2}>Follower demographics</Typography>
      <Typography className="t_oktelo t_misc" mb={2}>The demographic characteristics of followers, including countries.</Typography>
      {countryData.total_value.breakdowns[0].results
        .sort((a: any, b: any) => b.value - a.value) // Sort results by value in descending order
        .slice(0, 5) // Take only the top 5 countries
        .map((result: any) => (
          <AI_Charts
          key={result.dimension_values[0]} // Use country code as key
          title={result.dimension_values[0]} // Use country code as title
          count={result.value} // Use the value as count
          percentage={((result.value/igFollowers)*100)}
          color="#925fff"
        />
      ))}
      <Tag color="gold" bordered={false}>{sinceValue}</Tag>
      <Tag color="gold" bordered={false}>{untilValue}</Tag>
    </Box>
  </Grid>
))}


  </Grid>

</Box>


);
}

export default PageAnalytics;