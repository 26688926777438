import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";
import { CustomButtonProps } from "interfaces/common";

const CustomButton = ({
    type,
    margin,
    title,
    backgroundColor,
    color,
    fullWidth,
    icon,
    handleClick,
    disabled,
    style,
    className
}: CustomButtonProps) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Button
            disabled={disabled}
            type={type === "submit" ? "submit" : "button"}
            sx={{
                flex: fullWidth ? 1 : "unset",
                padding: "10px 15px",
                width: fullWidth ? "100%" : "fit-content",
                minWidth: 130,
                backgroundColor,
                borderRadius: '12px',
                color,
                margin,
                fontSize: '14px',
                fontWeight: 500,
                gap: "8px",
                textTransform: "none",
                "&:hover": {
                    opacity: 0.9,
                    backgroundColor,
                },
                ...style
            }}
            onClick={handleClick}
            className={className}
        >
            {icon}
            {!isMobile && title}
        </Button>
    );
};

export default CustomButton;