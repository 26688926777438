import React from "react";
import { HomeCardProps } from "interfaces/oktelo";
import { Box, Stack, Typography } from "@mui/material";
import { LinkPlaceholder, TextPlaceholder, VideoPlaceholder, okt_placeholer } from "assets";
import { Tag } from "antd";
import { ArticleOutlined, CollectionsOutlined, FormatAlignCenterOutlined, LinkOutlined, LocalCafeOutlined, OndemandVideoOutlined, PhotoCameraBackOutlined } from "@mui/icons-material";

const HomePostCard = ({
    attachment,
    tag,
    platform
}: HomeCardProps) => {

    const returnImageSrc = (type: string, url: any) => {
        if (type === "textOnly") {
          return TextPlaceholder;
        } else if (type === "photo" || type === "story") {
          return url.photoUrl;
        } else if (type === "video" || type === "reels") {
          return url.thumbnailUrl || VideoPlaceholder;
        } else if (type === "carousel") {
          return url.carouselUrls[0];
        } else if (type === "webUrl") {
          return LinkPlaceholder;
        } else {
          return okt_placeholer;
        }
      };

      const iconMap: Record<string, JSX.Element> = {
        textOnly: <FormatAlignCenterOutlined />,
        video: <OndemandVideoOutlined />,
        photo: <PhotoCameraBackOutlined />,
        webUrl: <LinkOutlined />,
        story: <LocalCafeOutlined />,
        carousel: <CollectionsOutlined />,
        default: <ArticleOutlined />,
      };

      const returnIcon = (type: string) => iconMap[type] || iconMap.default;

    return ( 
      <Box position="relative">
        <Box component="img"
        src={returnImageSrc(attachment.type, attachment.url)}
        height="200px"
        width="100%"
        style={{ objectFit: "cover", borderRadius: '6px' }}
        className="upcoming-hover"
        >
        </Box>
        <Box className="post-grid-type-box">
          {returnIcon(attachment.type)}
        </Box>
        {tag &&
        <Tag color="rgba(255,255,255,0.8)"
        bordered={false}
        style={{
          color: '#353535',
          position: 'absolute',
          right: '6px',
          top: '6px ',
          width: 'auto',
          borderRadius: '6px 0px 0px 6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '4px 10px',
          fontWeight: '600',
          fontSize: '12px',
          border: 0,
          borderRight: '2px solid', 
          borderColor: tag.color || '#353535'
        }}>
          {tag.title}
        </Tag>}
      </Box>
    )


}


export default HomePostCard