import React, { useEffect } from 'react';
import { Stack, Box, Typography, Button, Grid } from '@mui/material';
import { Calendar, Drawer, Tag } from 'antd';
import { ConfigProvider } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs'; 
import { PropertyProps, NotesProps, CampaignProps } from 'interfaces/common';
import NotesModal from 'components/common/NotesModal';
import { CircularProgress } from '@mui/material';

import { tts_content, tts_title } from 'utils/toolTipTexts';
import ShowTooltip from 'components/common/ToolTips';
import { useNavigate } from 'react-router-dom';

import '../components/styles/calendar.css'

import axios from 'axios';
import "dayjs/locale/hu";
import { AccessTimeOutlined, ArticleOutlined, CalendarMonthOutlined, CampaignOutlined, CheckOutlined, Facebook, Instagram, NoteOutlined, PublicOutlined } from '@mui/icons-material';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import MobileCalendar from 'components/common/calendar/MobileCalendar';
import { okt_placeholer } from 'assets';
import apiconfig from 'config';

dayjs.locale('hu');

const CalendarPage = () => {
  document.title = "Naptár | Oktelo";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const [notes, setNotes] = useState<NotesProps[]>([]);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  async function fetchUserData() {
    try {
      //setLoading(true);
      const response = await axios.get(`${apiconfig.BACKEND_URI}/data/calendaritems`);
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
     // setLoading(false);
    }
  }

useEffect(() => {
  fetchUserData();
}, []);


  const showModal = () => {
    setVisible(true);
    setOpen(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCreate = async (values: { title: string; description?: string; scheduled_at: Date; color?: string; }) => {
    try {

      if(!values.description){
        values.description = "";
      }
      setLoading(true);
      const requestData = { ...values };
      // Make a POST request to the API endpoint with the form values and user's email
      const response = await axios.post(`${apiconfig.BACKEND_URI}/notesdata`, requestData);
      
      // If the note creation is successful, update the state with the newly created note
      const createdNote: NotesProps = response.data; // Assuming the API returns the created note
      setNotes(prevNotes => [...prevNotes, createdNote]);
      
  
      setVisible(false);
      fetchUserData()
    } catch (error) {
      console.error('Error creating note:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleRemoveNote = async (noteID: string) => {
    try {
        const confirmed = window.confirm('Biztos törölni szeretnéd?');
        if (!confirmed) {
          return;
        }
        setLoading(true);
        // Make a DELETE request to your backend API to trigger the delete event
        const response = await axios.delete(`${apiconfig.BACKEND_URI}/notesdata/${noteID}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
          fetchUserData()
        } else {
            console.error('Failed to remove note:', response.statusText);
        }
    } catch (error) {
        console.error('Error removing note:', error);
    } finally {
        setLoading(false);
        setOpen(false);
    }
};

const customLocale: PickerLocale = {
  lang: {
    locale: "hu_HU",
    placeholder: "Válassz egy dátmot",
    rangePlaceholder: ["Kezdő dátum", "Vége dátum"],
    today: "Ma",
    now: "Most",
    backToToday: "Vissza mára",
    ok: "OK",
    clear: "Törlés",
    month: "Hónap",
    year: "Év",
    timeSelect: "Idő választás",
    dateSelect: "Dátum választás",
    monthSelect: "Choose a month",
    yearSelect: "Choose a year",
    decadeSelect: "Choose a decade",
    yearFormat: "YYYY",
    dateFormat: "YYYY.MM.DD",
    dayFormat: "D",
    dateTimeFormat: "YYYY.MM.DD HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: false,
    previousMonth: "Previous month (PageUp)",
    nextMonth: "Next month (PageDown)",
    previousYear: "Last year (Control + left)",
    nextYear: "Next year (Control + right)",
    previousDecade: "Last decade",
    nextDecade: "Next decade",
    previousCentury: "Last century",
    nextCentury: "Next century",
    shortWeekDays: ["Vas", "Hét", "Ked", "Szer", "Csüt", "Pén", "Szo"],
    shortMonths: [
      "Jan",
      "Feb",
      "Már",
      "Ápr",
      "Máj",
      "Jún",
      "Júl",
      "Aug",
      "Szep",
      "Okt",
      "Nov",
      "Dec"
    ]
  },
  timePickerLocale: {
    placeholder: "Select time"
  },
  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-wo",
  monthFormat: "YYYY-MM"
};
  


  useEffect(() => {
    // Remove the title attribute from the td elements
    document.querySelectorAll('.ant-picker-cell').forEach(td => {
      td.removeAttribute('title');
    });
  }, []);


  const dateCellRender = (value: any) => {
    const formattedDate = dayjs(value).format('YYYY-MM-DD');
  
    let vazlatPosts: any[] = [];
    let utemezettPosts: any[] = [];
    let publicPosts: any[] = [];
    let allNotes: any[] = [];
    let allCampaigns: any[] = [];
    let allApprovals: any[] = [];

    if (data) {
      if (data.posts?.vazlat?.length > 0) {
        vazlatPosts = data.posts.vazlat.filter((post: any) => {
          return dayjs(post.date).format('YYYY-MM-DD') === formattedDate;
        });
      }
  
      if (data.posts?.utemezve?.length > 0) {
        utemezettPosts = data.posts.utemezve.filter((post: any) => {
          return dayjs(post.date).format('YYYY-MM-DD') === formattedDate;
        });
      }
  
      if (data.posts?.publikalt?.length > 0) {
        publicPosts = data.posts.publikalt.filter((post: any) => {
          return dayjs(post.date).format('YYYY-MM-DD') === formattedDate;
        });
      }
  
      if (data.notes?.length > 0) {
        allNotes = data.notes.filter((note: any) => {
          return dayjs(note.date).format('YYYY-MM-DD') === formattedDate;
        });
      }
  
      if (data.campaigns?.length > 0) {
        allCampaigns = data.campaigns.filter((campaign: any) => {
          return dayjs(campaign.date).format('YYYY-MM-DD') === formattedDate;
        });
      }
  
      if (data.approvals?.length > 0) {
        allApprovals = data.approvals.filter((approval: any) => {
          return dayjs(approval.date).format('YYYY-MM-DD') === formattedDate;
        });
      }
  
      return (
        <ul className="events" style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          {/* Render vazlat posts */}
          {vazlatPosts.map((post: any) => (
            <ShowTooltip key={post._id} title="Vázlat poszt" content={post.title.slice(0,20) + '... | ' + dayjs(post.date).format('MM.DD HH:mm') + ' | ' + post.platform}>
              <li className="calendar-list-item">
                <Stack direction="row" gap={1} alignItems="center" onClick={() => navigate(`/posts/show/${post._id}`)}>
                    <CalendarMonthOutlined sx={{ color: '#925FFF' }}/>
                    {post.platform === "facebook" ? 
                    <Facebook sx={{ color: '#1e90ff' }}/>
                  :
                    <Instagram sx={{ color: '#FF8380'}}/>
                  }
                    <img src={post.thumbnail ?? okt_placeholer} alt="post media attachment"
                    style={{ borderRadius: '50%', width: '20px', height: '20px' }} />
                    {/* {post.title.slice(0,16)} */}
                    {dayjs(post.date).format('HH:mm')}
                </Stack>
              </li>
            </ShowTooltip>
          ))}
  
          {/* Render utemezett posts */}
          {utemezettPosts.map((post: any) => (
            <ShowTooltip key={post._id} title="Ütemezett poszt" content={post.title.slice(0,20) + '... | ' + dayjs(post.date).format('MM.DD HH:mm') + ' | ' + post.platform}>
              <li className="calendar-list-item">
                <Stack direction="row" gap={1} alignItems="center" onClick={() => navigate(`/posts/show/${post._id}`)}>
                    <AccessTimeOutlined sx={{ color: '#dd4736' }}/>
                    {post.platform === "facebook" ? 
                    <Facebook sx={{ color: '#1e90ff' }}/>
                  :
                    <Instagram sx={{ color: '#FF8380'}}/>
                  }
                    <img src={post.thumbnail ?? okt_placeholer} alt="post media attachment"
                    style={{ borderRadius: '50%', width: '20px', height: '20px' }} />
                    {/* {post.title.slice(0,16)} */}
                    {dayjs(post.date).format('HH:mm')}
                </Stack>
              </li>
            </ShowTooltip>
          ))}
  
          {/* Render public posts */}
          {publicPosts.map((post: any) => (
            <ShowTooltip key={post._id} title="Publikált poszt" content={post.title.slice(0,20) + '... | ' + dayjs(post.date).format('MM.DD HH:mm') + ' | ' + post.platform}>
              <li className="calendar-list-item">
                <Stack direction="row" gap={1} alignItems="center" onClick={() => navigate(`/posts/show/${post._id}`)}>
                    <PublicOutlined sx={{ color: '#96FF96' }}/>
                    {post.platform === "facebook" ? 
                    <Facebook sx={{ color: '#1e90ff' }}/>
                  :
                    <Instagram sx={{ color: '#FF8380'}}/>
                  }
                    <img src={post.thumbnail ?? okt_placeholer} alt="post media attachment"
                    style={{ borderRadius: '50%', width: '20px', height: '20px' }} />
                    {/* {post.title.slice(0,16)} */}
                    {dayjs(post.date).format('HH:mm')}
                </Stack>
              </li>
            </ShowTooltip>
          ))}
  
          {/* Render notes */}
          {allNotes.map((note: any) => (
            <ShowTooltip key={note._id} title="Jegyzet" content={note.description + ' | ' + dayjs(note.date).format('MM.DD HH:mm')}>
              <li className="calendar-list-item" style={{ backgroundColor: note.color || '#f7f7f7'}}>
                <Stack direction="row" gap={1} alignItems="center" onClick={() => navigate(`/notes/show/${note._id}`)}>
                    {note.icon}
                    {" "}
                    {note.title.slice(0, 16)}
                </Stack>
              </li>
            </ShowTooltip>
          ))}
  
          {/* Render campaigns */}
          {allCampaigns.map((campaign: any) => (
            <ShowTooltip key={campaign._id} title="Kampány" content={campaign.description + ' | ' + dayjs(campaign.date).format('MM.DD HH:mm') + ' | Státusz:' + campaign.status + ' | ' + campaign.postsCount + ' poszt'}>
              <li className="calendar-list-item">
                <Stack direction="row" gap={1} alignItems="center" onClick={() => navigate(`/campaigns/show/${campaign._id}`)}>
                    <CampaignOutlined sx={{ color: '#2426be'}}/>
                    {campaign.title.slice(0,16)}
                </Stack>
              </li>
            </ShowTooltip>
          ))}
  
          {/* Render approvals */}
          {allApprovals.map((approval: any) => (
            <ShowTooltip key={approval._id} title="Jóváhagyás" content={approval.description + ' | ' + dayjs(approval.date).format('MM.DD HH:mm') + ' | Státusz:' + approval.status + ' | ' + approval.postsCount + ' poszt'}>
              <li className="calendar-list-item">
                <Stack direction="row" gap={1} alignItems="center" onClick={() => navigate(`/previews/`)}>
                    <CheckOutlined sx={{ color: '#299d25'}}/>
                    {approval.title.slice(0,16)}
                </Stack>
              </li>
            </ShowTooltip>
          ))}
        </ul>
      );
    }
  
    return null;
  };

  const monthCellRender = (value: Dayjs) => {
    if (data) {
        const year = value.year();
        const month = value.month(); // Month is zero-indexed in Dayjs
        const monthStart = dayjs().year(year).month(month).startOf('month');
        const monthEnd = monthStart.endOf('month');

        let vazlatPosts: any;
        let utemezettPosts: any;
        let publicPosts: any;
        let allNotes: any;
        let allCampaigns: any;
        let allApprovals: any;
    
          if (data.posts?.vazlat?.length > 0) {
            vazlatPosts = data.posts.vazlat.filter((post: any) => {
              const propertyDate = dayjs(post.date);
              return propertyDate.isAfter(monthStart) && propertyDate.isBefore(monthEnd);
          }).length;
        }
      
          if (data.posts?.utemezve?.length > 0) {
            utemezettPosts = data.posts.utemezve.filter((post: any) => {
              const propertyDate = dayjs(post.date);
              return propertyDate.isAfter(monthStart) && propertyDate.isBefore(monthEnd);
          }).length;
        }
      
          if (data.posts?.publikalt?.length > 0) {
            publicPosts = data.posts.publikalt.filter((post: any) => {
              const propertyDate = dayjs(post.date);
              return propertyDate.isAfter(monthStart) && propertyDate.isBefore(monthEnd);
          }).length;
        }

          if (data.notes?.length > 0) {
            allNotes = data.notes.filter((note: any) => {
              const propertyDate = dayjs(note.date);
              return propertyDate.isAfter(monthStart) && propertyDate.isBefore(monthEnd);
          }).length;
        }
      
          if (data.campaigns?.length > 0) {
            allCampaigns = data.campaigns.filter((campaign: any) => {
              const propertyDate = dayjs(campaign.date);
              return propertyDate.isAfter(monthStart) && propertyDate.isBefore(monthEnd);
          }).length;
        }
      
          if (data.approvals?.length > 0) {
            allApprovals = data.approvals.filter((approval: any) => {
              const propertyDate = dayjs(approval.date);
              return propertyDate.isAfter(monthStart) && propertyDate.isBefore(monthEnd);
          }).length;
        }

        return (
            <ul className="events t_oktelo_normal size_14">
                {vazlatPosts > 0 && <li>💡 <span className="t_oktelo_semibold">Vázlat posztok:</span> {vazlatPosts}</li>}
                {utemezettPosts > 0 && <li>⏳ <span className="t_oktelo_semibold">Ütemezett posztok:</span>  {utemezettPosts}</li>}
                {publicPosts > 0 && <li>📩 <span className="t_oktelo_semibold">Közzétett posztok:</span>  {publicPosts}</li>}
                {allNotes > 0 && <li>🏷️ <span className="t_oktelo_semibold">Jegyzetek:</span>  {allNotes}</li>}
                {allApprovals > 0 && <li>✅ <span className="t_oktelo_semibold">Jóváhagyások: </span>  {allApprovals}</li>}
                {allCampaigns > 0 && <li>📢 <span className="t_oktelo_semibold">Kampányok:</span>  {allCampaigns}</li>}
            </ul>
        );

    } else {
        // Return null if data is not yet available
        return null;
    }
};

const cellRender = (current: any, info: any) => {
  if (info.type === 'date') return dateCellRender(current);
  if (info.type === 'month') return monthCellRender(current);
  return info.originNode;
};

const drawNoteDrawer = () => {
  return (
    <Drawer title="Notes & Events" onClose={onClose} open={open} zIndex={9999}>
      <Stack direction="row" gap={1} alignItems='center' mb={2}>
    <button className="home-action-button b925" onClick={() => showModal()}>
      Új jegyzet
    </button>
    </Stack> 
    {data && data.notes && data.notes.length > 0 && data.notes.map((note: NotesProps) => (
      <Stack key={note._id} gap={1} alignItems='flex-start' mb={2} borderBottom='1px solid #f6f6f6' py={1} width="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Tag key={note._id} bordered={false} color={note.color} className='tag_oktelo t_oktelo_medium size_12 darkblue' icon={note.icon}> {note.title}</Tag>
        <Button className="b_oktelo_red" onClick={() => handleRemoveNote(note._id)} sx={{ width: 'auto !important', padding: 0 }}>Törlés</Button>
        </Stack>   
      <Typography key={note._id} className='t_oktelo_normal size_12 darkblue'>{note.description}</Typography>
      <Typography key={note._id} className="t_oktelo_normal size_12 darkblue">{dayjs(note.date).format('LLL')}</Typography>
      </Stack>
      ))}
  </Drawer>
  )
}

  
  if (loading) return (
    <Box style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <CircularProgress sx={{color: "#925FFF", fontSize: "32px"}} />
    </Box>);

  return (
    <ConfigProvider
      theme={{
        components: {
          Calendar: {
            itemActiveBg: '#f8f8f8',
            colorPrimary: '#925FFF'
          },
        },
      }}
    >
      <Box width="100%">
        {open && drawNoteDrawer()}
        <Grid container spacing={2} mb={2}>
          <Grid item xl={6}>
            <Typography className="size_24 t_oktelo_bold darkblue">
              Tartalom naptár
            </Typography>
            <Typography className="size_14 t_oktelo_normal darkblue">
              Naptárba helyezett vázlat posztok, ütemzett posztok, közzétettek, kampányok, jegyzetek és jóváhagyások.
            </Typography>
          </Grid>
          <Grid item xl={6} display="flex" alignItems="flex-end">
          <Stack display="flex" flexDirection="row" alignContent="center" alignItems="flex-end" width="100%" justifyContent="flex-end" gap={1}>
              <button className="home-action-button b925" onClick={showDrawer}>
                Jegyzeteim
              </button>
              <button className="home-action-button bc6a" onClick={() => showModal()}>
                Új jegyzet
              </button>
              <NotesModal
                visible={visible}
                onCreate={handleCreate}
                onCancel={handleCancel}
              />
          </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={0} xs={0} key="desktop" sx={{ display: {xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}}>
            <Box className="oktelo-box" width="100%">
              <Calendar 
              cellRender={cellRender} 
              locale={customLocale}
              />
            </Box>
          </Grid>
          <Grid item xl={0} lg={0} md={0} sm={12} xs={12} key="mobile" sx={{ display: {xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}}>
            <Box className="oktelo-box" width="100%">
            {data && <MobileCalendar data={data}/>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ConfigProvider>

    
  );
};

export default CalendarPage;
