import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom';
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookSDKProvider } from "utils/FBSDK";
import { NotificationProvider } from './utils/NotificationProvider';
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import reportWebVitals from "./reportWebVitals";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import "index.css";
import "./components/styles/responsive.css";


const queryClient = new QueryClient();
const container = document.getElementById("root") as HTMLElement;
if (!container) {
  console.error('Root container is missing');
}
const root = createRoot(container);

const fetchData = async () => {
  const response = await fetch('https://jsonplaceholder.typicode.com/posts/1');
  return response.json();
};

const DataComponent = () => {
  const { data, error, isLoading } = useQuery(['data'], fetchData);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return <div>Data: {JSON.stringify(data)}</div>;
};

root.render(

  <QueryClientProvider client={queryClient}>
  <GoogleOAuthProvider clientId="157858432316-3bq7kcm236ka5dmnve4hrbtt833590ff.apps.googleusercontent.com">
    <FacebookSDKProvider>
      <NotificationProvider>

          <App />
          
         
    </NotificationProvider>
    </FacebookSDKProvider>
  </GoogleOAuthProvider> 
  </QueryClientProvider>

);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
