import React, { useState } from 'react';
import { Modal } from 'antd';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface AnalyticsModalProps {
    brief: string;
    tips: string;
    visible: boolean;
    onClose: () => void;
}

const AnalyticsModal: React.FC<AnalyticsModalProps> = ({ brief, tips, visible, onClose }) => {

    const onCancel = () => {
        onClose();
    };

    return (
        <Modal
            open={visible}
            title="✨ Oktelo AI válaszolt neked!"
            cancelText="Bezárás"
            onCancel={onCancel}
            onOk={(onCancel)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText={"Tovább"}
            okButtonProps={{ className: 'okt_modal_button'}}
            centered
        >
            <Stack direction="column" gap={2} my={4}>
                <Stack gap={1}>
                <Typography className="t_oktelo t_highlight">🔎 Rövid elemzés:</Typography>
                <Typography className="t_oktelo t_misc" sx={{ textAlign: 'justify'}}>{brief}</Typography>
                </Stack>
                <Stack gap={1}>
                <Typography className="t_oktelo t_highlight">✔️ Tippek a javításhoz:</Typography>
                <Typography className="t_oktelo t_misc" sx={{ textAlign: 'justify'}}>{tips}</Typography>
                </Stack>
            </Stack>

        </Modal>
    );
};

export default AnalyticsModal;
