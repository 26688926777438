import { Box, Typography, Stack, Avatar } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import { okt_placeholer } from "assets";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { PreviewProps } from "interfaces/common";


const InstagramPreview:React.FC<PreviewProps> = ({ selectedPage, postImage, areaFieldValue, pageName }) => {

    return (
    <Box display="flex" flexDirection="column" gap={2}> 

      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Box flexDirection="row" flex={1} display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
        <Avatar/>

         <Typography fontSize={16} fontWeight={500}>
          {pageName? pageName : 'Page name'}
        </Typography>
        </Box>
        <MoreHorizIcon/>
      </Stack>
    {/* Image preview */}
    <Stack
        sx={{
          width: '100%',
          position: 'relative',
          paddingTop: '100%', // 1:1 aspect ratio
          overflow: 'hidden',
          marginTop: '10px',
        }}
      >
        <img
          src={postImage.url ? postImage.url : okt_placeholer}
          alt={postImage ? postImage.name : 'Placeholder'}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Cover the entire area
          }}
        />
      </Stack>        
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Box flexDirection="row" flex={1} display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
        <FavoriteBorderIcon/><ModeCommentOutlinedIcon/><NearMeOutlinedIcon/>
        </Box>
        <BookmarkBorderOutlinedIcon/>
      </Stack>
      <Stack>
        <Typography fontSize={16} fontWeight={500}>10,329 likes</Typography>
      </Stack>
      <Stack>
        <Typography fontSize={16}><span style={{fontWeight: '500'}}>{pageName? pageName : 'Page name'}</span> {areaFieldValue}</Typography>
      </Stack>
    </Box>
    )
}

export default InstagramPreview