import React from 'react';
import { Stack, Typography } from '@mui/material';

interface ActionCardProps {
  title: string;
  description: string;
  status: string;
  buttonText: string;
  buttonClass?: string; // Optional prop
  onClick: () => void;
  buttonDisabled?: boolean;
}

const ActionCard: React.FC<ActionCardProps> = ({ title, description, status, buttonText, buttonClass, onClick, buttonDisabled }) => {
  return (
    <Stack
      direction="column"
      gap={2}
      p={2}
      border="1px solid #d9d9d9"
      borderRadius="8px"
      bgcolor="#f9f9f9"
      boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
      justifyContent="space-between"
    >
      <Typography className="t_oktelo_medium size_16 darkblue">
        {title}
      </Typography>
      <Typography className="t_oktelo_medium size_14 t_purple">
        {status}
      </Typography>
      <Typography className="t_oktelo_normal size_14 miscgray">
        {description}
      </Typography>
      <button
        className={`${buttonClass} home-action-button`}
        onClick={onClick}
        disabled={buttonDisabled ?? false}
      >
        {buttonText}
      </button>
    </Stack>
  );
};

export default ActionCard;
