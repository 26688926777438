import React, { useEffect } from "react";
import { useThemedLayoutContext } from "@refinedev/mui";
import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import type { IconButtonProps } from "@mui/material/IconButton";

const HamburgerIcon = (props: IconButtonProps) => (
  <IconButton color="inherit" aria-label="open drawer" edge="start" {...props} sx={{ marginLeft: '0px !important'}}>
    <Menu />
  </IconButton>
);

export const HamburgerMenu: React.FC = () => {
  const {
    siderCollapsed,
    setSiderCollapsed,
    mobileSiderOpen,
    setMobileSiderOpen,
  } = useThemedLayoutContext();


  return (
    <>
      {/* For large screens when sider is collapsed */}
      {siderCollapsed && (
        <HamburgerIcon
          onClick={() => setSiderCollapsed(!siderCollapsed)}
          sx={{
            display: { xs: 'none !important', md: 'none' },
          }}
        />
      )}

      {/* For mobile screens when mobile sider is closed */}
      {!mobileSiderOpen && (
        <HamburgerIcon
          onClick={() => setMobileSiderOpen(!mobileSiderOpen)}
          sx={{
            display: { xs: 'flex', md: 'none', lg: 'none', xl: 'none' },
          }}
        />
      )}
    </>
  );
};
