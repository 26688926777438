import React, {useState, useEffect} from "react";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";
import { ApexOptions } from "apexcharts";
import dayjs from "dayjs";

interface MetricData {
    end_time: string;
    value: number;
  }

  interface ChartData {
    [metricName: string]: MetricData[];
  }

  interface ChartProps {
    chartData: ChartData | undefined;
}

const WeeklyPerformance: React.FC<ChartProps> = ({ chartData }) => {
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});

    const metricNameMapping: { [key: string]: string } = {
        page_actions_post_reactions_like_total: "Poszt likeok",
        page_impressions: "Impressziók",
        page_views_total: "Oldal látogatók",
        impressions: "Impressziók",
        reach: "Elért felhasználók",
        profile_views: "Oldal látogatók",
        page_post_engagements: "Elköteleződések száma",
        page_impressions_unique: "Elért felhasználók",
    };


    useEffect(() => {
        if (chartData) {
            // Extracting series data
            const seriesData = Object.keys(chartData).map(metricName => ({
                name: metricNameMapping[metricName] || metricName,
                data: chartData[metricName].map(item => item.value),
            }));
    
            // Extracting options data
            const firstMetricValues = chartData[Object.keys(chartData)[0]];
            const optionsData: ApexOptions = {
                xaxis: {
                    categories: firstMetricValues.map(value => dayjs(value.end_time).format('MM.DD.')),
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0.25,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'area', 
                },
                colors: ["#925FFF", "#F08080", "#0098d4", "#ccff5f"],
                plotOptions: {
                    bar: {
                        borderRadius: 2,
                        horizontal: false,
                        columnWidth: "55%",
                    },
                    area: {
                        
                    }
                },
                markers: {
                    size: 0
                },
                grid: {
                    show: false,
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0, 
                },
                
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                tooltip: {
                    y: {
                        formatter(val: number) {
                            return `${val}`;
                        },
                    },
                },
            };
    
            setSeries(seriesData);
            setOptions(optionsData);

        }
    }, [chartData]);
   return (
    <Box width="100%">
    <ReactApexChart
        series={series}
        type="area"
        height={350}
        options={options}
        />
    </Box>
 )

}


export default WeeklyPerformance;